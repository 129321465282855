import React, { useState } from "react";
import Layout from "../../common/Layout";
import DynamicTitle from "../../common/DynamicTitle";
import TableContainer from "../../common/TableContainer";
import { Link, useLocation } from "react-router-dom";
import ActionDropdown from "../../common/ActionDropdown";
import Pagination from "../../atoms/Pagination";
import { useHistory } from "react-router-dom";
import { useFetchClientEngineeers } from "../../../../services/client/query";
// import RateAndReviewForm from "../forms/RateAndReviewForm";
import { removeEmptyObj } from "../../../../utils/helperFunctions";
import { StatusBtn } from "../../styles/statusBtn";
import FormModal from "../../form/FormModal";
import RateAndReviewForm from "../../form/RateAndReviewForm";
import { StyledDashboardPage } from "../../styles/styleDashboard";

const ClientEngineerPage2 = ({ pageType }) => {
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(window.location.search);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState("");
  const [assignmentId, setAssignmentId] = useState(null);
  const initialValues = {
    page: Number(queryParams.get("page")) || 1,
    size: 12,
    keyword: "",
  };
  const [filterOptions, setFilterOptions] = useState(initialValues);
  const [paramsValues, setParamsValues] = useState(
    removeEmptyObj(initialValues, "size") || {}
  );

  const handleImageUrl = (item) => !item || item.includes("null");

  const {
    data: clientEngineers,
    refetch: refetchClientEngs,
    isLoading,
  } = useFetchClientEngineeers(filterOptions);

  const handleModal = (id) => {
    setIsModalOpen(!isModalOpen);
    setAssignmentId(id);
  };

  const increment = () => {
    setParamsValues({ ...paramsValues, page: paramsValues.page + 1 });
    setFilterOptions({ ...filterOptions, page: filterOptions.page + 1 });

    const params = new URLSearchParams({
      ...paramsValues,
      page: paramsValues.page + 1,
    });
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const decrement = () => {
    setParamsValues({ ...paramsValues, page: paramsValues.page - 1 });
    setFilterOptions({ ...filterOptions, page: filterOptions.page - 1 });

    const params = new URLSearchParams({
      ...paramsValues,
      page: paramsValues.page - 1,
    });
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const newPage = (n) => {
    setParamsValues({ ...paramsValues, page: n });
    setFilterOptions({ ...filterOptions, page: n });

    const params = new URLSearchParams({ ...paramsValues, page: n });
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  return (
    <Layout navType="client" pageLoading={isLoading}>
      <DynamicTitle title="Engineers | Fellowship Product" />
      <StyledDashboardPage>
      <div className="page-header">
          <h4>My Engineers</h4>
        </div>
        <TableContainer>
          <table style={{ width: "100%" }}>
            <thead style={{ width: "100%" }}>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Email Address</th>
                <th>Phone Number</th>
                <th>Status</th>
                <th>Credit Allocation</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody style={{ width: "100%" }}>
              {clientEngineers?.data?.content.map((item, index) => (
                <tr className="" key={item.id + index + item.name}>
                  <td>
                    <img
                      src={
                        handleImageUrl(item.thumbnailUrl)
                          ? "/images/default-avatar.png"
                          : item.thumbnailUrl
                      }
                      alt="engineer"
                      className="engineer-img"
                    />
                  </td>
                  <td>
                    <Link
                      className="clickable"
                      to={{
                        pathname: `/client/engineers/${item.engineerId}`,
                        state: {
                            assignmentId: item.id,
                        }
                      }}
                    >
                      {item.name}
                    </Link>
                  </td>
                  <td>{item.email}</td>
                  <td>{item.telephoneNo}</td>
                  <td>
                    {item.status ? (
                      <StatusBtn
                        className="status-bg"
                        status={item.status?.toLowerCase()}
                      >
                        {item.status}
                      </StatusBtn>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>{item.creditAllocated || "-"}</td>
                  <td>
                    <ActionDropdown>
                      <p
                        onClick={() => {
                          handleModal(item.id);
                          setActiveModal("rate-review");
                        }}
                        className="rate-review"
                      >
                        Rate & Review
                      </p>
                    </ActionDropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
      </StyledDashboardPage>
      {clientEngineers && (
        <div className="page-parent">
          <Pagination
            currentPage={clientEngineers?.data.number + 1}
            totalClients={clientEngineers?.data.numberOfElements}
            metaData={clientEngineers?.data}
            page={clientEngineers?.data.number + 1}
            newPage={newPage}
            increment={increment}
            decrement={decrement}
            className="pagination"
          />
        </div>
      )}
      {isModalOpen && (
        <div className="form-modal">
          <FormModal centered handleModal={handleModal}>
            {activeModal === "rate-review" && (
              <RateAndReviewForm
                handleFetch={refetchClientEngs}
                pageType={pageType}
                assignmentId={assignmentId}
              />
            )}
          </FormModal>
        </div>
      )}
    </Layout>
  );
};

export default ClientEngineerPage2;
