import React from "react";
import Select from "react-select";
import { adminSubscriptionView } from "../common/listingData";
import { CircleLoader } from "react-spinners";
import { BiSearch } from "react-icons/bi";
import { FilterFields } from "./style";

const SubscriptionFilter = ({
  handleFilterChange,
  filterOptions,
  selectedFilter,
}) => {
  return (
    <FilterFields className="engineer-filters-wrapper">
      <div className="search-wrap mt-4">
        <BiSearch className="search-icon-engr" size={15} />
        <input
          type="text"
          placeholder="Search for Subscriptions"
          className="input-search"
          onChange={(e) => handleFilterChange("search", e.target.value)}
        />
        {selectedFilter === "keyword" && (
          <div className="input-spinner-select">
            <CircleLoader color="green" size="10px" />
          </div>
        )}
      </div>
      <div className="select-wrap mt-4">
        <Select
          className="my-select"
          value={adminSubscriptionView.options.filter(
            (option) => option.value === filterOptions.status
          )}
          options={adminSubscriptionView.options}
          placeholder="Status"
          onChange={(e) => handleFilterChange("status", e.value)}
        />
        {selectedFilter === "status" && (
          <div className="input-spinner-filter">
            <CircleLoader color="green" size="10px" />
          </div>
        )}
      </div>
    </FilterFields>
  );
};

export default SubscriptionFilter;
