import React from 'react'
import styled from "styled-components";

const FormTitleSection = ({title, onClick}) => {
    return (
        <StyledFormTitleSection>
            <p className="formTitle form-font">{title}</p>
            <img src="/images/close-icon.svg" alt="close icon" onClick={onClick} className="closeIcon"/>
        </StyledFormTitleSection>
    )
}

const StyledFormTitleSection = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 1.6rem 1.5rem 1.6rem;
    border-bottom: 1px solid #F0EFF6;
    .formTitle{
        font-size: 2rem;
        font-weight: 800;
        line-height: 3rem;
        color: #000000;
    }
    .closeIcon{
        width: 1.5rem;
        padding-bottom: .7rem;
        cursor: pointer;
    }

`;

export default FormTitleSection
