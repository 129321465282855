import { apiGet } from "../../utils/apiHelpers";

export const fetchRegisteredTotalNum = async (params) => {
  const [startDate, endDate] = params.queryKey;
  try {
    const res = await apiGet(
      `/client-registration/analytics/tracking?startDate=${startDate}&endDate=${endDate}`
    );

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchSignUpData = async (params) => {
  const [startDate, endDate] = params.queryKey;
  try {
    const res = await apiGet(
      `/client-registration/tracking?startDate=${startDate}&endDate=${endDate}`
    );

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchProfileViewsData = async (params) => {
  const [startDate, endDate, orgId] = params.queryKey;
  try {
    const res = await apiGet(
      `/page-view/tracking?startDate=${startDate}&endDate=${endDate}&organisation=${orgId}`
    );

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchSelectedEngRequests = async (params) => {
  const [startDate, endDate, orgId] = params.queryKey;
  try {
    const res = await apiGet(
      `/catalog/client-request/number-of-engineer/tracking?startDate=${startDate}&endDate=${endDate}&organisation=${orgId}`
    );

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchChooseEngineersForMeData = async (params) => {
  const [startDate, endDate, orgId] = params.queryKey;
  try {
    const res = await apiGet(
      `/admin/assignments/requests/tracking?startDate=${startDate}&endDate=${endDate}&organisation=${orgId}`
    );

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchChooseForMeAndSelectEngineerData = async (params) => {
  const [startDate, endDate, orgId] = params.queryKey;
  try {
    const res = await apiGet(
      `/catalog/client-request/tracking?startDate=${startDate}&endDate=${endDate}&organisation=${orgId}`
    );

    return res.data;
  } catch (err) {
    console.log(err);
  }
};
