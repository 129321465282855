import React from "react";
import styled from "styled-components";

const TotalElements = ({ element, totalElements }) => {
  return (
    <TotalElementDiv>
        {totalElements} {element}(s) found
    </TotalElementDiv>
  );
};

const TotalElementDiv = styled.div`
    border-top: 1px solid rgba(100, 115, 130, 0.1);
    border-bottom: 1px solid rgba(100, 115, 130, 0.1);
    padding: 12px 5px;
    margin-top: 26px;
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    color: #21334f;
    width: 100%;
`;

export default TotalElements;
