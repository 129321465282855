import React, { useState } from "react";
import Layout from "../common/Layout";
import DynamicTitle from "../common/DynamicTitle";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import TableContainer from "../common/TableContainer";
import ActionDropdown from "../common/ActionDropdown";
import FormModal from "../form/FormModal";
import useDialog from "../../../utils/useDialog";
import ProtectedComponents from "../local-routes/ProtectedComponents";
import { useFetchedBillingRateList } from "../../../services/billing/query";
import { deleteBillingRate } from "../../../services/billing/api";
import UpdateBilling from "../form/UpdateBilling";
import AddBilling from "../form/AddBilling";

const BillingPage = ({ pageType }) => {
  const { deleteItemDialog } = useDialog();

  const {
    data: billingRateList,
    refetch,
    isLoading,
  } = useFetchedBillingRateList();

  const [billingRateDetails, setBillingRateDetails] = useState(null);

  const [addBillingRateModal, setAddBillingRateModal] = useState(false);

  const [editBillingRateModal, setEditBillingRateModal] = useState(false);

  const handleAddBillingRateModal = (e) => {
    setAddBillingRateModal(!addBillingRateModal);
  };

  const handleEditBillingRate = (e) => {
    setEditBillingRateModal(!editBillingRateModal);
  };

  const handleDelete = (id, name) => {
    deleteItemDialog(deleteBillingRate, id, name, refetch);
  };

  return (
    <Layout pageLoading={isLoading} navType="admin">
      <DynamicTitle title="Billing Rate | Fellowship Product" />

      <BillingLayout>
        <div className="billing-header">
          <h1>Billing Rate</h1>
          <ProtectedComponents permission={["manageBillingRate"]}>
            <MyButton
              onClick={() => handleAddBillingRateModal()}
              className="btn-doc"
            >
              Add Billing Rate
            </MyButton>
          </ProtectedComponents>
        </div>
        {
          <TableContainer>
            <table style={{ width: "100%" }}>
              <thead style={{ width: "100%" }}>
                <tr>
                  <th>Amount</th>
                  <th>Engineer Level</th>
                  <ProtectedComponents permission={["manageBillingRate"]}>
                    <th>Action</th>
                  </ProtectedComponents>
                </tr>
              </thead>
              <tbody style={{ width: "100%" }}>
                {billingRateList?.map((item, index) => (
                  <tr className="" key={index + 1}>
                    <td>{item.amount}</td>
                    <td>{item.engineerLevel}</td>
                    <ProtectedComponents permission={["manageBillingRate"]}>
                      <td>
                        <ActionDropdown>
                          <p
                            onClick={() => {
                              handleEditBillingRate();
                              setBillingRateDetails(item);
                            }}
                          >
                            Edit
                          </p>
                          <p
                            onClick={() => {
                              handleDelete(item.id, item.engineerLevel);
                            }}
                          >
                            Delete
                          </p>
                        </ActionDropdown>
                      </td>
                    </ProtectedComponents>
                  </tr>
                ))}
                {billingRateList?.length === 0 && (
                  <tr>
                    <td colSpan="6" className="no-data">
                      No Data Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </TableContainer>
        }

        {addBillingRateModal && (
          <div className="form-modal">
            <FormModal className="form" handleModal={handleAddBillingRateModal}>
              {
                <AddBilling
                  handleModal={handleAddBillingRateModal}
                  refetch={refetch}
                />
              }
            </FormModal>
          </div>
        )}
        {editBillingRateModal && (
          <div className="form-modal">
            <FormModal className="form" handleModal={handleEditBillingRate}>
              {
                <UpdateBilling
                  handleModal={handleEditBillingRate}
                  refetch={refetch}
                  billingRateDetails={billingRateDetails}
                />
              }
            </FormModal>
          </div>
        )}
      </BillingLayout>
    </Layout>
  );
};

const BillingLayout = styled.div`
  .billing-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 4.8rem;
    margin-bottom: 1.1rem;
  }
  .billing-header h1 {
    font-weight: 700;
    font-size: 2.4rem;
  }
  .form-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
  }
`;

export default BillingPage;
