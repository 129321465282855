import React from "react";
import { Line } from "react-chartjs-2";
import Styled from "styled-components";

const Linegraph = ({
  title,
  theChart,
}) => {
  const config = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      title: {
        display: false,
        text: { title },
        font: {
          size: 20,
        },
      },
      legend: {
        display: false,
        labels: {
          boxHeight: 0,
          boxWidth: 15,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: false,
          text: "Months",
        },
      },
      y: {
        display: true,
        min: 0,
        ticks: {
          stepSize: 2,
        },
        grid: {
          borderDash: [4, 2],
          color: "#D7DBDE",
        },
      },
    },
  };

  return (
    <StyledGraph>
      <div className="header">
        <div>
          <p className="title">{title}</p>
        </div>
      </div>
      <div className="graph">
        <div className="p-4">
          <Line data={theChart} options={config} />
        </div>
      </div>
    </StyledGraph>
  );
};

const StyledGraph = Styled.div`
  overflow: auto;
  width: 100%;
  background: #fff;
  position: relative;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;


  .header {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 0px;
  }

  .header .title{
    font-size: 1.8rem;
  }

  select {
    padding: 5px 20px 5px 2px;
    border: 1px solid #f1f1f1;
    font-size: 12px;
  }
  @media only screen and (max-width: 770px){
    
  }
`;
export default Linegraph;
