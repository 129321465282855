export const catchError = (err) => {
    let errMsg = "";
  
    if (!err?.response?.data) {
      if (err?.response?.status === 400) {
        errMsg = process.env.REACT_APP_IS_PROD
            ? "error processing request at this time. pls contact the admin"
            : err.message
      } else {
        errMsg = err.message
      }

      return errMsg
    }

    if (err?.response?.data?.subErrors) {
      errMsg = err?.response?.data?.subErrors[0]?.message
      return errMsg
    }

    errMsg =
      err?.response?.data?.message ||
        err?.response?.data?.error ||
        err.message ||
        "error processing request at this time. pls contact the admin"
  
    return errMsg;
};
  