import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { apiGet, apiPut } from "../../../../utils/apiHelpers";
import { options } from "../../form/formSelectOptions";
import { toast } from "react-toastify";
import FormImgComponent from "../../form/enterpriseFormInputs/FormImgComponent";

const Information = ({ pageType }) => {
  const [profileImg, setProfileImg] = useState({});
  const [profileImgError] = useState("");
  const [imgUrl, setImgUrl] = useState(null);
  const [initialValues] = useState({
    email: "",
    firstName: "",
    lastName: "",
    status: "",
    phone: "",
  });

  const pictureType = ["jpg", "jpeg", "gif", "png"];

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email format").required("*Required"),
    firstName: Yup.string().required("*Required"),
    lastName: Yup.string().required("*Required"),
    status: Yup.string().required("*Required"),
    phone: Yup.string()
      .required("*Required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .max(14, "Must not be more than 14 digits")
      .min(11, "Must not be less than 11 digits"),
  });
  const new_token = localStorage.getItem("token");

  let config = {
    headers: {
      Authorization: `Bearer ${new_token}`,
      "Content-type": "application/json; charset=UTF-8",
    },
  };

  const updateAdminProfile = async (formData) => {
    try {
      if (pageType === "admin") {
        await apiPut(`/admin/user/account `, formData, config);
        toast.success("You've successfully updated profile");
      }
      if (pageType === "client") {
        await apiPut(`/private/user/account `, formData, config);
        toast.success("You've successfully updated profile");
      }
    } catch (err) {}
  };
  let multipartConfig = {
    headers: {
      Authorization: `Bearer ${new_token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const updateAdminProfilePic = async () => {
    let formData = new FormData();

    formData.append("profile_pic_upload", profileImg);
    try {
      if (pageType === "admin") {
        const res = await apiPut(
          `/admin/user/account/profilePic`,
          formData,
          multipartConfig,
          false
        );
        if (res.status === 200) {
          toast.success("You've successfully updated profile picture");
        }
      }
      if (pageType === "client") {
        const res = await apiPut(
          `/private/user/account/profilePic`,
          formData,
          multipartConfig,
          false
        );
        if (res.status === 200) {
          toast.success("You've successfully updated profile picture");
        }
      }
    } catch (err) {}
  };

  const fetchUserProfile = async () => {
    try {
      if (pageType === "admin") {
        let response = await apiGet(`/admin/user/account`);
        let res = response.data.data;
        setImgUrl(res.profilePic);
        const fields = ["email", "firstName", "lastName", "phone", "status"];
        fields.forEach((field) =>
          formik.setFieldValue(field, res[field], false)
        );
      }

      if (pageType === "client") {
        let response = await apiGet(`/private/user/account`);
        let res = response.data.data;
        setImgUrl(res.profilePic);
        const fields = ["email", "firstName", "lastName", "phone", "status"];
        fields.forEach((field) =>
          formik.setFieldValue(field, res[field], false)
        );
      }
    } catch (err) {
      toast.error(err);
    }
  };
  useEffect(() => {
    fetchUserProfile();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (profileImg.name) {
      updateAdminProfilePic();
    }
    // eslint-disable-next-line
  }, [profileImg]);

  const onSubmit = (values) => {
    updateAdminProfile(values);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <StyledDiv>
      <h3 className="profile">Profile Information</h3>
      <form onSubmit={formik.handleSubmit}>
        <div className="content mt-4">
          {/* <div className="grid-container">
            <div className="personal-info-div">
              <img
                className="personal-info"
                src={imageUrl}
                alt="info-icon"
              />
            </div>
            <div className="row change-row">
              <div className="col-md-1">
                <img
                  className="save-icon"
                  src="/images/save-icon.svg"
                  alt="save-icon"
                />
              </div>
              <div className=" change col-md-11 px-0">Change profile image</div>
            </div>
          </div> */}
          <FormImgComponent
            pictureType={pictureType}
            // label="Update picture"
            type="file"
            error={profileImgError}
            selectedFile={profileImg}
            setSelectedFile={setProfileImg}
            acceptTypes={`jpg, jpeg, gif, png. Size Limit 2mb`}
            updatePic={updateAdminProfilePic}
            imgUrl={imgUrl}
            pictureIcon="/images/edit-icon.svg"
            className="upload-icon"
          />
          <div className="row mt-5">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="firstname">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="firstname"
                  name="firstName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  error={
                    formik.touched.firstName && formik.errors.firstName
                      ? formik.errors.firstName
                      : null
                  }
                />
                <span className="error">{formik.errors.firstName}</span>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="lastname">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="lastname"
                  name="lastName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  error={
                    formik.touched.lastName && formik.errors.lastName
                      ? formik.errors.lastName
                      : null
                  }
                />
                <span className="error">{formik.errors.lastName}</span>
              </div>
            </div>
          </div>

          <div className="mt-3">
            <div className="form-group">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={
                  formik.touched.email && formik.errors.email
                    ? formik.errors.email
                    : null
                }
                disabled
              />
            </div>
          </div>

          <div className="mt-3">
            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="text"
                className="form-control"
                id="phone"
                name="phone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                error={
                  formik.touched.phone && formik.errors.phone
                    ? formik.errors.phone
                    : null
                }
              />
            </div>
            <span className="error">{formik.errors.phone}</span>
          </div>

          <div className="row mt-5 ml-2 mr-2">
            <div className="form-group" style={{ width: "100%" }}>
              <label htmlFor="status" className="form-font form-label">
                Status
              </label>
              <div className="select-field">
                <select
                  id="status"
                  name="status"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.status}
                  error={
                    formik.touched.status && formik.errors.status
                      ? formik.errors.status
                      : null
                  }
                  disabled
                >
                  {options.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <button type="submit">Save Changes</button>
        </div>
      </form>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  color: rgba(33, 51, 79, 1);
  .content {
    background: #fff;
    padding: 2.5rem 2.5rem 8.5rem 2.5rem;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 7fr;
  }
  .save-icon {
    height: 2rem;
  }
  .upload-icon {
    width: 21.15px;
    height: 21.15px;
    margin-top: 2.5rem;
    margin-left: 4rem;
    color: #34a853;
    cursor: pointer;
  }
  .personal-info-div {
    width: 6rem;
  }
  .change {
    font-size: 1.5rem;
    font-weight: 500;
    color: rgba(52, 168, 83, 1);
  }
  .change-row {
    margin-top: 2rem;
  }

  form {
    margin-top: 2rem;
  }
  .main-password {
    position: relative;
  }

  .profile {
    font-size: 2.3rem;
    font-weight: 600;
  }
  label {
    font-size: 1.8rem;
    font-weight: 600;
    opacity: 0.9;
  }
  input {
    height: 42px;
    margin-top: 1rem;
    font-size: 1.5rem;
  }
  button {
    float: right;
    color: #fff;
    background: linear-gradient(96.67deg, #34a853 0%, #b8d344 100%);
    border: none;
    font-size: 2rem;
    font-weight: 600;
    padding: 1rem 2rem;
  }

  .select-field {
    margin-top: 2.1rem;
    height: 4.1rem;
    border: 1px solid #ced4da;
    padding: 1rem 0rem 1.5rem 0.5rem;
  }

  select {
    display: inline-block;
    font-size: 1.6rem;
    color: rgba(33, 51, 79, 0.8);
    width: 100%;
  }
  .error {
    color: #ef5350;
    font-size: 12px;
    display: block;
  }
  @media screen and (min-width: 320px) and (max-width: 768px) {
    .change {
      font-size: 1.2rem;
      font-weight: 500;
      color: rgba(52, 168, 83, 1);
    }
    .change-row {
      margin-top: 2rem;
      margin-left: 2rem;
    }
  }
`;

export default Information;
