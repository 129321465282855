import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { apiDelete, apiGet, apiPut } from "../../../../utils/apiHelpers";
import AddUserForm from '../../form/AddUserForm';
import FormModal from '../../form/FormModal';
import Pagination from "../../atoms/Pagination";
import { toast } from "react-toastify";
import ProtectedComponents from "../../local-routes/ProtectedComponents";
import useDialog from "../../../../utils/useDialog";

const ManageUsers = ({ pageType }) => {
  const { deleteItemDialog } = useDialog();

  const [activeModal, setActiveModal] = useState(false);
  const handleModal = () => setActiveModal(!activeModal);
  const [idOfTable, setIdOfTable] = useState(-1);
  const [currentPage, setCurrentPage] = useState(1);
  const num = Math.floor((0.6 * window.screen.height) / 90);
  const [dataPerPage] = useState(num - 1);
  const [currentData, setCurrentData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [updateId, setUpdateId] = useState(0);
  const [metaData, setMetaData] = useState({});
  const [page, setPage] = useState(1);


  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIdOfTable(-1);
    }
  };
  const showModal = (index) => {
    setIdOfTable(index);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const increment = (e) => {
    e.preventDefault()
    setPage(page + 1)
  };

  const decrement = (e) => {
    e.preventDefault();
    setPage(page - 1)
  };

  const newPage = (n) => {
    setPage(n)
  };

  const fetchClientSingleManager = async () => {
    try {
      let response = await apiGet(
        `/private/users?page=${page}&size=5`,
        {},
        "client"
      );

      setMetaData({
        totalPages: response.data.data.totalPages,
        first: response.data.data.first,
        last: response.data.data.last,
      });
      setCurrentData(response.data.data.content || []);
      
    } catch (err) {
      toast.error(err);
    }
  };

  const fetchAdminSingleManager = async () => {
    try {
      let response = await apiGet(`/admin/users?page=${page}&size=5`);
      setMetaData({
        totalPages: response.data.data.totalPages,
        first: response.data.data.first,
        last: response.data.data.last,
      });
      setCurrentData(response.data.data.content || []);
    
    } catch (err) {
      toast.error(err);
    }
  };

  useEffect(() => {
    pageType === "client"
      ? fetchClientSingleManager()
      : fetchAdminSingleManager();

    // eslint-disable-next-line
  }, [page]);

  const activateUser = async (item) => {
    const userPayload = {
      ...item,
      status: "ACTIVE"
    };

    try {
      await apiPut(`/admin/users/${item.id}/activate`, userPayload, {});
      fetchAdminSingleManager();
      toast.success("You've successfully activated user");
    } catch (err) {
      console.log({err})
    }
  };

  const deActivateUser = async (item) => {
    const userPayload = {
      ...item,
      status: "INACTIVE",
    };

    try {    
      await apiPut(`/admin/users/${item.id}/deactivate`, userPayload, {});
      fetchAdminSingleManager();
      toast.success("You've successfully deactivated user");
    } catch (err) {
      console.log({err})
    }
  };

  const deleteUser = async (item) => {
    try {
      await apiDelete(`/admin/users/${item.id}`, {});
      fetchAdminSingleManager();
    } catch (err) {
      console.log({err})
    }
  };
  const viewUser = async (item) => {
    setUpdateId(item.id);
    handleModal();
    setIsEditing(true);
  };

  const deActivateClientUser = async (item) => {
    try{
      const result = await apiPut(`/private/users/${item.id}/deactivate`);
      if(result?.data?.status === 'OK'){
        toast.success("You've successfully deactivated user");
      }
      fetchClientSingleManager()
    }catch(err){
      console.log({err})
    }
  }
  const activateClientUser = async (item) => {
    try{
      const result = await apiPut(`/private/users/${item.id}/activate`);
      if(result?.data?.status === 'OK'){
        toast.success("You've successfully activated user");
      }
      fetchClientSingleManager()
    }catch(err){
      console.log({err})
    }
  }

  return (
    <StyledDiv isEditing={isEditing}>
      <StyledClientView>
        <div>
          <div className="row">
            <div className="col-md-8  ">
              <h2 className="mt-4 manager">Manager Users</h2>
            </div>
            <ProtectedComponents permission={["manageAdminUser", "manageOrganisationUser"]}>
              <div className="col-md-4 px-0">
                <div className=" add-eng">
                  <Button
                    variant="primary"
                    className="add-btn"
                    onClick={handleModal}>
                    Add New User
                  </Button>
                </div>
              </div>
            </ProtectedComponents>
            
          </div>

          <div className="mt-5 table-container">
            <table style={{ width: "100%" }}>
              <thead style={{ width: "100%" }}>
                <tr className="grid-container" style={{ display: "flex" }}>
                  <th className="heading-name pl-4" style={{ width: "30%" }}>
                    Name
                  </th>
                  <th className="heading-name pl-4" style={{ width: "40%" }}>
                    Email address
                  </th>
                  <th className="heading-name " style={{ width: "20%" }}>
                    Phone Number
                  </th>
                  <th className="heading-name " style={{ width: "20%" }}>
                    Status
                  </th>
                  {/* <th className="heading-name ">Role</th> */}
                  <th className="heading-name " style={{ width: "10%" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="table-body" style={{ width: "100%" }}>
                {currentData.map((item, index) => (
                  <tr
                    key={index}
                    className="grid-container bg-white"
                    style={{ display: "flex" }}>
                    <td className="" style={{ width: "30%" }}>
                      <span className="ml-4 table-text-name">
                        <div
                          className="others"
                          title={"sfsdfsdfsfdsdfsdfsdfss"}>
                          {item.name}
                        </div>
                      </span>
                    </td>

                    <td style={{ width: "40%" }}>
                      <span className="ml-4 table-text-name">
                        <div className="others">{item.email}</div>
                      </span>
                    </td>
                    <td style={{ width: "20%" }}>{item.phone}</td>
                    <td style={{ width: "20%" }}>{item.status}</td>
                    {/* <td className="pl-4" style={{ width: "20%" }}>{item.role}</td> */}
                    <td
                      style={{ position: "relative", width: "10%" }}
                      onClick={() => showModal(index)}>
                      <img
                        className="action-icon"
                        src="/images/action-dots.svg" 
                        style={{ width: "3.5rem" }}
                        alt="action"
                      />

                      {index === idOfTable ? (
                        <ProtectedComponents permission={["manageAdminUser", "manageOrganisationUser"]}>
                          <div ref={ref} className="popup">
                          <p onClick={() => viewUser(item)}>Edit User</p>
                          {item?.status === "ACTIVE" ? (
                            <p
                              className="red"
                              onClick={() =>
                                pageType === "admin" ? deActivateUser(item) : deActivateClientUser(item)
                              }>
                              Deactivate User
                            </p>
                          ) : (
                            <p
                              className="green"
                              onClick={() =>
                                pageType === "admin" ? activateUser(item) : activateClientUser(item)
                              }>
                              Activate User
                            </p>
                          )}
                          {pageType === "admin" ? (
                            <>
                              <p
                                onClick={() => deleteItemDialog(deleteUser, item, "user")}
                                className="red">
                                Delete User
                              </p>
                            </>
                          ) : null}
                        </div>
                        </ProtectedComponents>                    
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="page-parent">
              {/* <Pagination
                currentPage={currentPage}
                clientsPerPage={dataPerPage}
                totalClients={res ? res.length : []}
                paginate={(pageNumber) => setCurrentPage(pageNumber)}
                className="pagination"
              /> */}
              <Pagination
                currentPage={currentPage}
                clientsPerPage={dataPerPage}
                totalClients={currentData?.length ? currentData.length : 0}
                paginate={(pageNumber) => setCurrentPage(pageNumber)}
                metaData={metaData}
                page={page}
                newPage={newPage}
                increment={increment}
                decrement={decrement}
                className="pagination"
              />
            </div>

            {activeModal && (
              <div className="form-modal">
                <FormModal className="form" setIsEditing={setIsEditing} handleModal={handleModal}>
                  <AddUserForm
                    onClick={handleModal}
                    fetchClientSingleManager={fetchClientSingleManager}
                    fetchAdminSingleManager={fetchAdminSingleManager}
                    pageType={pageType}
                    setIsEditing={setIsEditing}
                    isEditing={isEditing}
                    updateId={updateId}
                  />
                </FormModal>
              </div>
            )}
          </div>
        </div>
      </StyledClientView>
    </StyledDiv>
  );
};
const StyledDiv = styled.div`
  box-sizing: border-box;
  /* table{
    table-layout: fixed;
  } */
  td {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    font-size: 1.5rem;
    word-wrap:break-word;
    /* overflow-x: scroll; */

  }

  tr:not(:first-child) {
    margin-top: 2rem;
  }
  .table-container {
      overflow: scroll;
      white-space: nowrap;
  
      
    }
  
  th {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem 0;
    font-size: 1.4rem;
  }

  .manager {
    font-size: 2rem;
    font-weight: 600;
  }

  .red {
    color: rgba(180, 0, 0, 0.4);
  }
  .green {
    color: #34a853;
  }
  .popup {
    position: absolute;
    width: 175px;
    right: 50px;
    top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    padding: 1rem 2rem;
    border: 1px solid rgba(33, 51, 79, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    z-index: ${({ isEditing }) => (isEditing ? 0 : 3)};
    border-radius: 10px;
    cursor: pointer;
    p:hover {
      display: inline-block;
      font-weight: 700;
    }
  }
  @media only screen and (min-width: 280px) and (max-width: 677px) {
    .table-container {
      overflow-x: scroll;
      white-space: nowrap;
      width: 29rem;
      
    }
    td{
      margin-left: 3rem;
    }
    th{
      margin-left: 3rem;
    }
    .popup {
    position: absolute;
    width: 175px;
    right: 4px;
    top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    padding: 1rem 2rem;
    text-align: center;
    border: 1px solid rgba(33, 51, 79, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    z-index: ${({ isEditing }) => (isEditing ? 0 : 3)};
    border-radius: 10px;
    p:hover {
      display: inline-block;
      font-weight: 700;
    }
  }
  }
  @media only screen and (max-width: 320px)  {
    .table-container {
      overflow-x: scroll;
      white-space: nowrap;
      width: 27rem;
      
    }
    td{
      margin-left: 3rem;
    }
    th{
      margin-left: 3rem;
    }
  }
  
`

const StyledClientView = styled.div`
  .add-eng {
    position: absolute;
    right: 0rem;
  }
  .add-btn {
    padding: 1rem 2rem;
    background: linear-gradient(96.67deg, #34a853 0%, #b8d344 100%);
    border: none;
    font-size: 1.5rem;
  }

  .heading-name {
    font-size: 1.5rem;
  }
  .form-modal {
    position: fixed;
    top: 0;
    left: 0;
  }
  .page-parent {
    margin-top: 7rem;
  }

  @media only screen and (min-width: 280px) and (max-width: 768px) {
    width: 100%;
    padding: 2rem 0rem;
    .add-eng {
      position: relative;
      right: 0px;
    }
    .add-eng {
      margin-top: 2rem;
    }
  }
  @media screen and (max-width: 1170px) {
    width: 100%;
  }
`;

export default ManageUsers;
