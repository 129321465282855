import { useState } from "react";
import styled from "styled-components";
import LoginActivity from "./clientActivity/LoginActivity";
import AddUserTracking from "./clientActivity/AddUserTracker";
import RateAndReviewTracker from "./clientActivity/RateAndReviewTracker";
import RequestEngineerActivity from "./clientActivity/RequestEngineerActivity";
import RequestSub from "./clientActivity/ResquestSub";
import CompanyDropdown from "../../atoms/CompanyDropdown";
import ProtectedComponents from "../../local-routes/ProtectedComponents";
import { Link } from "react-router-dom";

function ClientActivityTracker({ pageType }) {
  const [orgName, setOrgName] = useState("Activity Overview");
  const [organisationDetails, setOrganisationDetails] = useState(null)
  const [orgId, setOrgId] = useState(null);

  const handleClick = (id, name) => {
    setOrgId(id);
    setOrgName(name);
  };
  return (
    <StyledActivity>
      <div className="header head">
        <h2>You’re currently viewing:</h2>
        <CompanyDropdown setOrganisationDetails={setOrganisationDetails} handleClick={handleClick} orgName={orgName} orgId={orgId} setOrgName={setOrgName} setOrgId={setOrgId} />
      </div>
      {organisationDetails && <table>
        <tbody>
          <tr>
            <td>
              <Link to={`/admin/clients/users/${organisationDetails?.value}`}>{organisationDetails?.name}</Link>
            </td>
            <td>{organisationDetails?.contactPerson}</td>
            <td>{organisationDetails?.email}</td>
            <td>{organisationDetails?.telephoneNumber}</td>
            <td>{organisationDetails?.role}</td>
          </tr>
        </tbody>
      </table>}
      <div className="graph-cards">
        <LoginActivity orgId={orgId} />
        <AddUserTracking orgId={orgId} />
        <ProtectedComponents permission={["viewEngineerRequest", "managePlacement"]}>
          <RequestEngineerActivity orgId={orgId}/>
        </ProtectedComponents>
        <ProtectedComponents permission={["viewSubscriptionRequest"]}>
          <RequestSub orgId={orgId} />
        </ProtectedComponents>
        <ProtectedComponents permission={["managePlacement", "managePlacement"]}>
          <RateAndReviewTracker orgId={orgId} />
        </ProtectedComponents>
      </div>
    </StyledActivity>
  );
}

const StyledActivity = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),
    #fafffb;
    
    .header, .head {
      display: flex;
      gap: 20px;
      align-items: center;
    }
    .head {
      flex-direction: row;
    }
    
    .header > h2 {
    font-weight: 700;
    font-size: 21px;
    line-height: 30px;
    color: #21334f;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }
  /* .header > span{
    display: inline;
    font-weight: 400;
    font-size: 15px;
  } */

  .graph-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    // grid-auto-rows: 300px;
    gap: 20px;
    margin-top: 20px;
  }

  .chevron {
    width: 14px;
    height: 8px;
    margin-left: 25px;
  }

  @media only screen and (max-width: 767px) {
    .graph-cards {
      display: grid;
      grid-template-columns: 1fr;
    }

    .head {
      flex-direction: column;
      justify-content: flex-start;
    }

    .header > h2 {
      font-size: 20px;
    }
  }
`;

export default ClientActivityTracker;
