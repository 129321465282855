import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import {
  apiPut,
  apiPatch,
  apiDelete,
  apiGet,
  apiPost,
} from "../../../utils/apiHelpers";
import useComponentVisible from "../../../utils/useComponentVisible";
import { Link } from "react-router-dom";
import dateFormat, {
  formatEngineerRatingDate,
} from "../../../utils/FormatDate";
import { useAuth } from "../../../context/AuthState";
import { useAdmin } from "../../../context/AdminState";
import useDialog from "../../../utils/useDialog";
import ProtectedComponents from "../local-routes/ProtectedComponents";
import { toast } from "react-toastify";
import StarRatings from "./StarRating";

const { TableDateFormat } = dateFormat;

const ClientBar = ({
  item,
  tableDetails,
  pageType,
  handleModal,
  routeId,
  handleEngModal,
  refId,
  handleClientModal,
  handleEditSubModal,
  handlePackageModal,
  handleEditAssignment,
  handleViewInvoice,
  handleEditInvoice,
  // handleSendInvoice,
  handleIndustry,
  handleTechStack,
  viewUser,
  deleteUsers,
  deactivateUser,
  activateUser,
  handleAssignEngModal,
  downloadDoc,
  handleBilling,
  handleViewEngReqModal,
  docLoading,
  handleEngineerRatingModal,
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const { permissionCheck } = useAuth();
  const permRoles = localStorage?.getItem("roles");
  const permArr = permRoles.split(",");
  let id = item.id;
  let name = item.name;
  const { assignmentId, placementType } = item;
  let status = item.status;
  let organisation = item.organisation;
  let level = item.engineerLevel;
  let amount = item.amount;
  let url = item.document;
  let engId = item.engineerId;

  const history = useHistory();
  const currentLoc = history.location.pathname;

  const {
    fetchBillings,
    fetchSquads,
    fetchIndustry,
    fetchTechStackListing,
    fetchPackages,
    setOpenRatingModal,
  } = useAdmin();
  const [deleteItem, setDeleteItem] = useState(false);

  const activateClient = async (id) => {
    const [firstName, lastName] = item.contactPerson.split(" ");
    const userPayload = {
      email: item.email.toLowerCase(),
      firstName: firstName,
      lastName: lastName,
      organisationRole: "user",
      phone: item.telephoneNumber,
      roles: [0],
      status: "ACTIVE",
    };

    try {
      await apiPut(`/admin/organisations/${id}/activate`, userPayload, {});
      window.location.reload();
    } catch (error) {}
  };

  const deactivateClient = async (id) => {
    const [firstName, lastName] = item.contactPerson.split(" ");
    const userPayload = {
      email: item.email.toLowerCase(),
      firstName: firstName,
      lastName: lastName,
      organisationRole: "user",
      phone: item.telephoneNumber,
      roles: [0],
      status: "INACTIVE",
    };

    try {
      await apiPut(`/admin/organisations/${id}/deactivate`, userPayload, {});
      window.location.reload();
    } catch (error) {}
  };

  const sendNotification = async (id) => {
    try {
      await apiGet(`/admin/organisations/${id}/review_notification`);
      toast.success("Notification sent successfully");
    } catch (error) {
      console.log({ error });
    }
  };

  const sendLoginNotification = async (id) => {
    try {
      await apiPost(
        `/admin/engineers/send-account-created-email/${id}`,
        {},
        {}
      );
      toast.success("Notification sent successfully");
    } catch (error) {
      console.log({ error });
    }
  };

  const handleCancelSubRequest = async () => {
    const userPayload = { ...item, status: "CANCELLED" };

    try {
      await apiPatch(`/private/subscriptions/requests/${id}`, userPayload, {});

      window.location.reload();
    } catch (error) {
      console.log({ error });
    }
  };

  const handleCancelEngRequest = async () => {
    const userPayload = { ...item, status: "CANCELLED" };
    try {
      await apiPatch(`/private/assignments/requests/${id}`, userPayload, {});
      window.location.reload();
    } catch (error) {
      console.log({ error });
    }
  };

  const handleItem = (item) => (item.trim() ? item : "-");

  const handleImageUrl = (item) => !item || item.includes("null");

  const DownloadDocument = () => {
    downloadDoc(url);
  };
  const { deleteItemDialog, resendLinkConfirm } = useDialog();
  //delete billing
  const deleteBilling = async () => {
    try {
      await apiDelete(`/admin/billings/${item.id}`, {});
      fetchBillings();
    } catch (err) {
      console.log({ err });
    }
  };

  const downloadInvoice = async () => {
    try {
      const res = await apiGet(`/admin/invoices/${id}/download`, {
        responseType: "blob",
        headers: {
          "Content-type": "application/pdf",
        },
      });

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invoice.pdf");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (err) {
      toast.error("Error downloading invoice");
      console.log(err);
    }
  };

  const openRatingModal = (id) => {
    setOpenRatingModal(id);
  };

  const handleSendInvoice = async (id) => {
    try {
      await apiGet(`/admin/invoices/${id}/send-email`);
      toast.success("Invoice sent successfully");
    } catch (err) {
      console.log({ err });
    }
  };

  const deleteHandler = async (item) => {
    if (pageType === "squad") {
      try {
        await apiDelete(`/admin/squads/${item}`, {});
        setDeleteItem(true);
        fetchSquads(false);
      } catch (err) {
        console.log({ err });
      }
    }
    if (pageType === "industry") {
      try {
        await apiDelete(`/admin/industries/${item}`, {});
        setDeleteItem(true);
        fetchIndustry(false);
      } catch (err) {
        console.log({ err });
      }
    }
    if (pageType === "techstack") {
      try {
        await apiDelete(`/admin/techstacks/${item}`, {});
        setDeleteItem(true);
        fetchTechStackListing(false);
      } catch (err) {
        console.log({ err });
      }
    }
    if (pageType === "package") {
      try {
        await apiDelete(`/admin/packages/${item}`, {});
        setDeleteItem(true);
        fetchPackages();
      } catch (err) {
        console.log({ err });
      }
    }
  };

  const handleGenerateLink = (item) => {
    const callback = async () => {
      try {
        await apiPatch(
          `/admin/organisation/users/${item.id}/resendPasswordLink`
        );
      } catch (error) {
        console.log({ error });
      }
    };

    resendLinkConfirm(callback, item.id, item.name);
  };

  const handleTruncateText = (text) => {
    return text && text.length > 20 ? text.substring(0, 20) + "..." : text;
  };

  return (
    <StyledClientBar status={status?.toLowerCase()} deleteItem={deleteItem}>
      {tableDetails.map((detail, index) => {
        let current = item[detail];

        let displayName;
        if (Array.isArray(current) && detail === "techStackRequest") {
          return (
            <td>
              {current
                .map((data) => data["techStack"])
                .slice(0, 2)
                .join(", ")}
              ...{" "}
              <span
                onClick={() => handleViewEngReqModal(item)}
                className="viewRequest"
              >
                View Request
              </span>
            </td>
          );
        }

        if (pageType === "industry") {
          displayName =
            current?.length > 25 ? current?.slice(0, 25) + "..." : current;
        } else {
          displayName = current;
        }
        if (detail === "used") {
          current = "used";
        }
        if (detail === "engType") {
          current = item["engineerType"];
        }
        return status && current === status ? (
          <td key={index}>
            {status ? <button className="client-btn">{status}</button> : "-"}
          </td>
        ) : detail === "thumbnailUrl" || detail === "image" ? (
          <td key={index}>
            <img
              src={
                handleImageUrl(current) ? "/images/default-avatar.png" : current
              }
              alt="engineer"
              className="engineer-img"
            />
          </td>
        ) : detail === "dateRated" ? (
          <td key={index}>{formatEngineerRatingDate(current)}</td>
        ) : detail === "index" ? (
          <td>{index + 1}</td>
        ) : detail === "averageRating" ? (
          <td key={index}>
            <StarRatings numRating={current} />
          </td>
        ) : detail === "review" ? (
          <td key={index} className="review--text">
            <p>{handleTruncateText(current)}</p>
            {current.length > 20 && (
              <p
                className="view--more"
                onClick={() =>
                  handleEngineerRatingModal(
                    current,
                    item["dateRated"],
                    item["reviewerName"]
                  )
                }
              >
                View More
              </p>
            )}
          </td>
        ) : detail === "clientType" ? (
          <td key={index}>{handleItem(current)}</td>
        ) : detail === "gender" ? (
          <td key={index}>{handleItem(current)}</td>
        ) : detail === "engType" ? (
          <td key={index}>{handleItem(current)}</td>
        ) : detail === "name" &&
          Object.prototype.hasOwnProperty.call(item, "creditAllocated") ? (
          <td key={index}>
            <Link
              className="clickable"
              to={{
                pathname: `${
                  pageType === "placement-history"
                    ? `/admin/engineers/${engId}`
                    : `/client/engineers/${engId}`
                }`,
                state: {
                  assignmentId: id,
                },
              }}
            >
              {item.name}
            </Link>
          </td>
        ) : detail === "name" &&
          Object.prototype.hasOwnProperty.call(item, "subscriptionPlan") ? (
          <td key={index}>
            <Link
              className="clickable"
              to={{
                pathname: `/admin/clients/${id}`,
              }}
            >
              {item.name}
            </Link>
          </td>
        ) : detail === "username" &&
          Object.prototype.hasOwnProperty.call(item, "engineerType") ? (
          <td key={index}>
            <Link
              className="clickable"
              to={{
                pathname: `/admin/engineers/${id}`,
                state: {
                  pageTypeState: "admin-view-state",
                },
              }}
            >
              {item.username}
            </Link>
          </td>
        ) : detail === "username" &&
          Object.prototype.hasOwnProperty.call(item, "engineerType") ? (
          <td key={index}>
            <Link
              className="clickable"
              to={{
                pathname: `/admin/engineers/${id}`,
                state: {
                  pageTypeState: "admin-view-state",
                },
              }}
            >
              {item.username}
            </Link>
          </td>
        ) : current === "used" ? (
          <td key={index}>{item.numEngineers - item.availableCredit}</td>
        ) : detail === "dateAdded" ? (
          <td key={index}>{TableDateFormat(current)}</td>
        ) : detail === "action" ? (
          <td className="dots" key={index}>
            <span
              onMouseOver={() => setIsComponentVisible(!isComponentVisible)}
            >
              {pageType !== "client_engineers" ? (
                <span>
                  <span className="ellipse"></span>
                  <span className="ellipse"></span>
                  <span className="ellipse"></span>
                </span>
              ) : (
                <img
                  src="/images/review-icon.svg"
                  alt=""
                  className="action-img"
                />
              )}
            </span>

            {isComponentVisible &&
              (pageType === "clients" ? (
                <div className="popup" ref={ref}>
                  {permissionCheck(permArr, [
                    "manageClient",
                    "manageAdminUser",
                    "viewAccessOnly",
                  ]) && (
                    <p
                      onClick={(e) =>
                        history.push(`/admin/clients/users/${id}`)
                      }
                    >
                      List Users
                    </p>
                  )}
                  {permissionCheck(permArr, [
                    "assignEngineer",
                    "manageClient",
                    "manageEngineer",
                    "manageOrganisationUser",
                    "viewAssignment",
                    "uploadEngineerReport",
                    "viewEngineerReport",
                    "addEngineerComment",
                    "viewAccessOnly",
                  ]) && (
                    <p onClick={() => history.push(`/admin/clients/${id}`)}>
                      View Org Info
                    </p>
                  )}

                  {/* {permissionCheck(permArr, [
                  "manageSubscription",
                  "viewSubscription",
                ]) && (
                  <p
                    onClick={() =>
                      history.push(`/admin/subscriptions/sublisting/${id}`, {
                        organisation,
                        orgId: item.id,
                      })
                    }
                  >
                    View Subscription
                  </p>
                )} */}
                  {permissionCheck(permArr, ["manageClient"]) && (
                    <p onClick={() => handleClientModal(id)}>Update Client</p>
                  )}
                  {permissionCheck(permArr, ["manageClient"]) && (
                    <p onClick={() => sendNotification(id)}>
                      Send Notification
                    </p>
                  )}
                  {permissionCheck(permArr, ["manageClient"]) && (
                    <p onClick={() => activateClient(id)}>Activate Client</p>
                  )}
                  {permissionCheck(permArr, ["manageClient"]) && (
                    <p onClick={() => deactivateClient(id)} className="red">
                      Deactivate Client
                    </p>
                  )}
                </div>
              ) : pageType === "overview" ? (
                <div className="popup" ref={ref}>
                  {permissionCheck(permArr, [
                    "manageClient",
                    "manageAdminUser",
                    "viewAccessOnly",
                  ]) && (
                    <p
                      onClick={(e) =>
                        history.push(`/admin/clients/users/${id}`)
                      }
                    >
                      List Users
                    </p>
                  )}
                  {permissionCheck(permArr, [
                    "assignEngineer",
                    "manageClient",
                    "manageEngineer",
                    "manageOrganisationUser",
                    "viewAssignment",
                    "uploadEngineerReport",
                    "viewEngineerReport",
                    "addEngineerComment",
                    "viewAccessOnly",
                  ]) && (
                    <p onClick={() => history.push(`/admin/clients/${id}`)}>
                      View Org Info
                    </p>
                  )}

                  {permissionCheck(permArr, [
                    "manageSubscription",
                    "viewSubscription",
                    "viewAccessOnly",
                  ]) && (
                    <p
                      onClick={() =>
                        history.push(`/admin/subscriptions/sublisting/${id}`, {
                          organisation,
                        })
                      }
                    >
                      View Subscription
                    </p>
                  )}
                  {permissionCheck(permArr, ["manageClient"]) && (
                    <p onClick={() => activateClient(id)}>Activate Client</p>
                  )}
                  {permissionCheck(permArr, ["manageClient"]) && (
                    <p onClick={() => deactivateClient(id)} className="red">
                      Deactivate Client
                    </p>
                  )}
                </div>
              ) : pageType === "subscriptions" ? (
                <div ref={ref} className="popup">
                  {permissionCheck(permArr, [
                    "manageSubscription",
                    "viewSubscription",
                    "viewAccessOnly",
                  ]) && (
                    <p>
                      <Link
                        to={{
                          pathname: `${currentLoc}/${id}`,
                          state: [{ routeId }, { refId }, { subId: item.id }],
                        }}
                      >
                        View Subscription
                      </Link>
                    </p>
                  )}
                </div>
              ) : pageType === "engineers" ||
                pageType === "engineers_overview" ? (
                <div ref={ref} className="popup">
                  {permissionCheck(permArr, ["manageEngineer"]) && (
                    <p onClick={() => handleEngModal(id)}>View/Edit Engineer</p>
                  )}
                  {permissionCheck(permArr, ["assignEngineer"]) && (
                    <p onClick={() => handleAssignEngModal(id)}>
                      Assign Engineer
                    </p>
                  )}
                  {permissionCheck(permArr, [
                    "viewAssignment",
                    "viewAccessOnly",
                  ]) && (
                    <p>
                      <Link
                        to={{
                          pathname: `/admin/engineers/assignments/${id}`,
                          state: {
                            routeId,
                          },
                        }}
                      >
                        View Placement
                      </Link>
                    </p>
                  )}
                  {permissionCheck(permArr, [
                    "assignEngineer",
                    "manageEngineer",
                  ]) && (
                    <p onClick={() => sendLoginNotification(id)}>
                      Login Notification
                    </p>
                  )}
                  {/* <p onClick={() => handleEditAssignment(assignmentId, id)}>
                    Edit Placement
                  </p> */}
                </div>
              ) : pageType === "sub" ? (
                <ProtectedComponents permission={["manageSubscription"]}>
                  <div ref={ref} className="popup">
                    <p>
                      <Link
                        to={{
                          pathname: `${currentLoc}/${id}`,
                          state: [{ routeId }, { refId }, { subId: item.id }],
                        }}
                      >
                        View Subscription
                      </Link>
                    </p>
                    <p onClick={() => handleEditSubModal(id)}>
                      Update Subscription
                    </p>
                  </div>
                </ProtectedComponents>
              ) : pageType === "placement-history" ? ((
                  <ProtectedComponents
                    permission={["manageEngineer", "viewAssignment"]}
                  >
                    <div ref={ref} className="popup">
                      <p
                        className="text-tiny"
                        onClick={() =>
                          handleEditAssignment(id, "", placementType)
                        }
                      >
                        Edit Placement
                      </p>
                    </div>
                  </ProtectedComponents>
                )
              ) : pageType === "users" ? (
                <ProtectedComponents permission={["manageOrganisationUser"]}>
                  <div ref={ref} className="popup">
                    <p onClick={() => handleGenerateLink(item)}>
                      Generate Login Link
                    </p>
                    <p onClick={() => viewUser(item)}>Edit User info</p>
                    <p onClick={() => activateUser(item)}>Activate User</p>
                    <p onClick={() => deactivateUser(item)} className="red">
                      Deactivate User
                    </p>
                    <p
                      onClick={() =>
                        deleteItemDialog(deleteUsers, item, "user")
                      }
                      className="red"
                    >
                      Delete User
                    </p>
                  </div>
                </ProtectedComponents>
              ) : pageType === "package" ? (
                <ProtectedComponents permission={["managePackageSetup"]}>
                  <div ref={ref} className="popup">
                    <p onClick={() => handlePackageModal(id)}>View Package</p>

                    <p
                      className="red"
                      onClick={() => deleteItemDialog(deleteHandler, id, name)}
                    >
                      Delete Package
                    </p>
                  </div>
                </ProtectedComponents>
              ) : pageType === "squad" ? (
                <ProtectedComponents permission={["manageSquad"]}>
                  <div ref={ref} className="techpop squad">
                    <p onClick={() => viewUser(item)}>Update Squad</p>
                    <p
                      className="red"
                      onClick={() =>
                        deleteItemDialog(deleteHandler, id, `SQ${name}`)
                      }
                    >
                      Delete Squad
                    </p>

                    <Link
                      className="my-20"
                      to={{
                        pathname: `/admin/weeks/${id}`,
                      }}
                    >
                      View Weeks
                    </Link>

                    <Link
                      className="my-20"
                      to={{
                        pathname: `/admin/squad-admin/${name}/${id}`,
                      }}
                    >
                      View Squad Admins
                    </Link>
                  </div>
                </ProtectedComponents>
              ) : pageType === "industry" ? (
                <ProtectedComponents permission={["manageIndustrySetup"]}>
                  <div ref={ref} className="industrypop">
                    <p
                      onClick={() => {
                        handleIndustry(id, name);
                        handleModal();
                      }}
                    >
                      Update Industry
                    </p>
                    <p
                      className="red"
                      onClick={() => {
                        deleteItemDialog(deleteHandler, id, name);
                      }}
                    >
                      Delete Industry
                    </p>
                  </div>
                </ProtectedComponents>
              ) : pageType === "billing" ? (
                <ProtectedComponents permission={["manageBillingRate"]}>
                  <div ref={ref} className="industrypop">
                    <p
                      onClick={() => {
                        handleBilling(id, level, amount);
                        handleModal();
                      }}
                    >
                      Update Billing
                    </p>
                    <p
                      className="red"
                      onClick={() => {
                        deleteBilling(id);
                      }}
                    >
                      Delete Billing
                    </p>
                  </div>
                </ProtectedComponents>
              ) : pageType === "techstack" ? (
                <ProtectedComponents permission={["manageTechstackSetup"]}>
                  <div ref={ref} className="techpop techstack">
                    <p onClick={() => handleTechStack(id, item.name)}>
                      Update TechStack
                    </p>
                    <p
                      className="red"
                      onClick={() => {
                        deleteItemDialog(deleteHandler, id, name);
                      }}
                    >
                      Delete Language
                    </p>
                  </div>
                </ProtectedComponents>
              ) : pageType === "client_subscriptions" ? (
                <div ref={ref} className="popup">
                  {permissionCheck(permArr, [
                    "viewSubscription",
                    "ROLEORGADMIN",
                    "ROLEORGMEMBER",
                  ]) && (
                    <p>
                      <Link
                        to={{
                          pathname: `/client/subscription/${id}`,
                          state: {
                            routeId,
                          },
                        }}
                      >
                        View Subscription
                      </Link>
                    </p>
                  )}
                </div>
              ) : pageType === "client_engineers" ? (
                <div ref={ref} className="popup">
                  <p
                    onClick={() => openRatingModal(id)}
                    className="rate-review"
                  >
                    Rate & Review
                  </p>
                </div>
              ) : pageType === "adminEngReq" ? (
                <ProtectedComponents permission={["cancelEngineerRequest"]}>
                  <div
                    ref={ref}
                    className="popup"
                    onClick={handleCancelEngRequest}
                  >
                    <p className="red">Cancel Request</p>
                  </div>
                </ProtectedComponents>
              ) : pageType === "adminSubReq" ? (
                <ProtectedComponents permission={["cancelSubscriptionRequest"]}>
                  <div
                    ref={ref}
                    className="popup"
                    onClick={handleCancelSubRequest}
                  >
                    <p className="red">Cancel Request</p>
                  </div>
                </ProtectedComponents>
              ) : pageType === "clientEngineersReq" ? (
                <div
                  ref={ref}
                  className="popup"
                  onClick={handleCancelEngRequest}
                >
                  <p className="red">Cancel Request</p>
                </div>
              ) : pageType === "clientSubReq" ? (
                <div
                  ref={ref}
                  className="popup"
                  onClick={handleCancelSubRequest}
                >
                  <p className="red">Cancel Request</p>
                </div>
              ) : pageType === "document" ? (
                <div
                  ref={ref}
                  className="popup"
                  // onClick={handleCancelSubRequest}
                >
                  <p
                    disabled={docLoading}
                    style={{ cursor: "pointer" }}
                    onClick={DownloadDocument}
                  >
                    Download document
                  </p>
                </div>
              ) : pageType === "invoice" ? (
                <div
                  ref={ref}
                  className="invoicepop"
                  // onClick={handleCancelSubRequest}
                >
                  <p
                    disabled={docLoading}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleViewInvoice(id)}
                  >
                    View
                  </p>
                  <p
                    disabled={docLoading}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleEditInvoice(id)}
                  >
                    Edit
                  </p>
                  <p
                    disabled={docLoading}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleSendInvoice(id)}
                  >
                    Send
                  </p>
                  <p
                    disabled={docLoading}
                    style={{ cursor: "pointer" }}
                    onClick={() => downloadInvoice(id)}
                  >
                    Download
                  </p>
                </div>
              ) : (
                <ProtectedComponents
                  permission={["viewAssignment", "manageEngineer"]}
                >
                  <div ref={ref} className="popup">
                    <p
                      onClick={() =>
                        handleEditAssignment(assignmentId, "", placementType)
                      }
                    >
                      Edit Placement
                    </p>
                  </div>
                </ProtectedComponents>
              ))}
          </td>
        ) : pageType === "squad" ? (
          <td key={index}>{index === 0 ? "SQ" + current : current}</td>
        ) : (
          <td
            key={index}
            className={`${
              index === 0 && pageType === "industry" ? "first-column" : ""
            }`}
          >
            {displayName || "-"}
          </td>
        );
      })}
    </StyledClientBar>
  );
};

const StyledClientBar = styled.tr`
  background: #ffffff;
  border: 1px solid rgba(33, 51, 79, 0.1);
  margin-bottom: 1.6rem;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 400;
  color: #21334f;
  padding: 1.8rem 1.6rem;

  .review--text {
    display: flex;
    gap: 2px;
    align-items: center;

    & p {
      padding: 0;
      margin: 0;
    }

    & .view--more {
      background: green;
      padding: 0 10px;
      font-size: 12px;
      cursor: pointer;
      width: 80px;
      height: 20px;

      font-family: "Mulish";
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* identical to box height */

      text-align: center;

      color: #34a853;
      background: rgba(52, 168, 83, 0.1);
    }
  }

  .rate-review {
    z-index: -1;
  }

  .action-img {
    width: 2.5rem;
    cursor: pointer;
    z-index: -1;
  }

  .clickable {
    color: inherit;
  }

  .clickable:hover {
    text-decoration: underline;
  }

  .engineer-img {
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }

  .viewRequest {
    color: #34a853;
    background: rgba(52, 168, 83, 0.12);
    cursor: pointer;
    padding: 4px;
  }

  .dots {
    position: relative;
  }
  .dots:hover {
    cursor: pointer;
  }

  .first-column {
    max-width: 10px;
    overflow: auto;
    margin-left: 2rem;
  }

  .popup {
    position: absolute;
    width: 150px;
    right: 35px;
    top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    font-size: 1.4rem;
    padding: 0.5rem 1rem;
    border: 1px solid rgba(33, 51, 79, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    z-index: 2;
    border-radius: 10px;
    p:hover {
      display: inline-block;
      font-weight: 700;
      cursor: pointer;
    }
  }

  .my-20 {
    // border: 2px solid red;
    margin: 0.3rem 0;
    color: green;
  }

  a {
    text-decoration: none;
  }

  .techpop {
    position: absolute;
    width: 175px;
    right: 10px;
    top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    padding: 1rem 2rem;
    border: 1px solid rgba(33, 51, 79, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    z-index: 2;
    border-radius: 10px;
    p:hover {
      display: inline-block;
      font-weight: 700;
      cursor: pointer;
    }
  }
  .squad {
    right: calc(60% + 10px);
  }
  .techstack {
    right: calc(40% + 10px);
  }
  .industrypop {
    position: absolute;
    // width: 175px;
    right: 120px;
    top: 35px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    padding: 1rem 2rem;
    border: 1px solid rgba(33, 51, 79, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    z-index: 2;
    border-radius: 10px;
    p:hover {
      display: inline-block;
      font-weight: 700;
      cursor: pointer;
    }
  }

  .red {
    color: rgba(180, 0, 0, 0.4);
  }
  .invoicepop {
    position: absolute;
    // width: 175px;
    right: 170px;
    top: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    padding: 1rem 3rem;
    border: 1px solid rgba(33, 51, 79, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    z-index: 2;
    border-radius: 10px;
    p:hover {
      display: inline-block;
      font-weight: 700;
      cursor: pointer;
    }
  }

  .ellipse {
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background: black;
    display: inline-block;
    margin-right: 0.3rem;
  }
  .client-btn {
    background: ${({ status }) =>
      status === "active"
        ? "#34A853"
        : status === "retained"
        ? "#40c463"
        : status === "residence"
        ? "#6cd287"
        : status === "completed"
        ? "#34A853"
        : status === "internship"
        ? "#8adba0"
        : status === "not_assigned"
        ? "#DB6969"
        : status === "internship"
        ? "#48C76A"
        : status?.toLowerCase() === "terminated"
        ? "#D34444"
        : status === "in progress"
        ? "#FF8E00"
        : "#D34444"};
    color: white;
    border: none;
    margin: 0 auto;
    max-width: 120px;
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 600;
    padding: 10px;
  }

  .form-success {
    display: ${({ deleteItem }) => (deleteItem ? "flex" : "none")};
    width: 70%;
    background: "#ffffff";
    align-items: center;
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2rem;
  }

  .text-tiny {
    font-size: 11px;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 32rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }

  @media only screen and (min-width: 320px) and (max-width: 768px) {
    .techpop {
      position: absolute;
      width: 175px;
      /* right: 60px; */
      top: 35px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: #ffffff;
      padding: 1rem 2rem;
      border: 1px solid rgba(33, 51, 79, 0.1);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
      z-index: 2;
      border-radius: 10px;
      p:hover {
        display: inline-block;
        font-weight: 700;
        cursor: pointer;
      }
    }
    .industrypop {
      position: absolute;
      // width: 175px;
      right: 30px;
      top: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: #ffffff;
      padding: 1rem 2rem;
      border: 1px solid rgba(33, 51, 79, 0.1);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
      z-index: 2;
      border-radius: 10px;
      p:hover {
        display: inline-block;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
`;

export default ClientBar;
