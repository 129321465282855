export const eduQualification = [
    { label: "Choose", value: "" },
    { label: "Doctorate Degree", value: "DOCTORATE_DEGREE" },
    { label: "Master Degree", value: "MASTER_DEGREE" },
    { label: "Post Graduate Certificate", value: "POST_GRADUATE_CERTIFICATE" },
    { label: "Post Graduate Diploma", value: "POST_GRADUATE_DIPLOMA" },
    { label: "Bachelor Degree", value: "BACHELOR_DEGREE" },
    { label: "Associate Degree", value: "ASSOCIATE_DEGREE" },
    { label: "Professional Certificate", value: "PROFESSIONAL_CERTIFICATE" },
    { label: "Higher National Diploma", value: "HIGHER_NATIONAL_DEPLOMA" },
    { label: "Ordinary National Diploma", value: "ORDINARY_NATIONAL_DEPLOMA" },
    { label: "Vocational Certificate", value: "VOCATIONALL_CERTIFICATE" },
    { label: "Mooc Certificate", value: "MOOC_CERTIFICATE" },
]

export const locationOption = [
    { value: "", label: "SELECT OPTION" },
    { value: "REMOTE", label: "REMOTE" },
    { value: "ONSITE", label: "ONSITE" },
    { value: "HYBRID", label: "HYBRID" },
];

export const placementStatus = [
    { value: "", label: "SELECT OPTION" },
    { value: "INPROGRESS", label: "INPROGRESS" },
    { value: "REJECTED_BY_COMPANY", label: "REJECTED BY COMPANY" },
    { value: "REJECTED_BY_DEV", label: "REJECTED BY DEV" },
    { value: "COMPLETED", label: "COMPLETED" },
    { value: "TERMINATED", label: "TERMINATED" },
];

export const placement = [
    { value: "", label: "SELECT OPTION" },
    { value: "PLACED", label: "PLACED" },
    { value: "BOOKED_FOR_INTERNSHIP", label: "BOOKED FOR INTERNSHIP" },
    { value: "INTERVIEW", label: "INTERVIEW" },
    { value: "INTERNING", label: "INTERNING" },
    { value: "UNPLACED", label: "UNPLACED" },
    { value: "UNDER_PLACED", label: "UNDER PLACED" }
];

export const placementSource = [
    { value: "", label: "SELECT OPTION" },
    { value: "DECAGON", label: "DECAGON" },
    { value: "SELF_SOURCED", label: "SELF SOURCED" },
];