import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import { apiPost } from "../../../utils/apiHelpers";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import styled from "styled-components";
import { useAdmin } from "../../../context/AdminState";
import MyButton from "../../landingPage/MyButton";
import {format} from "date-fns";



const RateAndReviewForm = ({ handleFetch, pageType, assignmentId }) => {
  const { openRatingModal, setCloseRatingModal } = useAdmin();
  const [activeTechnicalField, setActiveTechnicalField] = useState(false);
  const [activeBehaviouralField, setActiveBehaviouralField] = useState(false);
  const [behaviouralRating, setBehaviouralRating] = useState({
    label: "",
    value: null,
  });
  const [technicalRating, setTechnicalRating] = useState({
    label: "",
    value: null,
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [url, setUrl] = useState("");

  const clickRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleOuterClick = (e) => {
    if (
      (clickRef.current && !clickRef.current.contains(e.target)) ||
      (dropdownRef.current && !dropdownRef.current.contains(e.target))
    ) {
      setActiveTechnicalField(false);
      setActiveBehaviouralField(false);
    }
  };
  
  useEffect(() => {
    if(pageType === "admin-view"){
      setUrl("/admin/reviews/rateAndReviewEngineer")
    } else if(pageType === "client_engineer_view" || pageType === "client_engineers"){
      setUrl("/private/reviews/rateAndReviewEngineer")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageType])

  useEffect(() => {
    if (behaviouralRating.value) {
      formik.values.behaviouralRating = behaviouralRating.value;
    }
    if (technicalRating.value) {
      formik.values.technicalRating = technicalRating.value;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [behaviouralRating.value, technicalRating.value]);

  const new_token = localStorage.getItem("token");

  let multipartConfig = {
    headers: {
      Authorization: `Bearer ${new_token}`,
      "Content-Type": "application/json",
    },
  };

  let today = new Date().toLocaleDateString().split("/").reverse().join("-");

  const rateEngineer = async (formData) => {
    if (
      !formik.values.behaviouralRating ||
      !formik.values.technicalRating ||
      !formik.values.review ||
      formik.values.review === "" ||
      !formik.values.dateOfReview
    ) {
      return toast.error("Please fill out all fields");
    }
    setLoading(true);

    //make call to the api
    try {
        let response = await apiPost(url, formData, multipartConfig);
        if (response.error) {
          throw response.data.response.status === 500
            ? new Error("")
            : response.data;
        }
      toast.success("Engineer rated successfully", {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setSuccess(true);
      setLoading(false);
      handleFetch();
    } catch (err) {
      setLoading(false);
    }
  };

  const onSubmit = (values) => {
    formik.values.assignmentId = assignmentId ? assignmentId : openRatingModal.assignmentId;
    
    if (formik.values.dateOfReview) {
      formik.values.dateOfReview = format(new Date(formik.values.dateOfReview), 'dd/MM/yyyy HH:mm')
    }

    rateEngineer(values);
  };

  const initialValues = {
    assignmentId: null,
    technicalRating: null,
    behaviouralRating: null,
    review: "",
    dateOfReview: today,
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  const closeModal = () => {
    formik.resetForm();
    setCloseRatingModal();
    setSuccess(false);
  };

  const ratingOptions = [
    { value: 5, label: "five" },
    { value: 4, label: "four" },
    { value: 3, label: "three" },
    { value: 2, label: "two" },
    { value: 1, label: "one" },
  ];

  return (
      <StyledRateAndReviewForm onClick={handleOuterClick}>
        {!success && (
          <ModalForm>
            <button onClick={closeModal} className="exit-btn">
              <img src="/images/times.svg" alt="x" className="times" />
            </button>
            <h3>Write a Review</h3>
            <hr />

            <Form onSubmit={formik.handleSubmit}>
              <div className="category">
                <label>Category</label>
                <div className="category-field">
                  <div className="input-flex">
                    <div className="field">
                      <p>
                        Technical Rating <span className="dash">-</span>
                      </p>
                      <div className="grey">
                        <p>
                          Readability of engineers code and adherence to project
                          development process
                        </p>
                      </div>
                    </div>
                    <div
                      className="chevron"
                      onClick={() => setActiveTechnicalField(true)}
                    >
                      <img
                        src="/images/chevron-down.svg"
                        className="chevron-icon"
                        alt="chevron"
                      />
                    </div>
                    <span>{technicalRating.value}</span>
                  </div>
                  <div className="input-flex">
                    <div className="field">
                      <p>
                        Behavioural Rating <span className="dash">-</span>
                      </p>
                      <div className="grey">
                        <p>
                          Ability to ask relevant question to clarify task and
                          initiative to drive project success
                        </p>
                      </div>
                    </div>
                    <div
                      className="chevron"
                      onClick={() => setActiveBehaviouralField(true)}
                    >
                      <img
                        src="/images/chevron-down.svg"
                        className="chevron-icon"
                        alt="chevron"
                      />
                    </div>
                    <span>{behaviouralRating.value}</span>
                  </div>
                  {activeTechnicalField && (
                    <div
                      onClick={() => setActiveTechnicalField(null)}
                      className="dropdown"
                      ref={clickRef}
                    >
                      {ratingOptions.map((data, i) => (
                        <div
                          key={i}
                          onClick={() => {
                            formik.values.technicalRating = data.value;
                            setTechnicalRating({
                              label: data.label,
                              value: data.value,
                            });
                          }}
                          className="dropdown-stars"
                        >
                          <span>{data.value}</span>{" "}
                          <img
                            key={i}
                            src={`/images/${data.label}-star.svg`}
                            className="stars"
                            alt="stars"
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  {activeBehaviouralField && (
                    <div
                      onClick={() => setActiveBehaviouralField(null)}
                      className="dropdown"
                      ref={dropdownRef}
                    >
                      {ratingOptions.map((data, i) => (
                        <div
                          key={i}
                          onClick={() => {
                            formik.values.behaviouralRating = data.value;

                            setBehaviouralRating({
                              label: data.label,
                              value: data.value,
                            });
                          }}
                          className="dropdown-stars"
                        >
                          <span>{data.value}</span>{" "}
                          <img
                            key={i}
                            src={`/images/${data.label}-star.svg`}
                            className="stars"
                            alt="stars"
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <label className="sm-label">Date</label>
                <input
                  type="date"
                  value={formik.values.dateOfReview}
                  onChange={formik.handleChange}
                  name="dateOfReview"
                />
              </div>
              <div>
                <label className="sm-label">Write a Review</label>
                <p className="warning">
                  * Comment should not exceed 3000 characters
                </p>
                <textarea
                  rows="7"
                  cols="5"
                  placeholder="Share your experience"
                  name="review"
                  maxLength="3000"
                  onChange={formik.handleChange}
                  value={formik.values.review}
                ></textarea>
              </div>
              <MyButton className="form-btn" type="submit" green={true} full>
                {" "}
                {loading ? (
                  <ClipLoader color={"#fff"} size={"30px"} />
                ) : (
                  "Submit review"
                )}
              </MyButton>
            </Form>
          </ModalForm>
        )}
        {success && (
          <SuccessDiv>
            <h1 className="">Thank You</h1>
            <p>Your rating was successfully added</p>
            <button onClick={closeModal}>Okay</button>
          </SuccessDiv>
        )}
      </StyledRateAndReviewForm>
  );
};

const StyledRateAndReviewForm = styled.div`
  background: #fff;
  padding: 30px 0;
  margin-top: 40px;
`;

const SuccessDiv = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  grid-gap: 10px;
  margin: 20%;
  h1 {
    font-size: 24px;
    font-weight: 700;
    color: #34a853;
    line-height: 30px;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    color: #273b4a;
    line-height: 23px;
  }
  button {
    background: #34a853;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    border-radius: 2px;
    border: 1px solid #34a853;
    padding: 1rem 2rem;
    transition: 0.4s;
  }
  button:hover {
    background: #fff;
    color: #34a853;
  }
`;

const ModalForm = styled.div`
  .times {
    width: 30px;
    margin-top: -4px;
  }
  .exit-btn {
    cursor: pointer;
    border: none;
    background-color: transparent;
    margin-left: 90%;
    position: absolute;
  }
  h3 {
    text-align: center;
    color: #273b4a;
    font-size: 18px;
    font-weight: 700;
  }
  hr {
    margin-top: 20px;
    width: 100%;
  }
`;

const Form = styled.form`
  padding: 0% 7%;

  .input-flex {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .input-flex > span {
    font-size: 1.2rem;
  }

  .field {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 80%;
    height: 59px;
    background: rgba(0, 0, 0, 0.03);
    border: 1px solid rgba(33, 51, 79, 0.15);
    padding: 0 20px;
  }
  .field > p {
    font-size: 14px;
    color: #273b4a;
    margin-top: 15px;
  }

  .warning {
    font-size: 11px;
  }

  .grey {
    width: 65%;
    height: 38px;
    background: rgba(0, 0, 0, 0.04);
    display: grid;
    overflow: auto;
    overflow-wrap: break-word;
  }
  .grey > p {
    font-size: 12px;
  }

  .input-flex:first-of-type > .field > div {
    margin-left: 17px;
  }

  label {
    line-height: 20px;
    color: #273b4a;
    font-weight: 700;
  }
  textarea {
    border: 1px solid rgba(33, 51, 79, 0.15);
    width: 100%;
    margin-bottom: 25px;
    padding: 10px 20px;
    font-size: 1.4rem;
  }
  textarea::placeholder {
    color: #ccd0d6;
  }

  div:first-of-type > label {
    font-size: 16px;
  }
  .sm-label {
    font-size: 14px;
    display: block;
  }

  .form-btn {
    border: 1px solid #34a853;
    font-weight: 600;
    font-size: 16px;
    transition: 0.5s;
  }
  .form-btn:hover {
    background: #20bf6b;
    border: 1px solid #20bf6b;
    color: #fff;
  }
  .dash {
    margin-left: 20px;
  }

  .dropdown {
    background: #fff;
    padding: 8px 0px;
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
    position: absolute;
    right: 60px;
    top: 0;
  }

  .dropdown-stars {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 4px 12px;
  }

  .dropdown-stars:hover {
    background: #eee;
  }
  .category {
    margin-bottom: 1.7rem;
  }

  .category-field {
    position: relative;
  }

  .chevron {
    border: 1px solid rgba(100, 115, 130, 0.1);
    padding: 1.8rem;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .stars {
    width: 80px;
  }

  .client-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2rem;
  }

  input[type="date"] {
    height: 35px;
    margin: 0 auto;
    width: 100%;
    max-width: 300px;
    font-size: 15px;
    text-transform: uppercase;
    background: rgba(0, 0, 0, 0.03);
    border: 1px solid rgba(33, 51, 79, 0.15);
    outline: none;
    padding: 0 10px;
    color: #000;
    box-sizing: border-box;
    margin-bottom: 15px;
  }
  input[type="date"]::-webkit-datetime-edit-month-field {
    color: #000;
  }
  input[type="date"]::-webkit-datetime-edit-day-field {
    color: #000;
  }
  input[type="date"]::-webkit-datetime-edit-year-field {
    color: #000;
  }
  input[type="date"]::-webkit-clear-button {
    font-size: 18px;
    height: 30px;
    position: relative;
    right: 5px;
    margin-right: 4px;
  }
  input[type="date"]::-webkit-inner-spin-button {
    height: 30px;
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    font-size: 18px;
    cursor: pointer;
  }

  @media screen and (max-width: 1000px) {
    .field {
      display: flex;
      align-items: center;
      /* gap: 20px; */
      width: 100%;
      height: 59px;
      padding: 0 20px;
    }
    .dash {
      display: none;
    }
    .grey {
      width: 100%;
    }
  }
  @media screen and (max-width: 500px) {
    .field {
      padding: 0 10px;
    }
  }
  @media screen and (max-width: 400px) {
    .field {
      padding: 0 10px;
      gap: 2px;
    }
    .input-flex {
      gap: 5px;
    }
  }
`;

export default RateAndReviewForm;
