import { toast } from "react-toastify";
import { apiGet, apiPatch } from "../../utils/apiHelpers";

export const fetchClientTechStackRequests = async (filterOptions) => {
  try {
    const res = await apiGet(
      `/private/assignments/requests?page=${filterOptions.page}&size=${filterOptions.size}`,
      {}
    );

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchPlacementRequests = async (filterOptions) => {
  try {
    const res = await apiGet(
      `/private/engineer-placement-requests?page=${filterOptions.page}&size=${filterOptions.size}`,
      {}
    );

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchChooseEngineerLineItems = async (params) => {
  const [requestId] = params.queryKey;
  try {
    const res = await apiGet(
      `/private/engineer-placement-requests/engineer/${requestId}`,
      ""
    );

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteRequestLineItem = async (data) => {
  const { requestId, lineItemId } = data;
  try {
    const res = await apiPatch(
      `/private/engineer-placement-requests/${requestId}/line-item/${lineItemId}`,
      ""
    );

    toast.success("Delete Successful", {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteTechStackRequest = async (data) => {
  const { requestId } = data;
  try {
    const res = await apiPatch(
      `/private/assignments/requests/${requestId}`,
      ""
    );

    toast.success("Request deleted successfully", {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteChooseEngineerRequest = async (data) => {
  const { requestId } = data;
  try {
    const res = await apiPatch(
      `/private/engineer-placement-requests/${requestId}`,
      ""
    );

    toast.success("Request deleted successfully", {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

// Fetch client Engineers
export const fetchClientEngineers = async (params) => {
  const [filterOptions] = params.queryKey
  try {
    const res = await apiGet(
      `/private/placements?page=${filterOptions.page}&size=12&search=${filterOptions.keyword}`, {}, "client");
    
    return res.data
  } catch (err) {
    console.log({err});
  }
};
