import React, { useState } from "react";
import styled from "styled-components";
import Layout from "../common/Layout";
import DynamicTitle from "../common/DynamicTitle";
import TechstackReq from "../admin/AdminEngRequest/TechstackReq";
import EngBasedRequest from "../admin/AdminEngRequest/EngBasedRequest";
import { useFetchedEngineerRequests } from "../../../services/engineerReq/query";

const AdminEngineerRequestPage = ({ pageType }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [filterOptions, setFilterOptions] = useState({
    page: 1,
    size: 12,
  });

  const { data: requestList, isLoading } =
    useFetchedEngineerRequests(filterOptions);

  return (
    <Layout pageLoading={isLoading} navType="admin">
      <DynamicTitle title="Placement Requests | Fellowship Product" />
      <StyledDashboardPage>
        <h3 className="header-text">Placement Requests</h3>
        <div className="btn-wrapper">
          <button
            onClick={() => setActiveTab(0)}
            className={activeTab === 0 ? "active" : "inactive"}>
            Techstack Request
          </button>
          <button
            onClick={() => setActiveTab(1)}
            className={activeTab === 1 ? "active" : "inactive"}>
            Engineer Request
          </button>
        </div>
        {activeTab === 0 && (
          <TechstackReq
            requestList={requestList}
            setFilterOptions={setFilterOptions}
            filterOptions={filterOptions}
            pageType={"admin"}
          />
        )}
        {activeTab === 1 && <EngBasedRequest />}
      </StyledDashboardPage>
    </Layout>
  );
};

const StyledDashboardPage = styled.div`
  .header-text {
    font-weight: 700;
    font-size: 2.4rem;
    color: rgba(33, 51, 79, 1);
    margin-bottom: 4.2rem;
    margin-top: 4.8rem;
  }

  .btn-wrapper {
    margin-bottom: 2.1rem;
  }

  .btn-wrapper button {
    border: none;
    font-size: 2rem;
  }

  .btn-wrapper button {
    margin-right: 24px;
  }

  .btn-wrapper .active {
    color: #34a853;
    background: #fff;
    padding: 16px;
    border: 1px solid rgba(52, 168, 83, 0.26);
  }

  .btn-wrapper .inactive {
    color: #21334f;
    background: transparent;
  }

  .search {
    width: 90%;
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid rgba(33, 51, 79, 0.1);
    position: relative;
  }

  .search {
    border: 1px #34a853 solid;
    border-radius: 0.5rem;
  }

  .viewRequest {
    color: #34a853;
    background: rgba(52, 168, 83, 0.12);
    cursor: pointer;
    padding: 4px;
  }

  @media screen and (max-width: 500px) {
    button {
      margin-right: 8px;
    }

    button {
      border: none;
      font-size: 1.6rem;
    }
  }
`;

export default AdminEngineerRequestPage;
