/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import FormInputComponent from "./enterpriseFormInputs/FormInputComponent";
import FormSelectComponent from "./enterpriseFormInputs/FormSelectComponent";
import FormTitleSection from "./FormTitleSection";
import FormUploadComponent from "./enterpriseFormInputs/FormUploadComponent";
import FormImgComponent from "./enterpriseFormInputs/FormImgComponent";
// import SelectedStack from "./SelectedStack";
import { apiPut, apiGet } from "../../../utils/apiHelpers";
import { setSelect, capitalize } from "../../../utils/helperFunctions";
import { useAdmin } from "../../../context/AdminState";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { BsTrash } from "react-icons/bs";
import FormTextAreaComponent from "./enterpriseFormInputs/FormTextAreaComponent";
import { FormMultiSelectComponent } from "./enterpriseFormInputs/FormMultiSelectComponent";
import { useFetchedFrameworks } from "../../../services/reference/query";
import FormToggleComponent from "./enterpriseFormInputs/FormToggleComponent";
import SelectedStack from "./SelectedStack";
import nprogress from "nprogress";
import FormWysiwygComponent from "./enterpriseFormInputs/FormWysiwygComponent";
import { eduQualification } from "../../../data";

const phoneRegExp = /^\d*(\+\d+)?$/;

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email format").required("*Required"),
  firstName: Yup.string().required("*Required"),
  lastName: Yup.string().required("*Required"),
  telephoneNo: Yup.string()
    .min(9, "*Phone number is not valid")
    .matches(phoneRegExp, "Phone number is not valid"),
  technicalSkills: Yup.string(),
  majorTechStacks: Yup.number("select an option"),
  squad: Yup.string("select an option"),
  about: Yup.string(""),
  videoResumeLink: Yup.string().matches(
    /^(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:(?:watch)?\?(?:.*&)?v(?:i)?=|embed\/|v\/|shorts\/))([\w-]{11})(?:\S+)?$/,
    "Must be a Youtube Link"
  ),
});

const EditEngForm = ({ onClick, engId, handleEngModal, reload }) => {
  const { stackList, fetchStackOptions, fetchLocation, locations } = useAdmin();

  const [success, setSuccess] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [resumeDoc, setResumeDoc] = useState({});
  const [profileImg, setProfileImg] = useState({});
  const [loading, setLoading] = useState(false);
  const [stackResult, setStackResult] = useState([]);
  const [squadOptions, setSquadOptions] = useState([]);
  const [stack, setStack] = useState("");
  const [stacks, setStacks] = useState("");
  const [resumeDocError, setResumeDocError] = useState("");
  const [profileImgError, setProfileImgError] = useState("");
  const [addMoreEdu, setAddMoreEdu] = useState(0);
  const [addMoreProjects, setAddMoreProjects] = useState(0);
  const [addMoreWorkExp, setAddMoreWorkExp] = useState(0);
  const [addMoreCert, setAddMoreCert] = useState(0);
  const [selectedOptionsError, setSelectedOptionsError] = useState("");

  const [educations, setEducations] = useState([
    {
      course: "",
      grade: "",
      graduationDate: "",
      institution: "",
      qualification: "",
      mainQualification: false,
    },
  ]);
  const [projects, setProjects] = useState([
    {
      description: "",
      mainProject: false,
      name: "",
      technologyUsed: "",
      projectLink: "",
    },
  ]);
  const [workExperiences, setWorkExperiences] = useState([
    {
      companyName: "",
      description: "",
      role: "",
      startDate: "",
      endDate: "",
    },
  ]);
  const [certifications, setCertifications] = useState([
    {
      dateIssued: "",
      expiryDate: "",
      issuingOrganisation: "",
      name: "",
    },
  ]);
  const [engDetails, setEngDetails] = useState({});
  const [initialValues, setInitialValues] = useState({
    email: "",
    firstName: "",
    lastName: "",
    userName: "",
    telephoneNo: "",
    gender: "",
    technicalSkills: "",
    majorTechStacks: "",
    otherTechStacks: "",
    squad: "",
    about: "",

    // new fields
    currentLocation: "",
    decadevEmailAddress: "",
    dateOfBirth: "",
    gitHubProfileLink: "",
    linkedInProfileLink: "",
    frameworks: [],
    videoResumeLink: "",
    visibleInFrontEnd: "",
  });

  let locationOptions =
    locations?.locations?.length > 0
      ? locations?.locations?.map((location) => {
          return {
            value: location.id,
            label: location.name,
          };
        })
      : [];

  const engType = [
    { label: "Engineer Type", value: "" },
    { label: "Decadev", value: "DECADEV" },
    { label: "Non Decadev", value: "NON DECADEV" },
  ];

  const projectsOptions = [
    { value: "", label: "Choose" },
    {
      value: "true",
      label: "True",
    },
    {
      value: "false",
      label: "False",
    },
  ];

  function pad(n) {
    return n < 10 ? "0" + n : n;
  }
  
  const formatDateForDisplay = (dateString) => {
    if (!dateString) {
      return "";
    }
    const myDate = new Date(dateString);
    const dayOfMonth = myDate.getDate();
    const month = myDate.getMonth();
    const year = myDate.getFullYear();
    const ddmmyyyy = year + "-" + pad(month + 1) + "-" + pad(dayOfMonth);
    return ddmmyyyy;
  };

  const docType = ["pdf", "docx", "doc"];
  const pictureType = ["jpg", "jpeg", "gif", "png"];

  const { data: frameworks } = useFetchedFrameworks();

  useEffect(() => {
    fetchEngineerDetails();
    fetchLocation();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setResumeDocError("");
  }, [resumeDoc]);

  useEffect(() => {
    setProfileImgError("");
  }, [profileImg]);

  useEffect(() => {
    let result = getStacksFromstackResult(stackResult);
    setStacks(result);
  }, [stackResult]);

  function getStacksFromstackResult(stackResult) {
    let obj = {};

    for (let i = 0; i < stackResult.length; i++) {
      let current = stackResult[i];

      obj[current.value] = current.label;
    }
    return obj;
  }

  const fetchEngineerDetails = async () => {
    try {
      const res = await apiGet(`/admin/engineers/${engId}`);
      let result = {
        ...res.data.data,
        engineerType: res.data.data.engineerType.toUpperCase(),
      };

      setEngDetails(result);
      setInitialValues(result);
      result.workExperiences.length > 0 &&
        setWorkExperiences(result?.workExperiences?.map((value, i) => {
          return {
            ...value,
            indexing: i,
          };
        }));
      result.projects.length > 0 && setProjects(result?.projects?.map((value, i) => {
        return {
          ...value,
          indexing: i,
        };
      }));
      result.certifications.length > 0 && setCertifications(
        result?.certifications?.map((value, i) => {
          return {
            ...value,
            indexing: i,
          };
        })
      );
      result.educations.length > 0 &&
        setEducations(
          result?.educations?.map((value, i) => {
            return {
              ...value,
              indexing: i,
              grade: value?.grade?.toUpperCase().split(" ").join("_"),
              qualification: value?.qualification
                ?.toUpperCase()
                .split(" ")
                .join("_"),
            };
          })
        );
    } catch (err) {
      toast.error(err);
    }
  };

  const formatDate = (date) => {
    if (!date) return "";

    if (date.includes("/")) {
      return date;
    }

    if (date.includes("-")) {
      return date.split("-").reverse().join("/");
    }

    return new Date(date).toLocaleDateString();
  };

  const reverseDob = (date) => {
    if (!date) return "";
    return date.split("/").reverse().join("-");
  };

  const editEngineerDetails = async (data) => {
    setLoading(true);

    nprogress.start();
    try {
      let response = await apiPut(`/admin/engineers/${engId}`, data);
      if (response.error) {
        throw response.data.response.status === 500
          ? new Error("")
          : response.data;
      }
      reload && reload();
      setSuccess(true);
      setLoading(false);
      nprogress.done();
      reload && reload();
    } catch (err) {
      setLoading(false);
      nprogress.done();
    }
  };

  useEffect(() => {
    let stckOpts = setSelect(stackList, "Stack");

    if (!stckOpts || stckOpts.length === 1) {
      fetchStackOptions();
      stckOpts = setSelect(stackList, "Stack");
    }

    setStackResult(stckOpts);
    fetchSquadOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stackList]);

  useEffect(() => {
    setSelectedOptionsError("");
  }, [selectedOptions]);

  useEffect(() => {
    setStack(selectedOptions[selectedOptions.length - 1]);
  }, [selectedOptions]);

  // Fetch squad options
  const fetchSquadOptions = async () => {
    try {
      const res = await apiGet(`/admin/squads`);
      let result = res.data.data;
      let options = result.map((item) => {
        return { value: item.id, label: "SQ" + item.name };
      });
      options.unshift({ value: "", label: "Select Squad" });
      setSquadOptions(options);
    } catch (err) {
      toast.error(err);
    }
  };

  const handleStack = (event, majorTechStacks) => {
    if (
      stack !== "" &&
      majorTechStacks !== event.target.value &&
      !selectedOptions.includes(event.target.value)
    ) {
      setSelectedOptions([...selectedOptions, event.target.value]);
    }
  };

  const deleteStack = (index) => {
    if (index > -1) {
      setSelectedOptions(selectedOptions.filter((value, i) => i !== index));
    }
  };

  const onSubmit = (values) => {
    let newValues = { ...values };
    newValues.otherTechStacks = selectedOptions;
    if (newValues.engineerType === "NON DECADEV") {
      newValues.engineerType = "NON_DECADEV";
    } else {
      newValues.engineerType = newValues?.engineerType?.toUpperCase();
    }

    newValues.engineerStatus = newValues?.engineerStatus?.toUpperCase() || "";
    newValues.gender = newValues?.gender.toUpperCase();

    if (newValues.dateOfBirth.includes("-")) {
      newValues.dateOfBirth = formatDate(newValues?.dateOfBirth);
    }

    newValues.squad = parseInt(newValues.squad);
    newValues.currentLocation = Number(newValues.currentLocation);
    newValues.majorTechStacks = [newValues.majorTechStacks];
    newValues.frameworks = newValues.frameworks.map((item) => item.value);

    const payload = {
      educations: educations,
      projects: projects,
      workExperiences: workExperiences,
      certifications: certifications,
    };
    payload.educations.map((item) => {
      item.graduationDate = formatDate(item.graduationDate);
      return item;
    });
    payload.workExperiences.map((item) => {
      item.endDate = formatDate(item?.endDate);
      item.startDate = formatDate(item?.startDate);
      return item;
    });
    payload.certifications.map((item) => {
      item.dateIssued = formatDate(item.dateIssued);
      item.expiryDate = formatDate(item.expiryDate);
      return item;
    });

    newValues = { ...newValues, ...payload };

    let formData = new FormData();

    if (resumeDoc) {
      formData.append("resume_upload", resumeDoc);
    }

    if (profileImg) {
      formData.append("profile_upload", profileImg);
    }

    formData.append(
      "engineer_dto",
      new Blob([JSON.stringify(newValues)], {
        type: "application/json",
      })
    );
    editEngineerDetails(formData);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const closeForm = () => {
    formik.resetForm();
    handleEngModal();
    setResumeDoc(() => ({}));
    setSuccess(false);
  };

  useEffect(() => {
    setSelectedOptions(
      engDetails?.otherTechStacks?.map((tech) => tech?.id?.toString()) || []
    );

    const getFrameworkValue = setSelect(
      engDetails.frameworks,
      "",
      "",
      "",
      true
    );

    formik.setValues({
      ...engDetails,
      squad: engDetails?.squad ? engDetails.squad.id : "",
      majorTechStacks: engDetails?.majorTechStacks?.length
        ? engDetails.majorTechStacks[0].id
        : "",
      frameworks: getFrameworkValue,
    });
    // eslint-disable-next-line
  }, [engDetails, initialValues]);

  const handleChanges = (e, index, type) => {
    const value = e.target.value;
    const name = e.target.name;
    if (type === "education") {
      if (name === "mainQualification") {
        const changeMainQualification = educations.map((item, i) => {
          if (index !== i) {
            item.mainQualification = false;
          } else item.mainQualification = value;

          return item;
        });

        setEducations(changeMainQualification);
      }
      const newEdu = [...educations];
      newEdu[index][name] = value;
      newEdu[index].indexing = index;
      setEducations(newEdu);
    }
    if (type === "projects") {
      const newProject = [...projects];
      if (name === "mainProject" && value === "true") {
        newProject[index][name] = true;
      } else if (name === "mainProject" && value === "false") {
        newProject[index][name] = false;
      } else {
        newProject[index][name] = value;
        newProject[index].indexing = index;
      }
      setProjects(newProject);
    }
    if (type === "workExperiences") {
      const newExp = [...workExperiences];
      newExp[index][name] = value;
      newExp[index].indexing = index;
      setWorkExperiences(newExp);
    }
    if (type === "certifications") {
      const newCert = [...certifications];
      newCert[index][name] = value;
      newCert[index].indexing = index;
      setCertifications(newCert);
    }
  };

  const deleteItems = (index, type) => {
    if (type === "educations") {
      const newEdu = [...educations];
      const newFilteredEdu = newEdu.filter((item, i) => item.indexing !== index);
      setEducations(newFilteredEdu);
    }
    if (type === "projects") {
      const newProject = [...projects];
      const newFilteredProj = newProject.filter((item, i) => item.indexing !== index);
      setProjects(newFilteredProj);
    }
    if (type === "workExperiences") {
      const newExp = [...workExperiences];
      const newFilteredExp = newExp.filter((item, i) => item.indexing !== index);
      setWorkExperiences(newFilteredExp);
    }
    if (type === "certifications") {
      const newCert = [...certifications];
      const newFilteredCert = newCert.filter((item, i) => item.indexing !== index);
      setCertifications(newFilteredCert);
    }
  };

  // add more button
  const addMore = (type) => {
    if (type === "educations") {
      let count = addMoreEdu;
      let newCount = count + 1;
      setAddMoreEdu(newCount);
      setEducations([
        ...educations,
        {
          course: "",
          grade: "",
          graduationDate: "",
          institution: "",
          qualification: "",
          mainQualification: false,
        },
      ]);
    }
    if (type === "projects") {
      let count = addMoreProjects;
      let newCount = count + 1;
      setAddMoreProjects(newCount);
      setProjects([
        ...projects,
        {
          description: "",
          mainProject: false,
          name: "",
          technologyUsed: "",
          projectLink: "",
        },
      ]);
    }
    if (type === "workExperiences") {
      let count = addMoreWorkExp;
      let newCount = count + 1;
      setAddMoreWorkExp(newCount);
      setWorkExperiences([
        ...workExperiences,
        {
          companyName: "",
          description: "",
          endDate: "",
          startDate: "",
          role: "",
        },
      ]);
    }
    if (type === "certifications") {
      let count = addMoreCert;
      let newCount = count + 1;
      setAddMoreCert(newCount);
      setCertifications([
        ...certifications,
        {
          dateIssued: "",
          expiryDate: "",
          issuingOrganisation: "",
          name: "",
        },
      ]);
    }
  };

  const gradeOptions = [
    { value: "", label: "Choose" },
    { value: "PASS", label: "Pass" },
    { value: "FIRST_CLASS", label: "First Class" },
    { value: "SECOND_CLASS_UPPER", label: "Second Class Upper" },
    { value: "SECOND_CLASS_LOWER", label: "Second Class Lower" },
    { value: "THIRD_CLASS", label: "Third Class" },
  ];

  const genderOptions = [
    { value: "", label: "Select Gender" },
    { value: "Male", label: "MALE" },
    { value: "Female", label: "FEMALE" },
  ];

  return (
    <StyledAddEngForm success={success}>
      <FormTitleSection title="Edit Engineers" onClick={handleEngModal} />
      <div className="form-section">
        <form onSubmit={formik.handleSubmit} className="client-form">
          <div className="d-flex justify-content-between">
            <FormImgComponent
              pictureType={pictureType}
              label="Upload Image"
              error={profileImgError}
              selectedFile={profileImg}
              setSelectedFile={setProfileImg}
              imgUrl={engDetails.profilePic}
              onSubmit={() => formik.submitForm()}
            />
            {/* <div>
              <label className="form-label form-font">
                Engineer visibility
              </label>
              <FormToggleComponent
                onChange={(x) => {
                  formik.setFieldValue("visibleInFrontEnd", x);
                }}
                value={formik.values.visibleInFrontEnd}
              />
            </div> */}
          </div>
          <div className="two-column">
            <FormInputComponent
              label="Email address"
              type="email"
              name="email"
              placeholder="Enter email address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null
              }
            />
            <FormSelectComponent
              label="Engineer Type"
              name="engineerType"
              options={engType}
              onChange={formik.handleChange}
              value={formik.values.engineerType}
              error={
                formik.touched.engineerType && formik.errors.engineerType
                  ? formik.errors.engineerType
                  : null
              }
            />
          </div>
          <div className="two-column">
            <FormInputComponent
              label="First Name"
              type="text"
              name="firstName"
              placeholder="Enter first name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              error={
                formik.touched.firstName && formik.errors.firstName
                  ? formik.errors.firstName
                  : null
              }
            />
            <FormInputComponent
              label="Last Name"
              type="text"
              name="lastName"
              placeholder="Enter last name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              error={
                formik.touched.lastName && formik.errors.lastName
                  ? formik.errors.lastName
                  : null
              }
            />
          </div>
          <div className="two-column">
            <FormInputComponent
              label="Nickname"
              type="text"
              name="userName"
              placeholder="Enter Nickname"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.userName}
              error={
                formik.touched.userName && formik.errors.userName
                  ? formik.errors.userName
                  : null
              }
            />
            <FormInputComponent
              label="Decadev Email Address"
              name="decadevEmailAddress"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.decadevEmailAddress}
              error={
                formik.touched.decadevEmailAddress &&
                formik.errors.decadevEmailAddress
                  ? formik.errors.decadevEmailAddress
                  : null
              }
            />
          </div>
          <div className="two-column">
            <FormInputComponent
              label="Phone number"
              type="tel"
              name="telephoneNo"
              placeholder="Enter phone number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.telephoneNo}
              error={
                formik.touched.telephoneNo && formik.errors.telephoneNo
                  ? formik.errors.telephoneNo
                  : null
              }
            />
            <FormSelectComponent
              label="Current Location"
              formName="currentLocation"
              options={[
                { label: "Current Location", value: "" },
                ...locationOptions,
              ]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.currentLocation}
              error={
                formik.touched.currentLocation && formik.errors.currentLocation
                  ? formik.errors.currentLocation
                  : null
              }
            />
          </div>
          <div className="two-column">
            <FormInputComponent
              label="Date of Birth"
              name="dateOfBirth"
              type="date"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={reverseDob(formik.values.dateOfBirth)}
            />

            {/* <FormSelectComponent
            label="Other stack (select technology)"
            name="stack"
            options={stackResult}
            onChange={(e) => handleStack(e)}
            value={stack}
            error={selectedOptionsError}
          />
          <div className="stack-section">
            {selectedOptions &&
              selectedOptions.map((option, index) => {
                return (
                  <SelectedStack
                    key={index}
                    name={stacks[option]}
                    onClick={(e) => {
                      e.preventDefault();
                      deleteStack(index);
                    }}
                  />
                );
              })}
          </div> */}
            <FormSelectComponent
              label="Squad"
              formName="squad"
              options={squadOptions}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.squad}
              error={
                formik.touched.squad && formik.errors.squad
                  ? formik.errors.squad
                  : null
              }
            />
          </div>

          <FormTextAreaComponent
            label="About"
            // type="text"
            name="about"
            placeholder="Type here..."
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.about}
            error={
              formik.touched.about && formik.errors.about
                ? formik.errors.about
                : null
            }
            className="w-100 pt-3"
          />
          <FormSelectComponent
            label="Gender"
            name="gender"
            options={genderOptions}
            onChange={formik.handleChange}
            value={formik.values.gender}
            onBlur={formik.handleBlur}
            error={
              formik.touched.gender && formik.errors.gender
                ? formik.errors.gender
                : null
            }
          />

          <FormSelectComponent
            label="Major Stack"
            formName="majorTechStacks"
            options={stackResult}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.majorTechStacks}
            error={
              formik.touched.majorTechStacks && formik.errors.majorTechStacks
                ? formik.errors.majorTechStacks
                : null
            }
          />

          <FormSelectComponent
            label="Other stack (select technology)"
            name="otherTechStacks"
            options={stackResult}
            onChange={(e) => handleStack(e, formik.values.otherTechStacks)}
            value={stack}
            error={selectedOptionsError}
          />

          <div className="stack-section">
            {selectedOptions &&
              selectedOptions.map((option, index) => {
                return (
                  <SelectedStack
                    key={index}
                    name={stacks[option]}
                    onClick={(e) => {
                      e.preventDefault();
                      deleteStack(index);
                    }}
                  />
                );
              })}
          </div>

          <FormMultiSelectComponent
            label="Framework"
            name="frameworks"
            options={setSelect(frameworks, "", "", "", true)}
            onChange={(selectedOption) => {
              formik.setFieldValue("frameworks", selectedOption);
            }}
            value={formik.values.frameworks}
            error={
              formik.touched.frameworks && formik.errors.frameworks
                ? formik.errors.frameworks
                : null
            }
          />
          <FormInputComponent
            label="Technical Skills"
            type="text"
            name="technicalSkills"
            placeholder="GitHub, Jira"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.technicalSkills}
            error={
              formik.touched.technicalSkills && formik.errors.technicalSkills
                ? formik.errors.technicalSkills
                : null
            }
          />

          <FormInputComponent
            label="Github Profile Link"
            name="gitHubProfileLink"
            placeholder="https://github.com/username"
            value={formik.values.gitHubProfileLink}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.gitHubProfileLink &&
              formik.errors.gitHubProfileLink
                ? formik.errors.gitHubProfileLink
                : null
            }
          />

          <FormInputComponent
            label="LinkedIn Profile Link"
            name="linkedInProfileLink"
            placeholder="https://www.linkedin.com/in/username"
            value={formik.values.linkedInProfileLink}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.linkedInProfileLink &&
              formik.errors.linkedInProfileLink
                ? formik.errors.linkedInProfileLink
                : null
            }
          />

          <div className="downld-link">
            <a
              href={engDetails.resume}
              target="_blank"
              rel="noreferrer"
              download>
              {`${
                formik.values.firstName && capitalize(formik.values.firstName)
              } ${
                formik.values.lastName && capitalize(formik.values.lastName)
              } Resume`}
            </a>
          </div>

          <fieldset>
            <legend className="heading-text">Educations</legend>

            {educations.map((edu, i) => (
              <div className="edu-new" key={i}>
                <FormInputComponent
                  label="Institution"
                  name="institution"
                  value={edu.institution}
                  onChange={(e) => {
                    handleChanges(e, i, "education");
                  }}
                />
                <div className="two-column">
                  <FormInputComponent
                    label="Course"
                    name="course"
                    value={edu.course}
                    onChange={(e) => {
                      handleChanges(e, i, "education");
                    }}
                  />
                  <FormSelectComponent
                    label="Grade"
                    formName="grade"
                    options={gradeOptions}
                    value={edu.grade}
                    onChange={(e) => {
                      handleChanges(e, i, "education");
                    }}
                  />
                </div>
                <div className="two-column">
                  <FormSelectComponent
                    label="Qualification"
                    name="qualification"
                    formName="qualification"
                    options={eduQualification}
                    value={edu.qualification}
                    onChange={(e) => {
                      handleChanges(e, i, "education");
                    }}
                  />
                  <FormInputComponent
                    label="Graduation Date"
                    name="graduationDate"
                    type="date"
                    value={formatDateForDisplay(edu?.graduationDate)}
                    onChange={(e) => {
                      handleChanges(e, i, "education");
                    }}
                  />
                </div>
                <div className="hq-toggle mb-3">
                  <p>Is this your Highest Qualification?</p>
                  <FormToggleComponent
                    name="mainQualification"
                    onChange={(x) => {
                      handleChanges(
                        { target: { name: "mainQualification", value: x } },
                        i,
                        "education"
                      );
                    }}
                    value={edu?.mainQualification}
                  />
                </div>
                {i > 0 && (
                  <div
                    className="span-btn"
                    onClick={() => {
                      deleteItems(edu.indexing, "educations");
                    }}>
                    <BsTrash className="delete-icon" />
                  </div>
                )}
              </div>
            ))}

            <button
              className="add-more"
              type="button"
              onClick={() => {
                addMore("educations");
              }}>
              {" "}
              <span>+</span> Add More Educational Background
            </button>
          </fieldset>

          <fieldset>
            <legend className="heading-text">Projects</legend>

            {projects.map((proj, i) => (
              <div className="project-new" key={i}>
                <div className="two-column">
                  <FormInputComponent
                    label="Name"
                    name="name"
                    value={proj.name}
                    onChange={(e) => {
                      handleChanges(e, i, "projects");
                    }}
                  />

                  <FormSelectComponent
                    label="Show to recruiters"
                    options={projectsOptions}
                    formName="mainProject"
                    onChange={(e) => {
                      handleChanges(e, i, "projects");
                    }}
                    value={proj.mainProject}
                  />
                </div>
                <FormInputComponent
                  label="Techonology used"
                  name="technologyUsed"
                  value={proj.technologyUsed}
                  onChange={(e) => {
                    handleChanges(e, i, "projects");
                  }}
                />

                <FormInputComponent
                  label="Project Link"
                  name="projectLink"
                  value={proj.projectLink}
                  onChange={(e) => {
                    handleChanges(e, i, "projects");
                  }}
                />

                <FormWysiwygComponent
                  label="Description"
                  name="description"
                  value={proj.description}
                  maxLength="999"
                  onChange={(e) => {
                    handleChanges(
                      { target: { name: "description", value: e } },
                      i,
                      "projects"
                    );
                  }}
                  error={
                    proj.description.length > 999
                      ? "Should not exceed 1000 characters"
                      : null
                  }
                />

                {i > 0 && (
                  <div
                    className="span-btn"
                    onClick={() => {
                      deleteItems(proj.indexing, "projects");
                    }}>
                    <BsTrash className="delete-icon" />
                  </div>
                )}
              </div>
            ))}

            <button
              className="add-more"
              type="button"
              onClick={() => {
                addMore("projects");
              }}>
              {" "}
              <span>+</span> Add More Projects
            </button>
          </fieldset>

          <fieldset>
            <legend className="heading-text">Work Experiences</legend>

            {workExperiences.map((proj, i) => (
              <div className="workexp-new" key={i}>
                <FormInputComponent
                  label="Company Name"
                  name="companyName"
                  value={proj.companyName}
                  onChange={(e) => {
                    handleChanges(e, i, "workExperiences");
                  }}
                />
                <FormInputComponent
                  label="Role"
                  name="role"
                  value={proj.role}
                  onChange={(e) => {
                    handleChanges(e, i, "workExperiences");
                  }}
                />
                <FormWysiwygComponent
                  label="Description"
                  name="description"
                  value={proj.description}
                  maxLength="999"
                  onChange={(e) => {
                    handleChanges(
                      { target: { name: "description", value: e } },
                      i,
                      "workExperiences"
                    );
                  }}
                  error={
                    proj.description.length > 999
                      ? "Should not exceed 1000 characters"
                      : null
                  }
                />
                <div className="two-column">
                  <FormInputComponent
                    label="Start Date"
                    name="startDate"
                    type="date"
                    value={formatDateForDisplay(proj.startDate)}
                    onChange={(e) => {
                      handleChanges(e, i, "workExperiences");
                    }}
                  />
                  <FormInputComponent
                    label="End Date"
                    name="endDate"
                    type="date"
                    value={formatDateForDisplay(proj.endDate)}
                    onChange={(e) => {
                      handleChanges(e, i, "workExperiences");
                    }}
                  />
                </div>

                {i > 0 && (
                  <div
                    className="span-btn"
                    onClick={() => {
                      deleteItems(proj.indexing, "workExperiences");
                    }}>
                    <BsTrash className="delete-icon" />
                  </div>
                )}
              </div>
            ))}

            <button
              className="add-more"
              type="button"
              onClick={() => {
                addMore("workExperiences");
              }}>
              {" "}
              <span>+</span> Add More Work Experience
            </button>
          </fieldset>

          <fieldset className="last-one">
            <legend className="heading-text">Certifications</legend>

            {certifications.map((proj, i) => (
              <div className="workexp-new" key={i}>
                <FormInputComponent
                  label="Name"
                  name="name"
                  value={proj.name}
                  onChange={(e) => {
                    handleChanges(e, i, "certifications");
                  }}
                />
                <FormInputComponent
                  label="Issuing Organisation"
                  name="issuingOrganisation"
                  value={proj.issuingOrganisation}
                  onChange={(e) => {
                    handleChanges(e, i, "certifications");
                  }}
                />

                <div className="two-column">
                  <FormInputComponent
                    label="Date Issued"
                    name="dateIssued"
                    type="date"
                    value={formatDateForDisplay(proj.dateIssued)}
                    onChange={(e) => {
                      handleChanges(e, i, "certifications");
                    }}
                  />
                  <FormInputComponent
                    label="Expiry Date"
                    name="expiryDate"
                    type="date"
                    value={formatDateForDisplay(proj.expiryDate)}
                    onChange={(e) => {
                      handleChanges(e, i, "certifications");
                    }}
                  />
                </div>

                {i > 0 && (
                  <div
                    className="span-btn"
                    onClick={() => {
                      deleteItems(proj.indexing, "certifications");
                    }}
                  >
                    <BsTrash className="delete-icon" />
                  </div>
                )}
              </div>
            ))}

            <button
              className="add-more"
              type="button"
              onClick={() => {
                addMore("certifications");
              }}
            >
              {" "}
              <span>+</span> Add More Certification
            </button>
          </fieldset>

          <FormInputComponent
            label="Video CV"
            type="text"
            name="videoResumeLink"
            placeholder="https://www.youtube.com/*"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.videoResumeLink}
            error={
              formik.touched.videoResumeLink && formik.errors.videoResumeLink
                ? formik.errors.videoResumeLink
                : null
            }
          />

          <FormUploadComponent
            label="Upload Resume"
            error={resumeDocError}
            selectedFile={resumeDoc}
            setSelectedFile={setResumeDoc}
            docType={docType}
            acceptTypes={`pdf, docx, doc. Size Limit 5mb`}
          />

          <MyButton className="form-btn add-client-btn" type="submit" full>
            {loading ? (
              <ClipLoader color={"#fff"} size={"30px"} />
            ) : (
              "Update Engineer"
            )}
          </MyButton>
        </form>
        <div className="form-success">
          <img
            src="/images/sucess-icon.svg"
            alt="green-tick"
            className="sucess-icon"
          />
          <h2 className="form-font green-text">Success</h2>
          <p className="form-font success-msg">
            You successfully edited Engineer.
          </p>
          <MyButton className="form-btn success-btn" onClick={closeForm}>
            Okay, Go to Engineer Page
          </MyButton>
        </div>
      </div>
    </StyledAddEngForm>
  );
};

const StyledAddEngForm = styled.div`
  width: 70rem;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);
  .underline {
    display: block;
  }
  .form-section {
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  }
  .two-column {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%;
  }

  .hq-toggle p {
    font-size: 1.6rem;
  }

  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3.2rem;
  }
  .add-client-btn {
    margin-bottom: 3rem;
  }
  .stack-section {
    display: flex;
    flex-wrap: wrap;
    width: 32rem;
  }
  .client-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .downld-link {
    margin-bottom: 2rem;
    font-size: 16px;
    color: blue;
  }
  .downld-link:hover {
    margin-bottom: 2rem;
    font-size: 16px;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 32rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }

  .add-more {
    text-align: center;
    font-size: 1.8rem;
    color: #21334f;
    width: 100%;
    padding: 0.7rem 1rem;
    background: #fff;
    border: 1px solid rgba(33, 51, 79, 0.15);
    margin-bottom: 2rem;

    span {
      font-weight: 800;
      font-size: 1.8rem;
      margin-right: 0.7rem;
    }
  }
  textarea {
    min-width: 100% !important;
  }

  fieldset {
    border: 0.1px gray solid;
    padding: 1rem 2rem;
    margin-top: 5rem;
  }

  legend {
    background: linear-gradient(96.67deg, #34a853 0%, #b8d344 100%);
    color: white;
    text-align: center;
    padding: 1rem;
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }
  .last-one {
    margin-bottom: 6rem;
  }
  .span-btn {
    font-size: 2rem !important;
    color: red !important;
    cursor: pointer;
  }
`;

export default EditEngForm;
