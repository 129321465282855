import {
  fetchBillingRates,
  fetchSingleBillingRate,
  createBillingRate,
  editBillingRate,
} from "./api";
import { useQuery, useMutation } from "react-query";

export const useFetchedBillingRateList = () => {
  const query = useQuery(["billingRate-list"], () => fetchBillingRates());

  return query;
};

export const useFetchedSingleBillingRate = (id) => {
  const query = useQuery([id, "single-billingRate"], fetchSingleBillingRate, {
    enabled: id ? true : false,
    cacheTime: 0,
  });

  return query;
};

export const useCreateBillingRate = () => {
  const mutation = useMutation((params) => createBillingRate(params));

  return mutation;
};

export const useEditBillingRate = () => {
  const mutation = useMutation((params) => editBillingRate(params, {}));

  return mutation;
};
