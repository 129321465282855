import React from "react";
import styled from "styled-components";
import RequestTracker from "./adminActivity/RequestTracker";
 import AdminAddClient from "./adminActivity/AdminAddClient";
import RateAndReviewTracker from "./adminActivity/RateAndReviewTracker";
import AddEngTracker from "./adminActivity/AddEngTracker";
import AddUserTracker from "./adminActivity/AdminAddUserTracker";
import AssignEngineer from "./adminActivity/AssignEngineer";
import LoginTracker from "./adminActivity/LoginTracker";
import ProtectedComponents from "../../local-routes/ProtectedComponents";

function AdminActivityTracker({ pageType }) {
  return (
    <StyledActivity>
      <div className="header">
        <h2>Activity Overview</h2>
      </div>
      <div className="graph-cards">
        <LoginTracker />
        <AddUserTracker />
        <AddEngTracker />
        <ProtectedComponents permission={["viewClient", "viewEngineerRequest", "manageClient"]}>
          <AdminAddClient />
        </ProtectedComponents>
        <AssignEngineer />
        <RequestTracker />
        <ProtectedComponents permission={["viewPlacement", "managePlacement"]}>
          <RateAndReviewTracker />
        </ProtectedComponents>
      </div>
    </StyledActivity>
  );
}

const StyledActivity = styled.div`
  .header {
    display: flex;
    gap: 20px;
    align-content: center;
  }

  .header > h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #21334f;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .graph-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    // grid-auto-rows: 300px;
    gap: 20px;
  }
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),
    #fafffb;

  .header > h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #21334f;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .graph-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    // grid-auto-rows: 266px;
    gap: 20px;
  }

  @media only screen and (max-width: 767px) {
    .graph-cards {
      display: grid;
      grid-template-columns: 1fr;
    }


    .header > h2 {
      font-size: 20px;
    }
  }
`;

export default AdminActivityTracker;
