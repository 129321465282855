import React, { useState, useEffect } from "react";
import { useGetChooseEngineersForMeData } from "../../../../../services/activity/query";
import StackedBars from "../../../common/activityTrackerCards/StackedBarCharts";

const ChooseForMeActivity = ({ orgId, startDate, endDate }) => {
  const [currentState, setCurrentState] = useState("All");
  const [month, setMonth] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [_, setPeriod] = useState("");
  const [allLanguages, setAllLanguages] = useState([]);

  const { data: chooseEngineersData, isLoading } =
    useGetChooseEngineersForMeData(startDate, endDate, orgId);

  const getEngineersActivity = () => {
    try {
      const data = chooseEngineersData?.data;
      let month = [];

      let output = []; // Extract unique languages from the input
      let languages = [
        ...new Set(
          data.flatMap((item) => item.request.map((req) => req.languages))
        ),
      ]; // Iterate over the languages

      for (let i = 0; i < data?.length; i++) {
        let item = data[i];
        month.push(item.month);
      }

      languages.forEach((language) => {
        let engineers = Array(data.length).fill(0);
        let counts = Array(data.length).fill(0); // Iterate over the input data i
        data.forEach((monthData, index) => {
          let request = monthData.request.find(
            (req) => req.languages === language
          ); // If request exists for the language in the current month
          if (request) {
            // let monthIndex = new Date(`${monthData.month} 1, 2023`).getMonth();
            engineers[index] = request.numberOfEngineers;
            counts[index] = request.count;
          }
        }); // Create language object with numberOfEngineers and count arrays
        let languageObj = {
          language: language,
          numberOfEngineers: engineers,
          count: counts,
        }; // Add language object to the output array
        output.push(languageObj);
      });

      setAllLanguages(output);
      setMonth(month);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getEngineersActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, currentState, isLoading, startDate]);

  const backgroundColor = [
    "red",
    "brown",
    "blue",
    "green",
    "purple",
    "yellow",
    "orange",
    "#ABF530",
    "black",
    "#32a881",
    "#32a895",
  ];
  const data = {
    labels: month,
    datasets: allLanguages.map((languageData, index) => {
      return {
        label: languageData.language,
        data: languageData.numberOfEngineers,
        backgroundColor: backgroundColor[index],
      };
    }),
  };

  return (
    <StackedBars
      data={data}
      title="Choose Engineer For Me"
      currentState={currentState}
      setCurrentState={setCurrentState}
      setPeriod={setPeriod}
    />
  );
};

export default ChooseForMeActivity;
