import React, { useState } from "react";
import Select from "react-select";
import { adminEngineerView } from "../common/listingData";
import { CircleLoader } from "react-spinners";
import { BiChevronDown, BiSearch } from "react-icons/bi";
import {
  useFetchedLocations,
  useFetchedSquads,
  useFetchedTechstacks,
} from "../../../services/reference/query";
import { setSelect } from "../../../utils/helperFunctions";
import { FilterFields } from "./style";
import { placement } from "../../../data";

const EngineersFilter = ({
  handleFilterChange,
  filterOptions,
  restore,
  selectedFilter,
}) => {
  const SHOW_MORE_KEY = "DECA_SHOW_MORE";
  const CHECK_MORE_STORAGE = localStorage.getItem(SHOW_MORE_KEY);
  const [more, setMore] = useState(CHECK_MORE_STORAGE || false);
  const [showAgeDropdown, setShowAgeDropdown] = useState(false);
  const { data: language } = useFetchedTechstacks();
  const { data: squads } = useFetchedSquads();
  const { data: currentLocations } = useFetchedLocations("");
  const { data: trainingLocations } = useFetchedLocations("batch");

  const formatSquad = squads
    ? squads.data.map((data) => {
        return {
          label: `SQ00${data.name}`,
          value: data.id,
        };
      })
    : [];

  const visibilityOptions = [
    { value: "", label: "Select visiblity" },
    { value: "true", label: "True" },
    { value: "false", label: "False" },
  ];

  const videoCVAvailability = [
    { value: "", label: "Select Video CV Availability" },
    { value: "true", label: "True" },
    { value: "false", label: "False" },
  ];

  return (
    <FilterFields columns="5" className="engineer-filters-wrapper">
      <div className="search-wrap mt-4">
        <BiSearch className="search-icon-engr" size={15} />
        <input
          type="text"
          placeholder="Search for Engineers"
          className="input-search"
          onChange={(e) => handleFilterChange("keyword", e.target.value)}
        />
        {selectedFilter === "keyword" && (
          <div className="input-spinner-select">
            <CircleLoader color="green" size="10px" />
          </div>
        )}
      </div>
      <div className="select-wrap mt-4">
        <Select
          className="my-select"
          value={setSelect(language?.data, "Language").filter(
            (option) => option.value === filterOptions.language
          )}
          options={setSelect(language?.data, "Language")}
          onChange={(e) => handleFilterChange("language", e.value)}
          placeholder="Select Language"
        />
        {selectedFilter === "language" && (
          <div className="input-spinner-filter">
            <CircleLoader color="green" size="10px" />
          </div>
        )}
      </div>
      <div className="select-wrap mt-4">
        <Select
          className="my-select"
          value={adminEngineerView.engineerTypeOptions.filter(
            (option) => option.value === filterOptions.engineerType
          )}
          options={adminEngineerView.engineerTypeOptions}
          placeholder="Engineer Type"
          onChange={(e) => handleFilterChange("engineerType", e.value)}
        />
        {selectedFilter === "engineerType" && (
          <div className="input-spinner-filter">
            <CircleLoader color="green" size="10px" />
          </div>
        )}
      </div>
      <div className="select-wrap mt-4">
        <Select
          className="my-select"
          value={[{ label: "Select Squad", value: "" }, ...formatSquad].filter(
            (option) => option.value === filterOptions.squad
          )}
          options={[{ label: "Select Squad", value: "" }, ...formatSquad]}
          placeholder="Select Squad"
          onChange={(e) => handleFilterChange("squad", e.value)}
        />
        {selectedFilter === "squad" && (
          <div className="input-spinner-filter">
            <CircleLoader color="green" size="10px" />
          </div>
        )}
      </div>
      <div className="select-wrap mt-4">
        <span
          className="select-wrap-expansion"
          onClick={() => {
            localStorage.setItem(SHOW_MORE_KEY, !more);
            setMore(!more);
          }}>
          {more ? "Show Less" : "Show More"}
        </span>
        <span
          className="clear-filters"
          onClick={() => {
            restore();
          }}>
          Clear Filters
        </span>
      </div>
      {more && (
        <>
          <div className="select-wrap mt-4">
            <Select
              className="my-select"
              value={adminEngineerView.engineerGenderOptions.filter(
                (option) => option.value === filterOptions.gender
              )}
              options={adminEngineerView.engineerGenderOptions}
              placeholder="Select Gender"
              onChange={(e) => handleFilterChange("gender", e.value)}
            />
            {selectedFilter === "gender" && (
              <div className="input-spinner-filter">
                <CircleLoader color="green" size="10px" />
              </div>
            )}
          </div>

          <div className="select-wrap mt-4">
            <Select
              className="my-select"
              value={adminEngineerView.engineerPlacementOptions.filter(
                (option) => option.value === filterOptions.assignTyp
              )}
              options={placement}
              placeholder="Placement Type"
              onChange={(e) => handleFilterChange("assignTyp", e.value)}
            />
            {selectedFilter === "assignTyp" && (
              <div className="input-spinner-filter">
                <CircleLoader color="green" size="10px" />
              </div>
            )}
          </div>

          <div className="select-wrap mt-4">
            <Select
              className="my-select"
              value={adminEngineerView.engineerStatusOptions.filter(
                (option) => option.value === filterOptions.engStatus
              )}
              options={adminEngineerView.engineerStatusOptions}
              placeholder="Select Status"
              onChange={(e) => handleFilterChange("engStatus", e.value)}
            />
            {selectedFilter === "engStatus" && (
              <div className="input-spinner-filter">
                <CircleLoader color="green" size="10px" />
              </div>
            )}
          </div>
          <div className="age mt-4">
            <div
              onClick={() => setShowAgeDropdown(!showAgeDropdown)}
              className="placeholder-wrapper">
              <div>Age</div>
              {selectedFilter === "minAge" || selectedFilter === "maxAge" ? (
                <div className="input-spinner-filter">
                  <CircleLoader color="green" size="10px" />
                </div>
              ) : (
                <BiChevronDown size={20} />
              )}
            </div>
            {showAgeDropdown && (
              <div className="age-container">
                <input
                  className="age-input"
                  type="tel"
                  placeholder="Min Age"
                  maxlength="2"
                  value={filterOptions.minAge}
                  onChange={(e) => handleFilterChange("minAge", e.target.value)}
                />

                <input
                  className="age-input"
                  type="tel"
                  placeholder="Max Age"
                  maxlength="2"
                  value={filterOptions.maxAge}
                  onChange={(e) => handleFilterChange("maxAge", e.target.value)}
                />
              </div>
            )}
          </div>

          <div className="select-wrap mt-4">
            <Select
              className="my-select"
              value={setSelect(
                currentLocations?.data,
                "",
                "",
                "Current Location"
              ).filter(
                (option) => option.value === filterOptions.currentLocation
              )}
              options={setSelect(
                currentLocations?.data,
                "",
                "",
                "Current Location"
              )}
              placeholder="Current Location"
              onChange={(e) => handleFilterChange("currentLocation", e.value)}
            />
            {selectedFilter === "currentLocation" && (
              <div className="input-spinner-filter">
                <CircleLoader color="green" size="10px" />
              </div>
            )}
          </div>

          <div className="select-wrap mt-4">
            <Select
              className="my-select"
              value={setSelect(
                trainingLocations?.data,
                "",
                "",
                "Training Location"
              ).filter(
                (option) => option.value === filterOptions.trainingLocation
              )}
              options={setSelect(
                trainingLocations?.data,
                "",
                "",
                "Training Location"
              )}
              placeholder="Training Location"
              onChange={(e) => handleFilterChange("trainingLocation", e.value)}
            />
            {selectedFilter === "trainingLocation" && (
              <div className="input-spinner-filter">
                <CircleLoader color="green" size="10px" />
              </div>
            )}
          </div>

          <div className="select-wrap mt-4">
            <Select
              className="my-select"
              value={visibilityOptions.filter(
                (option) => option.value === filterOptions.visibility
              )}
              options={visibilityOptions}
              placeholder="Select visibility"
              onChange={(e) => handleFilterChange("visibility", e.value)}
            />
            {selectedFilter === "visibility" && (
              <div className="input-spinner-filter">
                <CircleLoader color="green" size="10px" />
              </div>
            )}
          </div>
          <div className="select-wrap mt-4">
            <Select
              className="my-select"
              value={videoCVAvailability.filter(
                (option) => option.value === filterOptions.videoCVAvailability
              )}
              options={videoCVAvailability}
              placeholder="Video CV Availability"
              onChange={(e) => handleFilterChange("videoCVAvailability", e.value)}
            />
            {selectedFilter === "videoCVAvailability" && (
              <div className="input-spinner-filter">
                <CircleLoader color="green" size="10px" />
              </div>
            )}
          </div>
        </>
      )}
    </FilterFields>
  );
};

export default EngineersFilter;
