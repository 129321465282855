import React, { useReducer } from "react";
import {
  CLEAR_ERRORS,
  LOADING,
  FETCH_SUB_REQ_SUCCESS,
  FETCH_ENG_REQ_SUCCESS,
  FETCH_CLIENT_SUCCESS,
  FETCH_NEW_CLIENT_SUCCESS,
  FETCH_TECH_STACK_SUCCESS,
  ASSIGNMENT_LISTING,
  FETCH_INDUSTRY_SUCCESS,
  FETCH_SQUAD_SUCCESS,
  FETCH_PACKAGE_SUCCESS,
  FETCH_USER_SUCCESS,
  ORG_SUB_SUCCESS,
  FETCH_CLIENT_ENG_SUCCESS,
  FETCH_UNASSIGNED,
  FETCH_STACK_SUCCESS,
  FETCH_ENGINEER_SUCCESS,
  FETCH_TOP_ENGINEER_SUCCESS,
  FETCH_ASSIGNMENT_INFO,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_CLIENT_SUB_SUCCESS,
  FETCH_ADMIN_ENG_SUCCESS,
  FETCH_ADMIN_SUB_SUCCESS,
  FETCH_DOCUMENT_SUCCESS,
  FETCH_BILLING_SUCCESS,
  FETCH_PLACEMENT_HISTORY,
  INCREMENT_PAGE,
  DECREMENT_PAGE,
  SET_PAGE,
  SET_KEYWORD,
  SET_STATUS,
  SET_CLIENT_TYPE,
  SET_CUSTOMER_TYPE,
  SET_ENGINEER_TYPE,
  SET_LANGUAGE,
  SET_SQUAD,
  SET_TRAINING_LOCATION,
  SET_ENG_GENDER,
  SET_ENG_AGE,
  SET_CURRENT_LOCATION,
  SET_MIN_AGE,
  SET_MAX_AGE,
  SET_ENG_STATUS,
  SET_ENGINEER_STATUS,
  ON_SEARCH_LOADER,
  OFF_SEARCH_LOADER,
  ON_FILTER_LOADER,
  OFF_FILTER_LOADER,
  SET_LABELS,
  SET_SQUAD_DETAILS,
  SET_OPEN_RATING_MODAL,
  SET_CLOSE_RATING_MODAL,
  FETCH_ENGINEER_DETAILS,
  FETCH_INVOICE_SUCCESS,
  FETECH_LOCATION_SUCCESS,
  FETECH_TRAINING_LOCATION_SUCCESS,
  SET_SQUAD_ID,
} from "./types";
import { toast } from "react-toastify";
import { apiPost, apiGet } from "../utils/apiHelpers";
import AdminContext from "./AdminContext";
import AdminReducer from "./AdminReducer";
import useSearchAndFilter from "./useSearchAndFilter";

const AdminState = ({ children }) => {
  const initialState = {
    user: null,
    loading: false,
    error: null,
    isVerified: false,
    success: false,
    dashboardData: {},
    assignment_info: {},
    engineersList: [],
    subReqList: [],
    engReqList: [],
    organisationsList: [],
    assignmentStatusList: [],
    levelList: [],
    stackList: [],
    locations: [],
    trainingLocations: [],
    page: 1,
    keyword: "",
    status: "",
    location: "",
    currentLocation: "",
    minAge: "",
    maxAge: "",
    trainingLocation: "",
    engineerAge: "",
    engineerGender: "",
    clientType: "",
    customerType: "",
    EngineerType: "",
    organisation: "",
    language: "",
    squad: "",
    squadId: "",
    engineerStatus: "",
    engStatus: "",
    engDetails: null,
    searchLoader: false,
    filterLoader: false,
    labels: {
      languagesLabel: {},
      statusesLabel: {},
      companyTypeLabel: {},
      decaDevsLabel: {},
      isLoading: true,
    },
    squadDetails: {
      currentState: "",
      currentSquad: null,
      squads: [],
      squadsObj: {},
    },
    openRatingModal: {
      open: false,
      assignmentId: "",
    },
  };

  const [state, dispatch] = useReducer(AdminReducer, initialState);
  const {
    searchClientEngineers,
    searchClientSubscription,
    searchClients,
    searchEngineers,
    searchNewClients,
    searchSubscriptions,
    searchSubReq,
    searchEngReq,
    searchAdminSubReq,
    searchAdminEngReq,
    searchPlacementHistory,
  } = useSearchAndFilter(state, dispatch);

  let OrganizationDetails =
    JSON.parse(localStorage.getItem("client_details")) || {};
  let organisationId = OrganizationDetails.organisationId;

  const openSearchLoader = () => {
    dispatch({
      type: ON_SEARCH_LOADER,
    });
  };

  const closeSearchLoader = () => {
    dispatch({
      type: OFF_SEARCH_LOADER,
    });
  };

  const openFilterLoader = () => {
    dispatch({
      type: ON_FILTER_LOADER,
    });
  };

  const closeFilterLoader = () => {
    dispatch({
      type: OFF_FILTER_LOADER,
    });
  };

  const increment = () => {
    dispatch({
      type: INCREMENT_PAGE,
    });
  };

  const decrement = () => {
    dispatch({
      type: DECREMENT_PAGE,
    });
  };

  const newPage = (n) => {
    dispatch({
      type: SET_PAGE,
      payload: n,
    });
  };

  const setKeyword = (str) => {
    newPage(1);
    dispatch({
      type: SET_KEYWORD,
      payload: str,
    });
  };

  const setStatus = (str) => {
    newPage(1);
    dispatch({
      type: SET_STATUS,
      payload: str,
    });
  };

  const setEngStatus = (str) => {
    newPage(1);
    dispatch({
      type: SET_ENG_STATUS,
      payload: str,
    });
  };

  const setTrainingLocation = (str) => {
    newPage(1);
    dispatch({
      type: SET_TRAINING_LOCATION,
      payload: str,
    });
  };

  const setEngineerGender = (str) => {
    newPage(1);
    dispatch({
      type: SET_ENG_GENDER,
      payload: str,
    });
  };

  const setEngineerAge = (str) => {
    newPage(1);
    dispatch({
      type: SET_ENG_AGE,
      payload: str,
    });
  };

  const setCurrentLocation = (str) => {
    newPage(1);
    dispatch({
      type: SET_CURRENT_LOCATION,
      payload: str,
    });
  };

  const setMinAge = (str) => {
    newPage(1);
    dispatch({
      type: SET_MIN_AGE,
      payload: str,
    });
  };

  const setMaxAge = (str) => {
    newPage(1);
    dispatch({
      type: SET_MAX_AGE,
      payload: str,
    });
  };

  const setLanguage = (str) => {
    newPage(1);
    dispatch({
      type: SET_LANGUAGE,
      payload: str,
    });
  };

  const setSquad = (str) => {
    newPage(1);
    dispatch({
      type: SET_SQUAD,
      payload: str,
    });
  };

  const setSquadId = (str) => {
    newPage(1);
    dispatch({
      type: SET_SQUAD_ID,
      payload: str,
    });
  };

  const setEngineerStatus = (str) => {
    newPage(1);
    dispatch({
      type: SET_ENGINEER_STATUS,
      payload: str,
    });
  };

  const setClientType = (str) => {
    newPage(1);
    dispatch({
      type: SET_CLIENT_TYPE,
      payload: str,
    });
  };
  const setCustomerType = (str) => {
    newPage(1);
    dispatch({
      type: SET_CUSTOMER_TYPE,
      payload: str,
    });
  };

  const setEngineerType = (str) => {
    newPage(1);
    dispatch({
      type: SET_ENGINEER_TYPE,
      payload: str,
    });
  };

  const setLabels = (payload) => {
    dispatch({
      type: SET_LABELS,
      payload: payload,
    });
  };

  const setSquadDetails = (payload) => {
    dispatch({
      type: SET_SQUAD_DETAILS,
      payload: payload,
    });
  };
  const setOpenRatingModal = (id) => {
    dispatch({
      type: SET_OPEN_RATING_MODAL,
      payload: {
        assignmentId: id,
      },
    });
  };
  const setCloseRatingModal = () => {
    dispatch({
      type: SET_CLOSE_RATING_MODAL,
    });
  };

  //Fetch table data
  const fetchClients = async (edit = false) => {
    !edit && dispatch({ type: LOADING, payload: true });

    try {
      const res = await apiGet(
        `/admin/organisations?page=${state.page}&size=12&search=${state.keyword}&status=${state.status}&client_type=${state.customerType}`,
        "admin"
      );
      if (res.error) {
        throw res.data.response.status === 500 ? new Error("") : res.data;
      }
      dispatch({
        type: FETCH_CLIENT_SUCCESS,
        payload: res.data.data.content || [],
        meta: res.data.data,
      });
    } catch (err) {
      toast.error(
        err?.response?.data?.message || "Error Fetching organization"
      );
    }

    dispatch({ type: LOADING, payload: false });
  };

  //Fetch new table data
  const fetchNewClients = async () => {
    dispatch({ type: LOADING, payload: true });
    try {
      const res = await apiGet(
        `/admin/organisations?page=${state.page}&size=12&search=${state.keyword}`,
        "admin"
      );
      if (res.error) {
        throw res.data.response.status === 500 ? new Error("") : res.data;
      }

      dispatch({
        type: FETCH_NEW_CLIENT_SUCCESS,
        payload: res.data.data.content || [],
      });
    } catch (err) {
      toast.error(
        err?.response?.data?.message || "Error Fetching organization"
      );
    }
    dispatch({ type: LOADING, payload: false });
  };

  //Fetch subscription table data
  const fetchSubscriptions = async () => {
    dispatch({ type: LOADING, payload: true });
    try {
      const res = await apiGet(
        `/admin/subscriptions?page=${state.page}&size=12&search=${state.keyword}&status=${state.status}`,
        "admin"
      );

      dispatch({
        type: FETCH_SUBSCRIPTION_SUCCESS,
        payload: res.data.data.content || [],
        meta: res.data.data,
      });
    } catch (err) {
      toast.error("Error");
    }

    dispatch({ type: LOADING, payload: false });
  };

  //Fetch engineers table data
  const fetchEngineers = async (initialLoad = true) => {
    initialLoad && dispatch({ type: LOADING, payload: true });
    try {
      const res = await apiGet(
        `/admin/engineers?page=${state.page}&size=12&search=${state.keyword}&stack=${state.language}&status=${state.engStatus}&engTyp=${state.engineerType}&assignTyp=${state.engineerStatus}&squad=${state.squad}`,
        "admin"
      );
      dispatch({
        type: FETCH_ENGINEER_SUCCESS,
        payload: res.data.data.content || [],
        meta: res.data.data,
      });
    } catch (err) {
      toast.error("Error");
    }

    dispatch({ type: LOADING, payload: false });
  };

  //Fetch top engineers table data
  const fetchTopEngineers = async () => {
    dispatch({ type: LOADING, payload: true });
    try {
      const res = await apiGet(
        `/admin/dashboard/analytics/topEngineers?page=${state.page}&size=12&search=${state.keyword}`,
        "admin"
      );
      dispatch({
        type: FETCH_TOP_ENGINEER_SUCCESS,
        payload: res.data.data || [],
        meta: res.data.data,
      });
    } catch (err) {
      toast.error("Error");
    }

    dispatch({ type: LOADING, payload: false });
  };

  //Fetch user listing per organization
  const viewUsers = async (id) => {
    dispatch({ type: LOADING, payload: true });

    try {
      const res = await apiGet(`/admin/organisations/${id}/users`);
      dispatch({
        type: FETCH_USER_SUCCESS,
        payload: res.data.data.content || [],
      });
    } catch (err) {
      toast.error("Error");
    }
    dispatch({ type: LOADING, payload: false });
  };

  //Fetch subscription listing per organization
  const viewSubs = async (id) => {
    dispatch({ type: LOADING, payload: true });

    try {
      const res = await apiGet(`/admin/organisations/${id}/subscriptions`);
      dispatch({
        type: ORG_SUB_SUCCESS,
        payload: res.data.data.content || [],
      });
    } catch (err) {
      toast.error("Error");
    }
    dispatch({ type: LOADING, payload: false });
  };

  //Fetch assignment listing per engineer
  const fetchAssignmentListing = async (id, initialLoad = true) => {
    initialLoad && dispatch({ type: LOADING, payload: true });

    try {
      const res = await apiGet(`/admin/engineers/${id}/assignments`);
      dispatch({
        type: ASSIGNMENT_LISTING,
        payload: res.data.data || [],
      });
    } catch (err) {
      toast.error("Error");
    }
    dispatch({ type: LOADING, payload: false });
  };

  // Fetch Stack options
  const fetchStackOptions = async () => {
    try {
      const res = await apiGet(`/reference/techstacks`);

      dispatch({
        type: FETCH_STACK_SUCCESS,
        payload: res.data.data || [],
      });
    } catch (err) {
      toast.error(err);
    }
  };

  // Fetch client subscription
  const fetchClientSubscription = async () => {
    dispatch({ type: LOADING, payload: true });
    try {
      const res = await apiGet(
        `/admin/organisations/${organisationId}/subscriptions?page=${state.page}&size=12&search=${state.keyword}&status=${state.status}`,
        {},
        "client"
      );
      dispatch({
        type: FETCH_CLIENT_SUB_SUCCESS,
        payload: res.data.data.content || [],
        meta: res.data.data,
      });
    } catch (err) {
      toast.error(err.message);
    }

    dispatch({ type: LOADING, payload: false });
  };

  //fetch document

  const fetchDocument = async (orgId) => {
    try {
      const res = await apiGet(`/admin/organisations/${orgId}/documents`);
      dispatch({
        type: FETCH_DOCUMENT_SUCCESS,
        payload: res.data.data || [],
        meta: res.data.data,
      });
    } catch (err) {
      toast.error(err.message);
    }

    dispatch({ type: LOADING, payload: false });
  };

  const fetchInvoice = async (orgId) => {
    try {
      const res = await apiGet(`/admin/organisations/${orgId}/invoices
      `);

      dispatch({
        type: FETCH_INVOICE_SUCCESS,
        payload: res.data.data || [],
        meta: res.data.data,
      });
    } catch (err) {
      toast.error(err.message);
    }

    dispatch({ type: LOADING, payload: false });
  };

  const fetchLocation = async () => {
    try {
      const res = await apiGet(`/reference/locations`);
      dispatch({
        type: FETECH_LOCATION_SUCCESS,
        payload: res.data.data || [],
        meta: res.data.data,
      });
    } catch (err) {
      toast.error(err.message);
    }

    dispatch({ type: LOADING, payload: false });
  };

  const fetchTrainingLocation = async () => {
    try {
      const res = await apiGet(`/reference/locations?locType=batch`);
      dispatch({
        type: FETECH_TRAINING_LOCATION_SUCCESS,
        payload: res.data.data || [],
        meta: res.data.data,
      });
    } catch (err) {
      toast.error(err.message);
    }

    dispatch({ type: LOADING, payload: false });
  };

  //fetch document

  const fetchBillings = async () => {
    try {
      const res = await apiGet(`/admin/billings`);

      dispatch({
        type: FETCH_BILLING_SUCCESS,
        payload: res.data.data || [],
        meta: res.data.data,
      });
    } catch (err) {
      toast.error(err.message);
    }

    dispatch({ type: LOADING, payload: false });
  };

  // Fetch client Engineers
  const fetchClientEngineers = async () => {
    dispatch({ type: LOADING, payload: true });
    try {
      const res = await apiGet(
        `/private/placements?page=${state.page}&size=12&search=${state.keyword}`,
        {},
        "client"
      );
      dispatch({
        type: FETCH_CLIENT_ENG_SUCCESS,
        payload: res.data.data.content || [],
        meta: res.data.data,
      });
    } catch (err) {
      toast.error(err.message);
    }

    dispatch({ type: LOADING, payload: false });
  };

  //Fetch list of assignment info
  const fetchAssignmentInfo = async () => {
    try {
      const res = await apiGet("/forms/assignment", {}, "client");
      dispatch({
        type: FETCH_ASSIGNMENT_INFO,
        payload: res.data.data || [],
      });
    } catch (err) {
      toast.error(err.message);
    }

    dispatch({ type: LOADING, payload: false });
  };

  //Fetch list of assignment info
  const fetchUnassigned = async () => {
    try {
      const res = await apiGet("/admin/engineers/unassigned", {}, "admin");
      dispatch({
        type: FETCH_UNASSIGNED,
        payload: res.data.data || [],
      });
    } catch (err) {
      toast.error(err.message);
    }

    dispatch({ type: LOADING, payload: false });
  };

  //Fetch packages table data
  const fetchPackages = async () => {
    dispatch({ type: LOADING, payload: true });
    try {
      const res = await apiGet(
        `/admin/packages?page=${state.page}&size=12`,
        "admin"
      );
      dispatch({
        type: FETCH_PACKAGE_SUCCESS,
        payload: res.data.data || [],
      });
    } catch (err) {
      toast.error("Error");
    }

    dispatch({ type: LOADING, payload: false });
  };

  //Fetch admin subscription requests
  const fetchAdminSubReq = async () => {
    dispatch({ type: LOADING, payload: true });
    try {
      const res = await apiGet(
        `/admin/subscriptions/requests?page=${state.page}&size=12`,
        "admin"
      );
      dispatch({
        type: FETCH_ADMIN_SUB_SUCCESS,
        payload: res.data.data.content || [],
        meta: res.data.data,
      });
    } catch (err) {
      toast.error("Error");
    }

    dispatch({ type: LOADING, payload: false });
  };

  //Fetch admin engineer requests
  const fetchAdminEngReq = async () => {
    dispatch({ type: LOADING, payload: true });
    try {
      const res = await apiGet(
        `/admin/assignments/requests?page=${state.page}&size=12`,
        "admin"
      );

      dispatch({
        type: FETCH_ADMIN_ENG_SUCCESS,
        payload: res.data.data.content || [],
        meta: res.data.data,
      });
    } catch (err) {
      toast.error("Error");
    }

    dispatch({ type: LOADING, payload: false });
  };

  //Fetch industry table data
  const fetchIndustry = async (initial = true) => {
    initial && dispatch({ type: LOADING, payload: true });
    try {
      const res = await apiGet(
        `/admin/industries?page=${state.page}&size=12`,
        "admin"
      );
      dispatch({
        type: FETCH_INDUSTRY_SUCCESS,
        payload: res.data.data || [],
      });
    } catch (err) {
      toast.error("Error");
    }

    dispatch({ type: LOADING, payload: false });
  };

  //Fetch industry table data
  const fetchIndustryModal = async () => {
    try {
      const res = await apiGet(
        `/admin/industries?page=${state.page}&size=12`,
        "admin"
      );
      dispatch({
        type: FETCH_INDUSTRY_SUCCESS,
        payload: res.data.data || [],
      });
    } catch (err) {
      toast.error("Error");
    }
  };

  //Fetch squad table data
  const fetchSquads = async (initial = true) => {
    initial && dispatch({ type: LOADING, payload: true });
    try {
      const res = await apiGet(
        `/admin/squads?page=${state.page}&size=12`,
        "admin"
      );
      dispatch({
        type: FETCH_SQUAD_SUCCESS,
        payload: res.data.data || [],
        meta: res.data.data,
      });
    } catch (err) {
      toast.error("Error");
    }

    dispatch({ type: LOADING, payload: false });
  };

  //Fetch squad table data
  const fetchSquadsModal = async () => {
    try {
      const res = await apiGet(
        `/admin/squads?page=${state.page}&size=12`,
        "admin"
      );
      dispatch({
        type: FETCH_SQUAD_SUCCESS,
        payload: res.data.data || [],
        meta: res.data.data,
      });
    } catch (err) {
      toast.error("Error");
    }
  };

  //update Tech Stack table data real-time, to avoid loading modal.....
  const fetchUpdatedTechStackListing = async () => {
    try {
      const res = await apiGet("/admin/techstacks", "admin");

      dispatch({
        type: FETCH_TECH_STACK_SUCCESS,
        payload: res.data.data || [],
      });
    } catch (err) {
      toast.error("Error");
    }
  };

  //Fetch Tech Stack table data
  const fetchTechStackListing = async (initial = true) => {
    initial && dispatch({ type: LOADING, payload: true });
    try {
      const res = await apiGet("/admin/techstacks", "admin");

      dispatch({
        type: FETCH_TECH_STACK_SUCCESS,
        payload: res.data.data || [],
      });
    } catch (err) {
      toast.error("Error");
    }

    dispatch({ type: LOADING, payload: false });
  };

  // create subscription
  const createSubscription = async (orgId, formData) => {
    dispatch({ type: LOADING, payload: true });

    //make call to the api
    try {
      const res = await apiPost(
        `/organisations/${orgId}/subscriptions`,
        formData,
        {}
      );
      if (res.status < 400) {
        return true;
      }
    } catch (err) {}
    dispatch({ type: LOADING, payload: false });
  };

  // Engineer Assignment
  const assignEngineer = async (formData) => {
    dispatch({ type: LOADING, payload: true });
    try {
      await apiPost(`/assignments`, formData, {});
    } catch (err) {}

    dispatch({ type: LOADING, payload: false });
  };
  //fetch eng req

  const fetchEngReq = async () => {
    dispatch({ type: LOADING, payload: true });
    try {
      let response = await apiGet(
        `/private/assignments/requests?page=${state.page}&size=12`,
        {}
      );
      if (response.error) {
        throw response.data.response.status === 500
          ? new Error("")
          : response.data;
      }
      dispatch({
        type: FETCH_ENG_REQ_SUCCESS,
        payload: response.data.data.content || [],
        meta: response.data.data,
      });
    } catch (err) {
      console.log({ err: err });
      toast.error(err?.response?.data?.message || "error fetching Eng details");
    }
    dispatch({ type: LOADING, payload: false });
  };

  //fetch eng req

  const fetchEngReqModal = async () => {
    try {
      let response = await apiGet(
        `/private/assignments/requests?page=${state.page}&size=12`,
        {}
      );
      if (response.error) {
        throw response.data.response.status === 500
          ? new Error("")
          : response.data;
      }
      dispatch({
        type: FETCH_ENG_REQ_SUCCESS,
        payload: response.data.data.content || [],
        meta: response.data.data,
      });
    } catch (err) {
      console.log({ err: err });
      toast.error(err?.response?.data?.message || "error fetching Eng details");
    }
  };

  //fetch sub request
  const fetchSubReq = async () => {
    dispatch({ type: LOADING, payload: true });
    try {
      let response = await apiGet(
        `/private/subscriptions/requests?page=${state.page}&size=12`,
        {}
      );
      if (response.error) {
        throw response.data.response.status === 500
          ? new Error("")
          : response.data;
      }
      dispatch({
        type: FETCH_SUB_REQ_SUCCESS,
        payload: response.data.data.content || [],
        meta: response.data.data,
      });
    } catch (err) {
      toast.error(err?.response?.data?.message || "error fetching sub details");
    }
    dispatch({ type: LOADING, payload: false });
  };

  //fetch sub request
  const fetchSubReqModal = async () => {
    try {
      let response = await apiGet(
        `/private/subscriptions/requests?page=${state.page}&size=12`,
        {}
      );
      if (response.error) {
        throw response.data.response.status === 500
          ? new Error("")
          : response.data;
      }
      dispatch({
        type: FETCH_SUB_REQ_SUCCESS,
        payload: response.data.data.content || [],
        meta: response.data.data,
      });
    } catch (err) {
      toast.error(err?.response?.data?.message || "error fetching sub details");
    }
  };

  //Fetch placement History
  const fetchPlacementHistory = async (initialLoad = true) => {
    initialLoad && dispatch({ type: LOADING, payload: true });
    try {
      const res = await apiGet(
        `/admin/assignments?page=${state.page}&size=12&search=${state.keyword}&clientType=${state.clientType}&assignTyp=${state.engineerStatus}`,
        "admin"
      );
      if (res.error) {
        throw res.data.response.status === 500 ? new Error("") : res.data;
      }
      dispatch({
        type: FETCH_PLACEMENT_HISTORY,
        payload: res.data.data.content || [],
        meta: res.data.data,
      });
    } catch (err) {
      console.log({ err: err });
      toast.error("Error");
    }

    dispatch({ type: LOADING, payload: false });
  };

  // Fetch engineer Details
  const fetchEngineerDetails = async (engId) => {
    try {
      const res = await apiGet(`/admin/engineers/${engId}`);
      dispatch({
        type: FETCH_ENGINEER_DETAILS,
        payload: res.data.data || null,
      });
    } catch (err) {
      toast.error(err);
    }
  };

  // Clear Errors
  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });
  const gf = (pageType, id) => {
    if (pageType === "clients") {
      fetchClients();
    } else if (pageType === "overview") {
      fetchNewClients();
    } else if (pageType === "subscriptions") {
      fetchSubscriptions();
    } else if (pageType === "engineers") {
      fetchEngineers();
    } else if (pageType === "engineers_overview") {
      fetchTopEngineers();
    } else if (pageType === "client_subscriptions") {
      fetchClientSubscription();
    } else if (pageType === "client_engineers") {
      fetchClientEngineers();
    } else if (pageType === "users") {
      viewUsers(id);
    } else if (pageType === "sub") {
      viewSubs(id);
    } else if (pageType === "adminEngReq") {
      fetchAdminEngReq();
    } else if (pageType === "adminSubReq") {
      fetchAdminSubReq();
    } else if (pageType === "package") {
      fetchPackages();
    } else if (pageType === "squad") {
      fetchSquads();
    } else if (pageType === "industry") {
      fetchIndustry();
    } else if (pageType === "billing") {
      fetchBillings();
    } else if (pageType === "assignments") {
      fetchAssignmentListing(id);
    } else if (pageType === "techstack") {
      fetchTechStackListing();
    } else if (pageType === "clientSubReq") {
      fetchSubReq();
    } else if (pageType === "clientEngineersReq") {
      fetchEngReq();
    } else if (pageType === "placement-history") {
      fetchPlacementHistory();
    }
  };

  return (
    <AdminContext.Provider
      value={{
        client_token: state.client_token,
        assignment_info: state.assignment_info,
        engineersList: state.engineersList,
        subReqList: state.subReqList,
        engReqList: state.engReqList,
        assignmentStatusList: state.assignmentStatusList,
        organisationsList: state.organisationsList,
        loading: state.loading,
        page: state.page,
        fetchClients,
        fetchNewClients,
        fetchSubscriptions,
        fetchClientSubscription,
        fetchEngineers,
        fetchTopEngineers,
        fetchStackOptions,
        fetchClientEngineers,
        fetchBillings,
        fetchPlacementHistory,
        viewUsers,
        viewSubs,
        fetchPackages,
        fetchSquads,
        fetchIndustry,
        fetchAssignmentListing,
        fetchTechStackListing,
        fetchUpdatedTechStackListing,
        fetchUnassigned,
        fetchEngReq,
        fetchSubReq,
        fetchEngReqModal,
        fetchSubReqModal,
        fetchAdminSubReq,
        fetchAdminEngReq,
        fetchIndustryModal,
        fetchSquadsModal,
        fetchDocument,
        fetchInvoice,
        gf,
        dashboardData: state.dashboardData,
        locations: state.locations,
        clearErrors,
        assignEngineer,
        createSubscription,
        stackList: state.stackList,
        levelList: state.levelList,
        fetchAssignmentInfo,
        increment,
        decrement,
        newPage,
        setKeyword,
        setStatus,
        setClientType,
        setCustomerType,
        setEngineerType,
        setLanguage,
        setSquad,
        setSquadId,
        setEngineerStatus,
        setEngStatus,
        keyword: state.keyword,
        status: state.status,
        trainingLocation: state.trainingLocation,
        currentLocation: state.currentLocation,
        minAge: state.minAge,
        maxAge: state.maxAge,
        engineerAge: state.engineerAge,
        setEngineerAge,
        engineerGender: state.engineerGender,
        setEngineerGender,
        setTrainingLocation,
        setCurrentLocation,
        setMinAge,
        setMaxAge,
        leadStatus: state.leadStatus,
        engStatus: state.engStatus,
        clientType: state.clientType,
        customerType: state.customerType,
        engineerType: state.engineerType,
        language: state.language,
        squad: state.squad,
        engineerStatus: state.engineerStatus,
        organisation: state.organisation,
        openSearchLoader,
        closeSearchLoader,
        openFilterLoader,
        closeFilterLoader,
        searchLoader: state.searchLoader,
        filterLoader: state.filterLoader,
        searchClientEngineers,
        searchClientSubscription,
        searchClients,
        searchEngineers,
        searchNewClients,
        searchSubscriptions,
        searchSubReq,
        searchEngReq,
        searchAdminSubReq,
        searchAdminEngReq,
        searchPlacementHistory,
        setLabels,
        labels: state.labels,
        setSquadDetails,
        squadDetails: state.squadDetails,
        setOpenRatingModal,
        setCloseRatingModal,
        openRatingModal: state.openRatingModal,
        engDetails: state.engDetails,
        fetchEngineerDetails,
        fetchLocation,
        fetchTrainingLocation,
        trainingLocations: state.trainingLocations,
        squadId: state.squadId,
      }}>
      {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = () => {
  const context = React.useContext(AdminContext);
  if (context === undefined) {
    throw new Error("useAdmin must be used within a AuthProvider");
  }
  return context;
};

export default AdminState;
