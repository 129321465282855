import {
  fetchSquadAdmin,
  fetchSquadAdminUsers,
  createSquadAdminData,
  // eslint-disable-next-line no-unused-vars
  deleteSquadAdmin,
  editSquadAdmin,
  fetchSingleSquadAdmin,
} from "./api";
import { useQuery, useMutation } from "react-query";

export const useFetchedSquadAdminList = (squadId) => {
  const { data, isLoading, refetch, isRefetching, isFetching, status } =
    useQuery(["squad-admin-list", squadId], () => fetchSquadAdmin(squadId), {
      enabled: squadId ? true : false,
    });

  return { data, isLoading, refetch, isRefetching, isFetching, status };
};

export const useFetchedSquadAdminUsersList = () => {
  const { data, isLoading, refetch, isRefetching, isFetching, status } =
    useQuery("squad-admin-users-list", fetchSquadAdminUsers);

  return { data, isLoading, refetch, isRefetching, isFetching, status };
};

export const useFetchedSingleSquadAdmin = (id) => {
  const { data, isLoading, refetch, isRefetching, isFetching, status } =
    useQuery([id, "single-squad-admin"], fetchSingleSquadAdmin, {
      enabled: id ? true : false,
    });

  return { data, isLoading, refetch, isRefetching, isFetching, status };
};

export const useCreateSquadAdmin = (config) => {
  const mutation = useMutation((params) =>
    createSquadAdminData(params, config)
  );
  const { data, mutate, isLoading, refetch, isFetching, status, isSuccess } =
    mutation;
  return { data, mutate, isLoading, refetch, isFetching, status, isSuccess };
};

export const useEditSquadAdmin = (config) => {
  const { data, mutate, isLoading, refetch, isFetching, status, isSuccess } =
    useMutation((params, formData) =>
      editSquadAdmin(params, formData, config, {})
    );
  return { data, mutate, isLoading, refetch, isFetching, status, isSuccess };
};

//   export const useDeleteSquadAdmin = () => {
//     const mutation = useMutation("delete-squad-admin-data", deleteSquadAdmin, {});
//     return mutation;
//   };
