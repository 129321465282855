export const callData ={
    industry: [
        {"value": "Choose your option"},
        {"value": "fintech"},
        {"value": "banking"},
        {"value": "medical"},
        {"value": "others"}
    ],
    location: [
        {"value": "Choose your option"},
        {"value": "Canada"},
        {"value": "Nigeria"},
        {"value": "China"}
    ],
    about_us: [
        {"value": "Choose your option"},
        {"value": "linkedIn"},
        {"value": "social media"},
        {"value": "word of mouth"},
        {"value": "others"}
    ]
}

const getCountryList=()=>{
    return fetch(`${process.env.REACT_APP_BASE_PATH}/reference/countries`)
        .then(response => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error('Something went wrong ...');
            }
        }).then(res =>{
            return res.data.map(dat => ({ value: dat.name}));
        })
}

export const subscribeData= async() => ({
    type_of_industry: [
        {"value": "Choose your option"},
        {"value": "fintech"},
        {"value": "banking"},
        {"value": "medical"},
        {"value": "others"}
    ],
    stack: [
        {"value": "Choose your option"},
        {"value": "Node"},
        {"value": ".NET"},
        {"value": "Java"},
        {"value": "Python"},
        {"value": "Android"},
        {"value": "IOS"},
        {"value": "QA"}
    ],
    employee_number: [
        {"value": "Choose your option"},
        {"value": "1-10"},
        {"value": "11-50"},
        {"value": "50-100"},
        {"value": "Above 100"}
    ],
    developer_number: [
        {"value": "Choose your option"},
        {"value": "1-10"},
        {"value": "11-50"},
        {"value": "50-100"},
        {"value": "Above 100"}
    ],
    location: [
        {"value": "Choose your option"},
        ...await getCountryList()
        
    ],
    engineers_base: [
        {"value": "Choose your option"},
        {"value": "Remote"},
        {"value": "Onsite"}
    ],
   subscription_packages: [
    {   "value": "Choose your option"},
        {"value": "Tier 1(N1,000,000 for 4 engineers)"},
        {"value": "Tier 2(N2,000,000 for 10 engineers)"}
    ],
    hear_about_us: [
        {"value": "Choose your option"},
        {"value": "linkedIn"},
        {"value": "social media"},
        {"value": "word of mouth"},
        {"value": "others"}
    ]

})