import React, { useState } from "react";
import Layout from "../common/Layout";
import DynamicTitle from "../common/DynamicTitle";
import ProtectedComponents from "../local-routes/ProtectedComponents";
import MyButton from "../../landingPage/MyButton";
import FormModal from "../form/FormModal";
import {
  useActivateUser,
  useDeactivateUser,
  useOrganisationUsers,
} from "../../../services/organisation/query";
import TableContainer from "../common/TableContainer";
import { Link, useLocation, useParams } from "react-router-dom";
import ActionDropdown from "../common/ActionDropdown";
import Pagination from "../atoms/Pagination";
import { useHistory } from "react-router-dom";
import { removeEmptyObj } from "../../../utils/helperFunctions";
import { StyledDashboardPage } from "../styles/styleDashboard";
import {
  deleteUsers,
  handleGenerateLink,
} from "../../../services/organisation/api";
import useDialog from "../../../utils/useDialog";
import AddUserForm from "../form/AddUserForm";
import dateFormat from "../../../utils/FormatDate"
import { StatusBtn } from "../styles/statusBtn";

const { TableDateFormat } = dateFormat;

const UserListingPage2 = ({ pageType }) => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const { deleteItemDialog } = useDialog();

  const queryParams = new URLSearchParams(window.location.search);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState("");
  const [userId, setUserId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const initialValues = {
    page: Number(queryParams.get("page")) || 1,
  };
  const [filterOptions, setFilterOptions] = useState(initialValues);
  const [paramsValues, setParamsValues] = useState(
    removeEmptyObj(initialValues, "size") || {}
  );

  const {
    data: organisationUsers,
    refetch: refetchUsers,
    isLoading,
  } = useOrganisationUsers(id, filterOptions.page);
  
  const onMutateSuccess = () => {
    refetchUsers();
  };

  const { mutate: activateUser } = useActivateUser(onMutateSuccess, id);
  const { mutate: deactivateUser } = useDeactivateUser(onMutateSuccess, id);

  const handleModal = (id) => {
    setIsModalOpen(!isModalOpen);
    setUserId(id);
  };

  const increment = () => {
    setParamsValues({ ...paramsValues, page: paramsValues.page + 1 });
    setFilterOptions({ ...filterOptions, page: filterOptions.page + 1 });

    const params = new URLSearchParams({
      ...paramsValues,
      page: paramsValues.page + 1,
    });
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const decrement = () => {
    setParamsValues({ ...paramsValues, page: paramsValues.page - 1 });
    setFilterOptions({ ...filterOptions, page: filterOptions.page - 1 });

    const params = new URLSearchParams({
      ...paramsValues,
      page: paramsValues.page - 1,
    });
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const newPage = (n) => {
    setParamsValues({ ...paramsValues, page: n });
    setFilterOptions({ ...filterOptions, page: n });

    const params = new URLSearchParams({ ...paramsValues, page: n });
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  return (
    <Layout navType="admin" pageLoading={isLoading}>
      <DynamicTitle title="Users | Fellowship Product" />
      <StyledDashboardPage isModalOpen={isModalOpen}>
        <Link to="#" onClick={() => history.goBack()} className="goBack">
          <img
            className="back-arrow-img"
            src="/images/back-arrow.svg"
            alt="back arrow"
          />
          <span className="go-back-text ml-2">Go Back</span>
        </Link>
        <div className="page-header">
          <h4>Users</h4>
          <ProtectedComponents permission={["manageOrganisationUser"]}>
            <div className="d-flex">
              <MyButton
                onClick={() => {
                  handleModal();
                  setActiveModal("add-user");
                }}
                className="btn-comp"
              >
                Add Users
              </MyButton>
            </div>
          </ProtectedComponents>
        </div>
        <TableContainer>
          <table style={{ width: "100%" }}>
            <thead style={{ width: "100%" }}>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Date Added</th>
                <th>Role</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody style={{ width: "100%" }}>
              {organisationUsers?.data?.content.map((item, index) => (
                <tr className="" key={item.id + index + item.name}>
                  <td>
                    {item.name}
                  </td>
                  <td>{item.email}</td>
                  <td>{item.phone}</td>
                  <td>{TableDateFormat(item.dateAdded)}</td>
                  <td>{item.role || "-"}</td>
                  <td>
                    <StatusBtn status={item?.status.toLowerCase()}>
                        {item?.status}    
                    </StatusBtn>    
                </td>
                  <td>
                    <ActionDropdown>
                      <ProtectedComponents
                        permission={["manageOrganisationUser"]}
                      >
                        <p onClick={() => handleGenerateLink(item.id)}>
                          Generate Login Link
                        </p>
                        <p
                          onClick={() => {
                            handleModal(item.id);
                            setIsEditing(true);
                            setActiveModal("update-user");
                          }}
                        >
                          Edit User info
                        </p>
                        <p onClick={() => activateUser(item)}>
                          Activate User
                        </p>
                        <p
                          onClick={() => deactivateUser(item)}
                          className="red"
                        >
                          Deactivate User
                        </p>
                        <p
                          onClick={() =>
                            deleteItemDialog(
                              deleteUsers,
                              { userId: item.id, orgId: id },
                              "user",
                              refetchUsers
                            )
                          }
                          className="red"
                        >
                          Delete User
                        </p>
                      </ProtectedComponents>
                    </ActionDropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
        {organisationUsers && (
          <div className="page-parent">
            <Pagination
              currentPage={organisationUsers?.data.number + 1}
              totalClients={organisationUsers?.data.numberOfElements}
              metaData={organisationUsers?.data}
              page={organisationUsers?.data.number + 1}
              newPage={newPage}
              increment={increment}
              decrement={decrement}
              className="pagination"
            />
          </div>
        )}
      </StyledDashboardPage>
      {isModalOpen && (
        <div className="form-modal">
          <FormModal handleModal={handleModal}>
            {(activeModal === "add-user" || activeModal === "update-user") && (
              <AddUserForm
                onClick={handleModal}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                pageType="admin_client"
                organizationId={id}
                updateId={userId}
                refetch={refetchUsers}
              />
            )}
          </FormModal>
        </div>
      )}
    </Layout>
  );
};

export default UserListingPage2;
