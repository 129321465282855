import React from "react";
import styled from "styled-components";

const Pagination = ({
  totalClients,
  currentPage,
  metaData,
  page,
  newPage,
  increment,
  decrement,
}) => {
  return (
    <StyledPaginationn currentPage={currentPage}>
      {!metaData?.empty ? (
        <ul className="pagination">
          {!metaData.first && (
            <button onClick={decrement} className="page-link">
              <img
                src="/images/chevron.svg"
                className="rotate-img"
                alt="previous"
              />
            </button>
          )}
          {page > 3 && (
            <div className="d-flex">
              <li className={`page-item ${page === 1 ? "active" : ""}`}>
                <button
                  onClick={() => {
                    newPage(1);
                  }}
                  className="page-link">
                  {1}
                </button>
              </li>
              <img
                src="/images/three-dots.svg"
                className="threeDots mr-4"
                alt="three dots"
              />
            </div>
          )}
          {(page === 1 || page === 2 || page === 3) &&
            metaData?.totalPages > 3 &&
            [0, 1, 2, 3].map((number) => (
              <li
                key={number + 1}
                className={`page-item ${page === number + 1 ? "active" : ""}`}>
                <button
                  onClick={() => {
                    newPage(number + 1);
                  }}
                  className="page-link">
                  {number + 1}
                </button>
              </li>
            ))}
          {page >= 1 &&
            metaData?.totalPages <= 3 &&
            [...Array(metaData?.totalPages).keys()].map((number) => (
              <li
                key={number + 1}
                className={`page-item ${page === number + 1 ? "active" : ""}`}>
                <button
                  onClick={() => {
                    newPage(number + 1);
                  }}
                  className="page-link">
                  {number + 1}
                </button>
              </li>
            ))}
          {page !== 1 &&
            page !== 2 &&
            page !== 3 &&
            [...Array(metaData?.totalPages).keys()]
              .map((number) => (
                <li
                  key={number + 1}
                  className={`page-item ${
                    page === number + 1 ? "active" : ""
                  }`}>
                  <button
                    onClick={() => {
                      newPage(number + 1);
                    }}
                    className="page-link">
                    {number + 1}
                  </button>
                </li>
              ))
              .slice(page - 2, page + 2)}
          {metaData?.totalPages > 3 && page < metaData?.totalPages - 3 ? (
            <img
              src="/images/three-dots.svg"
              className="threeDots mr-3"
              alt="three dots"
            />
          ) : (
            ""
          )}

          {metaData?.totalPages > 3 && page < metaData?.totalPages - 3 ? (
            <li
              key={metaData?.totalPages}
              className={`page-item ${
                page === metaData?.totalPages ? "active" : ""
              }`}>
              <button
                onClick={() => {
                  newPage(metaData?.totalPages);
                }}
                className="page-link">
                {metaData?.totalPages}
              </button>
            </li>
          ) : (
            ""
          )}
          {!metaData.last && (
            <button onClick={increment} className="page-link">
              <img src="/images/chevron.svg" className="" alt="next" />
            </button>
          )}
        </ul>
      ) : (
        ""
      )}
    </StyledPaginationn>
  );
};
const StyledPaginationn = styled.nav`
  display: flex;
  justify-content: center;
  height: 54px;
  .page-link {
    display: inline-block;
    font-size: 16px;
    color: black;
    padding: 0rem 1.5rem;
    z-index: 0;
    height: 100%;
  }
  .batch-page {
    display: inline-block;
    margin-left: 0.8rem;
  }
  .page-item.active .page-link,
  button.page-link:active {
    background-color: #34a853 !important;
    border: none;
    z-index: 0;
    outline: none;
    color: white;
  }
  .lastPage {
    font-size: 18px;
    color: #21334f;
    padding: 0 14px;
    display: flex;
    align-items: center;
  }
  .rotate-img {
    transform: rotate(180deg);
  }
  .threeDots {
    width: 32px;
    margin-left: 14px;
    margin-bottom: 7px;
    align-self: end;
  }

  @media only screen and (max-width: 500px) {
    .page-link {
      padding: 0rem 1.2rem;
    }

    .threeDots {
      width: 12px;
    }
  }
`;
export default Pagination;
