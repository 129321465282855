import React, { useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import CustomLoader from "../atoms/CustomLoader";
import { Burger } from "../atoms/Hamburger";
import ConfigForm from "../form/ConfigForm";
import FormModal from "../form/FormModal";
import DashboardSidebar from "./dashboardSidebar/DashboardSidebar";

const Layout = ({ children, navType, hasBackground, pageLoading }) => {
  const [open, setOpen] = useState(false);
  const [group, setGroup] = useState("");

  const [configModal, setConfigModal] = useState(false);
  const handleClose = () => setConfigModal(false);

  const handleShow = (group) => {
    setGroup(group);
    setConfigModal(true);
  };

  return (
    <Wrapper
      className={"dashboard-layout"}
      open={open}
      background={hasBackground}
    >
      <div className="dashboard-layout__sidebar">
        <DashboardSidebar navType={navType} handleShow={handleShow} open={open} setOpen={setOpen} />
      </div>
      <div className="top-nav">
        <Container className="d-flex align-items-center justify-content-between">
          <img src="/images/Decagon-logo.svg" className="logo" alt="Decagon's logo" />
          <Burger open={open} setOpen={setOpen} />
        </Container>
        </div>
      <div className="dashboard-layout__contents">   
        <Container>
          {pageLoading ? <CustomLoader /> : children}
        </Container>
      </div>
      {configModal && (
        <div className="form-modal">
          <FormModal className="form">
            <ConfigForm
              onClick={handleClose}
              group={group}

            />
          </FormModal>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
.dashboard-layout__contents {
  position: relative;
}
  .top-nav{
    display: none;
  } 

  .hamburger {
    display: none;
  }

  .logo{
    width: 15rem;
    height: 3.605rem;
  }

  .form-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .dashboard-layout {
    &__sidebar {
      transition: all 0.8s;
      position: fixed;
      height: 100vh;
      width: 304px;
      transform: translate(0px);
      z-index: 1;
    }

    &__contents {
      padding: 2rem 2.4rem 2rem 2.4rem;
      transition: all 0.8s;
      margin-left: 304px;
      min-height: 100vh;
      background: ${({ background }) =>
    background
      ? "#f0f5f1"
      : "linear-gradient(0deg, rgba(0,0,0,0.04),rgba(0,0,0,0.04)), #fafffb"};
    }

    @media screen and (max-width: 1170px) {
      &__sidebar {
        transform: ${({ open }) =>
    open ? "translateX(0px)" : "translateX(-304px)"};
        box-shadow: ${({ open }) =>
    open ? "5px 0px 4px 2px #0000000f" : "unset"};
      }
      &__contents {
        margin-left: 0px;
        padding: 2.8rem 1rem 2rem 1rem;
      }
    }
  }
  @media (min-width: 1200px){
    .container {
      max-width: 1200px ;
    }

}
  @media screen and (max-width: 1170px) {
    .hamburger {
      display: flex;
    }

    .top-nav{
      display: block;
      padding: 2rem 0;
      background-color: #fff;
    }
  }
  @media only screen and (min-width: 280px) and (max-width: 728px){
  .dashboard-layout__contents {
      padding: 2.8rem .5rem 2rem .5rem;
  }
}
`;

export default Layout;
