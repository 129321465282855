import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { apiGet, apiPut } from "../../../../utils/apiHelpers";
import { options } from "../../form/formSelectOptions";
import { toast } from "react-toastify";

const OrganizationProfile = ({ pageType }) => {
  const [success, setSuccess] = useState("");

  const [initialValues] = useState({
    email: "",
    name: "",
    address: "",
    status: "",
    code: "",
    telephoneNumber: "",
    numberOfDevelopers: "",
    numberOfEmployees: "",
    contactPerson: "",
  });

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email format").required("*Required"),
    name: Yup.string().required("*Required"),
    address: Yup.string().required("*Required"),
    code: Yup.string().required("*Required"),
    status: Yup.string().required("*Required"),
    contactPerson: Yup.string().required("*Required"),
    telephoneNumber: Yup.string().required("*Required"),
    numberOfDevelopers: Yup.string().required("*Required"),
    numberOfEmployees: Yup.string().required("*Required"),
  });

  const fetchOrgProfile = async () => {
    try {
      let response = await apiGet(`/private/profile`);
      let res = response.data.data;
      const fields = [
        "email",
        "name",
        "address",
        "code",
        "status",
        "contactPerson",
        "telephoneNumber",
        "numberOfDevelopers",
        "numberOfEmployees",
      ];
      fields.forEach((field) => formik.setFieldValue(field, res[field], false));
    } catch (err) {
      toast.error(err);
    }
  };
  useEffect(() => {
    fetchOrgProfile();
    // eslint-disable-next-line
  }, []);

  const new_token = localStorage.getItem("token");

  let config = {
    headers: {
      Authorization: `Bearer ${new_token}`,
      "Content-type": "application/json; charset=UTF-8",
    },
  };

  const updateOrgProfile = async (formData) => {
    try {
      await apiPut(`/private/profile`, formData, config);
      toast.success("You've successfully updated profile");
      setSuccess(true);
    } catch (err) {
      toast.error(console.log({ err }));
    }
  };
  useEffect(() => {
    fetchOrgProfile();
    // eslint-disable-next-line
  }, [success]);

  const onSubmit = (values) => {
    updateOrgProfile(values);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <StyledDiv>
      <h3 className="profile">Profile Information</h3>
      <form onSubmit={formik.handleSubmit}>
        <div className="content mt-4">
          <div className="grid-container">
            <div className="personal-info-div">
              <img
                className="personal-info"
                src="/images/personal-info.svg"
                alt="info-icon"
              />
            </div>
            <div className="row change-row">
              <div className="col-md-1">
                <img
                  className="save-icon"
                  src="/images/save-icon.svg"
                  alt="save-icon"
                />
              </div>
              <div className=" change col-md-11 px-0">Change profile image</div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  error={
                    formik.touched.name && formik.errors.name
                      ? formik.errors.name
                      : null
                  }
                  disabled
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="contactPerson">Contact Person</label>
                <input
                  type="text"
                  className="form-control"
                  id="contactPerson"
                  name="contactPerson"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.contactPerson}
                  error={
                    formik.touched.contactPerson && formik.errors.contactPerson
                      ? formik.errors.contactPerson
                      : null
                  }
                />
                {formik.errors.contactPerson && formik.touched.contactPerson ? (
                  <div className="error-div mt-2">
                    {formik.errors.contactPerson}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="email">Email Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={
                    formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : null
                  }
                />
                {formik.errors.email && formik.touched.email ? (
                  <div className="error-div mt-2">{formik.errors.email}</div>
                ) : null}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="code">Code</label>
                <input
                  type="text"
                  className="form-control"
                  id="code"
                  name="code"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.code}
                  error={
                    formik.touched.code && formik.errors.code
                      ? formik.errors.code
                      : null
                  }
                  disabled
                />
              </div>
            </div>
          </div>

          <div className="mt-3">
            <div className="form-group">
              <label htmlFor="address">Address</label>
              <input
                type="address"
                className="form-control"
                id="address"
                name="address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
                error={
                  formik.touched.address && formik.errors.address
                    ? formik.errors.address
                    : null
                }
                disabled
              />
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="numberOfDevelopers">Number of Developers</label>
                <input
                  type="text"
                  className="form-control"
                  id="numberOfDevelopers"
                  name="numberOfDevelopers"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.numberOfDevelopers}
                  error={
                    formik.touched.numberOfDevelopers &&
                    formik.errors.numberOfDevelopers
                      ? formik.errors.numberOfDevelopers
                      : null
                  }
                />
                {formik.errors.numberOfDevelopers &&
                formik.touched.numberOfDevelopers ? (
                  <div className="error-div mt-2">
                    {formik.errors.numberOfDevelopers}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="numberOfEmployees">Number of Employees</label>
                <input
                  type="text"
                  className="form-control"
                  id="numberOfEmployees"
                  name="numberOfEmployees"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.numberOfEmployees}
                  error={
                    formik.touched.numberOfEmployees &&
                    formik.errors.numberOfEmployees
                      ? formik.errors.numberOfEmployees
                      : null
                  }
                />
                {formik.errors.numberOfEmployees &&
                formik.touched.numberOfEmployees ? (
                  <div className="error-div mt-2">
                    {formik.errors.numberOfEmployees}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="telephoneNumber">Telephone Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="telephoneNumber"
                  name="telephoneNumber"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.telephoneNumber}
                  error={
                    formik.touched.telephoneNumber &&
                    formik.errors.telephoneNumber
                      ? formik.errors.telephoneNumber
                      : null
                  }
                />
                {formik.errors.telephoneNumber &&
                formik.touched.telephoneNumber ? (
                  <div className="error-div mt-2">
                    {formik.errors.telephoneNumber}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="status" className="form-font form-label">
                  Status
                </label>

                <div className="select-field">
                  <select
                    id="status"
                    name="status"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.status}
                    error={
                      formik.touched.status && formik.errors.status
                        ? formik.errors.status
                        : null
                    }
                  >
                    {options.map((option, index) => (
                      <option key={index} value={option.value} disabled>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                {formik.errors.status && formik.touched.status ? (
                  <div className="error-div mt-2">{formik.errors.status}</div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="mt-4">
            <button type="submit">Save changes</button>
          </div>
        </div>
      </form>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  color: rgba(33, 51, 79, 1);
  .content {
    background: #fff;
    padding: 2.5rem 2.5rem 8.5rem 2.5rem;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 7fr;
  }
  .save-icon {
    height: 2rem;
  }
  .personal-info-div {
    width: 6rem;
  }
  .change {
    font-size: 1.5rem;
    font-weight: 500;
    color: rgba(52, 168, 83, 1);
  }
  .change-row {
    margin-top: 2rem;
  }

  form {
    margin-top: 2rem;
  }
  .main-password {
    position: relative;
  }
  .error-div {
    color: red;
    font-size: 1.4rem;
  }

  .profile {
    font-size: 2.3rem;
    font-weight: 600;
  }
  label {
    font-size: 1.8rem;
    font-weight: 600;
    opacity: 0.9;
  }
  input {
    height: 42px;
    margin-top: 1rem;
    font-size: 1.5rem;
  }
  button {
    float: right;
    color: #fff;
    background: linear-gradient(96.67deg, #34a853 0%, #b8d344 100%);
    border: none;
    font-size: 2rem;
    font-weight: 600;
    padding: 1rem 2rem;
  }

  .select-field {
    margin-top: 2.1rem;
    height: 4.1rem;
    border: 1px solid #ced4da;
    padding: 1rem 0rem 1.5rem 0.5rem;
  }

  select {
    display: inline-block;
    font-size: 1.6rem;
    color: rgba(33, 51, 79, 0.8);
    width: 100%;
  }

  @media screen and (min-width: 320px) and (max-width: 768px) {
    .change {
      font-size: 1.2rem;
      font-weight: 500;
      color: rgba(52, 168, 83, 1);
    }
    .change-row {
      margin-top: 2rem;
      margin-left: 2rem;
    }
  }
`;

export default OrganizationProfile;
