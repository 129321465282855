import { useQuery } from "react-query";
import {
  fetchRegisteredTotalNum,
  fetchSignUpData,
  fetchProfileViewsData,
  fetchChooseEngineersForMeData,
  fetchSelectedEngRequests,
  fetchChooseForMeAndSelectEngineerData,
} from "./api";

export const useTotalRegistered = (startDate, endDate) => {
  const { data, isLoading, refetch } = useQuery(
    [startDate, endDate, "total-registered"],
    fetchRegisteredTotalNum,
    {
      keepPreviousData: true,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetSignUpData = (startDate, endDate, orgId) => {
  const { data, isLoading, refetch } = useQuery(
    [startDate, endDate, "sign-up-data"],
    fetchSignUpData,
    {}
  );

  return { data, isLoading, refetch };
};

export const useGetProfileViewsData = (startDate, endDate, orgId) => {
  const { data, isLoading, refetch } = useQuery(
    [startDate, endDate, orgId, "profile-views"],
    fetchProfileViewsData,
    {}
  );

  return { data, isLoading, refetch };
};

export const useGetChooseEngineersForMeData = (startDate, endDate, orgId) => {
  const { data, isLoading, refetch } = useQuery(
    [startDate, endDate, orgId, "choose-for-engineers-for-me"],
    fetchChooseEngineersForMeData,
    {}
  );

  return { data, isLoading, refetch };
};

export const useGetSelectedEngRequests = (startDate, endDate, orgId) => {
  const { data, isLoading, refetch } = useQuery(
    [startDate, endDate, orgId, "selected-eng"],
    fetchSelectedEngRequests,
    {}
  );

  return { data, isLoading, refetch };
};

export const useChooseForMeAndSelectEngineer = (startDate, endDate, orgId) => {
  const { data, isLoading, refetch } = useQuery(
    [startDate, endDate, orgId, "choose-for-engineers-for-me/select-engineer"],
    fetchChooseForMeAndSelectEngineerData,
    {}
  );

  return { data, isLoading, refetch };
};
