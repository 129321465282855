import React, { useRef, useState } from 'react';
import styled from "styled-components";
import { format, isValid } from 'date-fns';
import FocusTrap from 'focus-trap-react';
import { DayPicker } from 'react-day-picker';
import { usePopper } from 'react-popper';

export default function DatePickerDialog({label, required, name, setDate, date}) {
  const [selected, setSelected] = useState();
  const [isPopperOpen, setIsPopperOpen] = useState(false);
    
  const popperRef = useRef(null);
  const buttonRef = useRef(null);
  const [popperElement, setPopperElement] = useState(
    null
  );

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start'
  });

  const closePopper = () => {
    setIsPopperOpen(false);
    buttonRef?.current?.focus();
  };

  const handleInputChange = (e) => {
    setDate(e.currentTarget.value);
    // const date = parse(e.currentTarget.value, 'dd/MM/y', new Date());
    const date = e.target.value
    if (isValid(date)) {
      setSelected(date);
      setDate(date);
    } else {
      setSelected(undefined);
      setDate("");
    }
  };

  const handleButtonClick = () => {
    setIsPopperOpen(true);
  };

  const handleDaySelect = (date) => {
    const formatDate = format(date, 'y-MM-dd')

    setSelected(date);
    setDate(formatDate);
    if (date) {
      setDate(format(date, 'y-MM-dd'));
      closePopper();
    } else {
      setDate('');
    }
  };

  return (
    <div className='position-relative'>
      <StyledFormInputComponent ref={popperRef}>
        {label && (
            <label htmlFor={name} className="form-font form-label">
            {label}
            {required && <span style={{ color: "red" }}> *</span>}
            </label>
        )}
        <input
          type="text"
          name={name}
          placeholder="yyyy-mm-dd"
          value={date}
          onChange={handleInputChange}
          className="input-reset pa2 ma2 bg-white black ba"
          disabled
        />
        <button
          ref={buttonRef}
          type="button"
          className="pa2 bg-white button-reset ba"
          aria-label="Pick a date"
          onClick={handleButtonClick}
        >
          <span role="img" aria-label="calendar icon">
            📅
          </span>
        </button>
      </StyledFormInputComponent>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
            fallbackFocus: buttonRef.current
          }}
        >
          <div
            tabIndex={-1}
            style={popper.styles.popper}
            className="dialog-sheet"
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
          >
            <DayPicker
              initialFocus={isPopperOpen}
              mode="single"
              defaultMonth={selected}
              fromYear={1990}
              toYear={4025}
              captionLayout="dropdown"
              selected={selected}
              onSelect={handleDaySelect}
            />
          </div>
        </FocusTrap>
      )}
    </div>
  );
}

const StyledFormInputComponent = styled.div`
  margin-bottom: 2.4rem;
  input {
    display: inline-block;
    font-size: 1.6rem;
    background: transparent;
    -webkit-appearance: none;
    min-width: 100%;
    border: none;
    height: 4.8rem;
    padding-bottom: 0rem;
    padding-left: 1rem;
    border: 1px solid rgba(33, 51, 79, 0.15) !important;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input:disabled {
    background: rgba(0, 0, 0, 0.1);
  }

  input:active {
    border: 1px solid green !important;
  }
  input:focus {
    border: 1px solid #34a853 !important;
  }
  button{
    position: absolute;
    right: 6px;
    top: 44px
  }
  @media only screen and (max-width: 405px) {
    input {
      min-width: 100%;
    }
  }
`;