import React from "react";
import { useAuth } from "../../../context/AuthState";


const ProtectedComponents = ({ children, permission}) => {
    const { permissionCheck } = useAuth();
    const permRoles = localStorage?.getItem("roles");
    const permArr = permRoles?.split(",") || []
    let check = permissionCheck(permArr, permission) 
  return (
    <>
        {check ? children : ""}
    </>
  );
};

export default ProtectedComponents;
