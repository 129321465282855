import React, { useEffect, useState } from "react";
import ActivityCard from "../../../common/activityTrackerCards/ActivityTrackerDoughnut";
import { apiGet } from "../../../../../utils/apiHelpers";
// import { toast } from "react-toastify";

export default function AddUserTracking({ orgId }) {
    const [superAdmin, setSuperAdmin] = useState(null);
    const [secUser, setSecUser] = useState(null);
    const [totalUsers, setTotalUsers] = useState(null);
  const [period, setPeriod] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true)
        const res = await apiGet(`/admin/users/tracking?period=${period}`);
        setSuperAdmin(res.data.data.totalAdminUsers);
        setSecUser(res.data.data.totalSecondaryUsers);
        setTotalUsers(res.data.data.totalUsers);
        setIsLoading(false)
      } catch (error) {
        console.log(error.message);
        setIsLoading(false)
      }
    })();
  }, [orgId, period]);
  return (
    <ActivityCard
      header="Users"
      centerValue={totalUsers}
      centerText=""
      greenCardNumber={superAdmin}
      greenCardText="Super Admin"
      goldCardNumber={secUser}
      goldCardText="Secondary User"
      greenLabel="Super Admin"
      goldLabel="Secondary User"
      greenData={superAdmin}
      goldData={secUser}
      setPeriod={setPeriod}
      backgroundColor1="#B8D344"
      backgroundColor2="#000066"
      isLoading={isLoading}
    />
  );
}
