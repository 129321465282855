import { apiGet, apiPost, apiPut, apiDelete } from "../../utils/apiHelpers";

export const fetchTrainingData = async () => {
  try {
    const res = await apiGet(`/admin/training-data-points`);

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchSingleTrainingData = async (id) => {
  try {
    const res = await apiGet(`/admin/training-data-points/${id}`);

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const createTrainingData = async (formData, config) => {
  try {
    await apiPost(
      `/admin/training-data-points`,
      formData,
      config
    );
   
  } catch (err) {
    throw err
  }
};

export const editTrainingData = async (data, config) => {
  const { updateId: id, formData } = data;

  try {
    await apiPut(
      `/admin/training-data-points/${id}`,
      formData,
      config
    );
  } catch (err) {
    throw err
  }
};

export const deleteTrainingData = async (id, config) => {
  try {
    let response = await apiDelete(`/admin/training-data-points/${id}`, config);
    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
  } catch (err) {
    console.log({ err });
  }
};

export const deleteDataPoint = async (id) => {
  try {
    let response = await apiDelete(
      `/admin/training-data-points/${id}`
    );
    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
  } catch (err) {
    console.log({ err });
  }
};
