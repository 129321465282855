import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import FormInputComponent from "./enterpriseFormInputs/FormInputComponent";
import FormTitleSection from "./FormTitleSection";
import { apiPost, apiGet, apiPut } from "../../../utils/apiHelpers";
import { toast } from "react-toastify";
import { useAdmin } from "../../../context/AdminState";
import FormSelectComponent from "./enterpriseFormInputs/FormSelectComponent";
import DatePickerDialog from "../atoms/DatePicker";
import { getUnixTime, format, parseISO } from "date-fns";
const AddSquad = ({
  onClick,
  fetchSquad,
  pageType,
  isEditing,
  setIsEditing,
  updateId,
  OrganizationId,
}) => {
  const [initialValues] = useState({
    name: "",
    location: "",
  });

  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");

  const { fetchSquadsModal, trainingLocations, fetchTrainingLocation } =
    useAdmin();

  let locationOptions =
    trainingLocations?.trainingLocations?.length > 0
      ? trainingLocations?.trainingLocations?.map((location) => {
          return {
            value: location.id,
            label: location.name,
          };
        })
      : [];

  const fetchSingleSquad = async () => {
    try {
      let response = await apiGet(`/admin/squads/${updateId}`);
      let res = response.data.data;
      const fields = ["name", "location"];
      setStartDate(res["startDate"]);
      setEndDate(res["endDate"]);
      fields.forEach((field) => formik.setFieldValue(field, res[field], false));
    } catch (err) {
      console.log({ err: err });
    }
  };

  useEffect(() => {
    fetchTrainingLocation();
    if (isEditing) {
      fetchSingleSquad();
    }
    // eslint-disable-next-line
  }, []);

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
  });

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const new_token = localStorage.getItem("token");

  let multipartConfig = {
    headers: {
      Authorization: `Bearer ${new_token}`,
      "Content-Type": "application/json; charset=UTF-8",
    },
  };

  //create users
  const createSquad = async (formData) => {
    setLoading(true);
    //make call to the api
    try {
      if (!isEditing) {
        await apiPost(`/admin/squads`, formData, multipartConfig);
        setSuccess(true);
      }
      if (isEditing) {
        await apiPut(`/admin/squads/${updateId}`, formData, multipartConfig);
        setSuccess(true);
      }
    } catch (err) {
      console.log({ err });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchSquad();
    // eslint-disable-next-line
  }, [success]);

  const onSubmit = (values) => {
    if (
      (startDate || endDate) &&
      getUnixTime(new Date(endDate)) < getUnixTime(new Date(startDate))
    ) {
      toast.error("End date cannot be before start date");
      return;
    }
    values.startDate =
      startDate && format(parseISO(startDate), "dd/MM/y")
        ? format(parseISO(startDate), "dd/MM/y")
        : "";
    values.endDate =
      endDate && format(parseISO(endDate), "dd/MM/y")
        ? format(parseISO(endDate), "dd/MM/y")
        : "";

    createSquad(values);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  useEffect(() => {
    fetchSquadsModal();
    // eslint-disable-next-line
  }, [success]);
  const closeForm = () => {
    formik.resetForm();
    onClick();
    setSuccess(false);
  };

  return (
    <StyledAddUserForm success={success}>
      <FormTitleSection
        title={isEditing ? "Update Squad" : "Add New Squad"}
        onClick={closeForm}
      />
      <div className="form-section">
        <form onSubmit={formik.handleSubmit} className="client-form">
          <p className="form-font form-title">Squad Details</p>

          <FormInputComponent
            label="Squad Name"
            type="text"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : null
            }
            required={true}
          />

          <FormSelectComponent
            label="Squad Location"
            name="location"
            options={[
              { label: "Select Location", value: "" },
              ...locationOptions,
            ]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.location}
            error={
              formik.touched.location && formik.errors.location
                ? formik.errors.location
                : null
            }
            required={true}
          />

          <DatePickerDialog
            label="Start Date"
            name="startDate"
            setDate={setStartDate}
            date={startDate}
            required={true}
          />

          <DatePickerDialog
            label="End Date"
            name="endDate"
            setDate={setEndDate}
            date={endDate}
          />

          <MyButton
            className="form-btn add-client-btn"
            loading={loading}
            type="submit"
            full
          >
            {isEditing ? "Update Squad" : " Add New Squad"}
          </MyButton>
        </form>
        <div className="form-success card">
          <img
            src="/images/sucess-icon.svg"
            alt="green-tick"
            className="sucess-icon"
          />
          <h2 className="form-font green-text">Success</h2>
          <p className="form-font success-msg">
            You successfully added a Squad.
          </p>
          <MyButton className="form-btn success-btn" onClick={closeForm}>
            View List of Squads
          </MyButton>
        </div>
      </div>
    </StyledAddUserForm>
  );
};

const StyledAddUserForm = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);
  .underline {
    display: block;
  }

  input .assig-date {
    width: 100% !important;
  }
  .form-section {
    width: 500px;
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3.2rem;
  }
  .add-client-btn {
    margin-bottom: 23.8rem;
    margin-top: 5rem;
  }
  .client-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .stack-section {
    display: flex;
    flex-wrap: wrap;
    width: 32rem;
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    margin-top: 8rem;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2.rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 32rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
  .select-field {
    // max-width: 35rem;
  }
  .perm-label {
    color: #21334f;
    font-size: 1.6rem;
    font-weight: 600;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    grid-column-gap: 1rem;
  }
  .checkbox-area {
    -webkit-appearance: button;
    margin-right: 1.5rem;
    display: none;
  }

  .check-label {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }
  .checkmark {
    width: 25px;
    height: 25px;
    border: 2px solid #34a853;
    display: inline-block;
    border-radius: 3px;
    margin-right: 1rem;
    background: #34a853
      url("https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/White_check.svg/1200px-White_check.svg.png")
      center/1250% no-repeat;
    transition: background-size 0.2s cubic-bezier(0.7, 0, 0.18, 1.24);
  }
  .check-label input:checked + .checkmark {
    background-size: 60%;
    transition: background-size 0.25s cubic-bezier(0.7, 0, 0.18, 1.24);
  }
  .item-span {
    font-size: 1.3rem;
    font-weight: 600;
  }
  .perm-head {
    font-size: 1.6rem;
    font-weight: 600;
  }
`;

export default AddSquad;
