export const weeKList = [
  {
    name: 1,
  },
  {
    name: 2,
  },
  {
    name: 3,
  },
  {
    name: 4,
  },
  {
    name: 5,
  },
  {
    name: 6,
  },
  {
    name: 7,
  },
  {
    name: 8,
  },
  {
    name: 9,
  },
  {
    name: 10,
  },
  {
    name: 11,
  },
  {
    name: 12,
  },
  {
    name: 13,
  },
  {
    name: 14,
  },
  {
    name: 15,
  },
  {
    name: 16,
  },
];

export const mappedWeeks = weeKList.map((week, index) => {
  return {
    label: 'Week '+ week.name,
    value: week.name,
  };
});
