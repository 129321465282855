import React, { useState, useEffect } from "react";
import { apiGet } from "../../../../../utils/apiHelpers";
import Linegraph from "../../../common/activityTrackerCards/Linegraph";
import styled from "styled-components";

const AdminAddClient = () => {
  const [currentState, setCurrentState] = useState("All");
  const [period, setPeriod] = useState("");
  const [month, setMonth] = useState([]);
  const [count, setCount] = useState([]);

  const getAdminClientActivity = async () => {
    try {
      const res = await apiGet(
        `/admin/organisations/tracking?period=${period}`,
        'admin'
      );

      const data = res.data.data;
      
      const month = [];
      const count = [];
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        month.push(item.month);
        count.push(item.count);
      }
      setMonth(month);
      setCount(count);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getAdminClientActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentState]);

  const chartData = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0.5, "rgba(52, 168, 83, .24)");
    gradient.addColorStop(0.7, "rgba(52, 168, 83, 0.24)");

    return {
      labels: month,
      datasets: [
        {
          data: count,
          pointRadius: 2,
          backgroundColor: gradient,
          fill: true,
          borderColor: "#B8D344",
          tension: 0.6,
          borderWidth: 1.33,
        },
      ],
    };
  };
  const canvas = document.createElement("canvas");
  const theChart = chartData(canvas);

  return (
    <StyledTracker>
      <Linegraph
        theChart={theChart}
        title="Add Client"
        currentState={currentState}
        setCurrentState={setCurrentState}
        setPeriod={setPeriod}
      />
    </StyledTracker>
  );
};

const StyledTracker = styled.div``;
export default AdminAddClient;
