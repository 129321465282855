import React from "react";
import styled from "styled-components";
import ProtectedComponents from "../../local-routes/ProtectedComponents";

const SettingsLayout = ({ active, setActive, pageType }) => {
  return (
    <StyledDiv>
      {pageType !== "engineer" && (
        <div
          className={`tabs-div ${active === "info" ? "active" : ""}`}
          onClick={(e) => setActive("info")}
        >
          <span className="tabs-span">Personal Information</span>
          <span className="greater-icon">
            <img src="/images/greater-than.svg" alt="back-icon" />
          </span>
        </div>
      )}
      <div
        className={`tabs-div mt-3 ${active === "password" ? "active" : ""}`}
        onClick={(e) => setActive("password")}
      >
        <span className="tabs-span">Password & Security</span>
        <span className="greater-icon">
          <img src="/images/greater-than.svg" alt="back-icon" />
        </span>
      </div>
      {pageType !== "engineer" && (
        <ProtectedComponents
          permission={[
            "manageOrganisationUser",
            "viewAdminUser",
            "manageAdminUser",
          ]}
        >
          <div
            className={`tabs-div mt-3 ${active === "manage" ? "active" : ""}`}
            onClick={(e) => setActive("manage")}
          >
            <span className="tabs-span">Manage Users</span>
            <span className="greater-icon">
              <img src="/images/greater-than.svg" alt="back-icon" />
            </span>
          </div>
        </ProtectedComponents>
      )}
      {pageType === "admin" && (
        <ProtectedComponents permission={["manageRole"]}>
          <div
            className={`tabs-div mt-3 ${active === "roles" ? "active" : ""}`}
            onClick={(e) => setActive("roles")}
          >
            <span className="tabs-span">Roles</span>
            <span className="greater-icon">
              {" "}
              <img src="/images/greater-than.svg" alt="back-icon" />
            </span>
          </div>
        </ProtectedComponents>
      )}

      {pageType === "client" && (
        <div
          className={`tabs-div mt-3  ${
            active === "org-profile" ? "active" : ""
          }`}
          onClick={(e) => setActive("org-profile")}
        >
          <span className="tabs-span">Organization Profile</span>
          <span className="greater-icon">
            {" "}
            <img src="/images/greater-than.svg" alt="back-icon" />
          </span>
        </div>
      )}
    </StyledDiv>
  );
};
const StyledDiv = styled.div`
  min-width: max-content;
  img {
    height: 1.5rem;
    width: 1.5rem;
  }
  .tabs-div {
    background: #fff;
    padding: 2rem;
  }
  .greater-icon {
    float: right;
    border-radius: 50%;
    background: #e9eaeb;
    padding: 0.4rem;
  }
  .tabs-span {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .active {
    border: 1px solid #34a853;
    color: #34a853;
    background: #fff;
    padding: 2rem;
  }

  @media only screen and (min-width: 280px) and (max-width: 678px) {
  }
`;

export default SettingsLayout;
