import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import FormInputComponent from "./enterpriseFormInputs/FormInputComponent";
import FormTitleSection from "./FormTitleSection";
import { ClipLoader } from "react-spinners";
import { apiGet, apiPut } from "../../../utils/apiHelpers";

const EditInvoice = ({ handleModal, invoiceId, refetch }) => {
  const [invoiceItemDto, setInvoiceItemDto] = useState([]);
  const [allInvoiceDto, setAllInvoiceDto] = useState();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    handleViewInvoice();
    // eslint-disable-next-line
  }, []);

  const handleViewInvoice = async () => {
    try {
      const res = await apiGet(`/admin/invoices/${invoiceId}/details`);

      setInvoiceItemDto(res.data.data.listInvoiceLineItemDtos);
      setAllInvoiceDto(res.data.data);
    } catch (e) {
      console.log({ e });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const payload = {
      ...allInvoiceDto,
      listInvoiceLineItemDtos: invoiceItemDto,
    };

    try {
      await apiPut(`/admin/invoices/${invoiceId}`, payload);
      refetch()
      setSuccess(true);
    } catch (e) {
      console.log("error", e);
    }
    setLoading(false);
  };

  const handleInvoiceItemDtoChange = (e, index) => {
    const { name, value } = e.target;
    const newItems = [...invoiceItemDto];
    newItems[index][name] = value;
    setInvoiceItemDto(newItems);
  };

  const handleCloseModal = () => {
    setSuccess(false)
    handleModal()
  }

  return (
    <StyledCreatePackageForm success={success}>
      <FormTitleSection title="Edit Invoice" onClick={handleCloseModal} />
      <div className="form-section">
        <form className="client-form">
          <fieldset className="last-one">
            <legend className="heading-text">Invoice Item</legend>

            {invoiceItemDto &&
              invoiceItemDto?.map(
                (
                  {
                    amount,
                    description,
                    displayAmount,
                    displayUnitPrice,
                    id,
                    quantity,
                    taxType,
                    unitPrice,
                  },
                  index
                ) => (
                  <div key={index}>
                    <legend>{description}</legend>
                    <FormInputComponent
                      label="Unit Price"
                      name="unitPrice"
                      placeholder="$"
                      // prefix="$"
                      type="number"
                      allowDecimals={false}
                      // onBlur={formik.handleBlur}
                      value={unitPrice}
                      onChange={(e) => handleInvoiceItemDtoChange(e, index)}
                    />
                    <FormInputComponent
                      label="Quantity"
                      // name="displayAmount"
                      type="number"
                      name="quantity"
                      // onBlur={formik.handleBlur}
                      value={quantity}
                      onChange={(e) => handleInvoiceItemDtoChange(e, index)}
                    />
                  </div>
                )
              )}
          </fieldset>

          <MyButton
            className="form-btn add-client-btn"
            onClick={onSubmit}
            type={"submit"}
            full
          >
            {" "}
            {loading ? (
              <ClipLoader color={"#fff"} size={"30px"} />
            ) : (
              "Add New Rate Billing"
            )}
          </MyButton>
        </form>
      </div>
      <div className="form-success card p-3">
        <img
          src="/images/sucess-icon.svg"
          alt="green-tick"
          className="sucess-icon"
        />
        <h2 className="form-font green-text">Success</h2>
        <p className="form-font success-msg">
          Invoice Updated Successfully
        </p>
        <MyButton className="form-btn success-btn" onClick={handleCloseModal}>
          Okay, Go back to Invoice List
        </MyButton>
      </div>
    </StyledCreatePackageForm>
  );
};

const StyledCreatePackageForm = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);
  .form-section {
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3.2rem;
  }
  .add-client-btn {
    margin-bottom: 23.8rem;
  }
  .client-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2.rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 100% !important;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
  fieldset {
    border: 0.1px gray solid;
    padding: 1rem 2rem;
    margin-bottom: 2rem;
  }
  legend {
    background: linear-gradient(96.67deg, #34a853 0%, #b8d344 100%);
    color: white;
    text-align: center;
    padding: 1rem;
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2.rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 100% !important;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
`;

export default EditInvoice;
