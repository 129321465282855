import React, { useState, useEffect } from "react";
import Layout from "../common/Layout";
import DynamicTitle from "../common/DynamicTitle";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import TableContainer from "../common/TableContainer";
import ActionDropdown from "../common/ActionDropdown";
// import SelectComponent from "../../landingPage/form/SelectComponent";
import { apiGet } from "../../../utils/apiHelpers";
import { toast } from "react-toastify";
import AddWeeks from "../form/AddWeeksForm";
import FormModal from "../form/FormModal";
import useDialog from "../../../utils/useDialog";
import { deleteSquadWeeks } from "../../../services/weeks/api";
import { useParams } from "react-router-dom";
import { useGetWeeks } from "../../../services/weeks/query";
import { Link } from "react-router-dom";
import { Menu } from "@headlessui/react";
import { Fragment } from "react";
import { format } from "date-fns";
import ProtectedComponents from "../local-routes/ProtectedComponents";

const Weeks = ({ pageType }) => {
  const [showModal, setShowModal] = useState(false);
  const [singleWeekId, setWeekId] = useState("");
  const [edit, setEdit] = useState(false);
  const [editingValues, setEditingValues] = useState({});
  const [squadList, setSquadList] = useState([]);
  const { deleteItemDialog } = useDialog();
  const weekParams = useParams();
  const { id: squadParamsId } = weekParams;

  const { data: listWeeks, refetch, isLoading } = useGetWeeks(squadParamsId);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [squadParamsId]);

  // fetch squad
  const selectSquad = async () => {
    try {
      let response = await apiGet(`/admin/squads`, {});

      if (response.error) {
        throw response.data.response.status === 500
          ? new Error("")
          : response.data;
      }

      const squads = response.data.data.map((squad) => ({
        label: `SQ00${squad.name}`,
        value: squad.id,
      }));

      setSquadList(squads);
    } catch (err) {
      console.log({ err: err });
      toast.error(
        err?.response?.data?.message || "error fetching squad details"
      );
    }
  };

  useEffect(() => selectSquad(), []);

  const handleDelete = (id, name) => {
    deleteItemDialog(deleteSquadWeeks, id, name, refetch);
  };

  const fillerSelect = () => {
    let value = "";
    if (!isNaN(squadParamsId)) {
      value = squadList?.filter((squad) => {
        return squad?.value === Number(squadParamsId);
      });
    }
    return value;
  };
  const defaultValue = fillerSelect()[0];

  return (
    <Layout pageLoading={isLoading} navType="admin">
      <DynamicTitle title="Weeks | Fellowship Product" />

      <TrainingStyle>
        <div className="client-header">
          <h1>Weeks</h1>
          <ProtectedComponents permission={["manageSquad"]}>
            <MyButton
              onClick={() => {
                setShowModal(!showModal);
                setEdit(false);
                setEditingValues({});
              }}
              className="btn-doc"
            >
              Add New Week
            </MyButton>
          </ProtectedComponents>
        </div>

        <div>
          <Menu>
            <Menu.Button className={"menu_button"}>
              {!defaultValue?.label ? "Select Squad" : defaultValue?.label}
            </Menu.Button>
            <Menu.Items className={"menu_list"}>
              {squadList.map((option) => (
                /* Use the `active` state to conditionally style the active item. */
                <Menu.Item key={option.value} as={Fragment}>
                  {({ active }) => (
                    <Link
                      to={{
                        pathname: `/admin/weeks/${option.value}`,
                      }}
                      className={`menu_item ${
                        active ? "active_style" : "inactive_style"
                      }`}
                    >
                      {option.label}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Menu>
        </div>

        <TableContainer>
          <table style={{ width: "100%" }}>
            <thead style={{ width: "100%" }}>
              <tr>
                <th>Weeks</th>
                <th>Start Date</th>
                <th>End Date</th>
                <ProtectedComponents permission={["manageSquad"]}>
                  <th>Action </th>
                </ProtectedComponents>
              </tr>
            </thead>
            <tbody style={{ width: "100%" }}>
              {listWeeks?.data.length > 0 ? (
                listWeeks?.data?.map(
                  ({ id, name, startDate, endDate }, index) => (
                    <tr className="" key={index + name}>
                      <td>{name}</td>
                      <td>{startDate}</td>
                      <td>{endDate}</td>

                      <ProtectedComponents permission={["manageSquad"]}>
                        <td>
                          <ActionDropdown>
                            <p
                              onClick={() => {
                                setShowModal(!showModal);
                                setWeekId(id);
                                setEdit(true);
                                setEditingValues({
                                  endDate: format(
                                    new Date(endDate),
                                    "yyyy-MM-dd"
                                  ),
                                  startDate: format(
                                    new Date(startDate),
                                    "yyyy-MM-dd"
                                  ),
                                  name,
                                  squad: squadParamsId,
                                });
                              }}
                            >
                              Edit Week
                            </p>

                            <p
                              onClick={() => {
                                handleDelete(id, "Week " + name);
                              }}
                            >
                              Delete Week
                            </p>
                          </ActionDropdown>
                        </td>
                      </ProtectedComponents>
                    </tr>
                  )
                )
              ) : (
                <p className="no_weeks">No Weeks created for this Squad</p>
              )}
            </tbody>
          </table>
        </TableContainer>
      </TrainingStyle>

      {showModal && (
        <div className="form-modal">
          <FormModal
            className="form"
            handleModal={() => {
              setShowModal(false);
            }}
          >
            <AddWeeks
              onClick={() => {
                setShowModal(!showModal);
              }}
              updateId={singleWeekId}
              isEditing={edit}
              editingValues={editingValues}
              reload={refetch}
            />
          </FormModal>
        </div>
      )}
    </Layout>
  );
};

const TrainingStyle = styled.div`
  .client-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 4.8rem;
    margin-bottom: 1.1rem;
  }
  .client-header h1 {
    font-weight: 700;
    font-size: 2.4rem;
  }

  .no_weeks {
    font-size: 1.5rem;
    font-weight: 500;
  }

  //MenuDropDown
  .menu_button {
    width: 100px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-size: 1.5rem;
    padding: 1rem 2rem 1rem 1rem;
    border: 0;
  }

  .menu_list {
    position: absolute;
    height: 150px !important;
    background-color: #fff;
    overflow-y: auto;
  }
  .menu_item {
    display: block;
    font-size: 1.5rem;
    color: #000;
    padding: 1rem;
    overflow-y: auto;
    text-decoration: none;
  }

  .active_style {
    background-color: #3b82f6;
    color: #ffffff;
  }
`;

export default Weeks;
