import { apiGet } from "../../utils/apiHelpers";

//Fetch engineers table data
export const fetchEngineerRequests = async (params) => {
  const [filterOptions] = params.queryKey;
  try {
    const res = await apiGet(
      `/admin/assignments/requests?page=${filterOptions.page}`,
      "admin"
    );

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

//Fetch placements request table data
export const fetchPlacementRequests = async (params) => {
  const [filterOptions] = params.queryKey;
  try {
    const res = await apiGet(
      `/admin/engineer-placement-requests?page=${filterOptions.page}`,
      "admin"
    );

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchPlacementRequestDetail = async (params) => {
  const [requestId] = params.queryKey;
  try {
    const res = await apiGet(
      `/admin/engineer-placement-requests/${requestId}`,
      "admin"
    );

    return res.data;
  } catch (err) {
    console.log(err);
  }
};


