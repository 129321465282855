import React, { useState } from "react"
import Layout from "../common/Layout";
import DynamicTitle from "../common/DynamicTitle";
import FormModal from "../form/FormModal";
import TableContainer from "../common/TableContainer";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../../context/AuthState";
import ActionDropdown from "../common/ActionDropdown";
import Pagination from "../atoms/Pagination";
import TotalElements from "../common/TotalElements";
import { useHistory } from "react-router-dom";
import { removeEmptyObj } from "../../../utils/helperFunctions";
import { usePlacementHistory } from "../../../services/assignment/query";
import PlacementFilter from "../filterFields/PlacementFilter";
import EditAssignmentForm from "../form/EditAssignmentForm";
import { StyledDashboardPage } from "../../enterprise/styles/styleDashboard"
import {StatusBtn} from "../styles/statusBtn"

const PlacementListing = ({ pageType }) => {
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(window.location.search);

  const { permissionCheck } = useAuth();
  const permRoles = localStorage?.getItem("roles");
  const permArr = permRoles.split(",");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState("");
  const [assignmentId, setAssignmentId] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("");
  const initialValues = {
    page: Number(queryParams.get("page")) || 1,
    size: 12,
    search: "",
    assignTyp: "",
  };
  const [filterOptions, setFilterOptions] = useState(initialValues);
  const [paramsValues, setParamsValues] = useState(
    removeEmptyObj(initialValues, "size") || {}
  );

  const reset = () => {
    setSelectedFilter("");
  };

  const {
    data: placementHistoryList,
    refetch: refetchHistory,
    isFetched,
    isLoading,
  } = usePlacementHistory(filterOptions, reset);

  const handleModal = (id) => {
    setIsModalOpen(!isModalOpen);
    setAssignmentId(id);
  };

  let timer;

  const handleFilterChange = (name, value) => {
    if (name === "search") {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setFilterOptions({ ...filterOptions, page: 1, [name]: value });
        setParamsValues({ ...paramsValues, [name]: value });
        setSelectedFilter(name);

        const params = new URLSearchParams({ ...paramsValues, [name]: value });
        history.push({
          pathname: location.pathname,
          search: params.toString(),
        });
      }, 1000);
    } else {
      setFilterOptions({ ...filterOptions, page: 1, [name]: value });
      setParamsValues({ ...paramsValues, [name]: value });
      setSelectedFilter(name);  
      const params = new URLSearchParams({ ...paramsValues, [name]: value });
      history.push({
        pathname: location.pathname,
        search: params.toString(),
      });
    }
  };

  const increment = () => {
    setParamsValues({ ...paramsValues, page: paramsValues.page + 1 });
    setFilterOptions({ ...filterOptions, page: filterOptions.page + 1 });

    const params = new URLSearchParams({
      ...paramsValues,
      page: paramsValues.page + 1,
    });
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const decrement = () => {
    setParamsValues({ ...paramsValues, page: paramsValues.page - 1 });
    setFilterOptions({ ...filterOptions, page: filterOptions.page - 1 });

    const params = new URLSearchParams({
      ...paramsValues,
      page: paramsValues.page - 1,
    });
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const newPage = (n) => {
    setParamsValues({ ...paramsValues, page: n });
    setFilterOptions({ ...filterOptions, page: n });

    const params = new URLSearchParams({ ...paramsValues, page: n });
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  return (
    <Layout navType="admin" pageLoading={isLoading}>
      <DynamicTitle title="Placement History | Fellowship Product" />
      <StyledDashboardPage isModalOpen={isModalOpen}>
        <div className="page-header">
          <h4>Placement History</h4>
        </div>
        <PlacementFilter
          handleFilterChange={handleFilterChange}
          filterOptions={filterOptions}
          selectedFilter={selectedFilter}
        />
        {(filterOptions.search ||
          filterOptions.assignTyp) &&
          placementHistoryList?.data?.totalElements > 0 &&
          isFetched && (
            <TotalElements
              element="engineer"
              totalElements={placementHistoryList?.data?.totalElements}
            />
          )}
        <TableContainer>
          <table style={{ width: "100%" }}>
            <thead style={{ width: "100%" }}>
              <tr>
                <th>Engineer Name</th>
                <th>Organisation</th>
                <th>Placement Date</th>
                <th>Resumption Date</th>
                <th>End Date</th>
                <th>Source</th>
                <th>Type</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody style={{ width: "100%" }}>
              {placementHistoryList?.data?.content.map(
                (
                  {
                    placementType,
                    id,
                    engineerId,
                    assignmentDate,
                    statusDate,
                    endDate,
                    name,
                    status,
                    placementSource,
                    organisation
                  },
                  index
                ) => (
                  <tr className="" key={index + name}>
                    <td>
                      <Link
                        className="clickable"
                        to={{
                          pathname: `/admin/engineers/${engineerId}`,
                          state: {
                            pageTypeState: "admin-view-state",
                          },
                        }}>
                        {name}
                      </Link>
                    </td>
                    <td>{organisation}</td>
                    <td>{assignmentDate}</td>
                    <td>{statusDate}</td>
                    <td>{endDate}</td>
                    <td>{placementSource}</td>
                    <td>{placementType}</td>
                    <td>
                      {status ? (
                        <StatusBtn
                          className="status-bg"
                          status={status?.toLowerCase()}>
                          {status}
                        </StatusBtn>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>
                      <ActionDropdown>
                        {permissionCheck(permArr, ["managePlacement"]) && (
                          <p
                            onClick={() => {
                              handleModal(id);
                              setActiveModal("edit-placement");
                            }}>
                            Edit Placement
                          </p>
                        )}
                        {permissionCheck(permArr, ["viewPlacement", "adminMemberAccess"]) && (
                          <p>
                            <Link
                              to={{
                                pathname: `/admin/engineers/assignments/${engineerId}`,
                                state: {
                                  routeId: engineerId,
                                },
                              }}>
                              View Placement
                            </Link>
                          </p>
                        )}
                      </ActionDropdown>
                    </td>
                  </tr>
                )
              )} 
            </tbody>
          </table>
        </TableContainer>
        {placementHistoryList && (
          <div className="page-parent">
            <Pagination
              currentPage={placementHistoryList?.data.number + 1}
              totalClients={placementHistoryList?.data.numberOfElements}
              metaData={placementHistoryList?.data}
              page={placementHistoryList?.data.number + 1}
              newPage={newPage}
              increment={increment}
              decrement={decrement}
              className="pagination"
            />
          </div>
        )}
      </StyledDashboardPage>
      {isModalOpen && (
        <div className="form-modal">
          <FormModal handleModal={handleModal}>
            {
              activeModal === "edit-placement" && (
                <EditAssignmentForm
                  assignmentId={assignmentId}
                  handleEditAssignment={handleModal}
                  refetch={refetchHistory}
                />
              )
            }
          </FormModal>
        </div>
      )}
    </Layout>
  );
};

export default PlacementListing;
