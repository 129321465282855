import { apiGet } from "../../utils/apiHelpers";

export const fetchSubscriptionList = async (params) => {
    const [filterOptions] = params.queryKey;

    try {
      const res = await apiGet(
        `/admin/subscriptions?page=${filterOptions.page}&size=12&search=${filterOptions.search}&status=${filterOptions.status}`,
        "admin"
      );
      
      return res.data
    } catch (err) {
      console.log({ err })
    }
};

export const fetchSubscriptionRequestList = async (params) => {
    const [filterOptions] = params.queryKey;

    try {
      const res = await apiGet(
        `/admin/subscriptions/requests?page=${filterOptions.page}`,
        "admin"
      );
      
      return res.data
    } catch (err) {
      console.log({ err })
    }
};
  