import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import { BiSearch } from "react-icons/bi";
import Select from "react-select";
import Pagination from "../atoms/Pagination";
import { apiGet, apiDelete, apiPut } from "../../../utils/apiHelpers";
import { toast } from "react-toastify";
import ProtectedComponents from "../local-routes/ProtectedComponents";
import CustomLoader from "../atoms/CustomLoader";
import ClientBar from "./ClientBar";
import FormModal from "../form/FormModal";
import AddClientForm from "../form/AddClientForm";
import AddBulkClientForm from "../form/AddBulkClientForm";
import { useAdmin } from "../../../context/AdminState";
import CreateSubForm from "../form/CreateSubForm";
import AddEngForm from "../form/AddEngForm";
import { useParams } from "react-router";
import { Link, useHistory } from "react-router-dom";
import EditEngForm from "../form/EditEngForm";
import CreatePackageForm from "../form/CreatePackageForm";
import UpdateClientForm from "../form/UpdateClientForm";
import UpdateSub from "../form/UpdateSub";
import EditPackageForm from "../form/EditPackageForm";
import EditAssignmentForm from "../form/EditAssignmentForm";
import CreateTechstack from "../form/CreateTechstack";
import CreateIndustry from "../form/CreateIndustry";
import UpdateIndustry from "../form/UpdateIndustry";
import UpdateTechstackForm from "../form/UpdateTechstackForm";
import CreateReport from "../form/CreateReport";
import CreateComment from "../form/CreateComment";
import AddUserForm from "../form/AddUserForm";
import AddSquad from "../form/AddSquad";
import RequestEngForm from "../form/RequestEngForm";
import AddSubRequest from "../form/AddSubRequest";
import AssignEngForm from "../form/AssignEngForm";
import AddDocument from "../form/AddDocument";
import CircleLoader from "react-spinners/CircleLoader";
import ClipLoader from "react-spinners/ClipLoader";
import AddBulkEngForm from "../form/AddBulkEngForm";
import fileDownload from "../../../utils/file-download";
import AddBilling from "../form/AddBilling";
import UpdateBilling from "../form/UpdateBilling";
import ViewEngReq from "../form/ViewEngReq";
import ViewInvoice from "../form/ViewInvoice";
import EditInvoice from "../form/EditInvoice";
import { BiChevronDown } from "react-icons/bi";

let timer;
const ListingInterface = ({
  details,
  pageType,
  refId,
  engineerDetails,
  downloadDoc,
  docLoading,
  handleEngineerRatingModal,
}) => {
  const {
    loading,
    gf,
    dashboardData,
    fetchSubReqModal,
    fetchEngReqModal,
    viewUsers,
    // fetchStackOptions,
    fetchEngineers,
    page,
    newPage,
    increment,
    decrement,
    setKeyword,
    keyword,
    openSearchLoader,
    openFilterLoader,
    searchClientEngineers,
    searchClientSubscription,
    searchClients,
    searchEngineers,
    // searchNewClients,
    searchSubscriptions,
    searchSubReq,
    searchEngReq,
    searchAdminSubReq,
    searchAdminEngReq,
    searchPlacementHistory,
    status,
    setStatus,
    setClientType,
    clientType,
    setCustomerType,
    customerType,
    setEngineerType,
    setEngineerStatus,
    engineerStatus,
    engineerType,
    setLanguage,
    engStatus,
    setEngStatus,
    language,
    setSquad,
    setSquadId,
    squad,
    searchLoader,
    filterLoader,
    fetchLocation,
    fetchTrainingLocation,
    locations,
    trainingLocation,
    trainingLocations,
    setTrainingLocation,
    currentLocation,
    setCurrentLocation,
    minAge,
    setMinAge,
    maxAge,
    setMaxAge,
    engineerAge,
    setEngineerAge,
    engineerGender,
    setEngineerGender,
  } = useAdmin();
  // console.log("minage", minAge);
  // console.log("maxage", maxAge);
  const {
    placeholder,
    btnInfo,
    title,
    headers,
    options,
    customerTypeOptions,
    tableDetails,
    engineerTypeOptions,
    engineerGenderOptions,
    engineerStatusOptions,
    engineerPlacementOptions,
    auth,
  } = details;

  const [show, setShow] = useState(false);

  const history = useHistory();

  let selectRef;
  // let clientOptionRef;
  let languageRef;
  // let engineerStatusRef;
  // let selectLanguageRef;

  const [handleEng, setHandleEng] = useState("eng");
  const handleShow = () => {
    setShow(!show || !handleEng);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const num = 6;
  const [dataPerPage] = useState(num);
  const [search, setSearch] = useState("");
  // eslint-disable-next-line
  const [dateFrom, setDateFrom] = useState("");
  // eslint-disable-next-line
  const [dateTo, setDateTo] = useState("");
  const [currentData, setCurrentData] = useState(null);
  const [metaData, setMetaData] = useState({});
  const [activeModal, setActiveModal] = useState(false);
  const [activeSubModal, setActiveSubModal] = useState(false);
  const [activeEngModal, setActiveEngModal] = useState(false);
  const [viewEngReq, setViewEngReq] = useState(false);
  const [clientModal, setClientModal] = useState(false);
  const [techstackModal, setTechstackModal] = useState(false);
  const [industryModal, setIndustryModal] = useState(false);
  const [handleClient, setHandleClient] = useState("client");
  const [, setLoad] = useState(false);
  const [viewInvoice, setViewInvoice] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");

  const [editInvoice, setEditInvoice] = useState(false);

  const [editSubModal, setEditSubModal] = useState(false);
  const [editAssigModal, setEditAssigModal] = useState(false);

  const [engReqDetails, setEngReqDetails] = useState(null);
  const [orgId, setOrgId] = useState(null);
  const [engId, setEngId] = useState(null);
  const [subId, setSubId] = useState(null);
  const [pckgId, setPckgId] = useState(null);
  const [industryId, setIndustryId] = useState(null);
  const [techstackId, setTechstackId] = useState(null);
  const [assignmentId, setAssignmentId] = useState(null);
  
  // eslint-disable-next-line
  const [placementType, setPlacementType] = useState(null);
  const [packageModal, setPackageModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [edit, setEdit] = useState(false);
  const [currentEng, setCurrentEng] = useState(null);
  const [industryName, setIndustryName] = useState(null);
  const [assignEngrModal, setAssignEngrModal] = useState(false);
  const [filterInput, setFilterInput] = useState("");
  const [localCustomerType, setLocalCustomerType] = useState("");
  const [languageList, setLanguageList] = useState([]);
  const [squadList, setSquadList] = useState([]);
  const [languageLabel, setLanguageLabel] = useState("");
  const [squadLabel, setSquadLabel] = useState("");
  const [localEngineerType, setLocalEngineerType] = useState("");
  const [localEngineerStatus, setLocalEngineerStatus] = useState("");
  const [engStatusLabel, setEngStatusLabel] = useState("");
  //create state for pagination and number of pages
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const [billingAmount, setBillingAmount] = useState(null);
  const [billingId, setBillingId] = useState(null);
  const [billingModal, setBillingModal] = useState(false);
  const [billingLevel, setBillingLevel] = useState(null);
  const [showAgeDropdown, setShowAgeDropdown] = useState(false);

  const [statusPlaceholder, setStatusPlaceholder] = useState("");
  const [more, setShowMore] = useState(false);

  const [engineerGenderLabel, setEngineerGenderLabel] = useState("");
  const [currentLocationLabel, setCurrentLocationLabel] = useState("");
  const [trainingLocationLabel, setTrainingLocationLabel] = useState("");

  const { id } = useParams();

  const searchId =
    auth === "client"
      ? JSON.parse(localStorage?.getItem("client_details")).organisationId
      : 0;

  const handleModal = () => {
    setActiveModal(!activeModal);
    setOrgId(id);
  };
  const handleSubModal = () => {
    // e.preventDefault();
    setOrgId(id);
    setActiveSubModal(!activeSubModal);
  };
  const handlePackageModal = (id) => {
    setPckgId(id);
    setPackageModal(!packageModal);
  };

  const handleTechStack = (id) => {
    setTechstackId(id);
    setTechstackModal(!techstackModal);
    setActiveModal(false);
  };

  const handleIndustry = (id, name) => {
    setIndustryId(id);
    setIndustryName(name);
    setIndustryModal(!industryModal);
    setActiveModal(false);
  };

  const handleBilling = (id, level, amount) => {
    setBillingId(id);
    setBillingAmount(amount);
    setBillingLevel(level);
    setBillingModal(!billingModal);
    setActiveModal(false);
  };

  const handleEngModal = (id) => {
    setEngId(id);
    setEdit(!edit);
    setActiveEngModal(!activeEngModal);
  };

  const handleAssignEngModal = (id) => {
    setEngId(id);
    setAssignEngrModal(!assignEngrModal);
  };

  //opens and closes edit subscription modal
  const handleEditSubModal = (id) => {
    setSubId(id);
    setEditSubModal(!editSubModal);
  };

  //opens and closes edit assingment modal
  const handleEditAssignment = (assignmentId, id, placementType) => {
    setAssignmentId(assignmentId);
    setEngId(id);
    setPlacementType(placementType);
    setEditAssigModal(!editAssigModal);
  };

  const handleViewInvoice = (id) => {
    setInvoiceId(id);
    setViewInvoice(!viewInvoice);
  };

  const handleEditInvoice = (id) => {
    setInvoiceId(id);
    setEditInvoice(!editInvoice);
  };

  // const handleSendInvoice = async (id) => {
  //   setInvoiceId(id);
  //   try {
  //     const res = await apiGet(`/admin/invoices/${invoiceId}/send-email`);
  //     console.log(res.data);
  //     toast.success("Invoice sent successfully");
  //   }

  //   catch(err){
  //     toast.error("Error sending invoice");
  //     console.log(err);
  //   }
  // }

  const handleClientModal = (id) => {
    setOrgId(id);
    setClientModal(!clientModal);
  };

  const handleViewEngReqModal = (item) => {
    setEngReqDetails(item);
    setViewEngReq(!viewEngReq);
  };

  const reloader = () => {
    setReload(!reload);
  };

  // Toggle pageType
  useEffect(() => {
    if (!searchLoader) {
      gf(pageType, id);
    }
    // eslint-disable-next-line
  }, [pageType, id, reload, page]);

  useEffect(() => {
    if (pageType === "clients") {
      searchClients();
    }

    if (pageType === "subscriptions") {
      searchSubscriptions();
    }

    if (pageType === "engineers") {
      searchEngineers();
    }

    if (pageType === "client_engineers") {
      searchClientEngineers();
    }

    if (pageType === "client_subscriptions") {
      searchClientSubscription();
    }
    if (pageType === "adminEngReq") {
      searchAdminEngReq();
    }

    if (pageType === "adminSubReq") {
      searchAdminSubReq();
    }

    if (pageType === "clientSubReq") {
      searchSubReq();
    }

    if (pageType === "clientEngineersReq") {
      searchEngReq();
    }

    if (pageType === "placement-history") {
      searchPlacementHistory();
      // console.log('test')
    }

    // eslint-disable-next-line
  }, [
    keyword,
    pageType,
    status,
    clientType,
    customerType,
    engineerType,
    language,
    squad,
    engineerStatus,
    engStatus,
    currentLocation,
    minAge,
    maxAge,
    trainingLocation,
    engineerGender,
    engineerAge,
  ]);

  // Toggle pageType
  useEffect(() => {
    setStatus("");
    setKeyword("");
    setClientType("");
    setCustomerType("");
    setEngineerType("");
    setSquad("");
    setSquadId("");
    setEngineerStatus("");
    setEngStatus("");
    setCurrentLocation("");
    setMinAge("");
    setMaxAge("");
    setTrainingLocation("");
    setEngineerAge("");
    setEngineerGender("");
    newPage(1);
    // eslint-disable-next-line
  }, [pageType]);

  useEffect(() => {
    if (pageType === "clients") {
      // console.log(selectRef);
      setStatusPlaceholder(selectRef?.props?.placeholder);
      // setClientTypePlaceholder(clientOptionRef.props.placeholder);
    }
    if (pageType === "engineers") {
      let refLabel = { label: languageLabel };
      languageRef.props = { ...languageRef.props, ...refLabel };
      // setEngStatusPlaceholder(engineerStatusRef.props.placeholder);
    }
    // fetchStackOptions();
    //  eslint-disable-next-line
  }, []);

  //Reset current data when search is empty
  useEffect(() => {
    setCurrentData(dashboardData[pageType]);
    setMetaData({
      totalPages: dashboardData.totalPages,
      first: dashboardData.first,
      last: dashboardData.last,
    });
  }, [dashboardData, page, pageType]);

  const handleDate = (e) => {
    if (dateFrom !== "" && dateTo !== "") {
      handleDateSearch();
    }
  };

  useEffect(() => {
    if (pageType === "sub") {
      handleDate();
    }
    // eslint-disable-next-line
  }, [dateTo, dateFrom]);

  // handle search
  const handleDateSearch = async () => {
    setLoad(true);
    try {
      const res = await apiGet(
        `/organisations/${id}/subscriptions/search?dateFrom=${dateFrom}?dateTo=${dateTo}`,
        {},
        auth
      );

      if (res.data.data.content.length === 0) {
        setCurrentData(res.data.data.content.slice());

        toast.info(`No match found`);
        setLoad(false);
        setTimeout(() => {
          setCurrentData(
            dashboardData[pageType].slice(indexOfFirstData, indexOfLastData)
          );
        }, 4000);
      } else {
        setCurrentData(res.data.data.content.slice());
        setLoad(false);
      }
    } catch (err) {
      console.log({ err });
      toast.error(err.message);
    }
    setLoad(false);
  };

  const handleSearch = async (e) => {
    openSearchLoader();
    clearTimeout(timer);
    timer = setTimeout(() => {
      setKeyword(search);
    }, 2000);
    // if (e.keyCode === 13) {
    //   setKeyword(search);
    // }
  };

  const getStatus = ({ value }) => {
    setStatus(value);
    setFilterInput("status");
    openFilterLoader();
  };
  const getStatusEng = ({ value, label }) => {
    setEngStatus(value);
    setEngStatusLabel(label);
    setFilterInput("engStatus");
    openFilterLoader();
  };
  const getEngineerType = ({ value }) => {
    setLocalEngineerType(value);
    let eng_type = "";
    if (value === "Decadev") {
      eng_type = "decadev";
    } else if (value === "Non decadev") {
      eng_type = "non_decadev";
    }
    setEngineerType(eng_type);
    setFilterInput("engineer type");
    openFilterLoader();
  };

  const getEngineerGender = ({ value, label }) => {
    setEngineerGender(value);
    setEngineerGenderLabel(label);
    setFilterInput("engineerGender");
    openFilterLoader();
  };

  const getLanguage = ({ value, label }) => {
    setLanguageLabel(label);
    setLanguage(value);
    setFilterInput("language");
    openFilterLoader();
  };

  const getSquad = ({ value, label }) => {
    setSquadLabel(label);
    setSquad(value);
    setFilterInput("squad");
    openFilterLoader();
  };

  const getCustomerType = ({ value }) => {
    setLocalCustomerType(value);
    let customer_type = "";
    if (value === "local client") {
      customer_type = "local_client";
    } else if (value === "international client") {
      customer_type = "intl_client";
    } else if (value === "local lead") {
      customer_type = "local_lead";
    } else if (value === "international lead") {
      customer_type = "intl_lead";
    }
    setCustomerType(customer_type);
    setFilterInput("customer type");
    openFilterLoader();
  };

  const getEngineerStatus = ({ value }) => {
    setLocalEngineerStatus(value);
    let eng_status = "";
    if (value === "Internship") {
      eng_status = "INTERNSHIP";
    } else if (value === "Residence") {
      eng_status = "RESIDENCE";
    } else if (value === "Billing") {
      eng_status = "BILLING";
    } else if (value === "Terminated") {
      eng_status = "TERMINATED";
    } else if (value === "Completed") {
      eng_status = "COMPLETED";
    } else if (value === "Retained") {
      eng_status = "RETAINED";
    } else if (value === "Not Assigned") {
      eng_status = "NOT_ASSIGNED";
    } else if (value === "Training") {
      eng_status = "TRAINING";
    } else if (value === "Live Project") {
      eng_status = "LIVE_PROJECT";
    } else if (value === "Interview") {
      eng_status = "INTERVIEW";
    } else if (value === "Under Review") {
      eng_status = "UNDER_REVIEW";
    } else if (value === "Shortlisted") {
      eng_status = "SHORTLISTED";
    }

    setEngineerStatus(eng_status);
    setFilterInput("engineer status");
    openFilterLoader();
  };

  const getEngineerCurrentLocation = ({ value, label }) => {
    setCurrentLocationLabel(label);
    setCurrentLocation(value);
    setFilterInput("currentLocation");
    openFilterLoader();
  };

  const getMinAge = (e) => {
    setMinAge(e.target.value);
    setFilterInput("age");
    openFilterLoader();
  };

  const getMaxAge = (e) => {
    setMaxAge(e.target.value);
    setFilterInput("age");
    openFilterLoader();
  };

  const getTrainingLocation = ({ value, label }) => {
    setTrainingLocationLabel(label);
    setTrainingLocation(value);
    setFilterInput("trainingLocation");
    openFilterLoader();
  };

  // useEffect(() => {
  //   setCurrentData(dashboardData[pageType] || []);
  // }, [pageType, dashboardData, indexOfLastData, indexOfFirstData]);

  // fetch client user
  const [updateId, setUpdateId] = useState(0);
  const [isEditing, setIsEditing] = useState(false);

  const fetchClientSingleManager = async () => {
    try {
      let response = await apiGet(
        `/admin/organisations/${id}/users`,
        {},
        "client"
      );
      if (response.error) {
        throw response.data.response.status === 500
          ? new Error("")
          : response.data;
      }
      setCurrentData(response.data.data.content);
    } catch (err) {
      console.log({ err });
      toast.error(err?.response?.data?.message || "error fetching user data");
    }
  };

  let locationOptions =
    locations?.locations?.length > 0
      ? locations?.locations?.map((location) => {
          return {
            value: location.id,
            label: location.name,
          };
        })
      : [];

  let trainingLocationOptions =
    trainingLocations?.trainingLocations?.length > 0
      ? trainingLocations?.trainingLocations?.map((location) => {
          return {
            value: location.id,
            label: location.name,
          };
        })
      : [];

  const fetchLanguages = async () => {
    try {
      let response = await apiGet(`/admin/techstacks`, {});
      if (response.error) {
        throw response.data.response.status === 500
          ? new Error("")
          : response.data;
      }
      const language = response.data.data.map((lang) => ({
        label: lang.name,
        value: lang.id,
      }));
      language.unshift({
        value: "",
        label: "Select Language",
      });
      setLanguageList(language);
    } catch (err) {
      console.log({ err });
      toast.error(err?.response?.data?.message || "error fetching user data");
    }
  };

  // fetch squad
  const selectSquad = async () => {
    try {
      let response = await apiGet(`/admin/squads`, {});

      if (response.error) {
        throw response.data.response.status === 500
          ? new Error("")
          : response.data;
      }

      const squads = response.data.data.map((squad) => ({
        label: `SQ00${squad.name}`,
        value: squad.id,
      }));
      squads.unshift({
        value: "",
        label: "Select Squad",
      });
      setSquadList(squads);
    } catch (err) {
      console.log({ err: err });
      toast.error(
        err?.response?.data?.message || "error fetching squad details"
      );
    }
  };

  const fetchEngineerRatings = async () => {
    try {
      let response = await apiGet(`/dev/reviews`, {});
      setCurrentData(response.data.data.content);
    } catch (error) {}
  };

  useEffect(() => {
    if (pageType === "engineers") {
      fetchLanguages();
      selectSquad();
      fetchLocation();
      fetchTrainingLocation();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (pageType === "users") {
      fetchClientSingleManager();
    }

    if (pageType === "engineer_ratings") {
      fetchEngineerRatings();
    }
    // eslint-disable-next-line
  }, []);

  const viewUser = async (item) => {
    setUpdateId(item.id);
    handleModal();
    setIsEditing(true);
  };

  //delete users
  const deleteUsers = async (item) => {
    try {
      await apiDelete(`/admin/organisations/${id}/users/${item.id}`, {});
      viewUsers(id);
    } catch (err) {
      console.log({ err });
    }
  };

  //activate users
  const activateUser = async (item) => {
    item.status = "ACTIVE";
    const userPayload = {
      ...item,
    };

    try {
      await apiPut(
        `/organisations/${id}/users/${item.id}/activate`,
        userPayload,
        {}
      );
      window.location.reload();
    } catch (err) {
      console.log({ err });
    }
  };

  //deactivate users
  const deactivateUser = async (item) => {
    item.status = "INACTIVE";
    const userPayload = {
      ...item,
    };

    try {
      await apiPut(
        `/organisations/${id}/users/${item.id}/deactivate`,
        userPayload,
        {}
      );
      window.location.reload();
    } catch (err) {
      console.log({ err });
    }
  };

  // fetch squad
  const fetchSquad = async () => {
    try {
      let response = await apiGet(`/admin/squads`, {});

      if (response.error) {
        throw response.data.response.status === 500
          ? new Error("")
          : response.data;
      }
      setCurrentData(response.data.data);

      const squads = response.data.data.map((squad) => ({
        label: `SQ${squad.name}`,
        value: squad.id,
      }));
      squads.unshift({
        value: "",
        label: "Select Squad",
      });
      setSquadList(squads);
    } catch (err) {
      toast.error(
        err?.response?.data?.message || "error fetching squad details"
      );
    }
  };
  const downloadProfiles = async () => {
    try {
      const data = await apiGet(
        `/admin/engineers/downloadBulkEngineerProfile`,
        { responseType: "blob" },
        "admin"
      );
      fileDownload(data.data, "Engineer_Profile.xlsx");
    } catch (err) {
      toast.error(
        err?.response?.data?.message ||
          "Not able to download profile at this time"
      );
    }
  };

  const handleShowMoreFilters = async () => {
    setShowMore(!more);
  };

  return loading && pageType !== "sub" ? (
    <CustomLoader />
  ) : (
    <StyledClientPage className={"styled-client-page"}>
      {pageType === "users" ? (
        <Link to="#" onClick={() => history.goBack()} className="goBack">
          <img
            className="back-arrow-img"
            src="/images/back-arrow.svg"
            alt="back arrow"
          />
          <span className="go-back-text">Go Back</span>
        </Link>
      ) : pageType === "assignments" ? (
        <Link to="#" onClick={() => history.goBack()} className="goBack">
          <img
            className="back-arrow-img"
            src="/images/back-arrow.svg"
            alt="back arrow"
          />
          <span className="go-back-text">Go Back</span>
        </Link>
      ) : (
        ""
      )}

      {page !== "document" && (
        <div className="client-header">
          {pageType !== "overview" &&
            pageType !== "engineers_overview" &&
            pageType !== "document" &&
            pageType !== "invoice" &&
            pageType !== "client_engineers" &&
            pageType !== "client_subscriptions" &&
            pageType !== "engineer_ratings" && <h4>{title || "Clients"}</h4>}
          {pageType === "engineer_ratings" && <h3>My Ratings</h3>}
          {pageType === "client_engineers" && <h3>{title}</h3>}
          {pageType === "client_subscriptions" && <h3>{title}</h3>}
          {pageType === "client_subscriptions" ? (
            ""
          ) : pageType === "document" ? (
            ""
          ) : pageType === "invoice" ? (
            ""
          ) : pageType === "overview" ? (
            ""
          ) : pageType === "engineers_overview" ? (
            ""
          ) : pageType === "client_engineers" ? (
            ""
          ) : pageType === "subscriptions" ? (
            ""
          ) : pageType === "adminEngReq" ? (
            ""
          ) : pageType === "adminSubReq" ? (
            ""
          ) : pageType === "assignments" ? (
            ""
          ) : pageType === "placement-history" ? (
            ""
          ) : pageType === "clientEngineersReq" ? (
            <MyButton onClick={handleModal} className="btn-comp">
              {btnInfo || "Add New Customer"}
            </MyButton>
          ) : pageType === "clientSubReq" ? (
            <MyButton onClick={handleShow} className="btn-comp">
              {btnInfo || "Add New Customer"}
            </MyButton>
          ) : pageType === "sub" ? (
            <ProtectedComponents permission={["manageSubscription"]}>
              <MyButton onClick={handleSubModal} className="btn-comp">
                {btnInfo || "Add New Customer"}
              </MyButton>
            </ProtectedComponents>
          ) : // Admin- Engineer page buttons
          pageType === "engineers" ? (
            <ProtectedComponents permission={["manageEngineer"]}>
              <div className="engineers-div">
                <MyButton
                  transparent
                  onClick={() => {
                    handleModal();
                    setHandleEng("eng");
                  }}
                  className="btn-comp">
                  {btnInfo || "Add New Customer"}
                </MyButton>
                <MyButton
                  transparent
                  onClick={() => {
                    handleModal();
                    setHandleEng("eng-bulk");
                  }}
                  className="btn-comp">
                  {"Add Bulk Engineer"}
                </MyButton>
                <MyButton
                  // onClick={() => {
                  //   handleModal();
                  //   setHandleEng("download-profile");
                  // }}
                  onClick={() => downloadProfiles()}
                  className="btn-comp">
                  {"Download Profiles"}
                </MyButton>
              </div>
            </ProtectedComponents>
          ) : (
            <ProtectedComponents
              permission={[
                "manageClient",
                "manageBillingRate",
                "manageOrganisationUser",
              ]}>
              <div className="d-flex">
                {pageType === "clients" ? (
                  <MyButton
                    transparent
                    onClick={() => {
                      handleModal();
                      setHandleClient("client-bulk");
                    }}
                    className="btn-comp mr-4">
                    {"Bulk Customer Upload"}
                  </MyButton>
                ) : (
                  ""
                )}

                <MyButton
                  onClick={() => {
                    handleModal();
                    setHandleClient("client");
                  }}
                  className="btn-comp">
                  {btnInfo || "Add a New Customer"}
                </MyButton>
              </div>
            </ProtectedComponents>
          )}
        </div>
      )}
      {pageType !== "billing" && (
        <div
          className={`${
            pageType === "clients" ? "client-input-flex" : "client-input"
          }`}>
          {/* {pageType === "sub" && pageType !== "engineers_overview" ? (
            <div className="date-search">
              <label htmlfor="datefrom">
                <span className="span-label">Date From:</span>
                <input
                  className="mt-1"
                  type="date"
                  id="datefrom"
                  onChange={(e) => {
                    setDateFrom(e.target.value);
                    handleDate(e);
                  }}
                  value={dateFrom}
                />
              </label>
              <label htmlfor="dateto">
                <span className="span-label">Date To:</span>
                <input
                  className="mt-1"
                  type="date"
                  id="dateto"
                  onChange={(e) => {
                    setDateTo(e.target.value);
                    handleDate(e);
                  }}
                  value={dateTo}
                />
              </label>
            </div>
          ) : null} */}
          {pageType !== "sub" &&
          pageType !== "overview" &&
          pageType !== "engineers_overview" &&
          pageType !== "squad" &&
          pageType !== "document" &&
          pageType !== "invoice" &&
          pageType !== "package" &&
          pageType !== "industry" &&
          pageType !== "assignments" &&
          pageType !== "client_subscriptions" &&
          pageType !== "client_engineers" &&
          pageType !== "clientEngineersReq" &&
          pageType !== "clientSubReq" &&
          pageType !== "engineer_ratings" &&
          pageType !== "engineers" &&
          pageType !== "techstack" ? (
            <div
              className={`${
                pageType === "engineers" ? "eng-client-search" : "client-search"
              } mt-4`}>
              <BiSearch className="search-icon" size={20} />
              <input
                type="text"
                placeholder={placeholder || "Search for Engineers"}
                className=""
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                onKeyUp={(e) => handleSearch(e)}
              />
              {searchLoader && (
                <div className="input-spinner-select">
                  <CircleLoader color="green" size="10px" />
                </div>
              )}
            </div>
          ) : null}

          {pageType !== "squad" &&
          pageType !== "sub" &&
          pageType !== "overview" &&
          pageType !== "document" &&
          pageType !== "invoice" &&
          pageType !== "engineers_overview" &&
          pageType !== "package" &&
          // pageType !== "client_subscriptions" &&
          pageType !== "client_engineers" &&
          pageType !== "clientSubReq" &&
          pageType !== "clientEngineersReq" &&
          pageType !== "engineers" &&
          pageType !== "adminEngReq" &&
          pageType !== "adminSubReq" &&
          pageType !== "industry" &&
          pageType !== "client_subscriptions" &&
          pageType !== "placement-history" &&
          pageType !== "assignments" &&
          pageType !== "engineer_ratings" &&
          pageType !== "techstack" ? (
            <div className="client-filter mt-4">
              <Select
                ref={(ref) => {
                  selectRef = ref;
                }}
                className="my-select"
                options={options}
                value={status}
                // placeholder={status ? status : "Select Status"}
                placeholder={
                  status
                    ? status
                    : statusPlaceholder
                    ? statusPlaceholder
                    : "Select Status"
                }
                onChange={(e) => getStatus(e)}
              />
              {filterLoader && filterInput === "status" && (
                <div className="input-spinner-filter">
                  <CircleLoader color="green" size="10px" />
                </div>
              )}
            </div>
          ) : null}

          {pageType !== "squad" &&
          pageType !== "overview" &&
          pageType !== "engineers_overview" &&
          pageType !== "package" &&
          pageType !== "document" &&
          pageType !== "invoice" &&
          pageType !== "sub" &&
          // pageType !== "client_subscriptions" &&
          pageType !== "client_engineers" &&
          pageType !== "clientSubReq" &&
          pageType !== "clientEngineersReq" &&
          pageType !== "engineers" &&
          pageType !== "adminEngReq" &&
          pageType !== "adminSubReq" &&
          pageType !== "industry" &&
          pageType !== "client_subscriptions" &&
          pageType !== "assignments" &&
          pageType !== "engineer_ratings" &&
          pageType !== "techstack" ? (
            <div className="client-filter mt-4">
              <Select
                // ref={(ref) => {
                // clientOptionRef = ref
                // }}
                className="my-select"
                options={customerTypeOptions}
                value={localCustomerType}
                placeholder={
                  localCustomerType ? localCustomerType : "Select Customer Type"
                }
                onChange={(e) => getCustomerType(e)}
              />
              {filterLoader && filterInput === "customer type" && (
                <div className="input-spinner-filter">
                  <CircleLoader color="green" size="10px" />
                </div>
              )}
            </div>
          ) : null}

          {pageType === "engineers" ? (
            <div className="engineer-filters-wrapper">
              <div
                className={`${
                  pageType === "engineers"
                    ? "eng-client-search"
                    : "client-search"
                } mt-4`}>
                <BiSearch className="search-icon-engr" size={15} />
                <input
                  type="text"
                  placeholder={placeholder || "Search for Engineers"}
                  className="input-search"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  onKeyUp={(e) => handleSearch(e)}
                />
                {searchLoader && (
                  <div className="input-spinner-select">
                    <CircleLoader color="green" size="10px" />
                  </div>
                )}
              </div>
              <div className="client-filter mt-4">
                <Select
                  ref={(ref) => {
                    languageRef = ref;
                  }}
                  className="my-select"
                  options={languageList}
                  value={language}
                  placeholder={
                    languageLabel ? languageLabel : "Select Language"
                  }
                  onChange={(e) => getLanguage(e)}
                />
                {filterLoader && filterInput === "language" && (
                  <div className="input-spinner-filter">
                    <CircleLoader color="green" size="10px" />
                  </div>
                )}
              </div>
              <div className="client-filter mt-4">
                <Select
                  className="my-select"
                  options={engineerTypeOptions}
                  value={localEngineerType}
                  placeholder={
                    localEngineerType ? localEngineerType : "Engineer Type"
                  }
                  onChange={(e) => getEngineerType(e)}
                />
                {filterLoader && filterInput === "engineer type" && (
                  <div className="input-spinner-filter">
                    <CircleLoader color="green" size="10px" />
                  </div>
                )}
              </div>
              <div className="client-filter mt-4">
                <Select
                  // ref={(ref) => {
                  //   languageRef = ref
                  // }}
                  className="my-select"
                  options={squadList}
                  value={squad}
                  placeholder={squadLabel ? squadLabel : "Select squad"}
                  onChange={(e) => getSquad(e)}
                />
                {filterLoader && filterInput === "squad" && (
                  <div className="input-spinner-filter">
                    <CircleLoader color="green" size="10px" />
                  </div>
                )}
              </div>
              <div className="client-filter mt-4">
                <span
                  className="client-filter-expansion"
                  onClick={handleShowMoreFilters}>
                  {more ? "Show Less" : "Show More"}
                </span>
              </div>
              {more && (
                <>
                  <div className="client-filter mt-4">
                    <Select
                      className="my-select"
                      options={engineerGenderOptions}
                      value={engineerGender}
                      placeholder={
                        engineerGenderLabel
                          ? engineerGenderLabel
                          : "Select Gender"
                      }
                      onChange={(e) => getEngineerGender(e)}
                    />
                    {filterLoader && filterInput === "engineerGender" && (
                      <div className="input-spinner-filter">
                        <CircleLoader color="green" size="10px" />
                      </div>
                    )}
                  </div>

                  <div className="client-filter mt-4">
                    <Select
                      className="my-select"
                      options={engineerPlacementOptions}
                      value={localEngineerStatus}
                      placeholder={
                        localEngineerStatus
                          ? localEngineerStatus
                          : "Placement Type"
                      }
                      onChange={(e) => getEngineerStatus(e)}
                    />
                    {filterLoader && filterInput === "engineer status" && (
                      <div className="input-spinner-filter">
                        <CircleLoader color="green" size="10px" />
                      </div>
                    )}
                  </div>

                  <div className="client-filter mt-4">
                    <Select
                      className="my-select"
                      options={engineerStatusOptions}
                      value={engStatus}
                      placeholder={
                        engStatusLabel ? engStatusLabel : "Select Status"
                      }
                      onChange={(e) => getStatusEng(e)}
                    />
                    {filterLoader && filterInput === "engStatus" && (
                      <div className="input-spinner-filter">
                        <CircleLoader color="green" size="10px" />
                      </div>
                    )}
                  </div>
                  <div className="age mt-4">
                    <div
                      onClick={() => setShowAgeDropdown(!showAgeDropdown)}
                      className="placeholder-wrapper">
                      <div>Age</div>
                      {filterLoader && filterInput === "age" ? (
                        <div className="input-spinner-filter">
                          <CircleLoader color="green" size="10px" />
                        </div>
                      ) : (
                        <BiChevronDown size={20} />
                      )}
                    </div>
                    {showAgeDropdown && (
                      <div className="age-container">
                        <input
                          className="age-input"
                          value={minAge}
                          type="number"
                          placeholder="Min Age"
                          onChange={(e) => getMinAge(e)}
                        />

                        <input
                          className="age-input"
                          type="number"
                          value={maxAge}
                          placeholder="Max Age"
                          onChange={(e) => getMaxAge(e)}
                        />
                        {/* {filterLoader && filterInput === "maxAge" && (
                          <div className="input-spinner-filter">
                            <CircleLoader color="green" size="10px" />
                          </div>
                        )} */}
                      </div>
                    )}
                  </div>

                  {/* <div className="client-filter mt-4">
                <Select
                  className="my-select"
                  options={
                    [<input type="date" />]
                  }
                  value={engineerAge}
                  placeholder={
                    engineerAgeLabel ? engineerAgeLabel : "Age"
                  }
                  onChange={(e) => getEngineerAge(e)}
                />
                {filterLoader && filterInput === "engineerAge" && (
                  <div className="input-spinner-filter">
                    <CircleLoader color="green" size="10px" />
                  </div>
                )}
              </div> */}

                  <div className="client-filter mt-4">
                    <Select
                      className="my-select"
                      options={[
                        { label: "Current Location", value: "" },
                        ...locationOptions,
                      ]}
                      value={currentLocation}
                      placeholder={
                        currentLocationLabel
                          ? currentLocationLabel
                          : "Current Location"
                      }
                      onChange={(e) => getEngineerCurrentLocation(e)}
                    />
                    {filterLoader && filterInput === "currentLocation" && (
                      <div className="input-spinner-filter">
                        <CircleLoader color="green" size="10px" />
                      </div>
                    )}
                  </div>

                  <div className="client-filter mt-4">
                    <Select
                      className="my-select"
                      options={[
                        { label: "Training Location", value: "" },
                        ...trainingLocationOptions,
                      ]}
                      value={trainingLocation}
                      placeholder={
                        trainingLocationLabel
                          ? trainingLocationLabel
                          : "Training Location"
                      }
                      onChange={(e) => getTrainingLocation(e)}
                    />
                    {filterLoader && filterInput === "trainingLocation" && (
                      <div className="input-spinner-filter">
                        <CircleLoader color="green" size="10px" />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          ) : null}
          {pageType === "placement-history" ? (
            <div className="client-filter mt-4">
              <Select
                className="my-select"
                options={engineerStatusOptions}
                value={localEngineerStatus}
                placeholder={
                  localEngineerStatus ? localEngineerStatus : "Placement Type"
                }
                onChange={(e) => getEngineerStatus(e)}
              />
              {filterLoader && filterInput === "engineer status" && (
                <div className="input-spinner-filter">
                  <CircleLoader color="green" size="10px" />
                </div>
              )}
            </div>
          ) : null}
        </div>
      )}

      {pageType === "document" ? (
        <div className="document-section">
          <div className="client-search mt-4">
            <BiSearch className="search-icon" size={20} />
            <input
              type="text"
              placeholder={placeholder || "Search for Engineers"}
              className=""
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              onKeyUp={(e) => handleSearch(e)}
            />
          </div>
          <ProtectedComponents permission={["manageClient"]}>
            <div className="doc-button-section">
              {/* <div className="client-filter">
                  <Select
                    className="my-select"
                    options={options}
                    value={status}
                    placeholder={status ? status : "Filter"}
                    onChange={(e) => getStatus(e)}
                  />

                </div> */}
              <div>
                <MyButton onClick={handleModal} className="btn-doc">
                  {btnInfo || "Add New Document"}
                </MyButton>
              </div>
            </div>
          </ProtectedComponents>
        </div>
      ) : null}
      {/* {pageType === "invoice" ? (
        <div className="document-section">
          <div>
                <MyButton onClick={handleModal} >
                  {btnInfo || "Generate Invoice"}
                </MyButton>
              </div>
        </div>
      ) : null} */}
      {/*  search result info tab for clients */}
      {pageType === "clients" &&
        (keyword || status || clientType) &&
        dashboardData?.totalElements > 0 && (
          <div className="search-result-size">
            {`${dashboardData?.totalElements} client(s) found`}
          </div>
        )}
      {/*  search result info tab for engineer placement */}
      {pageType === "placement-history" &&
        (keyword || status || clientType || engineerStatus) &&
        dashboardData?.totalElements > 0 && (
          <div className="search-result-size">
            {`${dashboardData?.totalElements} engineer(s) found`}
          </div>
        )}
      {(pageType === "clients" || pageType === "engineers") &&
        (keyword ||
          status ||
          language ||
          engineerStatus ||
          engineerType ||
          squad ||
          customerType ||
          engStatus ||
          currentLocation ||
          trainingLocation ||
          engineerGender ||
          engineerAge) &&
        dashboardData?.totalElements > 0 && (
          <div className="search-result-size">
            {`${dashboardData?.totalElements} ${
              pageType === "clients"
                ? "client(s) found"
                : pageType === "engineers"
                ? "engineer(s) found"
                : ""
            }`}
          </div>
        )}
      <div className="styled-table-parent">
        {currentData && (
          <table
            cellSpacing="0"
            className={`table client-table ${
              pageType === "clients" ? "table-mb" : ""
            }`}>
            <thead>
              <tr>
                {headers.map((item, index) => (
                  <th key={index} scope="col">
                    {item}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {currentData.length && !searchLoader ? (
                currentData.map((item, index) => (
                  <ClientBar
                    key={index}
                    id={item.id}
                    item={item}
                    tableDetails={tableDetails}
                    pageType={pageType}
                    handleModal={handleModal}
                    handleSubModal={handleSubModal}
                    handleEngModal={handleEngModal}
                    handleViewInvoice={handleViewInvoice}
                    handleEditInvoice={handleEditInvoice}
                    // handleSendInvoice={handleSendInvoice}
                    handleClientModal={handleClientModal}
                    setEng={setCurrentEng}
                    routeId={id}
                    refId={refId}
                    setCurrentData={setCurrentData}
                    currentData={currentData}
                    handleEditSubModal={handleEditSubModal}
                    handlePackageModal={handlePackageModal}
                    handleEditAssignment={handleEditAssignment}
                    handleIndustry={handleIndustry}
                    handleTechStack={handleTechStack}
                    viewUser={viewUser}
                    deleteUsers={deleteUsers}
                    deactivateUser={deactivateUser}
                    activateUser={activateUser}
                    handleAssignEngModal={handleAssignEngModal}
                    downloadDoc={downloadDoc}
                    handleBilling={handleBilling}
                    handleViewEngReqModal={handleViewEngReqModal}
                    docLoading={docLoading}
                    handleEngineerRatingModal={handleEngineerRatingModal}
                  />
                ))
              ) : searchLoader ? (
                <tr>
                  <td colSpan="6" className="no-data">
                    <ClipLoader color="green" size="30px" />
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan="6" className="no-data">
                    No Data Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
      {loading && <CustomLoader />}
      {show && pageType === "clientSubReq" ? (
        <AddSubRequest
          show={show}
          setShow={setShow}
          handleShow={handleShow}
          fetchSubRequest={fetchSubReqModal}
        />
      ) : null}
      {activeModal && (
        <div className="form-modal">
          <FormModal
            className="form"
            centered={pageType === "clientEngineersReq"}
            setIsEditing={setIsEditing}
            handleModal={handleModal}>
            {pageType === "sub" ? (
              <CreateSubForm
                onClick={handleModal}
                orgId={orgId}
                reload={reloader}
              />
            ) : pageType === "clients" && handleClient === "client" ? (
              <AddClientForm onClick={handleModal} reload={reloader} />
            ) : pageType === "engineers" && handleEng === "eng" ? (
              <AddEngForm onClick={handleModal} reload={reloader} />
            ) : pageType === "clients" && handleClient === "client-bulk" ? (
              <AddBulkClientForm onClick={handleModal} reload={reloader} />
            ) : pageType === "engineers" && handleEng === "eng-bulk" ? (
              <AddBulkEngForm onClick={handleModal} reload={reloader} />
            ) : pageType === "package" ? (
              <CreatePackageForm onClick={handleModal} reload={reloader} />
            ) : pageType === "industry" ? (
              <CreateIndustry onClick={handleModal} reload={reloader} />
            ) : pageType === "techstack" ? (
              <CreateTechstack onClick={handleModal} reload={reloader} />
            ) : pageType === "billing" ? (
              <AddBilling onClick={handleModal} reload={reloader} />
            ) : pageType === "client_subscription" ? (
              <CreateReport onClick={handleModal} reload={reloader} />
            ) : pageType === "client_subscription" ? (
              <CreateComment onClick={handleModal} reload={reloader} />
            ) : pageType === "document" ? (
              <AddDocument
                onClick={handleModal}
                reload={reloader}
                orgId={orgId}
              />
            ) : pageType === "squad" ? (
              <AddSquad
                onClick={handleModal}
                fetchSquad={fetchSquad}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                pageType="client"
                OrganizationId={id}
                updateId={updateId}
                reload={reloader}
              />
            ) : pageType === "users" ? (
              <AddUserForm
                onClick={handleModal}
                fetchClientSingleManager={fetchClientSingleManager}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                pageType="admin_client"
                OrganizationId={id}
                updateId={updateId}
              />
            ) : pageType === "clientEngineersReq" ? (
              <RequestEngForm
                onClick={handleModal}
                OrgId={searchId}
                fetchEngReq={fetchEngReqModal}
              />
            ) : (
              ""
            )}
          </FormModal>
        </div>
      )}

      {viewEngReq && (
        <ViewEngReq
          handleViewEngReqModal={handleViewEngReqModal}
          engReqDetails={engReqDetails}
        />
      )}

      {activeSubModal && (
        <div className="form-modal">
          <FormModal className="form" handleModal={handleSubModal}>
            <CreateSubForm
              orgId={orgId}
              reload={reloader}
              onClick={handleSubModal}
            />
          </FormModal>
        </div>
      )}

      {clientModal && (
        <div className="form-modal">
          <FormModal className="form" handleModal={handleClientModal}>
            <UpdateClientForm
              orgId={orgId}
              reload={reloader}
              onClick={handleClientModal}
            />
          </FormModal>
        </div>
      )}
      {activeEngModal && (
        <div className="form-modal">
          <FormModal className="form" handleModal={handleEngModal}>
            <EditEngForm
              edit={edit}
              engId={engId}
              orgId={orgId}
              handleEngModal={handleEngModal}
              engData={currentEng}
              reload={reloader}
            />
          </FormModal>
        </div>
      )}
      {editAssigModal && (
        <div className="form-modal">
          <FormModal className="form" handleModal={handleEditAssignment}>
            <EditAssignmentForm
              assignmentId={assignmentId}
              engId={engId}
              handleEditAssignment={handleEditAssignment}
              reload={reloader}
              perEngPlacementList={pageType === "assignments"}
              allEngPlacementList={pageType === "placement-history"}
            />
          </FormModal>
        </div>
      )}
      {viewInvoice && (
        <div className="form-modal">
          <FormModal className="form" centered handleModal={handleViewInvoice}>
            <ViewInvoice invoiceId={invoiceId} closeModal={handleViewInvoice} />
          </FormModal>
        </div>
      )}
      {editInvoice && (
        <div className="form-modal">
          <FormModal className="form" handleModal={handleEditInvoice}>
            <EditInvoice invoiceId={invoiceId} closeForm={handleEditInvoice} />
          </FormModal>
        </div>
      )}
      {/* handleEditInvoice */}
      {industryModal && (
        <div className="form-modal">
          <FormModal className="form" handleModal={handleIndustry}>
            <UpdateIndustry
              onClick={handleModal}
              industryId={industryId}
              handleIndustry={handleIndustry}
              reload={reloader}
              industryName={industryName}
            />
          </FormModal>
        </div>
      )}
      {billingModal && (
        <div className="form-modal">
          <FormModal className="form" handleModal={handleIndustry}>
            <UpdateBilling
              onClick={handleModal}
              billingId={billingId}
              handleBilling={handleBilling}
              reload={reloader}
              billingLevel={billingLevel}
              billingAmount={billingAmount}
            />
          </FormModal>
        </div>
      )}
      {techstackModal && (
        <div className="form-modal">
          <FormModal className="form" handleModal={handleTechStack}>
            <UpdateTechstackForm
              onClick={handleModal}
              techstackId={techstackId}
              handleTechStack={handleTechStack}
              reload={reloader}
            />
          </FormModal>
        </div>
      )}
      {packageModal && (
        <div className="form-modal">
          <FormModal className="form" handleModal={handlePackageModal}>
            <EditPackageForm
              edit={edit}
              onClick={handlePackageModal}
              pckgId={pckgId}
              handlePackageModal={handlePackageModal}
              engData={currentEng}
              reload={reloader}
            />
          </FormModal>
        </div>
      )}

      {editSubModal && (
        <div className="form-modal">
          <FormModal className="form" handleModal={handleEditSubModal}>
            <UpdateSub
              onClick={handleEditSubModal}
              subId={subId}
              orgId={id}
              reload={reloader}
            />
          </FormModal>
        </div>
      )}

      {assignEngrModal && (
        <div className="form-modal">
          <FormModal className="form" handleModal={handleAssignEngModal}>
            <AssignEngForm
              onClick={handleAssignEngModal}
              reload={reloader}
              engId={engId}
              currentData={currentData}
              singleEng={true}
              handleReFetch={fetchEngineers}
            />
          </FormModal>
        </div>
      )}

      {pageType !== "overview" &&
        pageType !== "engineers_overview" &&
        pageType !== "industry" &&
        pageType !== "techstack" &&
        pageType !== "package" &&
        pageType !== "document" &&
        pageType !== "sub" &&
        pageType !== "users" &&
        pageType !== "assignments" &&
        pageType !== "adminEngReq" &&
        pageType !== "billing" &&
        pageType !== "invoice" &&
        !searchLoader && (
          <div className="page-parent">
            <Pagination
              currentPage={currentPage}
              clientsPerPage={dataPerPage}
              totalClients={currentData?.length ? currentData.length : 0}
              paginate={(pageNumber) => setCurrentPage(pageNumber)}
              metaData={metaData}
              page={page}
              newPage={newPage}
              increment={increment}
              decrement={decrement}
              className="pagination"
            />
          </div>
        )}
    </StyledClientPage>
  );
};

const StyledClientPage = styled.div`
  position: relative;
  .styled-table-parent {
    overflow-y: auto;
    white-space: nowrap;
    margin-top: 5rem;
    /* z-index: -9999; */
  }
  .engineers-div {
    display: flex;
    gap: 1rem;
  }
  .client-input {
    display: flex;
    width: 100%;
    margin-bottom: 2.4rem;
  }
  .age {
    width: 100%;
    color: black;
    background-color: white;
    position: relative;
  }
  .placeholder-wrapper {
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
    margin-top: 1.5rem;
    padding: 0px 0.6rem;
  }
  .age-container {
    position: absolute;
    top: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    align-items: center;
    padding: 1rem;
  }
  .age-input {
    height: 2.5rem;
    width: 100%;
    border: 1px solid #ccc;
    outline: 1px solid #ccc;
    margin-top: 1rem;
  }
  .search-result-size {
    border-top: 1px solid rgba(100, 115, 130, 0.1);
    border-bottom: 1px solid rgba(100, 115, 130, 0.1);
    padding: 12px 5px;
    font-family: Mulish;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    color: #21334f;
    position: absolute;
    width: 100%;
  }

  .client-input-flex {
    display: flex;
    width: 100%;
    margin-bottom: 2.4rem;
  }

  .next-arr {
    background: white;
    background-image: url("/images/right-arrow.svg");
    height: 44px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    padding: 1.2rem 2.5rem;
    margin-left: 2rem;
    z-index: 2;
  }
  .next-arr:hover,
  .next-arr:focus,
  .next-arr:active,
  .next-arr::selection {
    background: #34a853;
    background-image: url("/images/right-arrow.svg");
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5%;
  }
  .client-filter {
    max-width: 100%;
    width: 60%;
    display: flex;
    align-items: center;
    /* height: 5rem; */
    margin-left: 1.6rem;
    position: relative;
  }
  .go-back-text {
    font-size: 1.5rem;
    font-weight: 600;
    margin-left: 0.6rem;
  }
  .loading-gif {
    width: 300px;
    height: 300px;
  }

  .back-arrow-img {
    width: 2rem;
    margin-bottom: 0.7rem;
  }
  .goBack {
    color: #21334f;
    text-decoration: none;
  }

  .client-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 25px;
    margin-bottom: 10px;
    // table-layout:fixed;
    th,
    td {
      padding: 15px 2rem;
      text-align: left;
      border-top: none;
      border-bottom: none;
      vertical-align: middle;
    }

    tr:first-child {
      th {
        font-size: 16px;
        padding: 0 2rem 0 2rem;
      }
    }
  }

  .table-mb {
    margin-bottom: 140px;
  }
  .search-icon {
    margin-left: 1.5rem;
  }
  .search-icon-engr {
    margin-left: 0.5rem;
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-yk16xz-control {
    border: none;
    height: 5rem;
  }
  .css-1pahdxg-control {
    border: none;
    height: 5rem;
    box-shadow: none;
  }
  .css-1pahdxg-control:hover {
    border: 1px #34a853 solid !important;
  }
  input {
    padding-left: 1.6rem;
  }
  input,
  .my-select {
    height: 5rem;
    font-size: 1.6rem;
    border: none;
    outline: none;
    color: #21334f;
    width: 100%;
  }
  .css-1hb7zxy-IndicatorsContainer {
    padding-bottom: 8px;
  }
  .css-1wa3eu0-placeholder {
    top: 40%;
  }
  .my-select {
    padding-bottom: 1rem;
  }

  .custom-select:focus {
    border: none;
  }
  input:focus {
    border: none;
  }

  .btn-comp {
    width: 200px;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }

  .client-search {
    width: 90%;
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid rgba(33, 51, 79, 0.1);
    position: relative;
  }
  .eng-client-search {
    width: 40%;
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid rgba(33, 51, 79, 0.1);
    position: relative;
  }
  .eng-client-search,
  .eng-client-search:focus {
    border: 1px #34a853 solid;
    border-radius: 0.5rem;
  }

  .client-search,
  .eng-client-search:focus {
    border: 1px #34a853 solid;
    border-radius: 0.5rem;
  }

  .input-spinner-select {
    position: absolute;
    right: calc(0% + 15px);
  }
  .input-spinner-filter {
    position: absolute;
    right: calc(0% + 40px);
    top: 45%;
  }

  input::placeholder {
    font-size: 16px;
    line-height: 20px;
    color: rgba(33, 51, 79, 0.6);
  }
  .client-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 4.8rem;
    margin-bottom: 1.1rem;
  }
  .client-header h4 {
    font-weight: 700;
    font-size: 2.4rem;
  }
  .client-header h3 {
    margin-bottom: -10rem;
    font-weight: 700;
    font-size: 2.4rem;
  }
  .form-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .no-data {
    text-align: center !important;
    font-size: large;
  }
  .date-search {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
  input[type="date"] {
    border: 1px solid #34a853;
    border-radius: 5px;
  }

  .span-label {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .document-section {
    display: grid;
    grid-template-columns: 10fr 2fr;
    width: 100%;
    align-items: center;
  }
  .btn-doc {
    width: 100%;
  }

  .engineer-filters {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
  }

  .engineer-filters-wrapper {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    width: 100%;
    padding: 0 1rem;

    .input-search {
      padding: 0 0.25rem;
    }
    .client-filter {
      margin-left: 0;
    }
    .client-filter-expansion {
      color: #34a853;
      cursor: pointer;
      font-size: 16px;
    }
  }

  .engineer-filters-wrapper > * {
    width: 100%;
  }

  .engineer-filters > .client-filter {
    display: inline-block;
  }
  @media screen and (max-width: 1170px) {
    width: 100%;
    .client-header {
      margin-top: 3.4rem;
      margin-bottom: 1.1rem;
    }
  }
  @media screen and (max-width: 800px) {
    .btn-comp {
      width: 190px;
    }
  }
  @media screen and (max-width: 770px) {
    .client-input {
      width: 100%;
    }
    .client-header h4 {
      font-size: 2rem;
    }
    .btn-comp {
      width: 170px;
      font-size: 14px;
    }
    .engineers-div {
      width: 100%;
      overflow: scroll;
      flex-direction: row;
    }
    .engineer-filters {
      display: block;
      gap: 1rem;

      overflow: auto;
      white-space: nowrap;
      width: 100%;
    }
  }
  @media screen and (max-width: 500px) {
    .client-header {
      margin-top: 1rem;
    }

    .client-header h4 {
      margin-bottom: 16px;
    }

    .client-input,
    .client-input-flex {
      width: 100%;
      flex-direction: column;
    }
    .client-search,
    .eng-client-search {
      width: 100%;
    }

    .client-filter {
      width: 100%;
      margin-left: 0rem;
    }

    .engineer-filters .client-filter {
      margin-right: 20px;
    }
    .engineer-filters .client-filter Select {
      width: 300px;
    }

    .engineer-filters-wrapper {
      // display: block;
      grid-template-columns: repeat(3, 1fr);
    }

    .btn-comp {
      height: 42px;
      font-size: 1.4rem;
    }
  }
`;
export default ListingInterface;
