import React, { useState, useEffect } from "react";
import ActivityDropdown from "../../../atoms/ActivityDropdown";
import { Pie } from "react-chartjs-2";
import styled from "styled-components";
import { apiGet } from "../../../../../utils/apiHelpers";

const Request = () => {
  const [currentState, setCurrentState] = useState("All");
  const [period, setPeriod] = useState("");
  const [engineerCount, setEngineerCount] = useState(0);
  const [subscriptionCount, setSubscriptionCount] = useState(0);

  const getRequestActivity = async () => {
    try {
      const res = await apiGet(
        `/admin/requests/admin-tracking?period=${period}`,
        'admin'
      );

      const data = res.data.data;
      setEngineerCount(data.engineerRequestCount);
      setSubscriptionCount(data.subscriptionRequestCount);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getRequestActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentState]);

  const data = {
    labels: ["Engineer Request", "Subscription Request"],
    datasets: [
      {
        label: "# of Votes",
        data: [engineerCount, subscriptionCount],
        backgroundColor: ["#34A853", "#B79D00"],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: true,
    responsive: true,
  };

  return (
    <StyledTracker>
      <div className="card">
        <div className="header">
          <ActivityDropdown
            header="Requests"
            setPeriod={setPeriod}
            currentState={currentState}
            setCurrentState={setCurrentState}
          />
        </div>

        {engineerCount === 0 && subscriptionCount === 0 && (
          <div className="no-data">No Data Available</div>
        )}
        {engineerCount > 0 ||
          (subscriptionCount > 0 && (
            <div className="content">
              <div className="pie">
                <Pie data={data} options={options} />
              </div>

              <div className="small-cards">
                <div className="mini-cards">
                  <div className="eng-card"></div>
                  <div className="title-cards">Processed Engineer Request</div>
                </div>
                <div className="mini-cards">
                  <div className="sub-card"></div>
                  <div className="title-cards">
                    Processed Subscription Request
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </StyledTracker>
  );
};

const StyledTracker = styled.div`
  .card {
    width: 100%;
    height: 100%;
    background: #fff;
    position: relative;
    .header {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }
    .content {
      height: 100%;
      overflow-x: scroll;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 3rem;
    }
    .pie {
      width: 20rem;
      height: 20rem;
    }
    .small-cards {
      display: flex;
      gap: 3rem;
    }
    .sub-card,
    .eng-card {
      height: 3rem;
      width: 3rem;
      border-radius: 0.7rem;
    }
    .sub-card {
      background: #b79d00;
    }
    .eng-card {
      background: #34a853;
    }
    .mini-cards {
      display: flex;
      gap: 0.8rem;
      align-items: center;
    }
    .title-cards {
      font-weight: 600;
      font-size: 1.2rem;
    }
    .no-data {
      font-size: 2rem;
      text-align: center;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
export default Request;
