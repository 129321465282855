import React, { useState } from "react";
import styled from "styled-components";
import ManageUsers from "./ManageUsers";
import SettingsLayout from "./SettingsLayout";
import PasswordSecurity from "./PasswordSecurity";
import Information from "./Information";
import Role from "./Role";
import OrganizationProfile from "./OrganizationProfile";
import ProtectedComponents from "../../local-routes/ProtectedComponents";

const SettingsRender = ({ pageType }) => {
  const [active, setActive] = useState(
    pageType === "engineer" ? "password" : "info"
  );
  return (
    <StyledDiv>
      <h3 className="setting">Settings</h3>
      <div className="settings-grid-container mt-5">
        <div>
          <SettingsLayout
            active={active}
            setActive={setActive}
            pageType={pageType}
          />
        </div>
        <div>
          {active === "manage" && pageType !== "engineer" && (
            <ProtectedComponents
              permission={
                pageType === "client"
                  ? ["manageOrganisationUser"]
                  : ["manageAdminUser", "viewAdminUser"]
              }
            >
              <ManageUsers pageType={pageType} />
            </ProtectedComponents>
          )}
          {active === "password" && <PasswordSecurity pageType={pageType} />}
          {active === "info" && pageType !== "engineer" && (
            <Information pageType={pageType} />
          )}
          {active === "org-profile" && pageType !== "engineer" && (
            <ProtectedComponents
              permission={
                pageType === "client"
                  ? ["manageOrganisationUser"]
                  : ["manageAdminUser", "viewAdminUser"]
              }
            >
              <OrganizationProfile pageType={pageType} />
            </ProtectedComponents>
          )}
          {active === "roles" && pageType === "admin" && (
            <ProtectedComponents permission={["manageRole"]}>
              <Role pageType={pageType} />
            </ProtectedComponents>
          )}
        </div>
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  box-sizing: border-box;
  .setting {
    font-size: 2.5rem;
    font-weight: 600;
  }
  .settings-grid-container {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 4rem;
  }
  @media only screen and (min-width: 280px) and (max-width: 768px) {
    margin-top: 4rem;
    .settings-grid-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 4rem;
    }
  }
  @media only screen and (min-width: 678px) and (max-width: 1024px) {
    .settings-grid-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 4rem;
    }
  }

  // @media only screen and (max-width: 321px){
  //   .settings-grid-container {
  //     grid-template-columns: 1fr;
  //     grid-gap: 4rem;
  //   }

  // }
`;

export default SettingsRender;
