export const organizationView = {
  placeholder: "search for clients",
  btnInfo: "Add New Customer",
  title: "Clients",
  headers: [
    "Company",
    "Contact Name",
    "Email Address",
    "Phone Number",
    "Customer Type",
    // "Subscription Plan",
    "Status",
    "Action",
  ],
  options: [
    { value: "", label: "Select Status" },
    { value: "pending", label: "Pending" },
    { value: "verified", label: "Verified" },
    { value: "unverified", label: "Unverified" },
    { value: "cold", label: "Cold" },
    { value: "warm", label: "Warm" },
  ],
  customerTypeOptions: [
    { value: "", label: "Select Customer Type" },
    { value: "local_client", label: "Local Client" },
    { value: "intl_client", label: "International Client" },
    { value: "local_lead", label: "Local Lead" },
    { value: "intl_lead", label: "International Lead" },
  ],
  tableDetails: [
    "name",
    "contactPerson",
    "email",
    "telephoneNumber",
    "clientType",
    // "subscriptionPlan",
    "customerStatus",
    "action",
  ],
  path: "/admin/organisations",
  auth: "admin",
};

export const overviewClients = {
  headers: [
    "Company",
    "Contact Name",
    "Email Address",
    "Phone Number",
    "Customer Type",
    // "Subscription Plan",
    "Status",
    "Action",
  ],

  tableDetails: [
    "name",
    "contactPerson",
    "email",
    "telephoneNumber",
    "clientType",
    // "subscriptionPlan",
    "customerStatus",
    "action",
  ],
  path: "/admin/organisations",
  auth: "admin",
};

export const overviewEngineers = {
  headers: [
    "",
    "Username",
    "Gender",
    "Language",
    "Eng. Type",
    "Status",
    "Action",
  ],

  tableDetails: [
    // "thumbnailUrl",
    "image",
    "username",
    "gender",
    "language",
    "engType",
    "status",
    "action",
  ],
  // path: "/admin/engineers",
  path: () => `/admin/engineers`,
  auth: "admin",
};

export const clientSubscriptionView = {
  placeholder: "search for subscriptions",
  btnInfo: "",
  title: "Subscriptions",
  headers: [
    "Package",
    "Number of Engineer",
    "Used Slots",
    "Available Credit",
    "Start Date",
    "Expiry Date",
    "Status",
    "Action",
  ],

  options: [
    { value: "", label: "Select Status" },
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ],
  tableDetails: [
    "packageName",
    "numEngineers",
    "used",
    "availableCredit",
    "startDate",
    "expireDate",
    "status",
    "action",
  ],
  path: "/client/subscriptions",
  auth: "client",
};

export const clientEngineerView = {
  placeholder: "search for engineers",
  btnInfo: "",
  title: "Engineers",
  headers: [
    "",
    "Name",
    "Email address",
    "Phone Number",
    "Status",
    "Credit Allocation",
    "Action",
  ],

  options: [
    { value: "name", label: "company name" },
    { value: "status", label: "status" },
  ],
  tableDetails: [
    "thumbnailUrl",
    "name",
    "email",
    "telephoneNo",
    "status",
    "creditAllocated",
    "action",
  ],
  path: (id) => `/organisations/${id}/assignments`,
  auth: "client",
};

export const adminEngReq = {
  placeholder: "search for engineers",
  btnInfo: "",
  title: "Engineer Request",
  headers: [
    "Organisation",
    "Request Date",
    "Numbers of Engineers",
    "Tech Stack",
    "Status",
    "Action",
  ],

  options: [
    { value: "name", label: "company name" },
    { value: "status", label: "status" },
  ],
  tableDetails: [
    "organisation",
    "requestDate",
    "numberOfEngineers",
    "techStackRequest",
    "status",
    "action",
  ],
  path: `/assignments/requests`,
  auth: "admin",
};

export const adminSubReq = {
  placeholder: "search for subscriptions",
  btnInfo: "",
  title: "Subscription Request",
  headers: ["Organisation", "Package Name", "Request Date", "Status", "Action"],

  options: [
    { value: "name", label: "company name" },
    { value: "status", label: "status" },
  ],
  tableDetails: [
    "organisation",
    "packageName",
    "requestDate",
    "status",
    "action",
  ],
  path: `/subscriptions/requests`,
  auth: "admin",
};

export const clientEngRequest = {
  placeholder: "search for engineers",
  btnInfo: "Request Engineers",
  title: "Engineers Request",
  headers: [
    "Organisation",
    "Tech Stack",
    "Numbers of Engineers",
    "Request Date",
    "Status",
    "Action",
  ],

  options: [
    { value: "name", label: "company name" },
    { value: "status", label: "status" },
  ],
  tableDetails: [
    "organisation",
    "techStackRequest",
    "numberOfEngineers",
    "requestDate",
    "status",
    "action",
  ],
  path: `/assignments/requests`,
  auth: "client",
};

export const clientSubReqView = {
  placeholder: "search for subscriptions",
  btnInfo: "Request Subscription",
  title: "Subscriptions Request",
  headers: ["Organisation", "Package", "Request Date", "Status", "Action"],

  options: [
    { value: "name", label: "company name" },
    { value: "status", label: "status" },
  ],
  tableDetails: [
    "organisation",
    "packageName",
    "requestDate",
    "status",
    "action",
  ],
  path: "/subscriptions/requests",
  auth: "client",
};

export const adminSubscriptionView = {
  placeholder: "search for subscriptions",
  btnInfo: "Add Subscriptions",
  title: "Subscriptions",
  headers: [
    "Organisation",
    "Package",
    "Start Date",
    "Expiry Date",
    "Status",
    "Action",
  ],
  options: [
    { value: "", label: "Select Status" },
    { value: "active", label: "Active" },
    { value: "expire", label: "Expire" },
  ],
  tableDetails: [
    "organisation",
    "packageName",
    "startDate",
    "expireDate",
    "status",
    "action",
  ],
  path: "/admin/subscriptions",
  auth: "admin",
};

export const documentView = {
  placeholder: "search for document",
  btnInfo: "Add Document",
  title: "Document",
  headers: [
    "Document Name",
    "Document Type",
    "Document Date",
    "Status",
    "Action",
  ],
  options: [
    { value: "", label: "Select Status" },
    { value: "active", label: "Active" },
    { value: "expire", label: "Expire" },
  ],
  tableDetails: ["documentName", "docType", "timeStamp", "status", "action"],
  path: "/admin/clients",
  auth: "admin",
};
export const invoiceView = {
  placeholder: "search for Invoice",
  btnInfo: "Generate Invoice",
  title: "Invoice",
  headers: ["Amount", "Date", "Action"],
  options: [
    { value: "", label: "Select Status" },
    { value: "active", label: "Active" },
    { value: "expire", label: "Expire" },
  ],
  tableDetails: ["displayTotalAmount", "invoiceDate", "action"],
  path: "/admin/clients",
  auth: "admin",
};

export const adminEngineerView = {
  placeholder: "Search For Engineers",
  btnInfo: "Add Engineers",
  title: "Engineers",
  headers: [
    "",
    "Username",
    "Gender",
    "Language",
    "Eng. Type",
    "Placement Type",
    "Status",
    "Action",
  ],

  engineerPlacementOptions: [
    { value: "", label: "Placement Type" },
    { value: "INTERNSHIP", label: "Internship" },
    { value: "RESIDENCE", label: "Residence" },
    { value: "BILLING", label: "Billing" },
    { value: "NOT_ASSIGNED", label: "Not Assigned" },
    { value: "INTERVIEW", label: "Interview" },
  ],
  engineerStatusOptions: [
    { value: "", label: "Select Status" },
    { value: "INPROGRESS", label: "In progress" },
    { value: "SHORTLISTED", label: "Shortlisted" },
    { value: "NOTSELECTED", label: "Not selected" },
    { value: "RETURNED", label: "Returned" },
    { value: "RETAINED", label: "Retained" },
    { value: "ENDED", label: "Ended" },
    { value: "COMPLETED", label: "Completed" },
    { value: "UNDER_REVIEW", label: "Under Review" },
    { value: "RETURNED", label: "Returned" },
    { value: "REJECTED_BY_COMPANY", label: "Rejected by Company" },
    { value: "RESIGNED", label: "Resigned" },
    { value: "TERMINATED", label: "Terminated" },
    { value: "PENDING", label: "Pending" },
  ],
  engineerTypeOptions: [
    { value: "", label: "Engineer Type" },
    { value: "decadev", label: "Decadev" },
    { value: "non_decadev", label: "Non Decadev" },
  ],
  engineerGenderOptions: [
    { value: "", label: "Select Gender" },
    { value: "MALE", label: "MALE" },
    { value: "FEMALE", label: "FEMALE" },
  ],
  engineerPlacementStageOptions: [
    { value: "", label: "Placement Stage" },
    { value: "Fellowship Interview", label: "Fellowship Interview" },
    { value: "Fellowship Internship", label: "Fellowship Internship" },
    { value: "Fellowship Residency", label: "Fellowship Residency" },
    { value: "Non-Fellowship Interview", label: "Non-Fellowship Interview" },
    { value: "Non-Fellowship Internship", label: "Non-Fellowship Internship" },
    { value: "Non-Fellowship Residency", label: "Non-Fellowship Residency" },
  ],
  engineerPaymentStatusOptions: [
    { value: "", label: "Payment Status" },
    { value: "Moratorium", label: "Moratorium" },
    { value: "Active", label: "Active" },
    { value: "Defaulting", label: "Defaulting" },
    { value: "Closed", label: "Closed" },
  ],
  engineerPaymentTypeOptions: [
    { value: "", label: "Payment Type" },
    { value: "Self-Sponsored", label: "Self-Sponsored" },
    { value: "Loan", label: "Loan" },
  ],
  engineerClientTypeOptions: [
    { value: "", label: "Client Type" },
    { value: "Local", label: "Local" },
    { value: "International", label: "International" },
  ],
  engineerAgeOptions: [
    { value: "", label: "Age" },
    { value: "0-20", label: "0-20" },
    { value: "21-23", label: "21-23" },
    { value: "24-26", label: "24-26" },
    { value: "27-29", label: "27-29" },
    { value: "30", label: "Above 30" },
  ],
  engineerCurrentLocationOptions: [
    { value: "", label: "Location" },
    { value: "Lagos", label: "Lagos" },
    { value: "Edo", label: "Edo" },
  ],
  engineerTrainingLocationOptions: [
    { value: "", label: "Training Location" },
    { value: "0", label: "EdoTechPark" },
    { value: "1", label: "Decagon Uno" },
  ],
  tableDetails: [
    "thumbnailUrl",
    "username",
    "gender",
    "language",
    "engType",
    "placementType",
    "status",
    "action",
  ],
  path: () => `/admin/engineers`,
  auth: "admin",
};

export const adminAssessmentView = {
  btn1Info: "Download",
  btn2Info: "Upload",
};

export const adminNotificationActions = {
  btn1Info: "Send Mail",
  btn2Info: "Add User",
  btn3Info: "Remove",
};

export const adminNotificationModuleParams = {
  option1: "WEEKLY_PERFORMANCE_NOTIFICATION",
};

export const userListing = {
  placeholder: "search for users",
  btnInfo: "Add Users",
  title: "Users",
  headers: ["Name", "Email", "Phone", "Date Added", "Role", "Status", "Action"],

  options: [
    { value: "name", label: "Name" },
    { value: "status", label: "Status" },
  ],
  tableDetails: [
    "name",
    "email",
    "phone",
    "dateAdded",
    "role",
    "status",
    "action",
  ],
  auth: "admin",
};

export const subListing = {
  placeholder: "search for subscriptions",
  btnInfo: "Add Subscriptions",
  title: "Subscriptions",
  headers: [
    "Organisation Name",
    "Package Name",
    "Num of Engineers",
    "Used slots",
    "Available Credit",
    "Start Date",
    "Expiry Date",
    "Status",
    "Action",
  ],

  options: [
    { value: "name", label: "Name" },
    { value: "status", label: "Status" },
  ],
  tableDetails: [
    "organisation",
    "packageName",
    "numEngineers",
    "used",
    "availableCredit",
    "startDate",
    "expireDate",
    "status",
    "action",
  ],
  auth: "admin",
};

export const assignmentListing = {
  placeholder: "",
  btnInfo: "",
  title: "Placement History",
  headers: [
    "Vendor",
    "Placement Date",
    "Status Date",
    "End Date",
    "Credit Allocation",
    "Status",
    "Action",
  ],

  options: [
    { value: "name", label: "Name" },
    { value: "status", label: "Status" },
  ],
  tableDetails: [
    "vendor",
    "assignmentDate",
    "statusDate",
    "endDate",
    "creditAllocated",
    "status",
    "action",
  ],
  auth: "admin",
};
export const placementListing = {
  placeholder: "",
  btnInfo: "",
  title: "Placement History",
  headers: [
    "Engineer Name",
    "Organisation",
    "Placement Date",
    "Resumption Date",
    "End Date",
    "Type",
    "Status",
    "Action",
  ],
  engineerStatusOptions: [
    { value: "", label: "Select Status" },
    { value: "Internship", label: "Internship" },
    { value: "Residence", label: "Residence" },
    { value: "Billing", label: "Billing" },
    { value: "Terminated", label: "Terminated" },
    { value: "Completed", label: "Completed" },
    { value: "Retained", label: "Retained" },
    { value: "Not Assigned", label: "Not Assigned" },
    { value: "Training", label: "Training" },
    { value: "Live Project", label: "Live Project" },
    { value: "Interview", label: "Interview" },
    { value: "Under Review", label: "Under Review" },
    { value: "Shortlisted", label: "Shortlisted" },
  ],
  engineerTypeOptions: [
    { value: "", label: "Engineer Type" },
    { value: "Decadev", label: "Decadev" },
    { value: "Non decadev", label: "Non Decadev" },
  ],
  clientTypeOptions: [
    { value: "", label: "Select Client Type" },
    { value: "direct local", label: "Direct local" },
    { value: "indirect local", label: "Indirect local" },
    { value: "direct international", label: "Direct international" },
    { value: "indirect international", label: "Indirect international" },
  ],
  tableDetails: [
    "name",
    "organisation",
    "assignmentDate",
    "statusDate",
    "endDate",
    "placementType",
    "status",
    "action",
  ],
  auth: "admin",
};

export const packageListing = {
  placeholder: "search for packages",
  btnInfo: "Add Packages",
  title: "Packages",
  headers: [
    "Name",
    "Amount",
    "Date Added",
    "Duration",
    "Num of Engineers",
    "Action",
  ],

  options: [
    { value: "name", label: "Name" },
    { value: "status", label: "Status" },
  ],
  tableDetails: [
    "name",
    "amount",
    "dateAdded",
    "duration",
    "numOfEngineers",
    "action",
  ],
  auth: "admin",
  path: `/admin/packages`,
};

export const squadListing = {
  placeholder: "search for squad",
  btnInfo: "Add Squad",
  title: "Squads",
  headers: ["Name", "Location", "Start Date", "End Date", "Action"],

  options: [
    { value: "name", label: "Name" },
    { value: "status", label: "Status" },
  ],
  tableDetails: ["name", "location", "startDate", "endDate", "action"],
  auth: "admin",
  path: `/admin/squads`,
};

export const industryListing = {
  placeholder: "search for industry",
  btnInfo: "Add Industry",
  title: "Industry",
  headers: ["Name", "Description", "Action"],

  options: [
    { value: "name", label: "Name" },
    { value: "status", label: "Status" },
  ],
  tableDetails: ["name", "description", "action"],
  auth: "admin",
  path: `/admin/industries`,
};

export const billingListing = {
  placeholder: "search for billing rate",
  btnInfo: "Add Billing Rate",
  title: "Billing Rate",
  headers: ["Amount", "Engineer Level", "Action"],
  tableDetails: ["amount", "engineerLevel", "action"],
  auth: "admin",
  path: `/admin/billing`,
};

export const techStackListing = {
  placeholder: "search for tech stack",
  btnInfo: "Add TechStack",
  title: "TechStack",
  headers: ["Id", "Name", "Action"],

  options: [
    { value: "name", label: "Name" },
    { value: "status", label: "Status" },
  ],
  tableDetails: ["id", "name", "action"],
  auth: "admin",
  path: `/admin/stacks`,
};

export const employeeNumber = [
  { value: "", label: "Choose your Option" },
  { value: "1-10", label: "1-10" },
  { value: "11-50", label: "11-50" },
  { value: "50-100", label: "50-100" },
  { value: "Above 100", label: "Above 100" },
];

export const developerNumber = [
  { value: "", label: "Choose your Option" },
  { value: "1-10", label: "1-10" },
  { value: "11-50", label: "11-50" },
  { value: "50-100", label: "50-100" },
  { value: "Above 100", label: "Above 100" },
];

export const engineerRatings = {
  headers: ["SN", "Organization", "Rater", "comment", "Date", "Star Rating"],

  tableDetails: [
    "index",
    "organisationName",
    "reviewerName",
    "review",
    "dateRated",
    "averageRating",
  ],

  path: () => `/engineer/ratings`,
  auth: "engineer",
};
