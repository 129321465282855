import React from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { GrammarlyEditorPlugin } from '@grammarly/editor-sdk-react'


const FormTextAreaComponent = ({
  label,
  type,
  name,
  value,
  onChange,
  onBlur,
  error,
  placeholder,
  defaultValue,
  max,
  maxLength,
  className,
  pageFormType
}) => {
  return (
    <StyledFormInputComponent>
      {label && (
        <label htmlFor={name} className="form-font form-label">
          {label} <span style={{fontSize: "10px"}}>{maxLength ? `${value?.length || 0}/${maxLength}` : ""}</span>
        </label>
      )}
      <GrammarlyEditorPlugin 
        clientId="client_1HVSLd98euSdd7jChbQmW8" 
        config={{
          activation: "immediate",
          // autocomplete: "on",
          underlines: "on",
          // toneDetector: "on"
        }}>
        <textarea
          type={type}
          id={name}
          max={max && type === "date" ? format(max, "yyyy-MM-dd") : null}
          placeholder={placeholder}
          name={name}
          maxLength={maxLength}
          onChange={onChange}
          onBlur={onBlur}
          onWheel={type === "number" ? (e) => e.target.blur() : undefined}
          value={value || defaultValue}
          className={`form-font ${className}`}
          rows={pageFormType!=="engReg" ? 8 : 6}
          cols={pageFormType!=="engReg" ? 4 : null}
        />
      </GrammarlyEditorPlugin>
      {error && <div className="form-error">{error}</div>}
    </StyledFormInputComponent>
  );
};

const StyledFormInputComponent = styled.div`
  margin-bottom: 2.4rem;
  textarea {
    display: inline-block;
    font-size: 1.6rem;
    background: transparent;
    -webkit-appearance: none;
    min-width: 32rem;
    border: none;
    padding-bottom: 0rem;
    padding-left: 1rem;
    border: 1px solid rgba(33, 51, 79, 0.15) !important;
  }
  textarea:active{
  border: 1px solid green !important;
}
textarea:focus{
  border: 1px solid green !important;
}

  @media only screen and (max-width: 405px) {
    textarea {
      min-width: 100%;
    }
  }
`;

export default FormTextAreaComponent;
