import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { apiPost, apiGet, apiPut } from "../../../utils/apiHelpers";
import FormInputComponent from "./enterpriseFormInputs/FormInputComponent";
import FormTitleSection from "./FormTitleSection";
import MyButton from "../../landingPage/MyButton";
import { toast } from "react-toastify";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { Popover } from "@headlessui/react";
import { ClipLoader } from "react-spinners";
const AddRole = ({
  onClick,
  fetchAdminRoles,
  isEditing,
  setIsEditing,
  updateId,
  pageType,
}) => {
  const [initialValues] = useState({
    name: "",
    description: "",
    permissions: [],
    roleType: `${pageType}`.toUpperCase(),
  });

  const validationSchema = Yup.object({
    name: Yup.string().required("*Required"),
    description: Yup.string().required("*Required"),
    permissions: Yup.array().required("*Required"),
    roleType: Yup.string().required("*Required"),
  });

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [permList, setPermList] = useState([]);
  const [permissions, setPermissions] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [_selectedRole, setSelectedRole] = useState([]);

  const fetchSingleRole = async () => {
    let permArr = [];
    try {
      let response = await apiGet(`/admin/roles/${updateId}`);
      let res = response.data.data;

      const fields = ["name", "description"];
      fields.forEach((field) => formik.setFieldValue(field, res[field], false));
      formik.setFieldValue("roleType", `${pageType}`.toUpperCase(), false);
      res.permissions.forEach((perm) =>
        perm.checked ? permArr.push(perm.id) : null
      );
      setSelectedRole(permArr);

      let permissionsResponse = res.permissions;
      permissionsResponse = permissionsResponse
        .map((singlePermissions) => {
          return {
            ...singlePermissions,
            permissions: singlePermissions.permissions.filter(
              (subPermissions) => {
                return subPermissions.checked === true;
              }
            ),
          };
        })
        .filter((item) => item.permissions.length > 0);

      setPermissions(permissionsResponse);

      // formik.setFieldValue("permissions", permissions, false);
    } catch (err) {
      toast.error(err);
    }
  };

  useEffect(() => {
    if (isEditing) {
      fetchSingleRole();
    }
    // eslint-disable-next-line
  }, [isEditing]);

  const new_token = localStorage.getItem("token");

  let config = {
    headers: {
      Authorization: `Bearer ${new_token}`,
      "Content-type": "application/json; charset=UTF-8",
    },
  };

  const createRole = async (formData) => {
    setLoading(true)
    try {
      if (!isEditing) {
        await apiPost(`/admin/roles`, formData, config);
        setSuccess(true);
      }

      if (isEditing) {
        await apiPut(`/admin/roles/${updateId}`, formData, config);
        setSuccess(true);
      }
    } catch (err) {
      console.log({ err });
    }
    setLoading(false)
  };
  useEffect(() => {
    fetchAdminRoles();
    // eslint-disable-next-line
  }, [success]);

  const fetchPermissions = async () => {
    try {
      let response = await apiGet(`/admin/permissions`);
      const permArr = [];
      const permVal = [];

      response.data.data.map((item) => {
        permArr.push({
          group: item.group,
          permissions: item?.permissions,
        });
        permVal.push(item.group);
        return item;
      });
      setPermList(permArr);
      // setPermValue(permVal);
    } catch (err) {
      toast.error(err);
    }
  };
  useEffect(() => {
    fetchPermissions();
  }, []);

  const onSubmit = (values) => {
    const allPermissions = [];
    // GET ALL PERMISSIONS
    permissions.map((singlePermissionsGroup) => {
      return singlePermissionsGroup.permissions?.map((singlePermission) => {
        return allPermissions.push(singlePermission.id);
      });
    });

    createRole({
      ...values,
      roleType: `${pageType}`.toUpperCase(),
      permissions: allPermissions,
    });
    if (success) {
      setIsEditing(false);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  const closeForm = () => {
    formik.resetForm();
    onClick();
    setSuccess(false);
    setIsEditing(false);
  };

  const handleCheck = (e) => {
    const { checked, value } = e.target;

    const checkPermissions = permList.find((item) => item.group === value);

    //ADD PERMISSIONS
    if (checked && checkPermissions) {
      setPermissions((prevPermissions) => {
        const checkPrevPermissions = prevPermissions.find(
          (item) => item.group === checkPermissions.group
        );

        if (checkPrevPermissions) {
          return prevPermissions;
        } else {
          setPermissions([...prevPermissions, checkPermissions]);
        }
      });
    } else {
      //REMOVE PERMISSIONS
      const removePermissions = permissions.filter(
        (item) => item.group !== value
      );

      setPermissions([...removePermissions]);
    }
  };

  const handleSubCheck = (e, groupName) => {
    const { checked, value } = e.target;
    const subPermissionsId = value;

    const checkSubPermissions = permissions?.find(
      (item) => groupName === item?.group
    );
    //ADD SUB-PERMISSIONS
    if (checked && checkSubPermissions) {
      let getSubPermissions = checkSubPermissions?.permissions;

      let subPermissionsIndex = permissions?.findIndex(
        (item) => groupName === item?.group
      );

      getSubPermissions.push({
        id: subPermissionsId,
      });

      permissions[subPermissionsIndex] = {
        group: groupName,
        permissions: getSubPermissions,
      };

      setPermissions([...permissions]);
    } else if (checked && !checkSubPermissions) {
      permissions.push({
        group: groupName,
        permissions: [
          {
            id: subPermissionsId,
          },
        ],
      });

      setPermissions([...permissions]);
    } else {
      //REMOVE SUB-PERMISSIONS
      let removeSubPermissions = permissions
        ?.find((item) => groupName === item?.group)
        ?.permissions?.filter((item) => subPermissionsId !== item.id);

      let removedSubPermissionsIndex = permissions?.findIndex(
        (item) => groupName === item?.group
      );
      permissions[removedSubPermissionsIndex] = {
        group: groupName,
        permissions: removeSubPermissions,
      };

      setPermissions([...permissions]);

      if (permissions[removedSubPermissionsIndex]?.permissions?.length === 0) {
        let newPermissions = permissions?.filter(
          (_, index) => index !== removedSubPermissionsIndex
        );
        setPermissions([...newPermissions]);
      }
    }
  };

  return (
    <StyledRoleForm>
      <FormTitleSection title={isEditing ? "Edit Role" : " Add New Role"} onClick={closeForm} />
      <div className="form-section">
        {!success ? (
          <form className="client-form" onSubmit={formik.handleSubmit}>
            <FormInputComponent
              label="Role"
              type="text"
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              error={
                formik.touched.name && formik.errors.name
                  ? formik.errors.name
                  : null
              }
              placeholder="Enter Name"
            />

            <FormInputComponent
              label="Description"
              type="text"
              name="description"
              placeholder="Enter Description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              error={
                formik.touched.description && formik.errors.description
                  ? formik.errors.description
                  : null
              }
            />

            <div className="mt-5">
              <p className="perm-head">Select Permissions</p>
              <div className="grid-container mt-4 ">
                {permList.map((group, index) => (
                  <div>
                    <label
                      htmlFor={"chk" + index}
                      key={index}
                      className="check-label ">
                      <input
                        className="checkbox-area"
                        type="checkbox"
                        id={"chk" + index}
                        name="permissions"
                        value={permList[index]?.group}
                        onChange={handleCheck}
                        checked={
                          permissions?.find(
                            (item) => item?.group === permList[index]?.group
                          )?.group || false
                        }
                      />
                      <span className="checkmark"></span>

                      <span
                        className="item-span"
                        style={{ position: "relative" }}>
                        {group.group}
                      </span>
                    </label>
                    <div
                      style={{
                        marginLeft: "1.5rem",
                      }}>
                      {group.permissions.length > 0
                        ? group.permissions?.map((subPermissions, index) => {
                            return (
                              <div>
                                <label
                                  htmlFor={"chk" + subPermissions.id}
                                  key={subPermissions.id}
                                  className="check-label ">
                                  <input
                                    className="checkbox-area"
                                    type="checkbox"
                                    id={"chk" + subPermissions.id}
                                    name="subPermissions"
                                    value={subPermissions.id}
                                    // value={permValue[index]}
                                    onChange={(e) =>
                                      handleSubCheck(e, group.group)
                                    }
                                    checked={
                                      permissions
                                        ?.find(
                                          (item) => group?.group === item?.group
                                        )
                                        ?.permissions.find(
                                          (item) =>
                                            subPermissions.id === item.id
                                        ) || false
                                    }
                                  />
                                  <span className="checkmark sub-checkmark"></span>

                                  <span
                                    className="item-span"
                                    style={{ position: "relative" }}>
                                    {subPermissions.name}
                                    <Popover>
                                      <Popover.Button className={"popover-btn"}>
                                        <BsFillInfoCircleFill color="gray" />
                                      </Popover.Button>

                                      <Popover.Panel className="popover-content">
                                        {subPermissions.description}
                                      </Popover.Panel>
                                    </Popover>
                                  </span>
                                </label>
                                {/* {item.name}
                          {item.id}
                          {item.description} */}
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <MyButton
              className="form-btn add-client-btn mt-5"
              type="submit"
              loading={loading}
              full>
                {(loading && <ClipLoader color={"#fff"} size={"30px"} />)}
                {isEditing ? "Save Role" : " Add New Role"}            
            </MyButton>
          </form>
        ) : null}

        {success ? (
          <div className="form-success card">
            <img
              src="/images/sucess-icon.svg"
              alt="green-tick"
              className="sucess-icon"
            />
            <h2 className="form-font green-text">Success</h2>
            <p className="form-font success-msg">
              {isEditing ? "Role Edit Successfully done" : "You have successfully added an new Role."}
            </p>
            <MyButton
              className="form-btn success-btn"
              onClick={closeForm}>
              View List of Roles
            </MyButton>
          </div>
        ) : null}
      </div>
    </StyledRoleForm>
  );
};
const StyledRoleForm = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);

  .underline {
    display: block;
  }
  .form-section {
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3.2rem;
  }
  .add-client-btn {
    margin-bottom: 23.8rem;
  }
  .stack-section {
    display: flex;
    flex-wrap: wrap;
    width: 32rem;
  }
  .client-form {
    display: block;
  }
  .form-success {
    display: flex;
    width: 100%;
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    margin-top: 8rem;
  }
  .permissions-div {
    display: flex;
  }
  .add-perms-btn {
    padding: 0.1rem 2rem;
    margin-top: 3.2rem;
    border: none;
    background: linear-gradient(96.67deg, #34a853 0%, #b8d344 100%);
    color: #fff;
    font-size: 3rem;
    font-weight: 600;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 32rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
  .search-wrapper {
    padding: 1rem;
    font-size: 1.7rem;
    border: 1px solid rgba(33, 51, 79, 0.15) !important;
  }
  .searchBox::placeholder {
    color: rgb(104, 116, 135);
  }
  .perm-label {
    color: #21334f;
    font-size: 1.6rem;
    font-weight: 600;
  }
  .grid-container {
    display: grid;
    gap: 4rem;
    grid-template-columns: 1fr 1fr !important;
  }
  .checkbox-area {
    -webkit-appearance: button;
    margin-right: 1.5rem;
    display: none;
  }

  .check-label {
    width: 100%;
    /* height: 100%; */
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }
  .checkmark {
    width: 25px;
    height: 25px;
    border: 2px solid #34a853;
    display: inline-block;
    border-radius: 3px;
    margin-right: 1rem;
    background: #34a853
      url("https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/White_check.svg/1200px-White_check.svg.png")
      center/1250% no-repeat;
    transition: background-size 0.2s cubic-bezier(0.7, 0, 0.18, 1.24);
  }

  .sub-checkmark {
    width: 20px;
    height: 20px;
  }
  .check-label input:checked + .checkmark {
    background-size: 60%;
    transition: background-size 0.25s cubic-bezier(0.7, 0, 0.18, 1.24);
  }
  .item-span {
    font-size: 1.3rem;
    font-weight: 600;
  }
  .perm-head {
    font-size: 1.6rem;
    font-weight: 600;
  }

  .info-icon {
    position: absolute;
    right: -1.5rem;
    top: 0;
  }

  .popover-btn {
    outline: none;
    border: none;
    top: -0.7rem;
    right: -1.5rem;
    position: absolute;
  }
  .popover-content {
    position: absolute;
    left: -30px;
    z-index: 9999;
    background-color: #fff;
    border: 1px solid gray;
    padding: 10px;
    text-transform: capitalize;
    width: 400px;
    white-space: normal;
  }
`;
export default AddRole;
