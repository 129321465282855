import React from "react";
import styled from "styled-components";
import { Popover } from "@headlessui/react";

const ActionDropdown = ({ children }) => {
  return (
    <ActionDiv>
        <Popover className="position-relative">
            <Popover.Button className="dots-btns">
                <span className="dots"> </span>
                <span className="dots"> </span>
                <span className="dots"> </span>
            </Popover.Button>

            <Popover.Panel className="position-absolute">
                <div className="popup">
                    {children}
                </div>

                {/* <img src="/solutions.jpg" alt="" /> */}
            </Popover.Panel>
        </Popover>
    </ActionDiv>
  );
};

const ActionDiv = styled.div`
.popup {
    position: absolute;
    min-width: 150px;
    right: -10px;
    top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    font-size: 1.4rem;
    padding: 0.5rem 1rem;
    border: 1px solid rgba(33, 51, 79, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    z-index: 2;
    border-radius: 10px;
    p:hover {
      display: inline-block;
      font-weight: 700;
      cursor: pointer;
    }
}
`;

export default ActionDropdown;
