import React from "react";
import styled from "styled-components";
import {Link, useHistory } from "react-router-dom";

const Goback = ({ onClick }) => {
  const history = useHistory();
  return (
    <GobackStyle>
      <Link to="#" onClick={() => history.goBack()} className="goBack">
        <img
          className="back-arrow-img"
          src={"/images/back-arrow.svg"}
          alt="back arrow"
        />
        <span className="go-back-text">Go Back</span>
      </Link>
    </GobackStyle>
  );
};

export default Goback;
const GobackStyle = styled.div`
  .goBack {
    color: #21334f;
    text-decoration: none;
  }
  .go-back-text {
    font-size: 1.5rem;
    font-weight: 600;
    margin-left: 0.6rem;
  }
  .back-arrow-img {
    width: 1.5rem;
    margin-bottom: 0.5rem;
  }
`;
