import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import SidebarNavItem from "./SidebarNavItem";
// import { Burger } from "../../atoms/Hamburger";
import { useAuth } from "../../../../context/AuthState";
import {
  BsPieChartFill,
  BsPeopleFill,
  BsFillBellFill,
  BsPersonBoundingBox,
  BsGearFill,
  BsBarChartFill,
  BsFillLayersFill,
  BsFillGiftFill,
  BsFillCalculatorFill,
  BsFillPersonFill,
} from "react-icons/bs";
import { FaGraduationCap } from "react-icons/fa";
import {
  AiFillAlert,
  AiOutlineException,
  AiOutlineUser,
  // AiOutlineFileSearch,
} from "react-icons/ai";
import { FiActivity } from "react-icons/fi";
import { GiTeamUpgrade } from "react-icons/gi";
import { FaFileInvoice } from "react-icons/fa";
import {
  MdAssessment,
  MdBarChart,
  MdNotifications,
  MdOutlineListAlt,
} from "react-icons/md";

const DashboardSidebar = ({ navType, open, setOpen, handleShow }) => {
  const [activeClientNav] = useState(false);
  const [activeAdminEngNav] = useState(false);
  const [activeAdminSettings] = useState(false);
  const [activeOverviewNav] = useState(false);
  const [activeAdminSubscription] = useState(false);
  const [activeAdminEngReqNav] = useState(false);
  const [activeAdminSubReqNav] = useState(false);
  const [activePlacementNav] = useState(false);

  const [activeSubNav] = useState(false);
  const [activeInvoiceNav] = useState(false);
  const [activeClientEngNav] = useState(false);
  const [activeClientSettings] = useState(false);
  const [activeEngNotifications] = useState(false);
  const [activeEngApplications] = useState(false);
  const [activeLoanTracker] = useState(false);

  const [activeClientEngReq] = useState(false);
  const [activeClientSubReq] = useState(false);
  const [activeConfigReq] = useState(false);
  const [activeAdminActivity] = useState(false);
  const [activeClientOverview] = useState(true);

  const [activeTrainingData] = useState(false);

  const { logout, permissionCheck } = useAuth();
  const permRoles = localStorage?.getItem("roles");
  const permArr = permRoles ? permRoles.split(",") : [];

  const userDetails =
    navType === "client"
      ? JSON.parse(localStorage?.getItem("client_details"))
      : {};

  return (
    <StyledDashboardSidebar>
      <div>
        <div className="sidebar-logo">
          <img src="/images/Decagon-logo.svg" alt="Decagon's logo" />
          {/* <Burger open={open} setOpen={setOpen} /> */}
        </div>
        <div className="sidebar-nav">
          <div className="sidebar-nav-title">
            <p>Menu</p>
          </div>
          {navType === "engineer" && (
            <SidebarNavItem
              active={activeClientSettings}
              title="Profile"
              to="/engineer/profile"
              icon={<AiOutlineUser className="sidebar-icon" />}
            />
          )}

          {navType === "engineer" && (
            <SidebarNavItem
              active={activeEngNotifications}
              title="Notifications"
              to="/engineer/notifications"
              icon={<AiFillAlert className="sidebar-icon" />}
            />
          )}

          {navType === "engineer" && (
            <SidebarNavItem
              active={activeEngApplications}
              title="My Job Applications"
              to="/engineer/job-applications"
              icon={<MdOutlineListAlt className="sidebar-icon" />}
            />
          )}

          {/* {navType !== "engineer" && (
            <SidebarNavItem
              active={activeClientSettings}
              title="Ratings"
              to="/engineer/ratings"
              icon={<AiOutlineFileSearch className="sidebar-icon" />}
            />
          )} */}

          {navType === "engineer" && (
            <SidebarNavItem
              active={activeClientSettings}
              title="Settings"
              to="/engineer/settings"
              icon={<BsGearFill className="sidebar-icon" />}
            />
          )}
          {navType === "admin" &&
            permissionCheck(permArr, [
              "viewAccessOnly",
              "ROLEADMINMEMBER",
              "adminMemberAccess",
              "viewEngineerDashboard",
              "viewClientDashboard",
              "viewTrainingDataTrendGraph",
              "manageLoanRecord",
            ]) && (
              <SidebarNavItem
                active={activeOverviewNav}
                to="/admin/overview"
                title="Overview"
                icon={<BsPieChartFill className="sidebar-icon" />}
              />
            )}
          {navType === "admin" &&
            permissionCheck(permArr, [
              "viewAccessOnly",
              "manageClient",
              "viewClient",
            ]) && (
              <SidebarNavItem
                active={activeClientNav}
                to="/admin/clients"
                title="Clients"
                icon={<BsPeopleFill className="sidebar-icon" />}
              />
            )}

          {navType === "admin" &&
            permissionCheck(permArr, [
              "viewAccessOnly",
              "manageEngineer",
              "viewEngineerProfile",
            ]) && (
              <SidebarNavItem
                active={activeAdminEngNav}
                to="/admin/engineers"
                title="Engineers"
                icon={<BsPersonBoundingBox className="sidebar-icon" />}
              />
            )}
          {navType === "admin" &&
            permissionCheck(permArr, ["manageLoanRecord"]) && (
              <SidebarNavItem
                active={activeLoanTracker}
                title="Loan Tracker"
                to="/admin/loan-tracker"
                icon={<MdBarChart className="sidebar-icon" />}
              />
            )}
          {navType === "admin" &&
            permissionCheck(permArr, ["viewAccessOnly", "manageEngineer"]) && (
              <SidebarNavItem
                active={activeEngApplications}
                title="Job Applications"
                to="/admin/job-applications"
                icon={<MdOutlineListAlt className="sidebar-icon" />}
              />
            )}
          {navType === "admin" &&
            permissionCheck(permArr, [
              "manageBillingRate",
              "viewAccessOnly",
            ]) && (
              <SidebarNavItem
                active={activeAdminSettings}
                to="/admin/billing"
                title="Billing Rate"
                icon={<BsFillCalculatorFill className="sidebar-icon" />}
              />
            )}

          {navType === "admin" &&
            permissionCheck(permArr, [
              "managePlacement",
              "adminMemberAccess",
              "viewPlacement",
            ]) && (
              <SidebarNavItem
                active={activePlacementNav}
                to="/admin/placements"
                title="Placement"
                icon={<BsFillPersonFill className="sidebar-icon" />}
              />
            )}

          {navType === "admin" &&
            permissionCheck(permArr, [
              "manageSubscription",
              "viewSubscription",
              "viewAccessOnly",
            ]) && (
              <SidebarNavItem
                active={activeAdminSubscription}
                to="/admin/subscriptions"
                title="Subscriptions"
                icon={<BsFillBellFill className="sidebar-icon" />}
              />
            )}

          {navType === "admin" &&
            permissionCheck(permArr, [
              "viewEngineerRequest",
              "viewAccessOnly",
              "cancelEngineerRequest",
            ]) && (
              <SidebarNavItem
                active={activeAdminEngReqNav}
                to="/admin/eng/requests"
                title="Placement Request"
                icon={<GiTeamUpgrade className="sidebar-icon" />}
              />
            )}

          {navType === "admin" &&
            permissionCheck(permArr, [
              "viewSubscriptionRequest",
              "viewAccessOnly",
              "cancelSubscriptionRequest",
            ]) && (
              <SidebarNavItem
                active={activeAdminSubReqNav}
                to="/admin/sub/requests"
                title="Subscription Request"
                icon={<AiOutlineException className="sidebar-icon" />}
              />
            )}
          {navType === "client" &&
            permissionCheck(permArr, [
              "viewClientDashboard",
              "viewEngineerRequest",
              "engineerRequest",
              "cancelEngineerRequest",
              "viewSubscription",
              "ROLEORGADMIN",
              "ROLEORGMEMBER",
              "ROLEORGMEMBER",
            ]) && (
              <SidebarNavItem
                active={activeClientOverview}
                to="/client/overview"
                title="Overview"
                icon={<BsPieChartFill className="sidebar-icon" />}
              />
            )}
          {navType === "client" &&
            permissionCheck(permArr, [
              "viewAssignment",
              "viewEngineerReport",
              "viewEngineerRequest",
              "addEngineerComment",
              "ROLEORGADMIN",
              "ROLEORGMEMBER",
            ]) && (
              <SidebarNavItem
                active={activeClientEngNav}
                title="My Engineers"
                to="/client/engineers"
                icon={<BsPersonBoundingBox className="sidebar-icon" />}
              />
            )}
          {navType === "client" &&
            userDetails.organisation.location !== "INTERNATIONAL" &&
            userDetails.organisation.isSubscribingClient &&
            permissionCheck(permArr, [
              "viewSubscription",
              "ROLEORGADMIN",
              "ROLEORGMEMBER",
            ]) && (
              <SidebarNavItem
                active={activeSubNav}
                to="/client/subscription"
                title="My Subscriptions"
                icon={<BsPeopleFill className="sidebar-icon" />}
              />
            )}
          {navType === "client" &&
            userDetails.organisation.location === "INTERNATIONAL" &&
            // userDetails.organisation.isSubscribingClient &&
            permissionCheck(permArr, [
              "viewInvoice",
              "ROLEORGADMIN",
              "ROLEORGMEMBER",
            ]) && (
              <SidebarNavItem
                active={activeInvoiceNav}
                to="/client/invoice"
                title="Invoice"
                icon={<FaFileInvoice className="sidebar-icon" />}
              />
            )}
          {navType === "client" &&
            permissionCheck(permArr, [
              "viewEngineerRequest",
              "engineerRequest",
              "viewEngineerRequest",
              "cancelEngineerRequest",
              "ROLEORGADMIN",
              "ROLEORGMEMBER",
            ]) && (
              <SidebarNavItem
                active={activeClientEngReq}
                title="Engineer Requests"
                to="/client/engineers-request"
                icon={<GiTeamUpgrade className="sidebar-icon" />}
              />
            )}
          {navType === "client" &&
            userDetails.organisation.location !== "INTERNATIONAL" &&
            userDetails.organisation.isSubscribingClient &&
            permissionCheck(permArr, [
              "viewSubscriptionRequest",
              "subscriptionRequest",
              "cancelSubscriptionRequest",
              "ROLEORGADMIN",
              "ROLEORGMEMBER",
            ]) && (
              <SidebarNavItem
                active={activeClientSubReq}
                title="Request Subscription"
                to="/client/subscription-request"
                icon={<AiOutlineException className="sidebar-icon" />}
              />
            )}
          {navType !== "engineer" && (
            <div className="sidebar-nav-title others">
              <p className="">Others</p>
            </div>
          )}
          {/* {navType === "engineer" && (
            <SidebarNavItem
              active={activeClientSettings}
              title="settings"
              to="/engineer/settings"
              icon={<BsGearFill className="sidebar-icon" />}
            />
          )} */}

          {navType === "admin" &&
            permissionCheck(permArr, [
              "viewAccessOnly",
              "manageClient",
              "viewClient",
              "viewEngineerRequest",
              "managePlacement",
              "viewActivities",
              "viewPlacement",
              "viewSubscriptionRequest",
            ]) && (
              <SidebarNavItem
                active={activeAdminActivity}
                to="/admin/activity"
                title="Activity"
                icon={<FiActivity className="sidebar-icon" />}
              />
            )}
            {navType === "admin" &&
            permissionCheck(permArr, ["manageLoanRecord"]) && (
              <SidebarNavItem
                active={activeLoanTracker}
                title="Loan Activity"
                to="/admin/loan-activity"
                icon={<MdBarChart className="sidebar-icon" />}
              />
            )}
          {navType === "admin" &&
            permissionCheck(permArr, [
              "manageAdminUser",
              "viewAccessOnly",
              "manageRole",
              "viewAdminUser",
              "ROLEADMINMEMBER",
              "adminMemberAccess",
            ]) && (
              <SidebarNavItem
                active={activeAdminSettings}
                to="/admin/settings"
                title="Settings"
                icon={<BsGearFill className="sidebar-icon" />}
              />
            )}
          {navType === "admin" &&
            permissionCheck(permArr, [
              "managePackageSetup",
              "viewAccessOnly",
            ]) && (
              <SidebarNavItem
                active={activeAdminSettings}
                to="/admin/packages"
                title="Packages"
                icon={<BsFillGiftFill className="sidebar-icon" />}
              />
            )}
          {navType === "admin" &&
            permissionCheck(permArr, [
              "manageIndustrySetup",
              "viewAccessOnly",
            ]) && (
              <SidebarNavItem
                active={activeAdminSettings}
                to="/admin/industries"
                title="Industries"
                icon={<BsBarChartFill className="sidebar-icon" />}
              />
            )}

          {navType === "admin" &&
            permissionCheck(permArr, [
              "manageSquadSetup",
              "manageSquad",
              "viewSquad",
            ]) && (
              <SidebarNavItem
                active={activeAdminSettings}
                to="/admin/squads"
                title="Squads"
                icon={<FaGraduationCap className="sidebar-icon" />}
              />
            )}

          {navType === "admin" &&
            permissionCheck(permArr, [
              "viewTrainingDataPointSetUp",
              "manageTrainingDataPointSetUp",
            ]) && (
              <SidebarNavItem
                active={activeTrainingData}
                to="/admin/trainingdata"
                title="Training Data"
                icon={<BsPeopleFill className="sidebar-icon" />}
              />
            )}
          {navType === "admin" &&
            permissionCheck(permArr, [
              "manageAssessment",
              "viewAssessment",
            ]) && (
              <SidebarNavItem
                active={activeTrainingData}
                to="/admin/assessments"
                title="Assessments"
                icon={<MdAssessment className="sidebar-icon" />}
              />
            )}
          {navType === "admin" &&
            permissionCheck(permArr, [
              "viewMailingList",
              "manageMailingList",
            ]) && (
              <SidebarNavItem
                active={activeTrainingData}
                to="/admin/notification"
                title="Notification"
                icon={<MdNotifications className="sidebar-icon" />}
              />
            )}

          {navType === "admin" &&
            permissionCheck(permArr, [
              "manageTechstackSetup",
              "viewAccessOnly",
            ]) && (
              <SidebarNavItem
                active={activeAdminSettings}
                to="/admin/techstacks"
                title="TechStacks"
                icon={<BsFillLayersFill className="sidebar-icon" />}
              />
            )}

          {navType === "admin" &&
            permissionCheck(permArr, ["manageFrameworkSetup"]) && (
              <SidebarNavItem
                active={activeAdminSettings}
                to="/admin/frameworks"
                title="Frameworks"
                icon={<BsFillLayersFill className="sidebar-icon" />}
              />
            )}

          {navType === "client" && (
            <SidebarNavItem
              active={activeClientSettings}
              title="Go to Dev Profile"
              to={`${
                process.env.REACT_APP_DEV_PROFILE_LINK
              }/fellowship/redirect?token=${localStorage.getItem(
                "token"
              )}&email=${userDetails?.email}`}
              select={"redirects"}
              icon={<BsPeopleFill className="sidebar-icon" />}
            />
          )}

          {navType === "client" && (
            <SidebarNavItem
              active={activeClientSettings}
              title="Settings"
              to="/client/settings"
              icon={<BsGearFill className="sidebar-icon" />}
            />
          )}

          {navType === "admin" &&
            permissionCheck(permArr, ["manageCoreConfiguration"]) && (
              <SidebarNavItem
                active={activeConfigReq}
                to="/admin/config"
                title="System Config"
                icon={<BsPeopleFill className="sidebar-icon" />}
                select={true}
                handleShow={handleShow}
              />
            )}
          <div className="logout-section" onClick={() => logout(navType)}>
            <span>
              <img
                src="/images/logout-icon.svg"
                className="logout-icon"
                alt="logout"
              />
            </span>
            <span className="green-text logout-text ">Logout</span>
          </div>
        </div>
      </div>
    </StyledDashboardSidebar>
  );
};

DashboardSidebar.propTypes = {
  navType: PropTypes.oneOf(["admin", "client", "engineer"]).isRequired,
};

const StyledDashboardSidebar = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  padding: 2rem 2.4rem 0 2.4rem;
  .sidebar-nav {
    height: calc(100vh - 14rem);
    overflow-y: scroll;
    flex-direction: column;
    display: flex;
  }
  .sidebar-logo {
    margin: 0 auto 5.501rem;
    width: 15rem;
    height: 3.605rem;
    display: flex;
    align-items: center;
    .hamburger {
      margin-left: 15px;
    }
  }
  .open-icon {
    transform: scale(1);
    transition: ease all 0.5s;
    margin-left: 2rem;
    cursor: pointer;
    :hover {
      transform: scale(1.25);
    }
  }
  .open-others {
    margin-left: 0.5rem;
  }
  .close-others {
    display: none;
  }
  .sidebar-nav-title p {
    padding-left: 2.3rem;
    font-style: normal;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 2.3rem;
    color: #21334f;
    margin-bottom: 1.6rem;
  }
  .others {
    margin-top: 3.6rem;
    display: flex;
    flex-direction: row;
  }
  .logout-section {
    cursor: pointer;
    bottom: 12rem;
    margin-top: auto;
    padding: 0.5rem 0 0.5rem 2.3rem;
    transition: margin-left 0.47s;
  }
  .logout-section:hover {
    margin-left: 1rem;
  }
  .logout-icon {
    width: 2rem;
    height: 2rem;
    margin-right: 1.6rem;
    margin-top: -0.8rem;
  }
  .logout-text {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;
  }
`;

export default DashboardSidebar;
