import { toast } from "react-toastify";
import { apiGet } from "../../../utils/apiHelpers";

export const options = [
  { value: "", label: "Select status" },
  { value: "ACTIVE", label: "Active" },
  { value: "INACTIVE", label: "Inactive" }
];

export const roles = {
  2: "User",
  1: "Admin",
};

export const roleOptions = [
  { value: "", label: "Select role" },
  { value: 1, label: "Admin" },
  { value: 2, label: "User" },
];


export const engineerOptions = [
  { value: "", label: "Select option" },
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];



export const fetchSquadOptions = async () => {
  try {
    const res = await apiGet(`/admin/squads`);
    return res.data.data;

  } catch (err) {
    toast.error(err);
  }
};

export const fetchRoleOptions = async () => {
  try {
    await apiGet(`/admin/roles`);
  } catch (err) {
    toast.error(err);
  }
};
