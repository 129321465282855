import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import FormInputComponent from "./enterpriseFormInputs/FormInputComponent";
import FormSelectComponent from "./enterpriseFormInputs/FormSelectComponent";
import FormTitleSection from "./FormTitleSection";
import FormUploadComponent from "./enterpriseFormInputs/FormUploadComponent";
import { apiPut, apiGet } from "../../../utils/apiHelpers";
import { customDateParser, setSelect } from "../../../utils/helperFunctions";
import { options } from "./formSelectOptions";
// import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { format } from "date-fns";

const validationSchema = Yup.object({
  amountPaid: Yup.string().required("*Required"),
  amount: Yup.string().required("*Required"),
  availableCredit: Yup.number().required("*Required"),
  packageName: Yup.string().required("*Required"),
  status: Yup.string().required("*Required"),
  startDate: Yup.date().required("*Required"),
  datePaid: Yup.date().required("*Required"),
  expireDate: Yup.date().required("*Required"),
});

const UpdateSub = ({ handleModal, subId, orgId, refetch }) => {
  const [loading, setLoading] = useState(false);
  // const [subDetails, setSubDetails] = useState({});
  const [packageOptions, setPackageOptions] = useState([]);

  const [success, setSuccess] = useState(false);
  const [contractDoc, setContractDoc] = useState({});
  const [receiptDoc, setReceiptDoc] = useState({});

  useEffect(() => {
    fetchPackageInfo();
  }, []);

  useEffect(() => {
    fetchSingleSubDetails();
    // eslint-disable-next-line
  }, []);

  // Fetch Package options
  const fetchPackageInfo = async () => {
    try {
      const res = await apiGet(`/admin/packages`);
      let result = res.data.data;

      let packageOpts = setSelect(result, "Package", true);

      setPackageOptions(packageOpts);
    } catch (err) {
      console.log(err);
    }
  };

  const formatDate = (date) => {
    return date.split("-").reverse().join("/");
  };

  const [initialValues, setInitialValues] = useState({
    amount: 0,
    amountPaid: 0,
    availableCredit: 0,
    packageName: "",
    status: "",
    datePaid: "",
    expireDate: "",
    startDate: "",
  });

  // Fetch Single Sub Details
  const fetchSingleSubDetails = async () => {
    try {
      const res = await apiGet(
        `/admin/organisations/${orgId}/subscriptions/${subId}`
      );
      let result = res.data.data;

      result.datePaid = format(customDateParser(result.datePaid), 'yyyy-MM-dd')
      result.expireDate = format(customDateParser(result.expireDate), 'yyyy-MM-dd')
      result.startDate = format(customDateParser(result.startDate), 'yyyy-MM-dd')

      // setSubDetails(result);
      setInitialValues(result);
    } catch (err) {
      console.log(err);
    }
  };

  // Edit Sub Details
  const editSubDetails = async (data) => {
    setLoading(true);
    try {
      let response = await apiPut(
        `/admin/organisations/${orgId}/subscriptions/${subId}`,
        data,
        {}
      );
      if (response.error) {
        throw response.data.response.status === 500
          ? new Error("")
          : response.data;
      }
      setSuccess(true);
      refetch();
    } catch (err) {
      console.log(err)
    }
    setLoading(false);
  };

  const onSubmit = (allValues) => {
    let formData = new FormData();

    const { duration, numEngineers, organisationId, packageId, ...values } =
      allValues;

    if (contractDoc.name) {
      formData.append("contract_upload", contractDoc);
    }
    if (receiptDoc.name) {
      formData.append("receipt_upload", receiptDoc);
    }

    let amount = values.amountPaid;
    let isNum = isNaN(amount);
    if (isNum) {
      let newNum = values.amountPaid.split("");
      let amt = newNum.filter((a) => a !== "₦" && a !== "," && a).join("");
      values.amountPaid = parseInt(amt);
    } else {
      values.amountPaid = amount;
    }
    values.startDate = formatDate(values.startDate);
    values.datePaid = formatDate(values.datePaid);
    values.expireDate = formatDate(values.expireDate);

    formData.append(
      "subscription_dto",
      new Blob([JSON.stringify(values)], {
        type: "application/json",
      })
    );

    editSubDetails(formData);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const closeForm = () => {
    formik.resetForm();
    handleModal();
    setContractDoc(() => ({}));
    setReceiptDoc(() => ({}));
    setSuccess(false);
  };

  useEffect(() => {
    formik.setValues(initialValues);
    // eslint-disable-next-line
  }, [initialValues]);

  return (
    <StyledAddEngForm success={success}>
      <FormTitleSection title="Edit Subscription" onClick={closeForm} />
      <div className="form-section">
        <form onSubmit={formik.handleSubmit} className="client-form">
          <FormInputComponent
            label="Amount"
            name="amount"
            placeholder="Enter amount paid"
            prefix="₦"
            allowDecimals={false}
            defaultValue={formik.values.amount}
            onBlur={formik.handleBlur}
            error={
              formik.touched.amount && formik.errors.amount
                ? formik.errors.amount
                : null
            }
          />

          <FormInputComponent
            label="Amount Paid"
            name="amountPaid"
            placeholder="Enter amount paid"
            prefix="₦"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            allowDecimals={false}
            value={formik.values.amountPaid}
            error={
              formik.touched.amountPaid && formik.errors.amountPaid
                ? formik.errors.amountPaid
                : null
            }
          />

          <FormInputComponent
            label="Available Credit"
            type="number"
            name="availableCredit"
            placeholder="Enter available credit"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.availableCredit}
            error={
              formik.touched.availableCredit && formik.errors.availableCredit
                ? formik.errors.availableCredit
                : null
            }
          />
          <FormInputComponent
            label="Date Paid"
            type="date"
            name="datePaid"
            placeholder="Enter date paid"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.datePaid}
            error={
              formik.touched.datePaid && formik.errors.datePaid
                ? formik.errors.datePaid
                : null
            }
          />
          <FormInputComponent
            label="Start Date"
            type="date"
            name="startDate"
            placeholder="Enter start date"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.startDate}
            error={
              formik.touched.startDate && formik.errors.startDate
                ? formik.errors.startDate
                : null
            }
          />
          <FormInputComponent
            label="Expire Date"
            type="date"
            name="expireDate"
            placeholder="Enter expire date"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.expireDate}
            error={
              formik.touched.expireDate && formik.errors.expireDate
                ? formik.errors.expireDate
                : null
            }
          />

          <FormSelectComponent
            label="Package Name"
            name="packageName"
            placeholder="Enter package Name"
            options={packageOptions}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.packageName}
            error={
              formik.touched.packageName && formik.errors.packageName
                ? formik.errors.packageName
                : null
            }
          />
          <FormSelectComponent
            label="Status"
            name="status"
            options={options}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.status}
            error={
              formik.touched.status && formik.errors.status
                ? formik.errors.status
                : null
            }
          />
          <FormUploadComponent
            label="Upload Contract"
            // error={contractDocError}
            selectedFile={contractDoc}
            setSelectedFile={setContractDoc}
          />
          <FormUploadComponent
            label="Upload Receipt"
            // error={receiptDocError}
            selectedFile={receiptDoc}
            setSelectedFile={setReceiptDoc}
          />
          <MyButton className="form-btn add-client-btn" type="submit" full>
            {loading ? (
              <ClipLoader color={"#fff"} size={"30px"} />
            ) : (
              "Edit Subscription"
            )}
          </MyButton>
        </form>
        <div className="form-success">
          <img
            src="/images/sucess-icon.svg"
            alt="green-tick"
            className="sucess-icon"
          />
          <h2 className="form-font green-text">Success</h2>
          <p className="form-font success-msg">
            You successfully edited Subscription.
          </p>
          <MyButton className="form-btn success-btn" onClick={closeForm}>
            Okay, Go to Subscription Page
          </MyButton>
        </div>
      </div>
    </StyledAddEngForm>
  );
};

const StyledAddEngForm = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);
  .underline {
    display: block;
  }
  .form-section {
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3.2rem;
  }
  .add-client-btn {
    margin-bottom: 23.8rem;
  }
  .stack-section {
    display: flex;
    flex-wrap: wrap;
    width: 32rem;
  }
  .client-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .downld-link {
    margin-bottom: 2rem;
    font-size: 16px;
    color: blue;
  }
  .downld-link:hover {
    margin-bottom: 2rem;
    font-size: 16px;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 32rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
`;

export default UpdateSub;
