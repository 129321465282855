import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import FormInputComponent from "./enterpriseFormInputs/FormInputComponent";
import FormSelectComponent from "./enterpriseFormInputs/FormSelectComponent";
import FormTitleSection from "./FormTitleSection";
import FormUploadComponent from "./enterpriseFormInputs/FormUploadComponent";
import { options } from "./formSelectOptions";
import { apiGet, apiPost } from "../../../utils/apiHelpers";
import { setSelect } from "../../../utils/helperFunctions";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";

const initialValues = {
  amountPaid: "",
  datePaid: "",
  packageId: "",
  startDate: "",
  status: "",
};

const docType = ["pdf", "docx", "doc"];
const receiptType = ["png", "jpg", "jpeg", "gif", "pdf"];

const validationSchema = Yup.object({
  amountPaid: Yup.string().required("*Required"),
  datePaid: Yup.date().required("*Required"),
  packageId: Yup.number().required("*Required"),
  startDate: Yup.date().when(
    "datePaid",
    (datePaid, Yup) =>
      datePaid && Yup.min(datePaid, "Start Date cannot be before Date Paid")
  ),
  // Yup.date().required("*Required"),
  status: Yup.string().required("*Required"),
});

const CreateSubForm = ({ handleModal, orgId, refetch }) => {
  const [packageOptions, setPackageOptions] = useState([]);

  const [success, setSuccess] = useState(false);
  const [contractDoc, setContractDoc] = useState({});
  const [receiptDoc, setReceiptDoc] = useState({});
  const [loading, setLoading] = useState(false);

  const [contractDocError, setContractDocError] = useState("");
  const [receiptDocError, setReceiptDocError] = useState("");

  useEffect(() => {
    fetchPackageInfo();
  }, []);

  // Fetch Package options
  const fetchPackageInfo = async () => {
    try {
      const res = await apiGet(`/admin/packages`);
      let result = res.data.data;

      let packageOpts = setSelect(result, "Package");

      setPackageOptions(packageOpts);
    } catch (err) {
      toast.error(err);
    }
  };

  const formatDate = (date) => {
    return date.split("-").reverse().join("/");
  };

  useEffect(() => {
    setContractDocError("");
  }, [contractDoc]);

  useEffect(() => {
    setReceiptDocError("");
  }, [receiptDoc]);

  const onSubmit = (values) => {
    if (!contractDoc.name || !receiptDoc.name) {
      if (!contractDoc.name) {
        setContractDocError("*Required");
      }
      if (!receiptDoc.name) {
        setReceiptDocError("*Required");
      }
      return;
    }
    let newNum = values.amountPaid.split("");
    let amt = newNum.filter((a) => a !== "₦" && a !== "," && a).join("");
    values.amountPaid = parseInt(amt);

    values.contract = contractDoc;
    values.receipt = receiptDoc;

    values.startDate = formatDate(values.startDate);
    values.datePaid = formatDate(values.datePaid);

    let formData = new FormData();
    formData.append("contract_upload", contractDoc);
    formData.append("receipt_upload", receiptDoc);
    formData.append("organisationId", orgId);
    formData.append(
      "subscription_dto",
      new Blob([JSON.stringify(values)], {
        type: "application/json",
      })
    );

    createSubscription(orgId, formData);
  };

  const createSubscription = async (orgId, formData) => {
    //make call to the api
    try {
      setLoading(true);

      await apiPost(
        `/admin/organisations/${orgId}/subscriptions`,
        formData,
        {}
      );
      setSuccess(true);
      refetch();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const closeForm = () => {
    formik.resetForm();
    handleModal();
    setContractDoc(() => ({}));
    setReceiptDoc(() => ({}));
    setSuccess(false);
  };

  return (
    <StyledCreateSubForm success={success}>
      <FormTitleSection title="Create Subscription" onClick={closeForm} />
      <div className="form-section">
        <form onSubmit={formik.handleSubmit} className="client-form">
          <p className="form-font form-title">Client Details</p>
          <FormInputComponent
            label="Amount Paid"
            type="number"
            name="amountPaid"
            placeholder="Enter amount paid"
            prefix="₦"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.amountPaid}
            allowDecimals={false}
            error={
              formik.touched.amountPaid && formik.errors.amountPaid
                ? formik.errors.amountPaid
                : null
            }
          />
          <FormInputComponent
            label="Date Paid"
            type="date"
            name="datePaid"
            placeholder="Enter date paid"
            max={new Date()}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.datePaid}
            error={
              formik.touched.datePaid && formik.errors.datePaid
                ? formik.errors.datePaid
                : null
            }
          />
          <FormInputComponent
            label="Start Date"
            type="date"
            name="startDate"
            placeholder="Enter start date"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.startDate}
            error={
              formik.touched.startDate && formik.errors.startDate
                ? formik.errors.startDate
                : null
            }
          />

          <FormSelectComponent
            label="Package Id"
            name="packageId"
            placeholder="Enter package Id"
            options={packageOptions}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.packageId}
            error={
              formik.touched.packageId && formik.errors.packageId
                ? formik.errors.packageId
                : null
            }
          />
          <FormSelectComponent
            label="Status"
            name="status"
            options={options}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.status}
            error={
              formik.touched.status && formik.errors.status
                ? formik.errors.status
                : null
            }
          />
          <FormUploadComponent
            docType={docType}
            label="Upload Contract"
            error={contractDocError}
            selectedFile={contractDoc}
            setSelectedFile={setContractDoc}
            acceptTypes={`pdf, docx. Size Limit 5mb`}
          />
          <FormUploadComponent
            receiptType={receiptType}
            label="Upload Receipt"
            error={receiptDocError}
            selectedFile={receiptDoc}
            setSelectedFile={setReceiptDoc}
            acceptTypes={`png, jpg, jpeg, gif, pdf. Size Limit 5mb`}
          />
          <MyButton className="form-btn add-client-btn" type={"submit"} full>
            {loading ? (
              <ClipLoader color={"#fff"} size={"30px"} />
            ) : (
              "Add New Subscription"
            )}
          </MyButton>
        </form>
        <div className="form-success card p-2">
          <img
            src="/images/sucess-icon.svg"
            alt="green-tick"
            className="sucess-icon"
          />
          <h2 className="form-font green-text">Success</h2>
          <p className="form-font success-msg">
            You successfully created a subscription.
          </p>
          <MyButton className="form-btn success-btn" onClick={closeForm}>
            Go back
          </MyButton>
        </div>
      </div>
    </StyledCreateSubForm>
  );
};

const StyledCreateSubForm = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);
  .form-section {
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3.2rem;
  }
  .add-client-btn {
    margin-bottom: 23.8rem;
  }
  .client-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2.rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 32rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
`;

export default CreateSubForm;
