import { toast } from "react-toastify";
import { apiGet } from "../utils/apiHelpers";
import {
  FETCH_CLIENT_SUCCESS,
  FETCH_NEW_CLIENT_SUCCESS,
  FETCH_CLIENT_ENG_SUCCESS,
  FETCH_ENGINEER_SUCCESS,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_CLIENT_SUB_SUCCESS,
  OFF_SEARCH_LOADER,
  OFF_FILTER_LOADER,
  FETCH_SUB_REQ_SUCCESS,
  FETCH_ENG_REQ_SUCCESS,
  FETCH_ADMIN_SUB_SUCCESS,
  FETCH_ADMIN_ENG_SUCCESS,
  FETCH_PLACEMENT_HISTORY,
} from "./types";

const useSearchAndFilter = (state, dispatch) => {
  const searchClients = async () => {
    try {
      const res = await apiGet(
        `/admin/organisations?page=${state.page}&size=12&search=${state.keyword}&status=${state.status}&client_type=${state.customerType}`,
        "admin"
      );

      if (res.error) {
        throw res.data.response.status === 500 ? new Error("") : res.data;
      }

      dispatch({
        type: FETCH_CLIENT_SUCCESS,
        payload: res.data.data.content || [],
        meta: res.data.data,
        totalClients:
          state.keyword || state.status || state.customerType
            ? res.data.data?.totalElements
            : "",
      });
    } catch (err) {
      toast.error(
        err?.response?.data?.message || "Error Fetching organization"
      );
    }

    dispatch({
      type: OFF_SEARCH_LOADER,
    });

    dispatch({
      type: OFF_FILTER_LOADER,
    });
  };

  const searchNewClients = async () => {
    try {
      const res = await apiGet(
        `/admin/organisations?page=${state.page}&size=12&search=${state.keyword}`,
        "admin"
      );
      if (res.error) {
        throw res.data.response.status === 500 ? new Error("") : res.data;
      }

      dispatch({
        type: FETCH_NEW_CLIENT_SUCCESS,
        payload: res.data.data.content || [],
      });
    } catch (err) {
      toast.error(
        err?.response?.data?.message || "Error Fetching organization"
      );
    }
    dispatch({
      type: OFF_SEARCH_LOADER,
    });

    dispatch({
      type: OFF_FILTER_LOADER,
    });
  };

  const searchSubscriptions = async () => {
    try {
      const res = await apiGet(
        `/admin/subscriptions?page=${state.page}&size=12&search=${state.keyword}`,
        "admin"
      );

      dispatch({
        type: FETCH_SUBSCRIPTION_SUCCESS,
        payload: res.data.data.content || [],
        meta: res.data.data,
      });
    } catch (err) {
      toast.error("Error");
    }

    dispatch({
      type: OFF_SEARCH_LOADER,
    });

    dispatch({
      type: OFF_FILTER_LOADER,
    });
  };

  const searchEngineers = async () => {
    try {
      const res = await apiGet(
        `/admin/engineers?page=${state.page}&size=12&search=${state.keyword}&engTyp=${state.engineerType}&stack=${state.language}&squad=${state.squad}&assignTyp=${state.engineerStatus}&status=${state.engStatus}&currentLocation=${state.currentLocation}&location=${state.trainingLocation}&age_range=${state.engineerAge}&gender=${state.engineerGender}&min_age=${state.minAge}&max_age=${state.maxAge}`,
        "admin"
      );
      dispatch({
        type: FETCH_ENGINEER_SUCCESS,
        payload: res.data.data.content || [],
        meta: res.data.data,
        totalEngineers:
          state.keyword ||
          state.status ||
          state.engineerType ||
          state.engineerStatus ||
          state.language ||
          state.squad ||
          state.engStatus ||
          state.engineerGender ||
          state.engineerAge ||
          state.trainingLocation ||
          state.currentLocation ||
          state.minAge ||
          state.maxAge
            ? res.data.data?.totalElements
            : "",
      });
    } catch (err) {
      toast.error("Error");
    }

    dispatch({
      type: OFF_SEARCH_LOADER,
    });

    dispatch({
      type: OFF_FILTER_LOADER,
    });
  };

  const searchClientSubscription = async () => {
    let organizationDetails =
      JSON.parse(localStorage.getItem("client_details")) || {};
    const { organisationId } = organizationDetails;
    try {
      const res = await apiGet(
        `/admin/organisations/${organisationId}/subscriptions?page=${state.page}&size=12&search=${state.keyword}&status=${state.status}`,
        {},
        "client"
      );
      dispatch({
        type: FETCH_CLIENT_SUB_SUCCESS,
        payload: res.data.data.content || [],
        meta: res.data.data,
      });
    } catch (err) {
      toast.error(err.message);
    }

    dispatch({
      type: OFF_SEARCH_LOADER,
    });

    dispatch({
      type: OFF_FILTER_LOADER,
    });
  };

  const searchClientEngineers = async () => {
    try {
      const res = await apiGet(
        `/private/placements?page=${state.page}&size=12&search=${state.keyword}`,
        {},
        "client"
      );
      dispatch({
        type: FETCH_CLIENT_ENG_SUCCESS,
        payload: res.data.data.content || [],
        meta: res.data.data,
      });
    } catch (err) {
      toast.error(err.message);
    }
    dispatch({
      type: OFF_SEARCH_LOADER,
    });

    dispatch({
      type: OFF_FILTER_LOADER,
    });
  };

  const searchEngReq = async () => {
    try {
      let response = await apiGet(
        `/private/assignments/requests?page=${state.page}&size=12`,
        {}
      );
      if (response.error) {
        throw response.data.response.status === 500
          ? new Error("")
          : response.data;
      }
      dispatch({
        type: FETCH_ENG_REQ_SUCCESS,
        payload: response.data.data.content || [],
        meta: response.data.data,
      });
    } catch (err) {
      console.log({ err: err });
      toast.error(err?.response?.data?.message || "error fetching Eng details");
    }
    dispatch({
      type: OFF_SEARCH_LOADER,
    });

    dispatch({
      type: OFF_FILTER_LOADER,
    });
  };

  const searchSubReq = async () => {
    try {
      let response = await apiGet(
        `/private/subscriptions/requests?page=${state.page}&size=12`,
        {}
      );
      if (response.error) {
        throw response.data.response.status === 500
          ? new Error("")
          : response.data;
      }
      dispatch({
        type: FETCH_SUB_REQ_SUCCESS,
        payload: response.data.data.content || [],
        meta: response.data.data,
      });
    } catch (err) {
      toast.error(err?.response?.data?.message || "error fetching sub details");
    }
    dispatch({
      type: OFF_SEARCH_LOADER,
    });

    dispatch({
      type: OFF_FILTER_LOADER,
    });
  };

  const searchAdminSubReq = async () => {
    try {
      const res = await apiGet(
        `/admin/subscriptions/requests?page=${state.page}&size=12`,
        "admin"
      );
      dispatch({
        type: FETCH_ADMIN_SUB_SUCCESS,
        payload: res.data.data.content || [],
        meta: res.data.data,
      });
    } catch (err) {
      toast.error("Error");
    }

    dispatch({
      type: OFF_SEARCH_LOADER,
    });

    dispatch({
      type: OFF_FILTER_LOADER,
    });
  };

  //Fetch admin engineer requests
  const searchAdminEngReq = async () => {
    try {
      const res = await apiGet(
        `/admin/assignments/requests?page=${state.page}&size=12`,
        "admin"
      );
      dispatch({
        type: FETCH_ADMIN_ENG_SUCCESS,
        payload: res.data.data.content || [],
        meta: res.data.data,
      });
    } catch (err) {
      toast.error("Error");
    }

    dispatch({
      type: OFF_SEARCH_LOADER,
    });

    dispatch({
      type: OFF_FILTER_LOADER,
    });
  };

  //Fetch placements records.
  const searchPlacementHistory = async () => {
    try {
      const res = await apiGet(
        `/admin/assignments?page=${state.page}&size=12&search=${state.keyword}&clientType=${state.clientType}&assignTyp=${state.engineerStatus}`,
        "admin"
      );
      if (res.error) {
        throw res.data.response.status === 500 ? new Error("") : res.data;
      }
      dispatch({
        type: FETCH_PLACEMENT_HISTORY,
        payload: res.data.data.content || [],
        meta: res.data.data,
        totalEngineers:
          state.keyword ||
          state.status ||
          state.clientType ||
          state.engineerStatus
            ? res.data.data?.totalElements
            : "",
      });
    } catch (err) {
      console.log({ err: err });
      toast.error("Error");
    }

    dispatch({
      type: OFF_SEARCH_LOADER,
    });

    dispatch({
      type: OFF_FILTER_LOADER,
    });
  };

  return {
    searchClients,
    searchNewClients,
    searchSubscriptions,
    searchEngineers,
    searchClientSubscription,
    searchClientEngineers,
    searchSubReq,
    searchEngReq,
    searchAdminSubReq,
    searchAdminEngReq,
    searchPlacementHistory,
  };
};

export default useSearchAndFilter;
