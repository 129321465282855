import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import FormInputComponent from "./enterpriseFormInputs/FormInputComponent";
import FormTitleSection from "./FormTitleSection";
import FormSelectComponent from "./enterpriseFormInputs/FormSelectComponent";
import FormUploadComponent from "./enterpriseFormInputs/FormUploadComponent";
import { apiPost } from "../../../utils/apiHelpers";
import { ClipLoader } from "react-spinners";

const AddDocument = ({ handleModal, refetch, orgId }) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documentDoc, setDocumentDoc] = useState({});
  const [documentDocError, setDocumentDocError] = useState("");

  const initialValues = {
    documentName: "",
    docType: "",
  };

  const docType = ["pdf", "docx", "doc"];
  
  const documentOption = [
    { value: "", label: "select option" },
    { value: "REPORT", label: "Report" },
    { value: "CONTRACT", label: "Contract" },
    { value: "OTHER", label: "Other" },
  ];

  const validationSchema = Yup.object({
    documentName: Yup.string().required("*Required"),
    docType: Yup.string().required("*Required"),
  });

  const new_token = localStorage.getItem("token");

  let multipartConfig = {
    headers: {
      Authorization: `Bearer ${new_token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const onSubmit = (values) => {
    if (!documentDoc.name) {
      if (!documentDoc.name) {
        setDocumentDocError("*Required");
      }
      return;
    }

    let formData = new FormData();
    formData.append("document_upload", documentDoc);
    formData.append(
      "document_dto",
      new Blob([JSON.stringify(values)], {
        type: "application/json",
      })
    );
    uploadDoc(formData);
  };

  //Add New Document
  const uploadDoc = async (formData) => {
    setLoading(true);
    //make call to the api
    try {
      let response = await apiPost(
        `/admin/organisations/${orgId}/documents`,
        formData,
        multipartConfig
      );

      if (response.error) {
        throw response.data.response.status === 500
          ? new Error("")
          : response.data;
      }

      setSuccess(true);
      refetch();
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    setDocumentDocError("");
  }, [documentDoc]);

  const closeForm = () => {
    formik.resetForm();
    handleModal();
    setSuccess(false);
  };
  return (
    <StyledDiv success={success}>
      <FormTitleSection title="Add New Document" onClick={closeForm} />
      <div className="form-section">
        <form onSubmit={formik.handleSubmit} className="client-form">
          <FormInputComponent
            label="Document Name"
            type="text"
            name="documentName"
            placeholder="Enter Document Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.documentName}
            error={
              formik.touched.documentName && formik.errors.documentName
                ? formik.errors.documentName
                : null
            }
          />
          <FormSelectComponent
            label="Document Type"
            type="text"
            name="docType"
            options={documentOption}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.docType}
            error={
              formik.touched.docType && formik.errors.docType
                ? formik.errors.docType
                : null
            }
          />

          <FormUploadComponent
            label="Upload Document"
            acceptTypes={`pdf, docx, doc. Size Limit 5mb`}
            pdf={true}
            docType={docType}
            error={documentDocError}
            selectedFile={documentDoc}
            setSelectedFile={setDocumentDoc}
          />

          <MyButton className="form-btn add-client-btn" type={"submit"} full>
            {" "}
            {loading ? (
              <ClipLoader color={"#fff"} size={"30px"} />
            ) : (
              "Add New Document"
            )}
          </MyButton>
        </form>

        <div className="form-success card">
          <img
            src="/images/sucess-icon.svg"
            alt="green-tick"
            className="sucess-icon"
          />
          <h2 className="form-font green-text">Success</h2>
          <p className="form-font success-msg">
            You've successfully added a document.
          </p>
          <MyButton className="form-btn success-btn" onClick={closeForm}>
            Okay, Go back to Document List
          </MyButton>
        </div>
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);

  .form-section {
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3.2rem;
  }
  .client-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-top: 8rem;
    padding: 1rem;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2.rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 32rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
`;

export default AddDocument;
