import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { adminAssessmentView } from "../common/listingData";
import Layout from "../common/Layout";
import ProtectedComponents from "../local-routes/ProtectedComponents";
import MyButton from "../../landingPage/MyButton";
import { MdArrowBack } from "react-icons/md";
import TableContainer from "../common/TableContainer";
import AssessmentsFilter from "../filterFields/AssessmentsFilter";
import FormModal from "../form/FormModal";
import UploadAssessmentForm from "../form/UploadAssessmentForm";
import {
  useFetchedSquads,
  useFetchedTechstacks,
} from "../../../services/reference/query";
import { downloadAssessmentData } from "../../../services/assessment/api";
import {
  useEditAssessment,
  useFetchedAssessment,
} from "../../../services/engineer/query";
import { mappedWeeks } from "../../../data/week";
import EditAsessmentForm from "../form/EditAssesmentForm";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const AdminAssessmentPage = ({ pageType }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedSquad, setSelectedSquad] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [editValues, setEditValues] = useState([]);
  const [, setLoading] = useState(false);

  const [filterOptions, setFilterOptions] = useState({
    language: "",
    week: "",
    squad: "",
  });

  const [isDependantLoaded, setIsDependantLoaded] = useState(false);

  const { data: language } = useFetchedTechstacks();
  const { data: squads } = useFetchedSquads();

  const { data: assessmentList, refetch: refetchAssessments } =
    useFetchedAssessment(filterOptions, isDependantLoaded);

  useEffect(() => {
    setFilterOptions({
      ...filterOptions,
      language: language?.data[0].id,
      squad: squads?.data[0].id,
      week: 1,
    });

    if (language && squads) {
      setIsDependantLoaded(true);
    }

    // eslint-disable-next-line
  }, [language, squads]);

  useEffect(() => {
    const getSelectedSquad = squads?.data.find(
      (item) => item.id === filterOptions.squad
    );
    const getSelectedLanguage = language?.data.find(
      (item) => item.id === filterOptions.language
    );
    setSelectedSquad(getSelectedSquad);
    setSelectedLanguage(getSelectedLanguage);
    // eslint-disable-next-line
  }, [filterOptions.language, filterOptions.squad]);

  const handleFilterChange = (name, value) => {
    setSelectedFilter(name);
    setFilterOptions({ ...filterOptions, [name]: value });
  };

  const handleModal = (id) => {
    setIsModalOpen(!isModalOpen);
  };

  //EDITING TABLES LOGIC
  let headerIdObject = assessmentList?.data?.header?.dataPointHeaders;

  headerIdObject = headerIdObject?.reduce((accum, value) => {
    const title = value.title;
    accum[title] = value?.dataPointId;

    return accum;
  }, {});

  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null,
  });

  //PRESENT DATA
  let existingValues = assessmentList?.data?.body?.map((value) => {
    let obj = {};

    obj["engrId"] = value?.engineerId;

    obj["trainingDataPointScores"] = value.trainingData?.reduce(
      (accum, value) => {
        const id = value.dataPointId;
        accum[id] = value?.dataPointScore;

        return accum;
      },
      {}
    );

    return obj;
  }, {});

  const handleChange = (event, objectKey, engineerId) => {
    let value = parseInt(event.target.value);
    const key = headerIdObject[objectKey];

    setEditValues((current) =>
      current.map((obj) => {
        if (obj.engrId === engineerId) {
          const editingTrainingDataPointScores = obj.trainingDataPointScores;
          return {
            ...obj,
            trainingDataPointScores: {
              ...editingTrainingDataPointScores,
              [key]: value,
            },
          };
        }

        return obj;
      })
    );
  };

  const { mutate, isLoading } = useEditAssessment();

  const handleSubmit = () => {
    //REMOVE UNEDITED DATA FROM EXISTING VALUES
    let formValues = existingValues.map((value) => {
      // eslint-disable-next-line array-callback-return
      return editValues.map((item) => {
        if (value.engrId === item.engrId) {
          let update = item.trainingDataPointScores;
          let origin = value.trainingDataPointScores;
          let newObj = {};

          newObj["engrId"] = value.engrId;
          newObj["trainingDataPointScores"] = Object.keys(update)
            .filter((key) => origin[key] !== update[key])
            .reduce((cur, key) => {
              return Object.assign(cur, { [key]: update[key] });
            }, {});

          return newObj;
        }
      });
    });

    //REMOVE NULL VALUES
    formValues = formValues.flat().filter((item) => {
      return item != null;
    });
    //REMOVE VALUES WITH NO TRAINING DATA
    formValues = formValues.filter(
      (item) => Object.keys(item?.trainingDataPointScores).length > 0
    );

    setLoading(true);

    mutate(
      {
        lang: filterOptions.language,
        week: filterOptions.week,
        squad: filterOptions.squad,
        formValues,
      },
      {
        onSuccess: () => {
          setInEditMode({ status: false, rowKey: 0 });
          toast.success("Edited Successfully");
          refetchAssessments();
        },

        onError: (error) => {
          console.log(error, "error");
        },
      }
    );
  };

  const handleKeyDown = (e) => {
    //ON ENTER KEY PRESS RUNS THIS FUNCTION
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  useEffect(() => {
    setEditValues(existingValues || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentList, refetchAssessments]);

  const getValue = (engineerId, testType) => {
    let value = editValues.find((item) => item.engrId === engineerId)?.[
      "trainingDataPointScores"
    ][headerIdObject[testType]];

    return value;
  };

  const onEdit = (id) => {
    setInEditMode({
      status: true,
      rowKey: id,
    });
  };

  const checkEditMode = (inEditMode, engineerId, testType, testNumber) => {
    return (
      inEditMode.status &&
      inEditMode.rowKey === engineerId &&
      headerIdObject[testType] === testNumber
    );
  };

  return (
    <Layout navType="admin">
      <StyledDashboardPage isModalOpen={isModalOpen}>
        <div className="go-back">
          <MdArrowBack size={"1.6rem"} />
          <p>Go back</p>
        </div>

        <div className="page-header">
          <h4>
            Squad {selectedSquad?.name} {selectedLanguage?.name}
          </h4>
          <ProtectedComponents permission={["manageAssessment"]}>
            <div className="engineers-div">
              <MyButton
                transparent
                onClick={() =>
                  downloadAssessmentData(selectedSquad, selectedLanguage)
                }
                className="btn-comp"
              >
                {adminAssessmentView.btn1Info}
              </MyButton>
              <MyButton
                onClick={() => {
                  handleModal();
                  setActiveModal("upload");
                }}
                className="btn-comp"
              >
                {adminAssessmentView.btn2Info}
              </MyButton>
            </div>
          </ProtectedComponents>
        </div>

        <AssessmentsFilter
          handleFilterChange={handleFilterChange}
          filterOptions={filterOptions}
          selectedFilter={selectedFilter}
          language={language}
          squads={squads}
          weeks={mappedWeeks}
        />

        <TableContainer>
          <table style={{ width: "100%" }}>
            <thead style={{ width: "100%" }}>
              <tr>
                {assessmentList?.data?.header?.staticHeaders
                  ?.filter((item) => item !== "S/N")
                  .map((header, i) => (
                    <th key={header}>{header}</th>
                  ))}
                {assessmentList?.data?.header?.dataPointHeaders?.map(
                  (header, i) => (
                    <th key={header.title}>{header.title}</th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {assessmentList &&
                assessmentList?.data?.body?.map(
                  ({
                    engineerId,
                    firstName,
                    lastName,
                    email,
                    trainingData,
                  }) => {
                    return (
                      <tr key={engineerId}>
                        <td>
                          <Link
                            to={`/admin/engineers/${engineerId}`}
                            className="link"
                          >
                            {firstName}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/admin/engineers/${engineerId}`}
                            className="link"
                          >
                            {lastName}
                          </Link>
                        </td>
                        <td>{email}</td>
                        <td className="" onClick={() => onEdit(engineerId)}>
                          {
                            <input
                              className={`score ${
                                checkEditMode(
                                  inEditMode,
                                  engineerId,
                                  "Agile Test",
                                  21
                                )
                                  ? "input_score"
                                  : null
                              } `}
                              type="number"
                              disabled={
                                !checkEditMode(
                                  inEditMode,
                                  engineerId,
                                  "Agile Test",
                                  21
                                )
                              }
                              value={getValue(engineerId, "Agile Test")}
                              onChange={(e) =>
                                handleChange(e, "Agile Test", engineerId)
                              }
                              onKeyDown={handleKeyDown}
                            />
                          }
                        </td>
                        <td className="" onClick={() => onEdit(engineerId)}>
                          {
                            <input
                              className={`score ${
                                checkEditMode(
                                  inEditMode,
                                  engineerId,
                                  "Week Task",
                                  23
                                )
                                  ? "input_score"
                                  : null
                              } `}
                              type="number"
                              disabled={
                                !checkEditMode(
                                  inEditMode,
                                  engineerId,
                                  "Week Task",
                                  23
                                )
                              }
                              value={getValue(engineerId, "Week Task")}
                              onChange={(e) =>
                                handleChange(e, "Week Task", engineerId)
                              }
                              onKeyDown={handleKeyDown}
                            />
                          }
                        </td>

                        <td onClick={() => onEdit(engineerId)}>
                          {
                            <input
                              className={`score ${
                                checkEditMode(
                                  inEditMode,
                                  engineerId,
                                  "Assessment Test",
                                  24
                                )
                                  ? "input_score"
                                  : null
                              } `}
                              type="number"
                              disabled={
                                !checkEditMode(
                                  inEditMode,
                                  engineerId,
                                  "Assessment Test",
                                  24
                                )
                              }
                              value={getValue(engineerId, "Assessment Test")}
                              onChange={(e) =>
                                handleChange(e, "Assessment Test", engineerId)
                              }
                              onKeyDown={handleKeyDown}
                            />
                          }
                        </td>

                        <td onClick={() => onEdit(engineerId)}>
                          {
                            <input
                              className={`score ${
                                checkEditMode(inEditMode, engineerId, "QA", 25)
                                  ? "input_score"
                                  : null
                              } `}
                              type="number"
                              disabled={
                                !checkEditMode(inEditMode, engineerId, "QA", 25)
                              }
                              value={getValue(engineerId, "QA")}
                              onChange={(e) =>
                                handleChange(e, "QA", engineerId)
                              }
                              onKeyDown={handleKeyDown}
                            />
                          }
                        </td>

                        <td onClick={() => onEdit(engineerId)}>
                          {
                            <input
                              className={`score ${
                                checkEditMode(
                                  inEditMode,
                                  engineerId,
                                  "Algo",
                                  26
                                )
                                  ? "input_score"
                                  : null
                              } `}
                              type="number"
                              disabled={
                                !checkEditMode(
                                  inEditMode,
                                  engineerId,
                                  "Algo",
                                  26
                                )
                              }
                              value={getValue(engineerId, "Algo")}
                              onChange={(e) =>
                                handleChange(e, "Algo", engineerId)
                              }
                              onKeyDown={handleKeyDown}
                            />
                          }
                        </td>
                      </tr>
                    );
                  }
                )}
            </tbody>
          </table>
        </TableContainer>

        {assessmentList?.data && (
          <div className="submit_btns">
            <div></div>
            <ProtectedComponents permission={["manageAssessment"]}>
              <div>
                <MyButton
                  loading={isLoading}
                  onClick={() => handleSubmit()}
                  className="btn-comp submit_btn"
                >
                  Submit
                </MyButton>
              </div>
            </ProtectedComponents>
          </div>
        )}
      </StyledDashboardPage>
      {isModalOpen && (
        <div className="form-modal">
          <FormModal handleModal={handleModal}>
            {activeModal === "upload" && (
              <UploadAssessmentForm
                onClick={handleModal}
                refetch={refetchAssessments}
              />
            )}

            {activeModal === "edit" && (
              <EditAsessmentForm onClick={handleModal} />
            )}
          </FormModal>
        </div>
      )}
    </Layout>
  );
};

const StyledDashboardPage = styled.div`
  .page-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 2.8rem;
    margin-bottom: 1.1rem;
  }
  .page-header h4 {
    font-weight: 700;
    font-size: 2.4rem;
  }
  .page-header h3 {
    margin-bottom: -10rem;
    font-weight: 700;
    font-size: 2.4rem;
  }

  .engineers-div {
    display: flex;
    gap: 1rem;
  }

  .engineer-img {
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }

  .link {
    color: #000000;
  }

  .clickable {
    color: inherit;
  }

  .clickable:hover {
    text-decoration: underline;
  }

  .go-back {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
  }
  .go-back p {
    margin: 0;
    font-size: 1.4rem;
  }
  .score {
    border: 1px solid #101828;
    border-radius: 0.4rem;
    display: flex;
    justify-content: center;
    width: 4.3rem;
    margin: auto;
    height: 4rem;
    padding: 0.8rem 0.8rem 0.4rem 0.8rem;
    color: #000000;
    cursor: text;
  }

  .submit_btns {
    display: flex;
    justify-content: space-between;
  }

  .submit_btn {
    height: 40px !important;
    width: 120px !important;
  }

  .input_score {
    border: 2px solid green;
  }
  th {
    text-align: center;
  }
  @media screen and (max-width: 1170px) {
    width: 100%;
    .page-header {
      margin-top: 3.4rem;
      margin-bottom: 1.1rem;
    }
  }
  @media screen and (max-width: 800px) {
  }
  @media screen and (max-width: 770px) {
    .page-header h4 {
      font-size: 2rem;
    }
    .engineers-div {
      width: 100%;
      overflow: scroll;
      flex-direction: row;
    }
  }
  @media screen and (max-width: 500px) {
    .page-header {
      margin-top: 1rem;
    }

    .page-header h4 {
      margin-bottom: 16px;
    }
  }
`;

export default AdminAssessmentPage;
