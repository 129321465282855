import styled from "styled-components";

export const StyledDashboardPage = styled.div`
  .page-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 4.8rem;
    margin-bottom: 1.1rem;
  }
  .page-header h4 {
    font-weight: 700;
    font-size: 2.4rem;
  }
  .page-header h3 {
    margin-bottom: -10rem;
    font-weight: 700;
    font-size: 2.4rem;
  }

  .multi-action {
    margin-top: 32px;
  }

  .multi-action button {
    background: transparent;
    font-size: 1.6rem;
    color: #34a853;
    border: 1px solid #34a853;
    padding: 0px 8px;
  }

  .toogle-all-text {
    text-align: center;
    font-size: 1.6rem;
  }

  .toogle-all-text button {
    background: transparent;
    font-size: 1.6rem;
    color: #34a853;
    font-weight: 700;
    border: none;
  }

  .engineers-div {
    display: flex;
    gap: 1rem;
  }

  .checkbox-area {
    -webkit-appearance: button;
    display: none;
  }

  .check-label {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }

  .checkmark {
    width: 18px;
    height: 18px;
    border: 2px solid #34a853;
    display: inline-block;
    border-radius: 3px;
    margin-right: 1rem;
    transition: background-size 0.2s cubic-bezier(0.7, 0, 0.18, 1.24);
  }
  .check-label input:checked + .checkmark {
    background-size: 60%;
    background: #34a853;
    transition: background-size 0.25s cubic-bezier(0.7, 0, 0.18, 1.24);
  }

  .engineer-img {
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }

  .clickable {
    color: inherit;
  }

  .clickable:hover {
    text-decoration: underline;
  }

  .visibility-btn {
    font-size: 14px;
    outline: none;
    border: none;
    padding: 0.5rem 0.8rem;
    color: #fff;
    border-radius: 1px;
    text-transform: capitalize;
    box-shadow: 5px 2px 0px 0px;
    transition: transform 1s;

    &:hover {
      transform: scale(1.1);
    }
  }

  .truth-btn {
    background-color: #34a853;
  }

  .falsy-btn {
    background-color: red;
  }

  .goBack {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;

    img {
      width: 2rem;
    }

    span {
      color: #21334f;
    }
  }

  @media screen and (max-width: 1170px) {
    width: 100%;
    .page-header {
      margin-top: 3.4rem;
      margin-bottom: 1.1rem;
    }
  }
  @media screen and (max-width: 800px) {
  }
  @media screen and (max-width: 770px) {
    .page-header h4 {
      font-size: 2rem;
    }
    .engineers-div {
      width: 100%;
      overflow: scroll;
      flex-direction: row;
    }
  }
  @media screen and (max-width: 500px) {
    .page-header {
      margin-top: 1rem;
    }

    .page-header h4 {
      margin-bottom: 16px;
    }
  }
`;