import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { apiGet, apiDelete } from "../../../../utils/apiHelpers";
import AddRole from "../../form/AddRole";
import FormModal from "../../form/FormModal";
import { toast } from "react-toastify";
import ProtectedComponents from "../../local-routes/ProtectedComponents";

const Role = ({ pageType }) => {
  const [res, setRes] = useState([]);
  const [activeModal, setActiveModal] = useState(false);
  const handleModal = () => setActiveModal(!activeModal);
  const [idOfTable, setIdOfTable] = useState(-1);
  const [isEditing, setIsEditing] = useState(false);
  const [updateId, setUpdateId] = useState(0);

  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIdOfTable(-1);
    }
  };
  const showModal = (index) => {
    setIdOfTable(index);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const fetchRoles = async () => {
    try {
      let response = await apiGet(`/admin/roles/manage`);
      setRes(response.data.data);
    } catch (err) {
      toast.error(
        err?.response?.data?.message || "Not able to fetch roles at this time"
      );
    }
  };
  
  useEffect(() => {
    fetchRoles();
  }, []);

  const updateRole = async (item) => {
    setUpdateId(item.id);
    handleModal();
    setIsEditing(true);
  };

  const deleteRole = async (item) => {
    try {
      await apiDelete(`/admin/roles/${item.id}`, {});
      fetchRoles();
    } catch (err) {
      toast.error(
        err?.response?.data?.message || "Not able to delete role at this time"
      );
    }
  };

  return (
    <StyledDiv isEditing={isEditing}>
      <StyledClientView>
        <div>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-md-8  ">
              <h2 className="mt-4 manager">Roles</h2>
            </div>
            <ProtectedComponents permission={["manageRole"]}>
              <div className="col-md-4 px-0">
                <div className=" add-eng">
                  <Button
                    variant="primary"
                    className="add-btn"
                    onClick={handleModal}
                  >
                    Add New Roles
                  </Button>
                </div>
              </div>
            </ProtectedComponents>
          </div>

          <div className="mt-5 table-container">
            <table style={{ width: "100%" }}>
              <thead style={{ width: "100%" }}>
                <tr className="grid-container" style={{ display: "flex" }}>
                  <th className="heading-name pl-4" style={{ width: "40%" }}>
                    Name
                  </th>
                  <th className="heading-name pl-4" style={{ width: "40%" }}>
                    Description
                  </th>
                  <th className="heading-name pl-4" style={{ width: "20%" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="table-body" style={{ width: "100%" }}>
                {res.map((item, index) => (
                  <tr
                    key={index}
                    className="grid-container bg-white"
                    style={{ display: "flex" }}
                  >
                    <td className="pl-4" style={{ width: "40%" }}>
                      <span className="table-text-name">
                        <div className="others">{item.name}</div>
                      </span>
                    </td>

                    <td className="pl-4" style={{ width: "40%" }}>
                      <span className=" table-text-name">
                        <div className="others">{item.description}</div>
                      </span>
                    </td>
                    <td
                      className="pl-4"
                      style={{ width: "20%", position: "relative" }}
                      onClick={() => showModal(index)}
                    >
                      <img
                        className="action-icon"
                        src="/images/action-dots.svg"
                        style={{ width: "3.5rem" }}
                        alt="action-icon"
                      />
                      {index === idOfTable ? (
                        <ProtectedComponents permission={["manageRole"]}>
                          <div ref={ref} className="popup">
                            <p onClick={() => updateRole(item)}>View Role</p>
                            <p className="red" onClick={() => deleteRole(item)}>
                              Remove Role
                            </p>
                          </div>
                        </ProtectedComponents>
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="page-parent">
              {activeModal && (
                <div className="form-modal">
                  <FormModal className="form">
                    <AddRole
                      onClick={handleModal}
                      fetchAdminRoles={fetchRoles}
                      pageType={pageType}
                      res={res}
                      setIsEditing={setIsEditing}
                      isEditing={isEditing}
                      updateId={updateId}
                    />
                  </FormModal>
                </div>
              )}
            </div>
          </div>
        </div>
      </StyledClientView>
    </StyledDiv>
  );
};
const StyledDiv = styled.div`
  box-sizing: border-box;
  td {
    display: flex;
    align-items: center;
    padding: 2rem 0;
    font-size: 1.5rem;
    word-break: break-all;
  }
  .table-container {
    overflow-y: scroll;
    white-space: nowrap;
  }
  tr:not(:first-child) {
    margin-top: 2rem;
  }
  th {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem 0;
    font-size: 1.4rem;
  }

  .red {
    color: rgba(180, 0, 0, 0.4);
  }
  .popup {
    position: absolute;
    width: 175px;
    right: 50px;
    top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    padding: 1rem 2rem;
    border: 1px solid rgba(33, 51, 79, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    z-index: ${({ isEditing }) => (isEditing ? 0 : 3)};
    border-radius: 10px;
    p:hover {
      display: inline-block;
      font-weight: 700;
    }
  }

  .manager {
    font-size: 2rem;
    font-weight: 600;
  }

  @media only screen and (min-width: 280px) and (max-width: 677px) {
    .table-container {
      overflow-x: scroll;
      white-space: nowrap;
      width: 27rem;
    }
    td {
      margin-left: 1rem;
    }
    th {
      margin-left: 1rem;
    }
  }
  .popup {
    position: absolute;
    width: 175px;
    right: 35px;
    top: 45px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #ffffff;
    padding: 1rem 2rem;
    border: 1px solid rgba(33, 51, 79, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    z-index: ${({ isEditing }) => (isEditing ? 0 : 3)};
    border-radius: 10px;
    text-align: center;
    p:hover {
      display: inline-block;
      font-weight: 700;
    }
  }
`;

const StyledClientView = styled.div`
  .add-eng {
    position: absolute;
    right: 0rem;
  }
  .add-btn {
    padding: 1rem 2rem;
    background: linear-gradient(96.67deg, #34a853 0%, #b8d344 100%);
    border: none;
    font-size: 1.5rem;
  }

  .page-parent {
    margin-top: 7rem;
  }

  .heading-name {
    font-size: 1.5rem;
  }
  .form-modal {
    position: fixed;
    top: 0;
    left: 0;
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    width: 100%;
    /* padding: 2rem 2.5rem; */
    .add-eng {
      position: relative;
      right: 0px;
    }
    .add-eng {
      margin-top: 2rem;
    }
  }
  @media screen and (max-width: 1170px) {
    width: 100%;
  }
`;

export default Role;
