import { toast } from "react-toastify";
import { apiGet } from "../../utils/apiHelpers";
import { catchError } from "../../utils/catchError";
import fileDownload from "../../utils/file-download";

export const downloadAssessmentData = async (squad, techStack) => {
    try {
      const data = await apiGet(`/admin/training-data/download?squad=${squad.id}&techStack=${techStack.id}`,
      { responseType: "blob" }
      );
      fileDownload(data.data, `${squad.name}-${techStack.name}.xlsx`);
      return data
    } catch (err) {
      const errMessage = catchError(err)
      toast.error(errMessage)
    }
};