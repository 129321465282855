import React, { useEffect, useState } from "react";
import ActivityTrackerBar from "../../../common/activityTrackerCards/ActivityTrackerBar";
import { apiGet } from "../../../../../utils/apiHelpers";
// import { toast } from "react-toastify";

const RequestEngineerActivity = ({ orgId }) => {
  const [language, setLanguage] = useState([]);
  const [count, setCount] = useState([]);
  const [period, setPeriod] = useState("");
  const [numberOfEngineers, setNumberOfEngineers] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await apiGet(
          `/admin/assignments/requests/tracking?organisation=${orgId}&period=${period}`
        );
        const arr1 = [];
        const arr2 = [];
        const arr3 = [];
        res.data.data.forEach((x) => {
          arr2.push(x.count);
          arr1.push(x.language);
          arr3.push(x.numberOfEngineers);
          setLanguage([...arr1]);
          setCount([...arr2]);
          setNumberOfEngineers([...arr3]);
        });
      } catch (error) {
        console.log(error.message);
      }
    })();
  }, [orgId, period]);

  return (
    <>
      <ActivityTrackerBar
        header="Request Engineer"
        language={language}
        count={count}
        numberOfEngineers={numberOfEngineers}
        setPeriod={setPeriod}
      />
    </>
  );
};

export default RequestEngineerActivity;
