import { useState, useEffect } from "react";
import { apiGet } from "../../../../../utils/apiHelpers";
// import { toast } from "react-toastify";
import ActivityTrackerDoughnut from "../../../common/activityTrackerCards/ActivityTrackerDoughnut";

const RateAndReviewTracker = ({orgId}) => {
  const [tracker, setTracker] = useState(null);
  const [period, setPeriod] = useState('');


  useEffect(() => {
    (async () => {
      try {
        const res = await apiGet(`/admin/reviews/tracking?organisation=${orgId}&period=${period}`);
        setTracker(res?.data?.data);
      } catch (error) {
        console.log(error.message);
      }
    })();
  }, [orgId, period])

  return (
    <div>
        <ActivityTrackerDoughnut
          header="Rate & Review"
          centerValue= {tracker?.averageRating}
          centerText="Average Rating"
          greenCardNumber= {tracker?.totalNumberOfAssignedEngineers}
          greenCardText="Total Engineers"
          goldCardNumber= {tracker?.totalNumberOfReviews}
          goldCardText="Total Ratings"
          greenLabel="Total Engineers"
          goldLabel="Total Ratings"
          greenData= {tracker?.totalNumberOfAssignedEngineers}
          goldData= {tracker?.totalNumberOfReviews}
          setPeriod={setPeriod}
          backgroundColor1="#34A853"
          backgroundColor2="#B79D00"
        />
    </div>
  )
}

export default RateAndReviewTracker