import React, { useState } from "react";
import styled from "styled-components";
import ListingInterface from "../common/ListingInterface";
import {subListing} from "../common/listingData";
import Layout from "../common/Layout";
import { useParams } from "react-router";
import DynamicTitle from "../common/DynamicTitle";

const SubListingPage = ({ pageType }) => {

  const [activeModal, setActiveModal] = useState(false);
  const {id} = useParams();

  const handleModal = () => setActiveModal(!activeModal);

  return (
    <Layout navType="admin" refId={id} >
      <DynamicTitle title="Sub List | Fellowship Product" />
      <StyledDashboardPage activeModal={activeModal}>
            <ListingInterface
              details={subListing}
              handleModal={handleModal}
              pageType={pageType}
              refId={id}
            />
       
      </StyledDashboardPage>
    </Layout>
  );
};

const StyledDashboardPage = styled.div`

`;

export default SubListingPage;
