import React, { useState } from "react";
import Layout from "../common/Layout";
import DynamicTitle from "../common/DynamicTitle";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import TableContainer from "../common/TableContainer";
import ActionDropdown from "../common/ActionDropdown";
import FormModal from "../form/FormModal";
import useDialog from "../../../utils/useDialog";
import ProtectedComponents from "../local-routes/ProtectedComponents";
import { useFetchedPackageList } from "../../../services/package/query";
import { deletePackage } from "../../../services/package/api";
import { format } from "date-fns";
import CreatePackageForm from "../form/CreatePackageForm";
import EditPackageForm from "../form/EditPackageForm";

const PackageListing = ({ pageType }) => {
  const { deleteItemDialog } = useDialog();

  const { data: packageList, refetch, isLoading } = useFetchedPackageList();

  const [packageDetails, setPackageDetails] = useState(null);

  const [addPackageModal, setAddPackageModal] = useState(false);

  const [editPackageModal, setEditPackageModal] = useState(false);

  const handleAddPackageModal = (e) => {
    setAddPackageModal(!addPackageModal);
  };

  const handleEditPackage = (e) => {
    setEditPackageModal(!editPackageModal);
  };

  const handleDelete = (id, name) => {
    deleteItemDialog(deletePackage, id, name, refetch);
  };

  return (
    <Layout pageLoading={isLoading} navType="admin">
      <DynamicTitle title="Packages | Fellowship Product" />

      <PackageLayout>
        <div className="package-header">
          <h1>Package</h1>
          <ProtectedComponents permission={["managePackageSetup"]}>
            <MyButton
              onClick={() => handleAddPackageModal()}
              className="btn-doc"
            >
              Add Package
            </MyButton>
          </ProtectedComponents>
        </div>
        {
          <TableContainer>
            <table style={{ width: "100%" }}>
              <thead style={{ width: "100%" }}>
                <tr>
                  <th>Name</th>
                  <th>Amount</th>
                  <th>Date Added</th>
                  <th>Duration</th>
                  <th>Num of Engineers</th>
                  <ProtectedComponents permission={["managePackageSetup"]}>
                    <th>Action</th>
                  </ProtectedComponents>
                </tr>
              </thead>
              <tbody style={{ width: "100%" }}>
                {packageList?.map((item, index) => (
                  <tr className="" key={index + 1}>
                    <td>{item.name}</td>
                    <td>{item.amount}</td>
                    <td>{format(new Date(item?.dateAdded), "dd/MM/y")}</td>
                    <td>{item.duration}</td>
                    <td>{item.numOfEngineers}</td>
                    <ProtectedComponents permission={["managePackageSetup"]}>
                      <td>
                        <ActionDropdown>
                          <p
                            onClick={() => {
                              handleEditPackage();
                              setPackageDetails(item);
                            }}
                          >
                            Edit
                          </p>
                          <p
                            onClick={() => {
                              handleDelete(item.id, item.name);
                            }}
                          >
                            Delete
                          </p>
                        </ActionDropdown>
                      </td>
                    </ProtectedComponents>
                  </tr>
                ))}
                {packageList?.length === 0 && (
                  <tr>
                    <td colSpan="6" className="no-data">
                      No Data Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </TableContainer>
        }

        {addPackageModal && (
          <div className="form-modal">
            <FormModal className="form" handleModal={handleAddPackageModal}>
              {
                <CreatePackageForm
                  handleModal={handleAddPackageModal}
                  refetch={refetch}
                />
              }
            </FormModal>
          </div>
        )}
        {editPackageModal && (
          <div className="form-modal">
            <FormModal className="form" handleModal={handleEditPackage}>
              {
                <EditPackageForm
                  handleModal={handleEditPackage}
                  refetch={refetch}
                  packageDetails={packageDetails}
                />
              }
            </FormModal>
          </div>
        )}
      </PackageLayout>
    </Layout>
  );
};

const PackageLayout = styled.div`
  .package-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 4.8rem;
    margin-bottom: 1.1rem;
  }
  .package-header h1 {
    font-weight: 700;
    font-size: 2.4rem;
  }
  .form-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
  }
`;

export default PackageListing;
