import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { apiGet } from "../../../../utils/apiHelpers";
import { useParams } from "react-router-dom";

function OrganizationSubscription(props) {
  const { id, subId } = useParams();
  const orgId =
    id || JSON.parse(localStorage.getItem("client_details")).organisationId;
  const [packageName, setPackageName] = useState("");
  const [amount, setAmount] = useState(0);
  const [status, setStatus] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [noOfEngineer, setNoOfEngineer] = useState(0);
  const [availableCredit, setAvailableCredit] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");

  const history = useHistory();

  const fetchSubscription = async () => {
    try {
      const res = await apiGet(
        `/admin/organisations/${orgId}/subscriptions/${subId}`
      );
      let data = res.data.data;

      setPackageName(data.packageName);
      setAmount(data.amount);
      setStatus(data.status);
      setExpiryDate(data.expireDate);
      setNoOfEngineer(data.numEngineers);
      setAvailableCredit(data.availableCredit);
      setCompanyLogo(data.organisationLogo);
      setCompanyName(data.organisationName);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchSubscription();
    // eslint-disable-next-line
  }, []);

  let barSize;
  if (packageName === "Tier 1") {
    barSize = availableCredit * 2.5;
  } else {
    barSize = noOfEngineer - availableCredit;
  }

  const handleImageUrl = (item) => !item || item.includes("null");

  return (
    <StyledClientHeader>
      <Link to="#" onClick={() => history.goBack()} className="goBack">
        <img
          className="back-arrow-img"
          src="/images/back-arrow.svg"
          alt="back arrow"
        />
        <span className="go-back-text">Go Back</span>
      </Link>

      <div className="company-logo row flex-nowrap align-items-center mt-5">
        <div className="col-md-6 d-flex align-items-center">
          <span className="carbon-icon-span">
            <img
              className="carbon-icon"
              src={handleImageUrl ? "/images/no-logo.png" : companyLogo}
              alt="company logo"
            />
          </span>
          <span className="carbon-logo-text">{companyName}</span>
        </div>

        <div className="col-md-6 d-flex justify-content-end">
          <div className="status">
            <div className="status-text">Status</div>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {status}
              </Dropdown.Toggle>
            </Dropdown>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-md-7 h-100">
          <Card style={{ padding: "2.4rem" }}>
            <Card.Body>
              <div className="row align-items-center">
                <div className="col-md-6 ">
                  <div>
                    <span>
                      <span className="teir">{packageName}</span>
                      <span className="plan">Plan</span>
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="dev-fee-div">
                    <span className="dev-fee"> &#8358;{amount}</span>
                    <span className="year"> / year</span>
                  </div>
                </div>
              </div>

              <div className="row bar-wrapper">
                <div className="col-md-8">
                  {packageName === "Tier 1" ? (
                    <p className="number-of-dev">
                      {4 - availableCredit} of 4 Engineers Used
                    </p>
                  ) : (
                    <p className="number-of-dev">
                      {10 - availableCredit} of 10 Engineers Used
                    </p>
                  )}
                  <div className="progress">
                    <div
                      className="bar"
                      style={{ width: `${barSize}0%` }}
                    ></div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>

        <div className="col-md-5 second-card">
          <Card className="h-100" style={{ padding: "2.4rem" }}>
            <Card.Body>
              <div className="row px-4">
                <div className="col-md-12 px-0">
                  <h2>Next Payment</h2>
                </div>
                <div className="col-md-12 px-0 mt-2">
                  <h1>on {expiryDate}</h1>
                </div>
                <Button className="reminder-btn px-5 py-3">
                  Cancel Subscription
                </Button>{" "}
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </StyledClientHeader>
  );
}

const StyledClientHeader = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),
    #fafffb;
  font-family: Mulish;
  .company-logo {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .back-arrow-img {
    width: 2rem;
    margin-bottom: 0.7rem;
  }
  .goBack {
    color: #21334f;
    text-decoration: none;
  }
  .go-back-text {
    font-size: 1.5rem;
    font-weight: 600;
    margin-left: 0.6rem;
  }
  .carbon-icon {
    height: 4.5rem;
    width: 5.5rem;
  }
  .carbon-icon-span {
    background: #fff;
    padding: 1rem 0.5rem;
  }
  .carbon-logo-text {
    margin-left: 2rem;
    font-size: 3rem;
  }

  .status-text {
    font-size: 1.5rem;
    margin-bottom: 0.3rem;
  }
  button#dropdown-basic.dropdown-toggle {
    background: #fff !important;
    color: rgba(33, 51, 79, 1) !important;
    text-transform: capitalize;
    border: 1px solid rgba(33, 51, 79, 0.1);
    font-size: 1.5rem;
  }
  button#dropdown-basic.dropdown-toggle::after {
    margin-left: 3rem;
    vertical-align: middle;
  }
  .teir {
    background: #21334f;
    padding: 0.5rem 0.7rem;
    color: #fff;
    font-size: 1.5rem;
  }
  .plan {
    font-size: 2rem;
    margin-left: 1rem;
  }
  .card {
    background: #ffffff;
    border: 1px solid rgba(33, 51, 79, 0.1);
    box-sizing: border-box;
  }
  .card-body {
    padding: 0;
  }
  .dev-fee {
    font-size: 3rem;
    font-weight: 800;
    color: rgba(33, 51, 79, 1);
  }
  .dev-fee-div {
    text-align: right;
  }
  .download-icon {
    height: 1.5rem;
    width: 2rem;
    margin-bottom: 0.7rem;
  }
  .view-contract {
    font-size: 1.5rem;
    font-weight: 600;
    color: #34a853;
    margin-left: 0.5rem;
  }
  .number-of-dev {
    font-size: 1.5rem;
  }
  .year {
    font-size: 1.5rem;
  }
  .bar-wrapper {
    margin-top: 7.7rem;
  }
  .reminder-btn {
    background: transparent !important;
    color: rgba(33, 51, 79, 0.46);
    border: 1px solid rgba(33, 51, 79, 0.1);
    box-sizing: border-box;
    margin-top: 4.9rem;
    font-size: 1.6rem;
    font-weight: 600;
  }
  .change-btn {
    background: linear-gradient(96.67deg, #34a853 0%, #b8d344 100%);
    color: #fff;
    font-size: 1.6rem;
    border: none;
  }
  .progress {
    padding: 0;
    width: 70%;
    height: 10px;
    overflow: hidden;
    background: #e5e5e5;
    border-radius: 6px;
  }

  .bar {
    position: relative;
    float: left;
    min-width: 1%;
    height: 100%;
    background: #34a853;
  }

  .second-card h2 {
    font-size: 1.6rem;
  }

  .second-card h1 {
    font-size: 2rem;
  }

  .invoice-name {
    font-size: 3rem;
    color: rgba(33, 51, 79, 1);
  }
  .invoice-number {
    font-size: 2rem;
    color: rgba(33, 51, 79, 1);
    margin-left: 0.6rem;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    .div-change {
      margin-top: 3rem;
    }
    .carbon-icon {
      height: 3.5rem;
      width: 4.5rem;
    }
    .carbon-logo-text {
      font-size: 2rem;
    }

    .dev-fee-div {
      text-align: left;
    }
    .status,
    .dev-fee-div {
      position: relative;
      right: 0px;
    }
    .bar-wrapper {
      margin-top: 4.2rem;
    }
  }
  @media screen and (max-width: 500px) {
    .dev-fee-div {
      margin-top: 1rem;
      text-align: left;
    }
  }

  @media screen and (max-width: 1170px) {
    width: 100%;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    width: 100%;
  }
`;

export default OrganizationSubscription;
