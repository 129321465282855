import React from "react";
import Select from "react-select";
import "../../../services/reference/query";
import { FilterFields } from "./style";

const AssessmentsFilter = ({ handleFilterChange, language, squads, weeks }) => {
  const formatSquad = squads
    ? squads.data.map((data) => {
        return {
          label: `SQ00${data.name}`,
          value: data.id,
        };
      })
    : [];

  const formatLanguage = language
    ? language.data.map((data) => {
        return {
          label: data.name,
          value: data.id,
        };
      })
    : [];

  return (
    <FilterFields className="engineer-filters-wrapper">
      <div className="select-wrap mt-4">
        <Select
          className="my-select"
          defaultValue={formatSquad[0]}
          placeholder={
            formatSquad.length ? formatSquad[0].label : "Select Squad"
          }
          options={formatSquad}
          onChange={(e) => handleFilterChange("squad", e.value)}
        />
      </div>
      <div className="select-wrap mt-4">
        <Select
          className="my-select"
          defaultValue={weeks[0]}
          options={weeks}
          placeholder={weeks.length ? weeks[0].label : "Week"}
          onChange={(e) => handleFilterChange("week", e.value)}
        />
      </div>
      <div className="select-wrap mt-4">
        <Select
          className="my-select"
          defaultValue={formatLanguage[0]}
          placeholder={
            formatLanguage.length ? formatLanguage[0].label : "Language"
          }
          options={formatLanguage}
          onChange={(e) => handleFilterChange("language", e.value)}
        />
      </div>
    </FilterFields>
  );
};

export default AssessmentsFilter;
