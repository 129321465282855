import {
  fetchWeeks,
  createWeek,
  editWeek,
} from "./api";
import { useQuery, useMutation } from "react-query";

export const useGetWeeks = (id) => {
  const { data, isLoading, refetch } = useQuery("weeks", () => fetchWeeks(id));

  return { data, isLoading, refetch };
};

export const useCreateWeek = (config) => {
  const mutation = useMutation((params) => createWeek(params, config), {
    // onSuccess: () => onCreateSuccess(),
  });

  return mutation;
};

export const useEditWeek = (config) => {
  const mutation = useMutation(
    (params, formData) => editWeek(params, formData, config),
    {}
  );
  return mutation;
};

