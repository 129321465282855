import React from "react";
import styled from "styled-components";
import "./style.module.css";
import MyButton from "../../landingPage/MyButton";

const AdminSSO = () => {
  localStorage.clear();
  return (
    <StyledLogin>
      <div className="form-logo">
        <img
          src={process.env.PUBLIC_URL + "/images/decagon-dark-logo.svg"}
          alt="Decagon logo"
        />
      </div>

      <div className="login-form-content">
        <div className="login-text-parent mb-4">
          <h2>Log in to Access Your Dashboard</h2>
        </div>

        <MyButton
          onClick={() =>
            window.location.replace(
              `${process.env.REACT_APP_BASE_URL}/admin/authorize`
            )
          }
          className="login-btn"
          full>
          Login with your Decagon account
        </MyButton>
      </div>
    </StyledLogin>
  );
};
const StyledLogin = styled.div`
  max-width: 70%;
  padding: 0rem 6.4rem;
  margin: 0 auto;
  color: #21334f;
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 100%;
  justify-content: center;
  .login-text-parent {
    margin-bottom: 2rem;

    h2 {
      margin-bottom: 0rem;
      font-weight: 700;
      font-size: 2.4rem;
    }
  }
  .login-form-content h1 {
    font-size: 3.2rem;
    line-height: 4rem;
    font-weight: 700;
  }
  .login-btn {
    width: 100%;
    border: none;
  }
  .login-btn: hover {
    color: white;
  }
  .login-text {
    opacity: 0.8;
    font-weight: 400;
  }
  .form-logo {
    width: 30%;
    margin-bottom: 5rem;
    // margin-bottom: auto;
  }
  .login-form-content {
    font-size: 16px;
    line-height: 27px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .form-link {
    color: #34a853;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 5.3rem;
    display: flex;
    justify-content: flex-end;
  }
  @media screen and (max-width: 770px) {
    max-width: 90%;
    padding: 7.35rem 3.5rem;
    padding-top: 3rem;
    .form-logo {
      width: 40%;
      margin-bottom: 10.4rem;
    }
  }

  @media screen and (max-width: 600px) {
    max-width: 90%;
    padding: 7.35rem 0;
    padding-top: 3rem;
    .form-logo {
      width: 40%;
      margin-bottom: 5.4rem;
    }
    .login-form-content {
      margin-top: 8rem;
    }
    .login-btn {
      width: 100%;
      border: none;
    }
  }
  @media screen and (max-width: 500px) {
    justify-content: start;
    .login-text-parent {
      margin-bottom: 2rem;

      h2 {
        margin-bottom: 0rem;
        font-weight: 700;
        font-size: 2rem;
      }
    }
    .login-form-content {
      margin-top: 3.4rem;
    }
    .login-btn {
      height: 52px;
      font-size: 1.6rem;
      margin-bottom: 5rem;
    }
  }

  @media only screen and (min-width: 677px) and (max-width: 1024px) {
    .login-text-parent {
      margin-bottom: 2rem;

      h2 {
        margin-bottom: 0rem;
        font-weight: 700;
        font-size: 3.5rem;
      }
    }
  }
`;
export default AdminSSO;
