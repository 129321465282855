import React, { useState, useEffect } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import MyButton from '../../landingPage/MyButton';
import FormInputComponent from './enterpriseFormInputs/FormInputComponent';
import FormTitleSection from './FormTitleSection';
// import { toast } from "react-toastify";
import { apiPost } from "../../../utils/apiHelpers";
import FormUploadComponent from './enterpriseFormInputs/FormUploadComponent';
import { ClipLoader } from 'react-spinners';


const initialValues = {
    reportName: "",
};

const validationSchema = Yup.object({
    reportName: Yup.string().required('*Required'),

})

const CreateReport = ({ onClick, assignmentId, handleOpenReport, reload }) => {

    const [success, setSuccess] = useState(false);
    const [reportDoc, setReportDoc] = useState({});
    const [loading, setLoading] = useState(false)
    const [reportDocError, setReportDocError] = useState("");
    const docType = ["pdf", "docx", "doc"];


    useEffect(() => {
        setReportDocError("");
    }, [reportDoc]);


    const onSubmit = (values) => {
        if (!reportDoc.name) {
            setReportDocError("*Required");
            return;
        }
        let formData = new FormData();
        formData.append("report_upload", reportDoc);
        formData.append(
            "report_dto",
            new Blob([JSON.stringify(values)], {
                type: "application/json",
            })
        );
        createReport(formData);
    };

    //create Report
    const createReport = async (formData) => {
        setLoading(true);
        //make call to the api
        try {
            let response = await apiPost(`/assignments/${assignmentId}/reports`, formData, true);

            if (response.error) {
                throw response.data.response.status === 500 ? new Error("") : response.data;
            }
            setSuccess(true);
            setLoading(false);
            reload();

        } catch (err) {
            setLoading(false);

        }
    };

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    })

    const closeForm = () => {
        formik.resetForm()
        onClick();
        setSuccess(false);
    }

    return (
        <StyledCreatePackageForm success={success}>
            <FormTitleSection title="Create Report" onClick={() => handleOpenReport()} />
            <div className="form-section">
                <form onSubmit={formik.handleSubmit} className="client-form">
                    <p className="form-font form-title">Report Details</p>



                    <FormInputComponent
                        label="Report Name"
                        type="text"
                        name="reportName"
                        placeholder="Enter report name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.reportName}
                        error={
                            formik.touched.reportName && formik.errors.reportName
                                ? formik.errors.reportName
                                : null
                        }
                    />

                    <FormUploadComponent
                        label="Upload Report"
                        error={reportDocError}
                        selectedFile={reportDoc}
                        setSelectedFile={setReportDoc}
                        acceptTypes={`pdf, docx, doc. Size Limit 5mb`}
                        pdf={true}
                        docType={docType}

                    />


                    <MyButton
                        className="form-btn add-client-btn"
                        type={"submit"}
                        full
                    >
                        {" "}
                        {loading ? <ClipLoader color={"#fff"} size={"30px"} /> : "Add New Report"}
                    </MyButton>
                </form>
                <div className="form-success">
                    <img
                        src="/images/sucess-icon.svg"
                        alt="green-tick"
                        className="sucess-icon"
                    />
                    <h2 className="form-font green-text">Success</h2>
                    <p className="form-font success-msg">
                        You successfully created a Report.
        </p>
                    <MyButton className="form-btn success-btn" onClick={closeForm}>
                        Okay, Go back to engineer Page
        </MyButton>
                </div>
            </div>
        </StyledCreatePackageForm>
    )
}

const StyledCreatePackageForm = styled.div`
    width: 100%;
    height: 100vh;
    z-index: 10;
    overflow-y: scroll;
    background: white;
    border: 1px solid rgba(33, 51, 79, 0.15);
    .form-section{
        padding: 4rem 4rem 0 4rem;
        overflow-y: scroll;
    }
    .form-title{
        font-size: 2rem;
        line-height: 2.5rem;
        margin-bottom: 3.2rem;
    }
    .add-client-btn{
        margin-bottom: 23.8rem;
    }
    .client-form{
        display: ${({ success }) => (success ? "none" : "block")};
        
    }
    .form-success{
        display: ${({ success }) => (success ? "flex" : "none")};
        width: 100%;
        flex-direction: column;
        align-items: center;

    }
    .sucess-icon{
        width: 4.2rem;
        margin-bottom: 1.7rem;
    }
    .green-text{
        font-size: 2.4rem;
        margin-bottom: 2.2.rem;
    }
    .success-msg{
        width: 25.9rem;
        font-size: 1.6rem;
        line-height: 2.4rem;
        text-align: center;
        margin-bottom: 3.8rem;
    }
    .success-btn{
        width: 100% !important;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 3.2rem;
    }
    .success-btn:hover,
    .success-btn:focus{
        background: #34A853;
        color: #ffffff;
        text-decoration: none;
        cursor: pointer;
    }

`;

export default CreateReport
