import React, { useReducer } from "react";
import AuthReducer from "./AuthReducer";
import AuthContext from "./AuthContext";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  CLEAR_ERRORS,
  LOGOUT,
  LOADING,
  VERIFIED,
} from "./types";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { apiGet, apiPost } from "../utils/apiHelpers";
import { useHistory } from "react-router-dom";

const AuthState = ({ children }) => {
  const checkAuthRoles = (token) => {
    const { user_type } = jwt_decode(token);

    switch (user_type) {
      case "ADMIN":
        return "admin";
      case "CLIENT":
        return "client";
      case "ENGINEER":
        return "engineer";
      default:
        return "admin";
    }
  };

  const getRoles = (roles) => {
    localStorage.setItem("roles", roles);
  };
  const setUserType = (token) => {
    const { user_type } = jwt_decode(token);
    if (user_type === "ADMIN") {
      localStorage.setItem("userType", "admin");
    }
    if (user_type === "CLIENT") {
      localStorage.setItem("userType", "client");
    }

    if (user_type === "ENGINEER") {
      localStorage.setItem("userType", "engineer");
    }
  };

  const permissionCheck = (a, b) => {
    let result = a.reduce((data, perm) => {
      return { ...data, [perm]: true };
    }, {});
    return b.some((perm) => result[perm]);
  };

  const initialState = {
    token: localStorage?.getItem("token"),
    isAuthenticated: localStorage?.getItem("token")
      ? checkAuthRoles(localStorage?.getItem("token"))
      : "admin",
    user: null,
    loading: false,
    error: null,
    isVerified: false,
    client_details: null,
  };

  const history = useHistory();
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  // Login User
  const login = async (formData, type) => {
    dispatch({
      type: LOADING,
      payload: true,
    });
    let path = "";
    try {
      //make call to the api
      const pathname = window.location.pathname.split("/")[1];

      if (pathname === "engineer") {
        type = "engineer";
        path = "dev";
      }

      if (pathname === "login") {
        if (formData.userName.endsWith("decagonhq.com")) {
          type = "admin";
          path = "admin";
        } else {
          type = "client";
          path = "private";
        }
      }

      // if (formData.userName.endsWith("decagonhq.com")) {
      //   type = "admin";
      //   path = "admin";
      // } else {
      //   type = "client";
      //   path = "private";
      // }

      const res = await apiPost(`/${path}/login`, formData, config, false);
      const { exp } = jwt_decode(res.data.data.token);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          ...res.data,
          type,
          isAuthenticated: checkAuthRoles(res.data.data.token),
          expiry: exp,
        },
      });
      getRoles(res.data.data.authorities);
      setUserType(res.data.data.token);
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: err.message,
      });
    }
  };

  //verify password
  const verifyToken = async (token) => {
    try {
      //make call to the api
      const res = await apiPost(
        "/private/createpassword/verify_token",
        { token },
        config,
        false
      );
      if (res.status === 200) {
        dispatch({
          type: VERIFIED,
          payload: true,
        });
      } else if (res.error) {
        throw res.data.response.status === 500 ? new Error("") : res.data;
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || "Invalid token");
      dispatch({
        type: VERIFIED,
        payload: false,
      });
      setTimeout(() => {
        window.location.href = "/subscribe";
      }, 5000);
    }
  };
  //create password
  const createPassword = async (data) => {
    try {
      const res = await apiPost("/private/createpassword", data, config, false);
      toast.success(res.data.message);
      history.push("/login");
    } catch (err) {}
  };

  // Logout
  const logout = async (data) => {
    const refreshToken = localStorage.getItem("refreshToken");
    try {
      if (state.isAuthenticated === "admin") {
        await apiPost("/admin/logout", {}, {});
      } else if (state.isAuthenticated === "client") {
        await apiPost("/private/logout", {}, {});
      } else {
        await apiPost("/dev/logout", { refreshToken }, {});
      }

      dispatch({ type: LOGOUT, payload: data });
    } catch (err) {}
  };

  //Redirect from DevProfile
  // Login User
  const redirectFromDevProfile = async (token, email) => {
    dispatch({
      type: LOADING,
      payload: true,
    });

    try {
      const type = "client";
      const res = await apiGet(
        `/private/auth-client/${email}`,
        config,
        "client",
        token
      );

      const { exp } = jwt_decode(res.data.token);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          ...res,
          type,
          isAuthenticated: checkAuthRoles(res.data.token),
          expiry: exp,
        },
      });
      getRoles(res.data.authorities);
      setUserType(res.data.token);
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: err.message,
      });
    }
  };

  // Clear Errors
  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  return (
    <AuthContext.Provider
      value={{
        client_details: state.client_details,
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        user: state.user,
        error: state.error,
        isVerified: state.isVerified,
        login,
        logout,
        verifyToken,
        createPassword,
        clearErrors,
        permissionCheck,
        redirectFromDevProfile,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

export default AuthState;
