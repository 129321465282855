import React from "react";
import styled from "styled-components";
import { useFetchedPlacementRequestDetail } from "../../../services/engineerReq/query";
import Modal from "../form/FormModal";

const ViewEngineerReq = ({reqId, handleViewEngReqModal}) => {
  const { data: requestDetails } = useFetchedPlacementRequestDetail(reqId)

  return (
    <ModalWrapper className="form-modal">
        <Modal centered>
        <div className="modalContent">
            <button onClick={handleViewEngReqModal} className="exit-btn">
            <img src="/images/times.svg" alt="x" className="times" />
            </button>
            <h3>Engineer's Details</h3>
            <hr />
            <div className="modalTable">
            <div>
                <div className="header">
                    <div className="header-col">Name</div>
                    <div className="header-col">Tech Stack</div>
                    <div className="header-col">Request Status</div>
                    <div className="header-col">Request Date</div>
                </div>
                <div className="body">
                    {
                        requestDetails?.data.engineerRequest?.map((item, i)=> (
                        <div key={i} className="body-content">
                            <div className="body-col">{item.engineerName}</div>
                            <div className="body-col">{item.engineerTechStack}</div>
                            <div className="body-col">{requestDetails.data.requestStatus}</div>
                            <div className="body-col">{requestDetails.data.requestDate}</div>
                        </div>
                        ))
                    }
                </div>
            </div>
            </div>
        </div>
        </Modal>
    </ModalWrapper>  
  );
};

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 1.6rem;
  
  .modalContent{
    background: #fff;
    width: 686px;
    position: relative;
    top: 40%;
    transform: translateY(-50%);
    padding: 32px 0;
  }

  .modalTable{
    padding: 10px 45px;
  }

  .header {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    text-align: center;
    font-weight: bold;
  }

  .body{
    margin-top: 10px;
  }

  .body-content {
    background: #F7F8F8;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    text-align: center;
    padding: 10px 0px;
    margin-bottom: 6px;
  }

  h4 {
    color: #273b4a;
    font-size: 16px;
    font-weight: 700;
  }

  .info{
    margin-top: 24px;
  }

  .info-content {
    border: 1px solid #E6E6E6;
    padding: 10px;
  }

  .times {
    width: 30px;
  }
  
  .exit-btn {
    cursor: pointer;
    border: none;
    background-color: transparent;
    position: absolute;
    right: 20px;
  }
  h3 {
    text-align: center;
    color: #273b4a;
    font-size: 18px;
    font-weight: 700;
  }
  hr {
    margin-top: 20px;
    width: 100%;
  }
`;

export default ViewEngineerReq;
