import swal from "sweetalert";
import { toast } from "react-toastify";

/**
 * Represents a React hook to show a delete dialog box.
 *
 */

export default function useDialog() {
  const deleteItemDialog = (handleDelete, params, name, refetch) => {
    swal({
      title: "Are you sure",
      text: `You want to delete ${name}? process cannot be reversed`,
      icon: "warning",
      buttons: {
        none: {
          text: "cancel",
          className: "cancel-delete",
          value: null,
        },
        confirm: {
          text: "delete",
          value: true,
          closeModal: false,
          className: "confirm--delete",
        },
      },
    }).then((value) => {
      if (value) {
        handleDelete(params)
          .then(() => {
            refetch()
            swal.stopLoading();
            swal.close();
            toast.success("Deleted successfully");
          })
          .catch((err) => {
            swal.stopLoading();
            swal.close();
          });
      } else {
        swal.stopLoading();
        swal.close();
      }
    });
  };

  const resendLinkConfirm = (cb, id, name) => {
    swal({
      title: "Resend Link?",
      text: `You want to resend ${name}? a new Login link? `,
      icon: "warning",
      buttons: {
        none: {
          text: "cancel",
          className: "confirm--delete",
          value: null,
        },
        confirm: {
          text: "Send",
          value: true,
          closeModal: false,
          className: "cancel-delete",
        },
      },
    }).then((value) => {
      if (value) {
        cb(id)
          .then(() => {
            swal.stopLoading();
            swal.close();
            toast.success("sent successfully");
          })
          .catch((err) => {
            swal.stopLoading();
            swal.close();
            toast.error(
              err?.response?.data?.message || "Could not send"
            );
          });
      } else {
        swal.stopLoading();
        swal.close();
      }
    });
  }

  const generateInvoice = (cb) => {
    swal({
      title: "Generate Invoice",
      text: `Are you sure want to generate an invoice?`,
      icon: "warning",
      buttons: {
        none: {
          text: "Cancel",
          className: "confirm--delete",
          value: null,
        },
        confirm: {
          text: "Generate",
          value: true,
          closeModal: false,
          className: "cancel-delete",
        },
      },
    }).then((value) => {
      if (value) {
        cb()
          .then(() => {
            swal.stopLoading();
            swal.close();
            toast.success("Invoice generated successfully");
          })
          .catch((err) => {
            swal.stopLoading();
            swal.close();
            toast.error(
              err?.response?.data?.message || "Could not generate invoice"
            );
          });
      } else {
        swal.stopLoading();
        swal.close();
      }
    });
  }

  return {
    generateInvoice,
    deleteItemDialog,
    resendLinkConfirm
  };
}



