import React, { useState } from "react";
import Layout from "../common/Layout";
import DynamicTitle from "../common/DynamicTitle";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import TableContainer from "../common/TableContainer";
import ActionDropdown from "../common/ActionDropdown";
import FormModal from "../form/FormModal";
import useDialog from "../../../utils/useDialog";
import ProtectedComponents from "../local-routes/ProtectedComponents";
import CreateIndustry from "../form/CreateIndustry";
import { useFetchedIndustryList } from "../../../services/industry/query";
import { deleteIndustry } from "../../../services/industry/api";
import UpdateIndustry from "../form/UpdateIndustry";

const IndustryListing = ({ pageType }) => { 
  const { deleteItemDialog } = useDialog();

  const { data: industryList, refetch, isLoading } = useFetchedIndustryList();

  const [industryId, setIndustryId] = useState(null);
  const [industryName, setIndustryName] = useState(null);

  const [addIndustryModal, setAddIndustryModal] = useState(false);

  const [editIndustryModal, setEditIndustryModal] = useState(false);

  const handleAddIndustryModal = (e) => {
    setAddIndustryModal(!addIndustryModal);
  };

  const handleEditIndustry = (e) => {
    setEditIndustryModal(!editIndustryModal);
  };

  const handleDelete = (id, name) => {
    deleteItemDialog(deleteIndustry, id, name, refetch);
  };

  return (
    <Layout pageLoading={isLoading} navType="admin">
      <DynamicTitle title="Industries | Fellowship Product" />

      <IndustryLayout>
        <div className="industry-header">
          <h1>Industry</h1>
          <ProtectedComponents permission={["manageIndustrySetup"]}>
            <MyButton
              onClick={() => handleAddIndustryModal()}
              className="btn-doc"
            >
              Add Industry
            </MyButton>
          </ProtectedComponents>
        </div>
        {
          <TableContainer>
            <table style={{ width: "100%" }}>
              <thead style={{ width: "100%" }}>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <ProtectedComponents permission={["manageIndustrySetup"]}>
                    <th>Action</th>
                  </ProtectedComponents>
                </tr>
              </thead>
              <tbody style={{ width: "100%" }}>
                {industryList?.map(({ id, name }, index) => (
                  <tr className="" key={index + 1}>
                    <td>{id}</td>
                    <td>{name}</td>
                    <ProtectedComponents permission={["manageIndustrySetup"]}>
                      <td>
                        <ActionDropdown>
                          <p
                            onClick={() => {
                              handleEditIndustry();
                              setIndustryId(id);
                              setIndustryName(name);
                            }}
                          >
                            Edit
                          </p>
                          <p
                            onClick={() => {
                              handleDelete(id, name);
                            }}
                          >
                            Delete
                          </p>
                        </ActionDropdown>
                      </td>
                    </ProtectedComponents>
                  </tr>
                ))}
                {industryList?.length === 0 && (
                  <tr>
                    <td colSpan="6" className="no-data">
                      No Data Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </TableContainer>
        }

        {addIndustryModal && (
          <div className="form-modal">
            <FormModal className="form" handleModal={handleAddIndustryModal}>
              {
                <CreateIndustry
                  handleModal={handleAddIndustryModal}
                  refetch={refetch}
                />
              }
            </FormModal>
          </div>
        )}
        {editIndustryModal && (
          <div className="form-modal">
            <FormModal className="form" handleModal={handleEditIndustry}>
              {
                <UpdateIndustry
                  handleModal={handleEditIndustry}
                  refetch={refetch}
                  industryId={industryId}
                  industryName={industryName}
                />
              }
            </FormModal>
          </div>
        )}
      </IndustryLayout>
    </Layout>
  );
};

const IndustryLayout = styled.div`
  .industry-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 4.8rem;
    margin-bottom: 1.1rem;
  }
  .industry-header h1 {
    font-weight: 700;
    font-size: 2.4rem;
  }
  .form-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
  }
`;

export default IndustryListing;
