import React from "react";
import ProtectedComponents from "../local-routes/ProtectedComponents";
import { sendLoginNotification } from "../../../services/engineer/api";
import { useToggleVisibility, useUplaceEngineer } from "../../../services/engineer/query";
import TableContainer from "../common/TableContainer";
import { Link } from "react-router-dom";
import ActionDropdown from "../common/ActionDropdown";
import clsx from "clsx";
import { StatusBtn } from "../styles/statusBtn";
import { StyledDashboardPage } from "../styles/styleDashboard";

const EngineerListTable = ({
  pageType,
  engineerList,
  refetchEngineers,
  setActiveModal,
  handleModal,
  setEngineerIds,
  engineerIds,
  filterOptions,
}) => {
  const onToggleSuccess = () => {
    setEngineerIds && setEngineerIds([]);
    // handleModal && handleModal();
    refetchEngineers();
  };

  const { mutate } = useToggleVisibility(onToggleSuccess);
  const { mutate: unplaceEngineer } = useUplaceEngineer(onToggleSuccess);

  const handleEngineerSelected = (e, id) => {
    if (e.target.checked) {
      setEngineerIds([...engineerIds, id]);
    } else if (e.target.checked === false) {
      let filterSelectedEngs = engineerIds.filter((x) => x !== id);

      setEngineerIds(filterSelectedEngs);
    }
  };

  const handleEngineersSelected = () => {
    const currEngineerIds = engineerList?.map((item) => item.id);
    if (engineerIds.length < filterOptions.size) {
      setEngineerIds(currEngineerIds);
    } else {
      setEngineerIds([]);
    }
  };

  const handleImageUrl = (item) => !item || item.includes("null");

  return (
    <>
      <StyledDashboardPage>
        <TableContainer>
          <table style={{ width: "100%" }}>
            <thead style={{ width: "100%" }}>
              <tr>
                {pageType === "engineer_list" && (
                  <th>
                    <label
                      style={{ width: "auto", color: "#212529" }}
                      className="check-label"
                    >
                      <input
                        className="checkbox-area"
                        type="checkbox"
                        onChange={handleEngineersSelected}
                        checked={
                          engineerIds.length >= filterOptions.size || false
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  </th>
                )}
                <th></th>
                <th>Username</th>
                <th>Gender</th>
                <th>Language</th>
                <th>Eng. Type</th>
                <th>Placement Type </th>
                <th>Placement Status</th>
                <th>Visibility</th>
                {pageType === "engineer_list" && <th>Action</th>}
              </tr>
            </thead>
            <tbody style={{ width: "100%" }}>
              {engineerList?.map(
                (
                  {
                    placementType,
                    id,
                    language,
                    thumbnailUrl,
                    gender,
                    engineerType,
                    username,
                    status,
                    frontEndVisibility,
                  },
                  index
                ) => (
                  <tr className="" key={id + index + username}>
                    {pageType === "engineer_list" && (
                      <td>
                        <label
                          style={{ width: "auto", color: "#212529" }}
                          className="check-label"
                        >
                          <input
                            className="checkbox-area"
                            type="checkbox"
                            onChange={(e) => handleEngineerSelected(e, id)}
                            checked={
                              engineerIds.find(
                                (x) => Number(x) === Number(id)
                              ) || false
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </td>
                    )}
                    <td>
                      <img
                        src={
                          handleImageUrl(thumbnailUrl)
                            ? "/images/default-avatar.png"
                            : thumbnailUrl
                        }
                        alt="engineer"
                        className="engineer-img"
                      />
                    </td>
                    <td>
                      <Link
                        className="clickable"
                        to={{
                          pathname: `/admin/engineers/${id}`,
                          state: {
                            pageTypeState: "admin-view-state",
                          },
                        }}
                      >
                        {username}
                      </Link>
                    </td>
                    <td>{gender}</td>
                    <td>{language}</td>
                    <td>{engineerType}</td>
                    <td>{placementType}</td>
                    <td>
                      {status ? (
                        <StatusBtn
                          className="status-bg"
                          status={status?.toLowerCase()}
                        >
                          {status}
                        </StatusBtn>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>
                      <button
                        onClick={() => mutate([id])}
                        className={clsx(
                          "visibility-btn",
                          frontEndVisibility ? "truth-btn" : "falsy-btn"
                        )}
                      >
                        {frontEndVisibility.toString()}
                      </button>
                    </td>
                    {pageType === "engineer_list" && <td>
                      <ProtectedComponents permission={["manageEngineer"]}>
                        <ActionDropdown>
                          <p
                            onClick={() => {
                              handleModal(id);
                              setActiveModal("edit-eng");
                            }}
                          >
                            View/Edit Engineer
                          </p>
                          <p
                            onClick={() => {
                              handleModal(id);
                              setActiveModal("assign-eng");
                            }}
                          >
                            Assign Engineer
                          </p>
                          <p>
                            <Link
                              to={{
                                pathname: `/admin/engineers/assignments/${id}`,
                                state: {
                                  routeId: id,
                                },
                              }}
                            >
                              View Placement
                            </Link>
                          </p>
                          <p onClick={() => sendLoginNotification(id)}>
                            Login Notification
                          </p>
                          <p onClick={()=> unplaceEngineer(id)}>
                              Unplace Engineer
                          </p>
                        </ActionDropdown>
                      </ProtectedComponents>
                    </td>}
                  </tr>
                )
              )}
            </tbody>
          </table>
        </TableContainer>
      </StyledDashboardPage>
    </>
  );
};

export default EngineerListTable;
