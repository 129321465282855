import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FormTitleSection from "./FormTitleSection";
import { apiGet } from "../../../utils/apiHelpers";
import CustomLoader from "../atoms/CustomLoader";

const ViewInvoice = ({ invoiceId, handleModal }) => {
  const [invoiceDto, setInvoiceDto] = useState();
  const [invoiceItemDto, setInvoiceItemDto] = useState([]);
  const [accountDetailsDto, setAccountDetailsDto] = useState();
  const [subTotal, setSubTotal] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleViewInvoice = async () => {
    setIsLoading(true);
    try {
      const res = await apiGet(`/admin/invoices/${invoiceId}/details`);

      setSubTotal(res.data.data.displaySubTotal);
      setTotalAmount(res.data.data.displayTotal);
      setInvoiceDto(res.data.data.listInvoiceDto);
      setInvoiceItemDto(res.data.data.listInvoiceLineItemDtos);
      setAccountDetailsDto(res.data.data.accountDetailsDto);
      setDueDate(res.data.data.dueDate);
    } catch (e) {
      console.log("error", e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleViewInvoice();
    // eslint-disable-next-line
  }, []);

  return isLoading ? (
    <CustomLoader />
  ) : (
    <StyledViewInvoice>
      <FormTitleSection title="View Invoice" onClick={handleModal} />
      <div>
        <div className="invoice-intro mb-3 padding">
          <div className="invoice-intro-invoice">
            <div>
              <h3>Invoice</h3>
            </div>
            <div>
              <p className="intro-text">
                {`${
                  invoiceDto?.organisationName
                    ? invoiceDto?.organisationName
                    : ""
                } ${
                  invoiceDto?.organisationAddress
                    ? invoiceDto?.organisationAddress
                    : ""
                }`}
              </p>
            </div>
            <div>
              <p className="intro-text">
                {`${invoiceDto?.companyName ? invoiceDto?.companyName : ""} ${
                  invoiceDto?.companyAddress ? invoiceDto?.companyAddress : ""
                }`}
              </p>
            </div>
          </div>
          <div className="invoice-intro-logo">
            <div className="invoice-intro-log-img">
              <img
                className="img"
                src="/images/Decagon-logo.svg"
                alt="Decagon"
              />
            </div>
            <div className="invoice-intro-log-text">
              <div>
                <div>
                  <p className="p-faint">Invoice Number</p>
                  <p>{invoiceDto?.invoiceNumber}</p>
                </div>
                <div>
                  <p className="p-faint">Invoice Date</p>
                  <p>{invoiceDto?.invoiceDate}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="both-table">
          <div className="invoice-table padding">
            <table>
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Unit Price</th>
                  <th>Tax</th>
                  <th>Amount(USD)</th>
                </tr>
              </thead>
              <tbody>
                {invoiceItemDto.map((item, index) => (
                  <tr key={index}>
                    <td>{item?.description}</td>
                    <td>{item?.quantity}</td>
                    <td>{item?.displayUnitPrice}</td>
                    <td>{item?.taxType}</td>
                    <td>{item?.displayAmount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="invoice-table-total padding">
            <div style={{ width: "40%" }}>
              <table>
                <tr>
                  <td>Subtotal</td>
                  <td> {subTotal}</td>
                </tr>
                <tr>
                  <th>Total</th>
                  <th>{totalAmount}</th>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className="padding">
          <p className="due-date">Due Date: {dueDate ? dueDate : "-"}</p>
          <p className="due-date">
            Kindly make payment to the below Account details.
          </p>
        </div>
        <div className="account padding">
          <p className="acc">Account Name: {accountDetailsDto?.accountName}</p>
          <p className="acc">
            Account Number: {accountDetailsDto?.accountNumber}
          </p>
          <p className="acc">Bank Name: {accountDetailsDto?.bankName}</p>
          <p className="acc">
            Routing Number: {accountDetailsDto?.routingNumber}
          </p>
        </div>
      </div>
    </StyledViewInvoice>
  );
};

export default ViewInvoice;

const StyledViewInvoice = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: 100vh;
  min-width: 70%;
  background: #fff;
  padding: 2rem;
  overflow-y: auto;
  .padding {
    padding: 16px 12px;
  }
  .invoice-intro {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  .invoice-intro-invoice {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 100px;
  }
  h3 {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #21334f;
  }
  .intro-text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #21334f;
    text-transform: uppercase;
  }
  .invoice-intro-logo {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
  .img {
    width: 134px;
    height: 29.74px;
  }
  .invoice-intro-log-text {
    display: flex;
    justify-content: space-around;
  }
  p {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #21334f;
  }
  .p-faint {
    color: #c4c4c4;
  }
  .both-table {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    /* padding: 16px 12px; */
  }
  .invoice-table {
    /* padding: 16px 12px; */
    /* background: #ffffff; */
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
    width: 100%;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    grid-area: a;
    background: #fff;
  }
  tr {
    cursor: pointer;
  }

  th {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #21334f;
  }

  td {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    max-width: 200px;
    /* identical to box height */

    color: #21334f;
  }

  th,
  td {
    text-align: left;
    padding: 20px 8px;
    border-bottom: 1px solid #dfe8fc;
  }
  .due-date {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #21334f;
  }
  .invoice-table-total {
    display: flex;
    justify-content: flex-end;
    margin-top: -25px;
    width: 100%;
  }
  .account {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 261px;
    background: #ffffff;
  }
`;
