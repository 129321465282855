import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { toast } from "react-toastify";

const ProtectedRoutes = ({
  component: Component,
  role,
  permission,
  ...rest
}) => {
  const p =
    permission &&
    permission.length > 0 &&
    permission.reduce((data, perm) => {
      return { ...data, [perm]: true };
    }, {});

  const [acceptedPerm] = useState(p || {});

  const permRoles = localStorage?.getItem("roles");
  const permArr = permRoles?.split(",");
  const userType = localStorage.getItem("userType");

  return (
    <>
      {userType === "engineer" ? (
        <Route
          {...rest}
          render={(props) => {
            if (role !== "engineer") {
              return (
                <Redirect
                  to={{
                    pathname: "/engineer/login",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              );
            } else {
              return <Component {...props} />;
            }
          }}
        />
      ) : (
        <Route
          {...rest}
          render={(props) => {
            if (
              permRoles &&
              permArr?.some((perm) => acceptedPerm[perm]) &&
              role
            ) {
              return <Component {...props} />;
            } else {
              role &&
                toast.error("You don't have permission to access this page");
              return (
                <Redirect
                  to={{
                    pathname: role === "admin" ? "/admin/login" : "/login",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              );
            }
          }}
        />
      )}
    </>
  );
};

export default ProtectedRoutes;
