import React from "react";
import styled from "styled-components";

const TableContainer = ({ children }) => {
  return (
    <TableDiv className="table-wrapper table-responsive">{children}</TableDiv>
  );
};

const TableDiv = styled.div`
  .table-wrapper {
    white-space: nowrap;
  }

  table {
    border-collapse: separate;
    border-spacing: 0 25px;
    margin-bottom: 10px;
    white-space: nowrap;
    width: 100%;
  }

  thead {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),
      #fafffb;
    font-size: 16px;
    padding: 0 1rem 0 1rem;
  }


  td {
    border: none;
    font-size: 16px;
    padding: 15px 2rem;
  }

  th {
    border: none;
    font-size: 16px;
    padding: 0px 2rem;
  }

  tbody tr {
    margin-bottom: 5rem !important;
  }

  td {
    background: #fff;
  }

  .dots-btns {
    background-color: transparent;
    outline: none;
    border: none;
  }

  .dots {
    border-radius: 50%;
    width: 6px;
    height: 6px;
    background: black;
    display: inline-block;
    margin-right: 0.3rem;
  }
`;

export default TableContainer;
