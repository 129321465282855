import React, { useState } from "react";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
  useCreateLoanDetail,
  useSearchEngineers,
} from "../../../services/loanManagement/query";
import { getToken } from "../../../utils/helperFunctions";
import MyButton from "../../landingPage/MyButton";
import SelectedStack from "./SelectedStack";
import { ComboboxComponent } from "./enterpriseFormInputs/ComboboxComponent";
import FormTitleSection from "./FormTitleSection";

const SendJobRecommendation = ({ handleModal, refetch }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [nameSearch, setNameSearch] = useState(null);

  let config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-type": "application/json; charset=UTF-8",
    },
  };

  const { data } = useSearchEngineers(nameSearch);
  const { mutate, isLoading } = useCreateLoanDetail(config, (res) => {
    setIsSuccess(true);
    refetch();
    toast.success("You successfully added a Loan Detail.");
  });

  const deleteStack = (index) => {
    if (index > -1) {
      setSelectedOptions(selectedOptions.filter((value, i) => i !== index));
    }
  };
  // eslint-disable-next-line
  const handleSubmit = (e) => {
    e.preventDefault();
    mutate({});
  };

  return (
    <StyledSendJobRecommendation>
      <div className="title-header">
        <FormTitleSection title="Send Recommendations" onClick={handleModal} />
      </div>
      {isSuccess && (
        <div className="form-success card p-3">
          <img
            src="/images/sucess-icon.svg"
            alt="green-tick"
            className="sucess-icon"
          />
          <h2 className="form-font green-text">Success</h2>
          <p className="form-font success-msg">
            You successfully sent Recommendations.
          </p>
          <MyButton className="form-btn success-btn" onClick={handleModal}>
            Go to Job role listing
          </MyButton>
        </div>
      )}
      {!isSuccess && (
        <form className="form-wrapper">
          <ComboboxComponent
            options={data?.data}
            onSelect={(x) => {
              if (!selectedOptions.find((item) => item.id === x.id)) {
                setSelectedOptions([...selectedOptions, x]);
              }
              setNameSearch(null);
            }}
            placeholder="Search engineer by name"
            onChange={(e) => setNameSearch(e?.target?.value)}
          />
          <div className="mt-4 d-flex items-center flex-wrap">
            {selectedOptions &&
              selectedOptions.map((option, index) => {
                return (
                  <SelectedStack
                    key={index}
                    name={option.firstName + " " + option.lastName}
                    onClick={(e) => {
                      e.preventDefault();
                      deleteStack(index);
                    }}
                  />
                );
              })}
          </div>
          {selectedOptions.length > 0 && (
            <MyButton className="form-btn add-client-btn" type="submit" full>
              {isLoading ? (
                <ClipLoader color={"#fff"} size={"30px"} />
              ) : (
                "Send Recommendations"
              )}
            </MyButton>
          )}
        </form>
      )}
    </StyledSendJobRecommendation>
  );
};

const StyledSendJobRecommendation = styled.div`
  background: #fff;
  width: 572px;
  height: 100%;

  .disabled-field {
    background: #ccc !important;
    cursor: not-allowed;
  }

  .title-header {
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
    margin-bottom: 16px;
  }

  .form-wrapper {
    padding: 0 23px 30px;
    width: 100%;
    height: 90%;
    overflow-y: auto;
  }

  .form-success {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 32rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
    background: #34a853;
  }
`;

export default SendJobRecommendation;
