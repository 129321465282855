import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Layout from "../../common/Layout";
import { IoIosArrowForward } from "react-icons/io";
import DynamicTitle from "../../common/DynamicTitle";
import { useHistory } from "react-router-dom";
import { apiGet } from "../../../../utils/apiHelpers";
// import { toast } from "react-toastify";
import CustomLoader from "../../atoms/CustomLoader";

const ViewList = ({ invoiceId, closeModal }) => {
  // const handleViewInvoice = () => {
  // handleViewInvoice();
  // };
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchInvoice = async () => {
    setLoading(true);
    try {
      const res = await apiGet(`/private/invoices`);
      setInvoices(res.data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchInvoice();
  }, []);

  // console.log(invoices);
  // "companyAddress": "string",
  // "companyName": "string",
  // "displayTotalAmount": "string",
  // "id": 0,
  // "invoiceDate": "2022-06-28T08:54:43.033Z",
  // "invoiceNumber": "string",
  // "organisationAddress": "string",
  // "organisationName": "string",
  // "totalAmount": 0

  const history = useHistory();
  return (
    <Layout navType="client">
      <DynamicTitle title="Invoice" />
     {loading && (
          <CustomLoader />
        )}
      <StyledViewInvoice>
        <div className="invoice-header">
          <h3 className="invoice-text">All Invoices</h3>
        </div>
        
          {invoices && invoices.length > 0 ? (
            invoices.map((invoice, index) => (
              <div key={index} className="invoice-intro-logo">
                <div className="invoice-intro-log-img">
                  <p>{invoice?.organisationAddress}</p>
                </div>
                <div className="invoice-intro-log-text">
                  <div>
                    <p className="p-faint">Invoice Number</p>
                    <p>{invoice?.invoiceNumber}</p>
                  </div>
                  <div>
                    <p className="p-faint">Invoice Date</p>
                    <p>{invoice?.invoiceDate}</p>
                  </div>
                </div>
                <div>
                  <p className="">
                   {invoice?.companyAddress}
                  </p>
                </div>
                <div className="arrow">
                  <IoIosArrowForward
                    onClick={() => history.push(`/client/invoice/detail/${invoice?.id}`)}
                    style={{
                      fontSize: "30px",
                      marginTop: "30px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              </div>
            ))
          ) : (
            <p>No Invoices to display yet</p>
          )}
      </StyledViewInvoice>
    </Layout>
  );
};

export default ViewList;

const StyledViewInvoice = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
  h3 {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;

    color: #21334f;
  }
  .invoice-intro-logo {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    background: #fff;
    gap: 20px;
    padding: 20px;
  }
  .invoice-intro-log-text {
    display: flex;
    flex-direction: column;
  }
  p {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #21334f;
  }
  .p-faint {
    color: #c4c4c4;
  }
  .arrow {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  .invoice-header {
    display: flex;
    justify-content: flex-start;
  }
  .invoice-text {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;

    color: #21334f;
  }
`;
