import {
  fetchIndustries,
  fetchSingleIndustry,
  createIndustry,
  editIndustry,
} from "./api";
import { useQuery, useMutation } from "react-query";

export const useFetchedIndustryList = () => {
  const query = useQuery(["industry-list"], () => fetchIndustries());

  return query;
};

export const useFetchedSingleIndustry = (id) => {
  const query = useQuery([id, "single-industry"], fetchSingleIndustry, {
    enabled: id ? true : false,
  });

  return query;
};

export const useCreateIndustry = () => {
  const mutation = useMutation((params) => createIndustry(params));

  return mutation;
};

export const useEditIndustry = () => {
  const mutation = useMutation((params) => editIndustry(params, {}));
  return mutation;
};
