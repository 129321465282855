import React, { useState } from "react";
import Layout from "../common/Layout";
import DynamicTitle from "../common/DynamicTitle";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import TableContainer from "../common/TableContainer";
import ActionDropdown from "../common/ActionDropdown";
import FormModal from "../form/FormModal";
import useDialog from "../../../utils/useDialog";
import ProtectedComponents from "../local-routes/ProtectedComponents";
import CreateTechstack from "../form/CreateTechstack";
import UpdateTechstackForm from "../form/UpdateTechstackForm";
import { useFetchedTechstackList } from "../../../services/techstack/query";
import { deleteTechstack } from "../../../services/techstack/api";

const TechstackListing = ({ pageType }) => { 
  const { deleteItemDialog } = useDialog();

  const { data: techstackList, refetch, isLoading } = useFetchedTechstackList();

  const [techstackId, setTechstackId] = useState(null);
  const [techstackName, setTechstackName] = useState(null);

  const [addTechstackModal, setAddTechstackModal] = useState(false);

  const [editTechstackModal, setEditTechstackModal] = useState(false);

  const handleAddTechstackModal = (e) => {
    setAddTechstackModal(!addTechstackModal);
  };

  const handleEditTechstack = (e) => {
    setEditTechstackModal(!editTechstackModal);
  };

  const handleDelete = (id, name) => {
    deleteItemDialog(deleteTechstack, id, name, refetch);
  };

  return (
    <Layout pageLoading={isLoading} navType="admin">
      <DynamicTitle title="Techstack Page | Fellowship Product" />

      <TechstackLayout>
        <div className="techstack-header">
          <h1>Techstack</h1>
          <ProtectedComponents permission={["manageTechstackSetup"]}>
            <MyButton
              onClick={() => handleAddTechstackModal()}
              className="btn-doc"
            >
              Add Techstack
            </MyButton>
          </ProtectedComponents>
        </div>
        {
          <TableContainer>
            <table style={{ width: "100%" }}>
              <thead style={{ width: "100%" }}>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <ProtectedComponents permission={["manageTechstackSetup"]}>
                    <th>Action</th>
                  </ProtectedComponents>
                </tr>
              </thead>
              <tbody style={{ width: "100%" }}>
                {techstackList?.map(({ id, name }, index) => (
                  <tr className="" key={index + 1}>
                    <td>{id}</td>
                    <td>{name}</td>
                    <ProtectedComponents permission={["manageTechstackSetup"]}>
                      <td>
                        <ActionDropdown>
                          <p
                            onClick={() => {
                              handleEditTechstack();
                              setTechstackId(id);
                              setTechstackName(name);
                            }}
                          >
                            Edit
                          </p>
                          <p
                            onClick={() => {
                              handleDelete(id, name);
                            }}
                          >
                            Delete
                          </p>
                        </ActionDropdown>
                      </td>
                    </ProtectedComponents>
                  </tr>
                ))}
                {techstackList?.length === 0 && (
                  <tr>
                    <td colSpan="6" className="no-data">
                      No Data Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </TableContainer>
        }

        {addTechstackModal && (
          <div className="form-modal">
            <FormModal className="form" handleModal={handleAddTechstackModal}>
              {
                <CreateTechstack
                  handleModal={handleAddTechstackModal}
                  refetch={refetch}
                />
              }
            </FormModal>
          </div>
        )}
        {editTechstackModal && (
          <div className="form-modal">
            <FormModal className="form" handleModal={handleEditTechstack}>
              {
                <UpdateTechstackForm
                  handleModal={handleEditTechstack}
                  refetch={refetch}
                  techstackId={techstackId}
                  techstackName={techstackName}
                />
              }
            </FormModal>
          </div>
        )}
      </TechstackLayout>
    </Layout>
  );
};

const TechstackLayout = styled.div`
  .techstack-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 4.8rem;
    margin-bottom: 1.1rem;
  }
  .techstack-header h1 {
    font-weight: 700;
    font-size: 2.4rem;
  }
  .form-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
  }
`;

export default TechstackListing;
