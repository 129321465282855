import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import styled from "styled-components";
import { useChooseForMeAndSelectEngineer } from "../../../../../services/activity/query";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      align: "start",
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
};

const ChooseEngineerForMeAndSelectEngineerMultiLines = ({
  orgId,
  startDate,
  endDate,
}) => {
  const { data: apiData } = useChooseForMeAndSelectEngineer(
    startDate,
    endDate,
    orgId
  );

  const allRequiredData = apiData?.data;

  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const sortByMonthShortName = (arr) => {
    return arr.sort((a, b) => {
      const monthA = labels.indexOf(a.period);
      const monthB = labels.indexOf(b.period);
      return monthA - monthB;
    });
  };
  const chooseEngineerForMeRequestTracking = sortByMonthShortName(
    allRequiredData?.chooseEngineerForMereQuesTracking || []
  );

  const selectEngineerRequestTracking = sortByMonthShortName(
    allRequiredData?.selectEngineerRequesTracking || []
  );

  const data = {
    labels,
    datasets: [
      {
        label: "Choose Engineer For Me",
        data: chooseEngineerForMeRequestTracking.map((data) => data.count),
        borderColor: "#F04438",
        backgroundColor: "#F04438",
      },
      {
        label: "Select Engineers",
        data: selectEngineerRequestTracking.map((data) => data.count),
        borderColor: "blue",
        backgroundColor: "blue",
      },
    ],
  };
  return (
    <StyleDiv>
      <div className="header">
        <p className="">Choose Engineer For Me / Select Engineers</p>
      </div>
      <div className="content">
        <Line options={options} data={data} />
      </div>
    </StyleDiv>
  );
};

const StyleDiv = styled.div`
  background: #fff;
  width: 100%;

  overflow: auto;
  border: 1px solid rgba(33, 51, 79, 0.1);

  .header {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 23px;
    padding: 10px 10px 0px;
  }

  .content {
    padding: 2rem;
  }
`;

export default ChooseEngineerForMeAndSelectEngineerMultiLines;
