import {
  fetchFrameworks,
  fetchLocations,
  fetchSquads,
  fetchTechstacks,
  fetchWeeks,
} from "./api";
import { useQuery } from "react-query";

export const useFetchedTechstacks = () => {
  const { data, isLoading, refetch } = useQuery("techstacks-ref", fetchTechstacks);

  return { data, isLoading, refetch };
};

export const useFetchedFrameworks = () => {
  const { data, isLoading, refetch, isRefetching, isFetching, status } =
    useQuery(["frameworks-ref"], () => fetchFrameworks());

  return { data, isLoading, refetch, isRefetching, isFetching, status };
};

export const useFetchedSquads = () => {
  const { data, isLoading, refetch } = useQuery("squads-ref", fetchSquads);

  return { data, isLoading, refetch };
};

export const useFetchedWeeks = (squadId) => {
  const { data, isLoading, refetch } = useQuery(
    [squadId, "weeks"],
    fetchWeeks,
    {
      enabled: squadId ? true : false,
    }
  );

  return { data, isLoading, refetch };
};

export const useFetchedLocations = (locType) => {
  const { data, isLoading, refetch } = useQuery(
    [locType, "locations-ref"],
    fetchLocations
  );

  return { data, isLoading, refetch };
};
