import React, { useState } from "react";
import Layout from "../common/Layout";
import DynamicTitle from "../common/DynamicTitle";
import TableContainer from "../common/TableContainer";
import { useLocation } from "react-router-dom";
import Pagination from "../atoms/Pagination";
import TotalElements from "../common/TotalElements";
import { useHistory } from "react-router-dom";
import { removeEmptyObj } from "../../../utils/helperFunctions";
import { StyledDashboardPage } from "../styles/styleDashboard";
import { useSubscriptionList } from "../../../services/subscription/query";
import SubscriptionFilter from "../filterFields/SubscriptionFilter";
import {StatusBtn} from "../styles/statusBtn"

const SubscriptionPage = ({ pageType }) => {
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(window.location.search);

  const [selectedFilter, setSelectedFilter] = useState("");
  const initialValues = {
    page: Number(queryParams.get("page")) || 1,
    size: 12,
    search: "",
    status: "",
  };
  const [filterOptions, setFilterOptions] = useState(initialValues);
  const [paramsValues, setParamsValues] = useState(
    removeEmptyObj(initialValues, "size") || {}
  );

  const reset = () => {
    setSelectedFilter("");
  };

  const {
    data: subscriptionList,
    isFetched,
    isLoading,
  } = useSubscriptionList(filterOptions, reset);

  let timer;

  const handleFilterChange = (name, value) => {
    if (name === "search") {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setFilterOptions({ ...filterOptions, page: 1, [name]: value });
        setParamsValues({ ...paramsValues, [name]: value });
        setSelectedFilter(name);

        const params = new URLSearchParams({ ...paramsValues, [name]: value });
        history.push({
          pathname: location.pathname,
          search: params.toString(),
        });
      }, 1000);
    } else {
      setFilterOptions({ ...filterOptions, page: 1, [name]: value });
      setParamsValues({ ...paramsValues, [name]: value });
      setSelectedFilter(name);
      const params = new URLSearchParams({ ...paramsValues, [name]: value });
      history.push({
        pathname: location.pathname,
        search: params.toString(),
      });
    }
  };

  const increment = () => {
    setParamsValues({ ...paramsValues, page: paramsValues.page + 1 });
    setFilterOptions({ ...filterOptions, page: filterOptions.page + 1 });

    const params = new URLSearchParams({
      ...paramsValues,
      page: paramsValues.page + 1,
    });
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const decrement = () => {
    setParamsValues({ ...paramsValues, page: paramsValues.page - 1 });
    setFilterOptions({ ...filterOptions, page: filterOptions.page - 1 });

    const params = new URLSearchParams({
      ...paramsValues,
      page: paramsValues.page - 1,
    });
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const newPage = (n) => {
    setParamsValues({ ...paramsValues, page: n });
    setFilterOptions({ ...filterOptions, page: n });

    const params = new URLSearchParams({ ...paramsValues, page: n });
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  return (
    <Layout navType="admin" pageLoading={isLoading}>
      <DynamicTitle title="Subscriptions | Fellowship Product" />
      <StyledDashboardPage>
        <div className="page-header">
          <h4>Subscriptions</h4>
        </div>
        <SubscriptionFilter
          handleFilterChange={handleFilterChange}
          filterOptions={filterOptions}
          selectedFilter={selectedFilter}
        />
        {(filterOptions.search || filterOptions.status) &&
          subscriptionList?.data?.totalElements > 0 &&
          isFetched && (
            <TotalElements
              element="subscription"
              totalElements={subscriptionList?.data?.totalElements}
            />
          )}
        <TableContainer>
          <table style={{ width: "100%" }}>
            <thead style={{ width: "100%" }}>
              <tr>
                <th>Organisation</th>
                <th>Package</th>
                <th>Start Date</th>
                <th>Expiry Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody style={{ width: "100%" }}>
              {subscriptionList?.data?.content.map((item, index) => (
                <tr className="" key={index}>
                  <td>{item.organisation}</td>
                  <td>{item.packageName}</td>
                  <td>{item.startDate}</td>
                  <td>{item.expireDate}</td>
                  <td>
                    {item.status ? (
                      <StatusBtn
                        className="status-bg"
                        status={item.status?.toLowerCase()}
                      >
                        {item.status}
                      </StatusBtn>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
        {subscriptionList && (
          <div className="page-parent">
            <Pagination
              currentPage={subscriptionList?.data.number + 1}
              totalClients={subscriptionList?.data.numberOfElements}
              metaData={subscriptionList?.data}
              page={subscriptionList?.data.number + 1}
              newPage={newPage}
              increment={increment}
              decrement={decrement}
              className="pagination"
            />
          </div>
        )}
      </StyledDashboardPage>
    </Layout>
  );
};

export default SubscriptionPage;
