import { useQuery, useMutation } from "react-query";
import {
  activeLoans,
  completedLoan,
  createLoanDetail,
  decadevLoan,
  defaultingLoans,
  getLoanDashboardCards,
  listDecadevLoan,
  listLoanActivityTracking,
  listLoanDefaulters,
  moratoriumLoans,
  nonDefaultingLoans,
  searchAllEngineersByName,
  servicedByDecagon,
  updateLoanDetail,
} from "./api";

export const useSearchEngineers = (search) => {
  const query = useQuery(
    [search, "search-engineers"],
    searchAllEngineersByName,
    {
      enabled: search?.length > 1 ? true : false,
    }
  );

  return query;
};

export const useDecadevLoanList = (filterOptions) => {
  const query = useQuery([filterOptions, "list-decadev-loan"], listDecadevLoan, {
    keepPreviousData: true,
  });

  return query;
};

export const useDecadevLoan = (loanId) => {
  const query = useQuery([loanId, "decadev-loan"], decadevLoan, {
    cacheTime: 0
  });

  return query;
};

export const useLoanDefaulters = (filterOptions) => {
  const query = useQuery([filterOptions, "list-loan-defaulter"], listLoanDefaulters, {
    keepPreviousData: true,
  });

  return query;
};

export const useLoanActivityTracking = (filterOptions) => {
  const query = useQuery([filterOptions, "list-loan-defaulter"], listLoanActivityTracking, {
    keepPreviousData: true,
  });

  return query;
};

export const useLoanDashboardCard = (filterOptions) => {

  const query = useQuery([filterOptions, "loan-dashboard"], getLoanDashboardCards, {
    keepPreviousData: true,
  });

  return query;
};

export const useCreateLoanDetail = (config, onCreateSuccess) => {
  const mutation = useMutation((params) => createLoanDetail(params, config), {
    onSuccess: () => onCreateSuccess(),
  });

  return mutation;
};

export const useUpdateLoanDetail = (config, onUpdateSuccess) => {
  const mutation = useMutation((params) => updateLoanDetail(params, config), {
    onSuccess: () => onUpdateSuccess(),
  });

  return mutation;
};

export const useCompletedLoan = (filterOptions) => {

  const query = useQuery([filterOptions, "completed-loan"], completedLoan, {
    keepPreviousData: true,
  });

  return query;
};

export const useLoansInMoratorium = (filterOptions) => {

  const query = useQuery([filterOptions, "moratorium-loan"], moratoriumLoans, {
    keepPreviousData: true,
  });

  return query;
};

export const useLoansActive = (filterOptions) => {

  const query = useQuery([filterOptions, "active-loan"], activeLoans, {
    keepPreviousData: true,
  });

  return query;
};

export const useDefaultingLoans = (filterOptions) => {

  const query = useQuery([filterOptions, "defaulting-loan"], defaultingLoans, {
    keepPreviousData: true,
  });

  return query;
};

export const useNonDefaultingLoans = (filterOptions) => {

  const query = useQuery([filterOptions, "non-defaulting-loan"], nonDefaultingLoans, {
    keepPreviousData: true,
  });

  return query;
};

export const useLoansServicedByDecagon = (filterOptions) => {

  const query = useQuery([filterOptions, "loans-serviced-by-decagon"], servicedByDecagon, {
    keepPreviousData: true,
  });

  return query;
};
