import React, { useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import CircleLoader from "react-spinners/CircleLoader";

const options = [
  { value: "", label: "All" },
  { value: "TODAY", label: "Today" },
  { value: "YESTERDAY", label: "Yesterday" },
  { value: "LAST7DAYS", label: "Last 7 Days" },
  { value: "LAST30DAYS", label: "Last 30 Days" },
  { value: "LAST3MONTHS", label: "Last 3 Months" },
  { value: "THISMONTH", label: "This Month" },
];

const customStyles = {
  indicatorSeparator: (styles) => ({ display: "none" }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "rgba(33,51,79,0.8)",
      fontSize: "1rem",
      fontWeight: "700",
    };
  },
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? '1px solid #34A853' : '1px solid rgba(0,0,0,0.2)',
    boxShadow: 'none',
    '&:hover': {
        border: '1px solid #34A853',
        cursor: "pointer",
    }
}),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? " #34A853" : "#fff",
      color: isFocused ? "#fff" : "#263238",
      fontSize: "1rem",
      cursor: isDisabled ? "not-allowed" : "pointer",
      padding: "5px",
    };
  },
};

export default function ActivityDropdown({
  header,
  currentState,
  setCurrentState,
  setPeriod,
  isLoading,
}) {
  const [placeholder, setPlaceholder] = useState("All");

  const handleChange = (e) => {
    setPlaceholder(e.label);
    setCurrentState(e.value);
    setPeriod(e.value);
  };

  return (
    <Wrapper>
      <div>{header}</div>
      <Select
        className="my-select"
        options={options}
        styles={customStyles}
        placeholder={placeholder}
        onChange={(e) => handleChange(e)}
      />

      {isLoading && (
        <div className="input-spinner-select">
          <CircleLoader color="green" size="10px" />
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;

  & .my-select {
    font-size: 1rem;
    border: none;
    outline: none;
    color: #21334f;
    width: 100px;
  }

  & .input-spinner-select {
    position: absolute;
    right: calc(0% + 25px);
    top: 45%;
  }

  & .opener {
    padding: 5px 5px 5px 2px;
    border: 1px solid #f1f1f1;
    width: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    cursor: pointer;

    & p {
      margin: 0;
      padding: 0;
    }
  }
`;

// import React, { useRef, useState, useEffect } from "react";
// import styled from "styled-components";
// import { IoIosArrowDown } from "react-icons/io";
// import { IconContext } from "react-icons";

// const options = {
//   All: "",
//   Today: "TODAY",
//   Yesterday: "YESTERDAY",
//   "Last 7 Days": "LAST7DAYS",
//   "Last 30 Days": "LAST30DAYS",
//   "Last 3 Months": "LAST3MONTHS",
//   "This Month": "THISMONTH",
// };

// export default function ActivityDropdown({
//   header,
//   currentState,
//   setCurrentState,
//   setPeriod
// }) {
//   const [showDropdown, setShowDropDown] = useState(false);
//   const container = useRef(null);

//   const outsideClick = (e) => {
//     if (container.current?.contains(e.target)) {
//       // inside click
//       return;
//     }
//     setShowDropDown(false);
//   };

//   useEffect(() => {
//     // add event when component is mounted
//     document.addEventListener("mousedown", outsideClick);

//     // call function when unmounted
//     return () => {
//       document.removeEventListener("mousedown", outsideClick);
//     };
//   }, []);

//   const handleClick = (option) => {
//     const current = options[option];
//     setCurrentState(option);
//     setPeriod(current)
//   };
//   return (
//     <Wrapper>
//       <div>{header}</div>
//       <div
//         className="opener"
//         ref={container}
//         onClick={() => setShowDropDown(!showDropdown)}
//       >
//         <p>
//           {currentState &&
//             (currentState.length > 7
//               ? currentState.substring(0, 7) + "..."
//               : currentState)}
//         </p>
//         <div>
//           <IconContext.Provider value={{ size: "15px" }}>
//             <IoIosArrowDown
//               onClick={() => setShowDropDown(!showDropdown)}
//               style={{ cursor: "pointer" }}
//             />
//           </IconContext.Provider>
//         </div>
//       </div>
//       <DropWrapper visible={showDropdown}>
//         <DropdownStyles>
//           <ul>
//             {Object.keys(options).map((option, i) => (
//               <List
//                 option={option}
//                 active={currentState === option}
//                 key={i}
//                 handleClick={handleClick}
//                 currentState={currentState}
//               />
//             ))}
//           </ul>
//         </DropdownStyles>
//       </DropWrapper>
//     </Wrapper>
//   );
// }

// const List = ({ active, handleClick, option }) => {
//   return (
//     <ListWrapper
//       className={active ? "active" : ""}
//       onClick={() => handleClick(option)}
//     >
//       <p>{option}</p>
//     </ListWrapper>
//   );
// };

// const ListWrapper = styled.li`
//   display: flex;
//   align-items: center;
//   & p {
//     margin: 0;
//   }
//   & div {
//     margin-left: 100%;
//   }
// `;

// const Wrapper = styled.div`
//   width: 100%;
//   height: 100%;
//   position: relative;
//   box-sizing: border-box;
//   display: flex;
//   justify-content: space-between;
//   padding: 10px;
//   align-items: center;

//   & .opener {
//     padding: 5px 5px 5px 2px;
//     border: 1px solid #f1f1f1;
//     width: 80px;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     font-family: "Mulish";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 12px;
//     line-height: 15px;
//     cursor: pointer;

//     & p {
//       margin: 0;
//       padding: 0;
//     }
//   }
// `;

// const DropWrapper = styled.div`
//   position: absolute;
//   right: 20px;
//   top: 50px;
//   border-radius: 5px;
//   background-color: #f0f5f1;
//   transform: ${(props) => (props.visible ? "scale(1)" : "scale(0.0)")};
//   transition: transform 300ms;
//   z-index: 100000 !important;
//   width: 150px;
//   height: 200px;
//   box-shadow: rgba(100, 100, 111, 0.2) 0px 3px 10px 0px;
//   font-family: "Mulish";
//   font-style: normal;
//   font-weight: 400;
//   font-size: 12px;
//   line-height: 15px;

//   &:before {
//     content: "";
//     display: block;
//     width: 0;
//     height: 0;
//     position: absolute;
//     border-top: 12px solid transparent;
//     border-bottom: 12px solid #f0f5f1;
//     border-right: 12px solid transparent;
//     border-left: 12px solid transparent;
//     right: 0px;
//     top: -22px;
//   }
// `;

// const DropdownStyles = styled.div`
//   /* box-shadow: rgba(100, 100, 111, 0.2) 0px 3px 10px 0px; */
//   border-radius: 5px;
//   height: 100%;
//   width: 100%;
//   & ul {
//     list-style: none;
//     padding: 0;
//     margin: 0;
//     background: transparent;
//   }

//   & ul li {
//     padding: 5px 10px;
//     display: grid;
//     align-items: center;
//     background: transparent;
//   }

//   & ul li:hover {
//     cursor: pointer;
//     background: #f0f5f1;
//     filter: brightness(90%);
//   }

//   & ul li.active {
//     background: #f0f5f1 !important;
//     filter: brightness(90%);
//     border: none;
//     padding: 5px 10px;
//   }
// `;
