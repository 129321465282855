import React from 'react'
import {BounceLoader} from "react-spinners";
import styled from "styled-components";
import { css } from "@emotion/core";


const override = css`
  display: flex;
  span {
      background: green;
  }
`;

const Wrap = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

const CustomLoader = () => {
    return (
        <Wrap>
            <BounceLoader size={200} css={override}/>  
        </Wrap>
    )
}

export default CustomLoader
