import React, { useState } from "react";
import styled from "styled-components";
import Layout from "../common/Layout";
import { Container } from "react-bootstrap";
import SettingsRender from '../admin/SettingsView/SettingsRender';
import DynamicTitle from "../common/DynamicTitle";

const SettingsPage = () => {
  const [activeModal, ] = useState(false);

  return (
    <Layout navType="admin" hasBackground>
      <DynamicTitle title="Settings | Fellowship Product" />
      <StyledDashboardPage activeModal={activeModal}>
        <Container>
          <SettingsRender pageType="admin"/>
        </Container>
      </StyledDashboardPage>
    </Layout>
  );
};

const StyledDashboardPage = styled.div``;

export default SettingsPage;
