import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteChooseEngineerRequest,
  deleteRequestLineItem,
  deleteTechStackRequest,
  fetchChooseEngineerLineItems,
  fetchClientEngineers,
  fetchClientTechStackRequests,
  fetchPlacementRequests,
} from "./api";

export const useFetchedClientTechStackRequests = (filterOptions) => {
  const query = useQuery([filterOptions, "client-placement-req"], () =>
    fetchClientTechStackRequests(filterOptions)
  );

  return query;
};

export const usePlacementRequests = (filterOptions) => {
  const query = useQuery([filterOptions, "client-placement-requests"], () =>
    fetchPlacementRequests(filterOptions)
  );

  return query;
};

export const useChooseEngineersLineItems = (requestId) => {
  const query = useQuery(
    [requestId, "choose-engineer-lineitems"],
    (params) => fetchChooseEngineerLineItems(params),
    {
      cacheTime: 0,
    }
  );

  return query;
};

export const useDeleteTechStackEngineerRequests = (filterOptions) => {
  const queryClient = useQueryClient();
  const mutation = useMutation((params) => deleteTechStackRequest(params), {
    onSuccess: () => {
      queryClient.invalidateQueries([filterOptions, "client-placement-req"]);
    },
  });
  return mutation;
};

export const useDeleteChooseEngineerRequests = (filterOptions) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (params) => deleteChooseEngineerRequest(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          filterOptions,
          "client-placement-requests",
        ]);
      },
    }
  );
  return mutation;
};

export const useDeleteRequestLineItem = (requestId) => {
  const queryClient = useQueryClient();
  const mutation = useMutation((params) => deleteRequestLineItem(params), {
    onSuccess: () => {
      queryClient.invalidateQueries([requestId, "choose-engineer-lineitems"]);
    },
  });
  return mutation;
};

export const useFetchClientEngineeers = (filterOptions) => {
  const query = useQuery([filterOptions, "client-engineers"], fetchClientEngineers);

  return query;
};
