import React, { useState } from "react";
import { adminEngineerView } from "../common/listingData";
import Layout from "../common/Layout";
import DynamicTitle from "../common/DynamicTitle";
import ProtectedComponents from "../local-routes/ProtectedComponents";
import MyButton from "../../landingPage/MyButton";
import {
  downloadProfiles,
} from "../../../services/engineer/api";
import FormModal from "../form/FormModal";
import AddEngForm from "../form/AddEngForm";
import AddBulkEngForm from "../form/AddBulkEngForm";
import {
  useFetchedEngineers,
  useToggleAllVisibility,
  useToggleVisibility,
} from "../../../services/engineer/query";
import { useLocation } from "react-router-dom";
import EditEngForm from "../form/EditEngForm";
import AssignEngForm from "../form/AssignEngForm";
import Pagination from "../atoms/Pagination";
import TotalElements from "../common/TotalElements";
import EngineersFilter from "../filterFields/EngineersFilter";
import { useHistory } from "react-router-dom";
import { removeEmptyObj } from "../../../utils/helperFunctions";
import { StyledDashboardPage } from "../../enterprise/styles/styleDashboard"
import EngineerListTable from "../admin/EngineerListTable";

const AdminEngineerPage = ({ pageType }) => {
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(window.location.search);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState("");
  const [engId, setEngId] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [engineerIds, setEngineerIds] = useState([]);
  const [isAllEngSelected, setIsAllEngSelected] = useState(false);
  const initialValues = {
    page: Number(queryParams.get("page")) || 1,
    size: 12,
    keyword: "",
    language: Number(queryParams.get("language") || "") || "",
    engStatus: queryParams.get("engStatus") || "",
    engineerType: queryParams.get("engineerType") || "",
    assignTyp: queryParams.get("assignTyp") || "",
    squad: Number(queryParams.get("squad") || "") || "",
    gender: queryParams.get("gender") || "",
    currentLocation: Number(queryParams.get("currentLocation")) || "",
    trainingLocation: Number(queryParams.get("trainingLocation")) || "",
    minAge: queryParams.get("minAge") || "",
    maxAge: queryParams.get("maxAge") || "",
    visibility: queryParams.get("visibility") || "",
    videoCVAvailability: queryParams.get("videoCVAvailability") || ""
  };
  const [filterOptions, setFilterOptions] = useState(initialValues);
  const [paramsValues, setParamsValues] = useState(
    removeEmptyObj(initialValues, "size") || {}
  );

  const reset = () => {
    setSelectedFilter("");
  };

  const {
    data: engineerList,
    refetch: refetchEngineers,
    isFetched,
    isLoading,
  } = useFetchedEngineers(filterOptions, reset);

  const onToggleSuccess = () => {
    setEngineerIds([]);
    setIsAllEngSelected(false);
    refetchEngineers();
  };

  const { mutate } = useToggleVisibility(onToggleSuccess);
  const { mutate: toggleAllVisibility } = useToggleAllVisibility(
    { ...filterOptions, size: engineerList?.data?.totalElements },
    onToggleSuccess
  );

  const handleModal = (id) => {
    setIsModalOpen(!isModalOpen);
    id && setEngId(id);
  };

  let timer;

  const handleFilterChange = (name, value) => {
    setParamsValues({ ...paramsValues, [name]: value });
    setSelectedFilter(name);
    setEngineerIds([]);
    setIsAllEngSelected(false);
    if (name === "keyword") {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setFilterOptions({ ...filterOptions, page: 1, [name]: value });

        const params = new URLSearchParams({ ...paramsValues, [name]: value });
        history.push({
          pathname: location.pathname,
          search: params.toString(),
        });
      }, 1000);
    } else {
      setFilterOptions({ ...filterOptions, page: 1, [name]: value });
      const params = new URLSearchParams({ ...paramsValues, [name]: value });
      history.push({
        pathname: location.pathname,
        search: params.toString(),
      });
    }
  };

  const increment = () => {
    setParamsValues({ ...paramsValues, page: paramsValues.page + 1 });
    setFilterOptions({ ...filterOptions, page: filterOptions.page + 1 });

    const params = new URLSearchParams({
      ...paramsValues,
      page: paramsValues.page + 1,
    });
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const decrement = () => {
    setParamsValues({ ...paramsValues, page: paramsValues.page - 1 });
    setFilterOptions({ ...filterOptions, page: filterOptions.page - 1 });

    const params = new URLSearchParams({
      ...paramsValues,
      page: paramsValues.page - 1,
    });
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const newPage = (n) => {
    setParamsValues({ ...paramsValues, page: n });
    setFilterOptions({ ...filterOptions, page: n });

    const params = new URLSearchParams({ ...paramsValues, page: n });
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const restore = () => {
    setFilterOptions({
      page: 1,
      size: 12,
      keyword: "",
      language: "",
      engStatus: "",
      engineerType: "",
      assignTyp: "",
      squad: "",
      gender: "",
      currentLocation: "",
      trainingLocation: "",
      minAge: "",
      maxAge: "",
      visibility: "",
    });
    setParamsValues({});

    history.push({
      pathname: location.pathname,
    });
  };

  return (
    <Layout navType="admin" pageLoading={isLoading}>
      {/* <RateAndReviewForm /> */}
      <DynamicTitle title="Engineers | Fellowship Product" />
      <StyledDashboardPage isModalOpen={isModalOpen}>
        <div className="page-header">
          <h4>{adminEngineerView.title || "Engineers"}</h4>
          <ProtectedComponents permission={["manageEngineer"]}>
            <div className="engineers-div">
              <MyButton
                transparent
                onClick={() => {
                  handleModal();
                  setActiveModal("eng");
                }}
                className="btn-comp">
                {adminEngineerView.btnInfo || "Add New Engineer"}
              </MyButton>
              <MyButton
                transparent
                onClick={() => {
                  handleModal();
                  setActiveModal("eng-bulk");
                }}
                className="btn-comp">
                {"Add Bulk Engineer"}
              </MyButton>
              <MyButton onClick={() => downloadProfiles()} className="btn-comp">
                {"Download Profiles"}
              </MyButton>
            </div>
          </ProtectedComponents>
        </div>
        <EngineersFilter
          handleFilterChange={handleFilterChange}
          filterOptions={filterOptions}
          restore={restore}
          selectedFilter={selectedFilter}
        />
        {(filterOptions.keyword ||
          filterOptions.age ||
          filterOptions.assignTyp ||
          filterOptions.currentLocation ||
          filterOptions.engStatus ||
          filterOptions.engineerType ||
          filterOptions.gender ||
          filterOptions.language ||
          filterOptions.squad ||
          filterOptions.visibility ||
          filterOptions.videoCVAvailability) &&
          engineerList?.data?.totalElements > 0 &&
          isFetched && (
            <TotalElements
              element="engineer"
              totalElements={engineerList?.data?.totalElements}
            />
          )}
        {engineerIds.length > 0 && (
          <div className="multi-action">
            <button
              onClick={() => {
                if (isAllEngSelected) {
                  toggleAllVisibility();
                  return;
                }
                mutate(engineerIds);
              }}>
              Toggle Visibility
            </button>
          </div>
        )}
        {engineerIds.length > 0 &&
          engineerList?.data.totalElements > filterOptions.size && (
            <div className="toogle-all-text">
              <span>
                All {engineerList?.data.size} Engineers on this page are
                selected.{" "}
              </span>
              {isAllEngSelected ? (
                <button
                  onClick={() => {
                    setEngineerIds([]);
                    setIsAllEngSelected(false);
                  }}>
                  Clear Selection
                </button>
              ) : (
                <button onClick={() => setIsAllEngSelected(true)}>
                  Select all {engineerList?.data.totalElements} engineers in the
                  System
                </button>
              )}
            </div>
          )}
        <EngineerListTable 
          pageType="engineer_list"
          engineerList={engineerList?.data.content}
          refetchEngineers={refetchEngineers}
          isModalOpen={isModalOpen}
          setActiveModal={setActiveModal} 
          handleModal={handleModal} 
          setIsAllEngSelected={setIsAllEngSelected}
          setEngineerIds={setEngineerIds}
          engineerIds={engineerIds}
          filterOptions={filterOptions}
        />
        {engineerList && (
          <div className="page-parent">
            <Pagination
              currentPage={engineerList?.data.number + 1}
              totalClients={engineerList?.data.numberOfElements}
              metaData={engineerList?.data}
              page={engineerList?.data.number + 1}
              newPage={newPage}
              increment={increment}
              decrement={decrement}
              className="pagination"
            />
          </div>
        )}
      </StyledDashboardPage>
      {isModalOpen && (
        <div className="form-modal">
          <FormModal handleModal={handleModal}>
            {activeModal === "eng" && (
              <AddEngForm onClick={handleModal} reload={refetchEngineers} />
            )}
            {activeModal === "eng-bulk" && (
              <AddBulkEngForm onClick={handleModal} reload={refetchEngineers} />
            )}
            {activeModal === "edit-eng" && (
              <EditEngForm
                engId={engId}
                handleEngModal={handleModal}
                reload={refetchEngineers}
              />
            )}
            {activeModal === "assign-eng" && (
              <AssignEngForm
                onClick={handleModal}
                engId={engId}
                singleEng={true}
                reload={refetchEngineers}
              />
            )}
          </FormModal>
        </div>
      )}
    </Layout>
  );
};

export default AdminEngineerPage;
