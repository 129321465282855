import { useOrganisationSubscriptions } from "../../../../services/organisation/query";
import TableContainer from "../../common/TableContainer";
import ActionDropdown from "../../common/ActionDropdown";
import { Link, useHistory, useLocation } from "react-router-dom";
import { StatusBtn } from "../../styles/statusBtn";
import Layout from "../../common/Layout";
import DynamicTitle from "../../common/DynamicTitle";
import { StyledDashboardPage } from "../../styles/styleDashboard";
import Pagination from "../../atoms/Pagination";
import { useState } from "react";

const ClientSubscriptionPage = () => {
  let organizationDetails =
    JSON.parse(localStorage.getItem("client_details")) || {};
  let organisationId = organizationDetails.organisationId;

  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(window.location.search);

  const initialValues = {
    page: Number(queryParams.get("page")) || 1
  };
  const [paramsValues, setParamsValues] = useState(initialValues);
  
  const { data: subscriptionList, isLoading } = useOrganisationSubscriptions(organisationId, paramsValues);

  const increment = () => {
    setParamsValues({ ...paramsValues, page: paramsValues.page + 1 });

    const params = new URLSearchParams({
      ...paramsValues,
      page: paramsValues.page + 1,
    });
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const decrement = () => {
    setParamsValues({ ...paramsValues, page: paramsValues.page - 1 });

    const params = new URLSearchParams({
      ...paramsValues,
      page: paramsValues.page - 1,
    });
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const newPage = (n) => {
    setParamsValues({ ...paramsValues, page: n });

    const params = new URLSearchParams({ ...paramsValues, page: n });
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };


  return (
    <Layout navType="client" pageLoading={isLoading}>
      <DynamicTitle title="Client Subscription | Fellowship Product" />
      <StyledDashboardPage>
        <div className="page-header">
          <h4>Subscriptions</h4>
        </div>
        <TableContainer>
          <table style={{ width: "100%" }}>
            <thead style={{ width: "100%" }}>
              <tr>
                <th>Package</th>
                <th>Number of Engineer</th>
                <th>Unused Slot(s)</th>
                <th>Available Credit</th>
                <th>Start Date</th>
                <th>Expiry Date</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody style={{ width: "100%" }}>
              {subscriptionList?.data?.content.map((item, index) => (
                <tr className="" key={index + 1}>
                  <td>{item.packageName}</td>
                  <td>{item.numEngineers}</td>
                  <td>{item.availableCredit - item.numEngineers}</td>
                  <td>{item.availableCredit}</td>
                  <td>{item.startDate}</td>
                  <td>{item.expireDate}</td>
                  <td>
                    {item.status ? (
                      <StatusBtn
                        className="status-bg"
                        status={item.status?.toLowerCase()}
                      >
                        {item.status}
                      </StatusBtn>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    <ActionDropdown>
                      <Link to={`/client/subscription/${item.id}`}>
                        View Subscription
                      </Link>
                    </ActionDropdown>
                  </td>
                </tr>
              ))}
              {subscriptionList?.data.length === 0 && (
                <tr>
                  <td colSpan="6" className="no-data">
                    No Data Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </TableContainer>
        {subscriptionList && (
          <div className="page-parent">
            <Pagination
              currentPage={subscriptionList?.data.number + 1}
              totalClients={subscriptionList?.data.numberOfElements}
              metaData={subscriptionList?.data}
              page={subscriptionList?.data.number + 1}
              newPage={newPage}
              increment={increment}
              decrement={decrement}
              className="pagination"
            />
          </div>
        )}
      </StyledDashboardPage>
    </Layout>
  );
};

export default ClientSubscriptionPage;
