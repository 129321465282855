import React from "react";
import styled from "styled-components";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import ActivityDropdown from "../../atoms/ActivityDropdown";

const MultiBars = (props) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          borderDash: [4, 2],
          color: "#D7DBDE",
        },
      },
    },
  };

  return (
    <Tracker centerText={props.centerText}>
      <div className="card">
        <div className="header">
          <ActivityDropdown
            header={props.title}
            currentState={props.currentState}
            setCurrentState={props.setCurrentState}
            setPeriod={props.setPeriod}
          />
        </div>
        <div className="content">
          <Bar options={options} data={props.data} />
        </div>
      </div>
    </Tracker>
  );
};

const Tracker = styled.div`
  .card {
    width: 100%;
    background: #fff;
    position: relative;

    .header {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }

    .content {
      display: grid;
      box-sizing: border-box;
      padding: 2rem;
      row-gap: 1.5rem;
      place-items: center;
      height: 100%;
    }
    select {
      padding: 5px 20px 5px 2px;
      border: 1px solid #f1f1f1;
    }
  }
`;

export default MultiBars;
