import axios from "axios";
import nProgress from "nprogress";
import { toast } from "react-toastify";

export const getAccessToken = async () => {
  nProgress.start()
  const user = localStorage?.getItem("userType");
  const email = localStorage?.getItem("userEmail");

  // const path = user === "engineer" ? "dev" : user
  const userType =
    user === "engineer"
      ? JSON.parse(localStorage.getItem("engineerDetails"))
      : JSON.parse(localStorage.getItem("client_details"));

  const accessPayload = {
    accessToken: localStorage?.getItem("token"),
    refreshToken: localStorage?.getItem("refreshToken"),
    userName: user === "admin" ? email : userType.email,
  };

  const endpointUrl = user === "client" ? "private" : user === "engineer" ? "dev" : user
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/${endpointUrl}/refreshToken`,
      {
        method: "POST",
        body: JSON.stringify(accessPayload),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    );
    const res = await response.json();

    if (response.status !== 200) {
      throw new Error(response.status);
    }
    if (response.status === 401) {
      localStorage.clear();
      window.location.href =
        user === "engineer"
          ? "/engineer/login"
          : userType === "admin"
          ? "/admin/login"
          : "/login";
    }
    
    nProgress.done()
    return res.data.token;
  } catch (err) {
    console.log(err);
  }
  nProgress.done()
};

axios.interceptors.response.use(null, async (err) => {
  let originalReq = err.config;
  if (originalReq && err.response && err.response.status === 401) {
    const token = await getAccessToken();
    if (token) {
      localStorage.setItem("token", token);
      originalReq.headers["Authorization"] = `Bearer ${token}`;
      return axios(originalReq);
    }

    if (token === undefined) {
      localStorage.clear();
      const pathname = window.location.pathname.split("/")[1];

      window.location.href =
        pathname === "engineer"
          ? "/engineer/login"
          : pathname === "admin"
          ? "/admin/login"
          : "/login";
    }
  } else {
    if (
      err.request.responseType === "blob" &&
      err.response.data instanceof Blob &&
      err.response.data.type &&
      err.response.data.type.toLowerCase().indexOf("json") !== -1
    ) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          err.response.data = JSON.parse(reader.result);
          resolve(Promise.reject(err));
        };

        reader.onerror = () => {
          reject(err);
        };

        reader.readAsText(err.response.data);
      });
    }

    if (!err?.response?.data) {
      if (err?.response?.status === 400) {
        toast.error(
          process.env.REACT_APP_IS_PROD
            ? "error processing request at this time. pls contact the admin"
            : err.message
        );
      } else {
        toast.error(err.message);
      }
      toast.clearWaitingQueue();
      return Promise.reject(err);
    }
    if (err?.response?.data?.subErrors) {
      toast.error(err?.response?.data?.subErrors[0]?.message);
      toast.clearWaitingQueue();
      return Promise.reject(err);
    }
    toast.error(
      err?.response?.data?.message ||
        err?.response?.data?.error ||
        err.message ||
        "error processing request at this time. pls contact the admin"
    );
    toast.clearWaitingQueue();
    return Promise.reject(err);
  }
});

export const apiGet = (path, conf = {}, auth = "admin", token = "") => {
  // const token = getParameterByName("token")
  const config = {
    ...conf,
    headers: {
      Authorization: `Bearer ${token || localStorage.getItem("token")}`,
    },
  };
  if (!auth) {
    config.headers = {};
  }
  return axios.get(`${process.env.REACT_APP_BASE_PATH}${path}`, config);
};

export const apiPost = (path, data, { headers, ...conf }, auth = true) => {
  const Authorization = auth && `Bearer ${localStorage.getItem("token")}`;

  const config = {
    ...conf,
    headers: {
      Authorization,
      ...(headers ? headers : {}),
    },
  };

  return axios.post(`${process.env.REACT_APP_BASE_PATH}${path}`, data, config);
};

export const apiPut = (path, data, conf = {}) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    ...conf,
  };
  return axios.put(`${process.env.REACT_APP_BASE_PATH}${path}`, data, config);
};

export const apiPatch = (path, data, conf = {}) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    ...conf,
  };
  return axios.patch(`${process.env.REACT_APP_BASE_PATH}${path}`, data, config);
};

export const apiDelete = (path, conf = {}) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    ...conf,
  };
  return axios.delete(`${process.env.REACT_APP_BASE_PATH}${path}`, config);
};

export const errorHandler = (payload) => {};
