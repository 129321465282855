import React, { useEffect, useState } from "react";
import styled from "styled-components";

const StarRating = ({ numRating }) => {
  const [isRatingIsFive, setIsRatingIsFive] = useState(false);

  useEffect(() => {
    if (numRating < 5) {
      setIsRatingIsFive(false);
    } else {
      setIsRatingIsFive(true);
    }
  }, [numRating]);

  return (
    <RatingWrapper>
      {isRatingIsFive ? (
        [...Array(5).keys()].map((i) => (
          <img
            key={i}
            src="/images/star.svg"
            alt="star"
          />
        ))
      ) : (
        <Rating numRating={numRating} />
      )}
    </RatingWrapper>
  );
};

const Rating = ({ numRating }) => {
    const num = Number.parseInt(numRating)
  const currentRating = 5 - num;
 
  const yellowStars = [...Array(num).keys()].map((i) => (
    <img
      key={`${i}-${new Date().toISOString()}`}
      src="/images/star.svg"
      alt="star"
    />
  ));

  const grayStars = [...Array(currentRating).keys()].map((i) => (
    <img
      key={`${new Date().toISOString()}--${i}`}
      src="/images/grey-star.svg"
      alt="star"
    />
  ));
  const rating = yellowStars.concat(grayStars);
  return rating;
};

const RatingWrapper = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;

    & img {
        width: 100%;
        height: 100%;
    }
`

export default StarRating;


