import React from "react";
import Select from "react-select";
import "../../../services/reference/query";
import { weeklyModuleOptions } from "../../../data/notificationModule";
import { FilterFields } from "./style";

const NotificationsFilter = ({ handleFilterChange, weeks, squads }) => {
  const selectStyles = {
    control: (styles) => ({
      ...styles,
      width: "295px",
    }),
  };

  const formatSquad = squads
    ? squads.data.map((data) => {
        return {
          label: `SQ00${data.name}`,
          value: data.id,
        };
      })
    : [];

  return (
    <FilterFields className="engineer-filters-wrapper">
      <div className="select-wrap mt-4">
        <Select
          className="my-select"
          defaultValue={"Weekly Performance Notification"}
          placeholder={"Weekly Performance Notification"}
          options={weeklyModuleOptions}
          styles={selectStyles}
          // onChange={(e) => handleFilterChange("squad", e.value)}
        />
      </div>
      <div className="select-wrap mt-4">
        <Select
          className="my-select"
          defaultValue={formatSquad[0]}
          placeholder={
            formatSquad.length ? formatSquad[0].label : "Select Squad"
          }
          options={formatSquad}
          onChange={(e) => handleFilterChange("squad", e.value)}
        />
      </div>
      <div className="select-wrap mt-4">
        <Select
          className="my-select"
          defaultValue={weeks[0]}
          options={weeks}
          placeholder={weeks.length ? weeks[0].label : "Week"}
          onChange={(e) => handleFilterChange("week", e.value)}
        />
      </div>
    </FilterFields>
  );
};

export default NotificationsFilter;
