import React, {useState} from "react";
import styled from "styled-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { apiPost } from "../../../../utils/apiHelpers";
import { toast } from "react-toastify";
import MyButton from "../../../landingPage/MyButton";
import FormInputComponent from "../../form/enterpriseFormInputs/FormInputComponent";

const PasswordSecurity = ({pageType}) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [loading, setLoading] = useState(false)
    const togglePasswordVisiblity = () => {
      setPasswordShown(passwordShown ? false : true);
    };
  
   const [initialValues] = useState({
     confirmPassword: "",
     newPassword: "",
     password: "",
   });
  
   const validationSchema = Yup.object({
    password: Yup.string().required("*Required"),
    newPassword: Yup.string().required('*Required').min(6, "six minimum characters"),
    confirmPassword: Yup.string().required("*Required").oneOf([Yup.ref('newPassword'), null], 'Passwords must match').min(6, "six minimum characters"),
  });

    const new_token = localStorage.getItem("token");

    let multipartConfig = {
      headers: {
        Authorization: `Bearer ${new_token}`,
        "Content-Type": "application/json; charset=UTF-8",
      },
    };

  const changePassword = async (formData) => {
    setLoading(true);
      //make call to the api
      try {
       
          if (pageType === "engineer") {
            await apiPost(
              `/dev/changepassword`,
              formData,
              multipartConfig
            );
            toast.success("Password Changed Successfully");
            setLoading(false);
            formik.resetForm();
          }

          if (pageType === "admin") {
            await apiPost(
              `/admin/user/account/changepassword`,
              formData,
              multipartConfig
            );
            toast.success("Password Changed Successfully");
            setLoading(false);
            formik.resetForm();
          }

          if (pageType === "client") {
            await apiPost(
              `/private/user/account/changepassword`,
              formData,
              multipartConfig
            );
             toast.success(
               "Password Changed Successfully"
             );
            setLoading(false);
            formik.resetForm();
          
        }
      } catch (err) {
        setLoading(false);
      }
    };
  
  const onSubmit = (values) => {
    changePassword(values);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  console.log(formik)
  
  return (
    <StyledDiv>
      <h3 className="pass">Password & Security</h3>

      <form onSubmit={formik.handleSubmit}>
        <div className="form-group main-div">
          <label htmlFor="password">Password</label>
          <FormInputComponent
            type={passwordShown ? "text" : "password"}
            className="form-control main-password"
            id="password"
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            togglePasswordVisiblity={togglePasswordVisiblity}
            error={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : null
            }
          />
        </div>
        <div className="row mt-5">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="newPassword">Confirm Password</label>
              <FormInputComponent
                type={passwordShown ? "text" : "password"}
                className="form-control"
                id="newPassword"
                name="newPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newPassword}
                error={
                  formik.touched.newPassword &&
                  formik.errors.newPassword
                    ? formik.errors.newPassword
                    : null
                }
              />{" "}
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <FormInputComponent
                type={passwordShown ? "text" : "password"}
                className="form-control"
                id="confirmPassword"
                name="confirmPassword"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                error={
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword
                    ? formik.errors.confirmPassword
                    : null
                }
              />{" "}
            </div>
          </div>
        </div>

        <div className="mt-4">
          <MyButton loading={loading} type="submit">
            {"Save changes"}
          </MyButton>
        </div>
      </form>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  color: rgba(33, 51, 79, 1);

  form {
    background: #fff;
    margin-top: 2rem;
    padding: 2.5rem 2.5rem 8.5rem 2.5rem;
  }
  .main-password {
    position: relative;
  }
  i {
    position: relative;
    float: right;
    top: -4rem;
    font-size: 1.9rem;
    left: -3rem;
  }
  .pass {
    font-size: 2.3rem;
    font-weight: 600;
  }
  label {
    font-size: 1.8rem;
    font-weight: 600;
    opacity: 0.9;
  }
  input {
    height: 42px;
    margin-top: 1rem;
  }
  input[type="text"] {
    font-size: 1.5rem;
  }

  button {
    float: right;
    color: #fff;
    background: linear-gradient(96.67deg, #34a853 0%, #b8d344 100%);
    border: none;
    font-size: 2rem;
    font-weight: 600;
    padding: 1rem 2rem;
  }

  .loader {
    border: 16px solid #f3f3f3; 
    border-top: 16px solid rgb(52, 168, 83);
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    margin: 0 auto;
    position: relative;
    top: 50%;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default PasswordSecurity;
