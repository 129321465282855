import { apiGet, apiPost, apiPut, apiDelete } from "../../utils/apiHelpers";

export const fetchWeeks = async (id) => {
  try {
    const res = await apiGet(`/admin/weeks/${id}`);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const createWeek = async (formData, config) => {
  try {
    await apiPost(`/admin/weeks`, formData, config);
  } catch (err) {
    throw err;
  }
};

export const editWeek = async (data, config) => {
  const { updateId: id, formatFormData } = data;
 
  try {
    await apiPut(`/admin/weeks/${id}`, formatFormData, config);
  } catch (err) {
    throw err;
  }
};

export const deleteSquadWeeks = async (id) => {
  try {
    let response = await apiDelete(`/admin/weeks/${id}`);
    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
  } catch (err) {
    console.log({ err });
  }
};
