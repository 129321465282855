import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import Select from "react-select";
import FormTitleSection from "./FormTitleSection";
import FormSelectComponent from "./enterpriseFormInputs/FormSelectComponent";
import { useAdmin } from "../../../context/AdminState";
import FormInputComponent from "./enterpriseFormInputs/FormInputComponent";
import { apiPost } from "../../../utils/apiHelpers";
import { toast } from "react-toastify";
import FormUploadComponent from "./enterpriseFormInputs/FormUploadComponent";
import { ClipLoader } from "react-spinners";
import FormTextAreaComponent from "./enterpriseFormInputs/FormTextAreaComponent";
import { locationOption, placement, placementSource, placementStatus } from "../../../data";

const AssignEngForm = ({
  onClick,
  handleReFetch,
  engId,
  singleEng,
  reload,
}) => {
  const {
    fetchAssignmentInfo,
    fetchUnassigned,
    organisationsList,
    engineersList,
    // assignmentStatusList,
    fetchBillings,
    levelList,
    fetchEngineerDetails,
    engDetails,
  } = useAdmin();

  const { id } = useParams();

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [contractDoc, setContractDoc] = useState({});
  const [label, setLabel] = useState("");
  const [orgDetail, setOrgDetail] = useState(null);
  const [contractDocError, setContractDocError] = useState("");

  const placementValues = {
    engineerId: engId || "",
    organisationId: id || "",
    placementType: "",
    placementStatus: "",
    placementSource: "",
    assignmentDate: "",
    expectedEndDate: "",
    resumptionDate: "",
    workLocation: "",
    comment: "",
    clientComment: "",
    devComment: "",
  };

  if (orgDetail?.location === "INTERNATIONAL") {
    placementValues.billingRate = "";
  }

  let validationSchema = Yup.object({
    // engineerId: Yup.string().required("*Required"),
    organisationId: Yup.string().required("*Required"),
    placementType: Yup.string(),
    placementSource: Yup.string(),
    placementStatus: Yup.string(),
    assignmentDate: Yup.date(),
  });

  const new_token = localStorage.getItem("token");

  let multipartConfig = {
    headers: {
      Authorization: `Bearer ${new_token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const formatDate = (date) => {
    return date.split("-").reverse().join("/");
  };

  useEffect(() => {
    fetchAssignmentInfo();
    fetchUnassigned();
    fetchBillings();
    if (singleEng && engId) {
      fetchEngineerDetails(engId);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setContractDocError("");
  }, [contractDoc]);

  const docType = ["pdf", "docx", "doc"];

  const engineerOptions = engineersList.map((eng) => ({
    value: eng.id,
    label: eng.name,
  }));

  const organizationOptions = [];
  let newOrgList = organisationsList?.map((org) => ({
    label: org.name,
    value: org.id,
  }));

  organizationOptions.push(
    { label: "Select Organisation", value: "" },
    ...newOrgList
  );

  const levelOptions = [];
  let newList = levelList?.map((lev) => ({
    value: lev.id,
    label: lev.engineerLevel,
  }));

  levelOptions.push({ label: "Select Engineer Level", value: "" }, ...newList);

  //assign engineer
  const assignEngineer = async (values) => {
    setLoading(true);
    //make call to the api
    try {
      let response = await apiPost(
        `/admin/assignments`,
        values,
        multipartConfig
      );

      if (response.error) {
        throw response.data.response.status === 500
          ? new Error("")
          : response.data;
      }
      reload && reload();
      setSuccess(true);
      setLoading(false);
      handleReFetch(false);
      toast.success("Engineer Assigned Successfully");
    } catch (err) {
      setLoading(false);
    }
  };

  const onSubmit = (values) => {
    const newValues = JSON.parse(JSON.stringify(values));

    newValues.assignmentDate = formatDate(values.assignmentDate);
    newValues.expectedEndDate = formatDate(values.expectedEndDate);

    let formData = new FormData();
    newValues.resumptionDate = formatDate(values.resumptionDate);
    formData.append("contract_upload", contractDoc);
    formData.append(
      "assignment_dto",
      new Blob([JSON.stringify(newValues)], {
        type: "application/json",
      })
    );

    assignEngineer(formData);
  };

  const formik = useFormik({
    initialValues: placementValues,
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    const orgDetail = organisationsList.find(
      (org) => org.id === Number(formik.values.organisationId)
    );
    setOrgDetail(orgDetail);

    // eslint-disable-next-line
  }, [formik.values, organisationsList]);

  const closeForm = () => {
    formik.resetForm();
    onClick();
    setContractDoc(() => ({}));
    setSuccess(false);
  };

  return (
    <StyledCreatePackageForm success={success}>
      <FormTitleSection title="Assign Engineer" onClick={() => closeForm()} />
      <div className="form-section">
        <form onSubmit={formik.handleSubmit} className="client-form">
          <div>
            <label className="assig-label" htmlFor="engineer">
              Engineer
            </label>
            {singleEng ? (
              <FormInputComponent
                type="text"
                name="engineerId"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={
                  engDetails
                    ? `${engDetails?.firstName} ${engDetails?.lastName}`
                    : ""
                }
                error={
                  formik.touched.engineerId && formik.errors.engineerId
                    ? formik.errors.engineerId
                    : null
                }
                disabled={true}
              />
            ) : (
              <Select
                className="assig-select"
                options={engineerOptions}
                placeholder="Select engineer"
                name="engineerId"
                onChange={(selectedOption) => {
                  let d = selectedOption;
                  let even = { target: { name: "engineerId", value: d.value } };
                  setLabel(d.label);
                  formik.handleChange(even);
                }}
                onBlur={formik.handleBlur}
                type="select"
                value={{ label: label, value: formik.values.refresh }}
                error={
                  formik.touched.engineerId && formik.errors.engineerId
                    ? formik.errors.engineerId
                    : null
                }
              />
            )}

            <FormSelectComponent
              label="Assign to"
              name="organisationId"
              options={organizationOptions}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.organisationId}
              error={
                formik.touched.organisationId && formik.errors.organisationId
                  ? formik.errors.organisationId
                  : null
              }
              required
            />

            <FormSelectComponent
              label="Placement Type"
              name="placementType"
              options={placement}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.placementType}
              error={
                formik.touched.placementType && formik.errors.placementType
                  ? formik.errors.placementType
                  : null
              }
              
            />

            <FormSelectComponent
              label="Placement Status"
              name="placementStatus"
              options={placementStatus}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.placementStatus}
              error={
                formik.touched.placementStatus && formik.errors.placementStatus
                  ? formik.errors.placementStatus
                  : null
              }
              
            />

            <FormSelectComponent
              label="Placement Source"
              name="placementSource"
              options={placementSource}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.placementSource}
              error={
                formik.touched.placementSource && formik.errors.placementSource
                  ? formik.errors.placementSource
                  : null
              }
              
            />

            <FormInputComponent
              label="Assignment Date"
              type="date"
              name="assignmentDate"
              placeholder="Enter assignment date"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.assignmentDate}
              error={
                formik.touched.assignmentDate && formik.errors.assignmentDate
                  ? formik.errors.assignmentDate
                  : null
              }
              className="assig-date"
              
            />
            {formik.values.placementType !== "BILLING" && (
              <FormInputComponent
                label="Expected End Date"
                type="date"
                name="expectedEndDate"
                placeholder="Enter end date"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.expectedEndDate}
                error={
                  formik.touched.expectedEndDate &&
                  formik.errors.expectedEndDate
                    ? formik.errors.expectedEndDate
                    : null
                }
                className="assig-date"
                disabled={
                  formik.values.placementType === "INTERNSHIP" ||
                  formik.values.placementType === "RESIDENCE"
                }
              />
            )}
          </div>
          <div>
            <FormInputComponent
              label="Resumption Date"
              type="date"
              name="resumptionDate"
              placeholder="Enter resumption date"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.resumptionDate}
              error={
                formik.touched.resumptionDate && formik.errors.resumptionDate
                  ? formik.errors.resumptionDate
                  : null
              }
              className="assig-date"
            />
            <label className="assig-label" htmlFor="work-loacation">
              Work Location
            </label>
            <Select
              className="assig-select"
              options={locationOption}
              placeholder="Select Location"
              name="workLocation"
              onChange={(selectedOption) => {
                let d = selectedOption;
                let even = { target: { name: "workLocation", value: d.value } };
                formik.handleChange(even);
              }}
              onBlur={formik.handleBlur}
              type="select"
              value={{
                label: formik.values.workLocation,
                value: formik.values.workLocation,
              }}
              error={
                formik.touched.workLocation && formik.errors.workLocation
                  ? formik.errors.workLocation
                  : null
              }
            />
            {orgDetail?.location === "INTERNATIONAL" && (
              <FormInputComponent
                label="Engineer Bill"
                name="billingRate"
                placeholder="$"
                type="number"
                allowDecimals={true}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.billingRate}
                error={
                  formik.touched.billingRate && formik.errors.billingRate
                    ? formik.errors.billingRate
                    : null
                }
              />
            )}

            <FormUploadComponent
              label="Upload Contract"
              error={contractDocError}
              selectedFile={contractDoc}
              setSelectedFile={setContractDoc}
              acceptTypes={`pdf, docx, doc. Size Limit 5mb`}
              pdf={true}
              docType={docType}
            />
          </div>

          <FormTextAreaComponent
            label="Placement Admin Comment"
            type="text"
            name="comment"
            placeholder="Enter comment"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.comment}
            error={
              formik.touched.comment && formik.errors.comment
                ? formik.errors.comment
                : null
            }
            className="w-100"
          />
          <FormTextAreaComponent
            label="Client Comment"
            type="text"
            name="clientComment"
            placeholder="Enter client comment"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.clientComment}
            error={
              formik.touched.clientComment && formik.errors.clientComment
                ? formik.errors.clientComment
                : null
            }
            className="w-100"
          />
          <FormTextAreaComponent
            label="Dev Comment"
            type="text"
            name="devComment"
            placeholder="Enter dev comment"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.devComment}
            error={
              formik.touched.devComment && formik.errors.devComment
                ? formik.errors.devComment
                : null
            }
            className="w-100"
          />
          <MyButton className="form-btn" type={"submit"} full>
            {" "}
            {loading ? (
              <ClipLoader color={"#fff"} size={"30px"} />
            ) : (
              "Assign Engineer"
            )}
          </MyButton>
        </form>
        <div className="form-success card p-3">
          <img
            src="/images/sucess-icon.svg"
            alt="green-tick"
            className="sucess-icon"
          />
          <h2 className="form-font green-text">Success</h2>
          <p className="form-font success-msg">
            You successfully Assigned an Engineer.
          </p>
          <MyButton className="form-btn success-btn" onClick={closeForm}>
            Okay, Go back to Engineer's list
          </MyButton>
        </div>
      </div>
    </StyledCreatePackageForm>
  );
};

const StyledCreatePackageForm = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);
  .form-btn {
    width: 100%;
    margin-top: 3.2rem;
  }
  .form-section {
    overflow-y: scroll;
    // display: ${({ success }) => (success ? "none" : "block")};
    padding: 4rem 4rem 4rem 4rem;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3.2rem;
  }
  input .assig-date {
    width: 100% !important;
  }

  .css-yk16xz-control {
    border-radius: 0;
    border: 1px solid rgba(33, 51, 79, 0.15) !important;
    margin: 0.5rem 0 2.4rem;
    height: 48px;
    font-size: 16px;
    color: green;
  }

  .css-1pahdxg-control {
    border: 1px solid rgba(33, 51, 79, 0.15);
    box-shadow: none;
    height: 48px;
    margin: 0.5rem 0 2.4rem;
  }
  .css-1pahdxg-control:hover {
    border: 1px solid rgba(33, 51, 79, 0.15);
    border-radius: 0;
    /* box-shadow: none; */
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-tlfecz-indicatorContainer {
    padding-right: 16px;
  }
  .css-tj5bde-Svg {
    color: #21334f;
    width: 15px;
  }
  .css-1wa3eu0-placeholder {
    color: rgba(33, 51, 79, 0.8);
    font-size: 16px;
    /* line-height: 20px; */
  }
  .css-g1d714-ValueContainer {
    font-size: 16px;
  }

  .btn-wrapper {
    margin-bottom: 2.2rem;
  }

  .btn-wrapper button {
    border: none;
    font-size: 1.6rem;
    font-weight: bold;
  }

  .btn-wrapper button:first-of-type {
    margin-right: 24px;
  }

  .btn-wrapper .active {
    color: #34a853;
    background: #fff;
    padding: 14px;
    border: 1px solid rgba(52, 168, 83, 0.26);
  }

  .btn-wrapper .inactive {
    color: #21334f;
    background: transparent;
  }

  @media screen and (max-width: 500px) {
    .btn-wrapper button:first-of-type {
      margin-right: 8px;
    }

    .btn-wrapper button {
      border: none;
      font-size: 1.2rem;
    }
  }

  .assig-label {
    color: black;
    font-weight: 600;
    font-size: 16px;
    /* line-height: 20px; */
  }
  .add-client-btn {
    margin-bottom: 23.8rem;
  }
  .client-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2.rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 100% !important;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
`;

export default AssignEngForm;
