// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import Layout from "../common/Layout";
import DynamicTitle from "../common/DynamicTitle";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import TableContainer from "../common/TableContainer";
import ActionDropdown from "../common/ActionDropdown";
import FormModal from "../form/FormModal";
import AddNewSquadAdmin from "../form/AddSquadAdmin";
// import Pagination from "../atoms/Pagination";
import { useFetchedSquadAdminList } from "../../../services/squad-admin/query";
import { useParams } from "react-router-dom";
import useDialog from "../../../utils/useDialog";
import { deleteSquadAdmin } from "../../../services/squad-admin/api";
import ProtectedComponents from "../local-routes/ProtectedComponents";

const SquadAdminPage = ({ pageType }) => {
  const { id: updateId, name: squadName } = useParams();

  const { deleteItemDialog } = useDialog();

  const {
    data: squadAdminList,
    refetch,
    isLoading,
  } = useFetchedSquadAdminList(updateId);

  const [squadAdminId, setSquadAdminId] = useState(null);

  const [addNewSqAdminModal, setAddNewSqAdminModal] = useState(false);

  const [activeEditModal, setActiveEditModal] = useState(false);

  const showAddAdmin = (e) => {
    setAddNewSqAdminModal(!addNewSqAdminModal);
  };
  const showEditAdmin = (e) => {
    setActiveEditModal(!activeEditModal);
  };

  const handleDelete = (id, name) => {
    deleteItemDialog(deleteSquadAdmin, id, name, refetch);
  };

  return (
    <Layout pageLoading={isLoading} navType="admin">
      <DynamicTitle title="Squad Admin Page | Fellowship Product" />

      <SquadAdminPageLayout>
        <div className="client-header">
          <h1>Squad {squadName} Admins</h1>
          <ProtectedComponents permission={["manageSquad"]}>
            <MyButton
              onClick={() => setAddNewSqAdminModal(true)}
              className="btn-doc"
            >
              Create Admin
            </MyButton>
          </ProtectedComponents>
        </div>
        {
          <TableContainer>
            <table style={{ width: "100%" }}>
              <thead style={{ width: "100%" }}>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Language</th>
                  <ProtectedComponents permission={["manageSquad"]}>
                    <th>Action</th>
                  </ProtectedComponents>
                </tr>
              </thead>
              <tbody style={{ width: "100%" }}>
                {squadAdminList?.map(
                  ({ id, firstName, email, role, language }, index) => (
                    <tr className="" key={index + 1}>
                      <td>{firstName}</td>
                      <td>{email}</td>
                      <td>{role}</td>
                      <td>{language}</td>
                      <ProtectedComponents permission={["manageSquad"]}>
                        <td>
                          <ActionDropdown>
                            <p
                              onClick={() => {
                                setActiveEditModal(true);
                                setSquadAdminId(id);
                              }}
                            >
                              {" "}
                              Edit{" "}
                            </p>

                            <p
                              onClick={() => {
                                handleDelete(id, firstName, "Admin");
                              }}
                            >
                              {" "}
                              Delete{" "}
                            </p>
                          </ActionDropdown>
                        </td>
                      </ProtectedComponents>
                    </tr>
                  )
                )}
                {squadAdminList?.length === 0 && (
                  <tr>
                    <td colSpan="6" className="no-data">
                      No Data Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </TableContainer>
        }

        {addNewSqAdminModal && (
          <div className="form-modal">
            <FormModal className="form" handleModal={showAddAdmin}>
              {
                <AddNewSquadAdmin
                  closeModal={showAddAdmin}
                  reload={refetch}
                  updateId={updateId}
                />
              }
            </FormModal>
          </div>
        )}
        {activeEditModal && (
          <div className="form-modal">
            <FormModal className="form" handleModal={showEditAdmin}>
              {
                <AddNewSquadAdmin
                  closeModal={showEditAdmin}
                  isEditing
                  updateId={updateId}
                  reload={refetch}
                  squadAdminId={squadAdminId}
                />
              }
            </FormModal>
          </div>
        )}
        {/* <div className="page-parent">
                <Pagination
                  currentPage={currentPage}
                  clientsPerPage={dataPerPage}
                  totalClients={currentData?.length ? currentData.length : 0}
                  paginate={(pageNumber) => setCurrentPage(pageNumber)}
                  metaData={metaData}
                  page={page}
                  newPage={newPage}
                  increment={increment}
                  decrement={decrement}
                  className="pagination"
                />
              </div> */}
      </SquadAdminPageLayout>
    </Layout>
  );
};

const SquadAdminPageLayout = styled.div`
  .client-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 4.8rem;
    margin-bottom: 1.1rem;
  }
  .client-header h1 {
    font-weight: 700;
    font-size: 2.4rem;
  }
  .form-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
  }
`;

export default SquadAdminPage;
