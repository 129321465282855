import Select, { components } from "react-select";
import styled from "styled-components";

const styles = {
  container: (base) => ({
    ...base,
    height: "44px",
    fontSize: "14px",
    width: "100%"
    // border: "1px solid #98A2B3"
  }),
  control: (base) => ({
    ...base,
    border: "1px solid #98A2B3",
    borderRadius: "none",
    height: "48px",
    width: "100%"
  }),
  valueContainer: base => ({
    ...base,
    paddingLeft: 12,
    color: "#98A2B3",
  }),
  placeholder: (base) => ({
    ...base,
    color: "#98A2B3",
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: "white",
    color: "#212529",
    ':active': {
      backgroundColor: "white"
    }
  }),
};

export const FormMultiSelectComponent = ({label, name, options, value, onChange, placeholder, required=false, isMulti=true}) => {

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <div style={{display: "flex", alignItems: "center"}}>
            <label htmlFor={props.label} style={{width: "auto", color: "#212529"}} className="check-label">
              <input
                className="checkbox-area"
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
              />
              <span className="checkmark"></span>
            </label>
            <label style={{marginTop: "12px"}}>{props.label}</label>
          </div>
        </components.Option>
      </div>
    );
  };

  return (
    <StyledFormSelectComponent>
      {label && (
        <label htmlFor={name} className="form-font form-label">
          {label}
          {required && <span style={{ color: "red" }}> *</span>}
        </label>
      )}
      <Select
        options={options}
        isMulti={true}
        onChange={onChange}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        allowSelectAll={true}
        isClearable={false}
        placeholder={placeholder}
        // unstyled={true}
        controlShouldRenderValue={true}
        components={{ Option: isMulti ? Option : null, IndicatorSeparator: () => null }}
        value={value}
        classNamePrefix="decagon"
        styles={styles}
        name={name}
      />
    </StyledFormSelectComponent>
  )
}

const StyledFormSelectComponent = styled.div`
  margin-bottom: 2.4rem;
  .checkbox-area {
    -webkit-appearance: button;
    margin-right: 1.5rem;
    display: none;
  }

  .check-label {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }
  .checkmark {
    width: 20px;
    height: 20px;
    border: 2px solid #34a853;
    display: inline-block;
    border-radius: 3px;
    margin-right: 1rem;
    transition: background-size 0.2s cubic-bezier(0.7, 0, 0.18, 1.24);
  }
  .check-label input:checked + .checkmark {
    background-size: 60%;
    background: #34a853;
    transition: background-size 0.25s cubic-bezier(0.7, 0, 0.18, 1.24);
  }
`;