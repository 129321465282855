import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import FormInputComponent from "./enterpriseFormInputs/FormInputComponent";
import FormTitleSection from "./FormTitleSection";
import { ClipLoader } from "react-spinners";
import { useCreateIndustry } from "../../../services/industry/query";

const initialValues = {
  name: "",
};

const validationSchema = Yup.object({
  name: Yup.string()
    .required("*Required")
    .max(50)
    // eslint-disable-next-line
    .test("non-repeated words", "unique ${path}s must not repeat", (value) => {
      if (value !== undefined) {
        const industryTyped = value.split(" ");
        const unique = Array.from(new Set(industryTyped));
        return industryTyped.length === unique.length;
      }
    }),
});

const CreateIndustry = ({ handleModal, refetch }) => {
  const [success, setSuccess] = useState(false);

  const { mutate: createIndustry, isLoading } = useCreateIndustry();

  const onSubmit = (values) => {
    createIndustry(values, {
      onSuccess: () => {
        refetch();
        setSuccess(true);
      },
      onError: (error) => {
        setSuccess(false);
      },
    });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const closeForm = () => {
    formik.resetForm();
    handleModal();
    setSuccess(false);
  };

  return (
    <StyledCreatePackageForm success={success}>
      <FormTitleSection title="Create Industry" onClick={closeForm} />
      <div className="form-section">
        <form onSubmit={formik.handleSubmit} className="client-form">
          <p className="form-font form-title">Industry Details</p>

          <FormInputComponent
            label="Industry Name"
            type="text"
            name="name"
            placeholder="Enter industry name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : null
            }
          />

          <MyButton className="form-btn add-client-btn" type={"submit"} full>
            {isLoading ? (
              <ClipLoader color={"#fff"} size={"30px"} />
            ) : (
              "Create New Industry"
            )}
          </MyButton>
        </form>
        <div className="form-success card p-3">
          <img
            src="/images/sucess-icon.svg"
            alt="green-tick"
            className="sucess-icon"
          />
          <h2 className="form-font green-text">Success</h2>
          <p className="form-font success-msg">
            You successfully created a Industry.
          </p>
          <MyButton className="form-btn success-btn" onClick={closeForm}>
            Okay, Go back to Industry Listing Page
          </MyButton>
        </div>
      </div>
    </StyledCreatePackageForm>
  );
};

const StyledCreatePackageForm = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);
  .form-section {
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3.2rem;
  }
  .add-client-btn {
    margin-bottom: 23.8rem;
  }
  .client-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2.rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 100% !important;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
`;

export default CreateIndustry;
