import React, { useState } from "react";
import styled from "styled-components";
import Layout from "../common/Layout";
import DynamicTitle from "../common/DynamicTitle";
import FormModal from "../form/FormModal";
import TableContainer from "../common/TableContainer";
import { Link, useHistory, useParams } from "react-router-dom";
import { useAuth } from "../../../context/AuthState";
import ActionDropdown from "../common/ActionDropdown";
import { useEngAssignmentListing } from "../../../services/assignment/query";
import EditAssignmentForm from "../form/EditAssignmentForm";
import { StyledDashboardPage } from "../styles/styleDashboard"

const AssignmentListing2 = ({ pageType }) => {
  const { id } = useParams()
  const history = useHistory()

  const { permissionCheck } = useAuth();
  const permRoles = localStorage?.getItem("roles");
  const permArr = permRoles.split(",");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState("");
  const [assignmentId, setAssignmentId] = useState(null);

  const {
    data: engAssignmentList,
    refetch: refetchAssignment,
    isLoading,
  } = useEngAssignmentListing(id);

  const handleModal = (id) => {
    setIsModalOpen(!isModalOpen);
    setAssignmentId(id);
  };

  return (
    <Layout navType="admin" pageLoading={isLoading}>
      <DynamicTitle title="Placement List | Fellowship Product" />
      <StyledDashboardPage isModalOpen={isModalOpen}>
        <Link to="#" onClick={() => history.goBack()} className="goBack">
          <img
            className="back-arrow-img"
            src="/images/back-arrow.svg"
            alt="back arrow"
          />
          <span className="go-back-text ml-2">Go Back</span>
        </Link>
        <div className="page-header">
          <h4>Placement History</h4>
        </div>
        <TableContainer>
          <table style={{ width: "100%" }}>
            <thead style={{ width: "100%" }}>
              <tr>
                <th>Organisation</th>
                <th>Placement Date</th>
                <th>Resumption Date</th>
                <th>End Date</th>
                <th>Credit Allocation</th>
                <th>Placement Type</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody style={{ width: "100%" }}>
              {engAssignmentList?.data?.map(
                (
                  {
                    placementType,
                    assignmentId,
                    assignmentDate,
                    statusDate,
                    endDate,
                    status,
                    vendor,
                    creditAllocated
                  },
                  index
                ) => (
                  <tr className="" key={index + assignmentId}>
                    <td>{vendor}</td>
                    <td>{assignmentDate}</td>
                    <td>{statusDate}</td>
                    <td>{endDate}</td>
                    <td>{creditAllocated}</td>
                    <td>{placementType}</td>
                    <td>
                      {status ? (
                        <BadgeStyle
                          className="status-bg"
                          status={status?.toLowerCase()}>
                          {status}
                        </BadgeStyle>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>
                      <ActionDropdown>
                        {permissionCheck(permArr, ["managePlacement"]) && (
                          <p
                            onClick={() => {
                              handleModal(assignmentId);
                              setActiveModal("edit-placement");
                            }}>
                            Edit Placement
                          </p>
                        )}
                      </ActionDropdown>
                    </td>
                  </tr>
                )
              )} 
            </tbody>
          </table>
        </TableContainer>
      </StyledDashboardPage>
      {isModalOpen && (
        <div className="form-modal">
          <FormModal handleModal={handleModal}>
            {
              activeModal === "edit-placement" && (
                <EditAssignmentForm
                  assignmentId={assignmentId}
                  handleEditAssignment={handleModal}
                  refetch={refetchAssignment}
                />
              )
            }
          </FormModal>
        </div>
      )}
    </Layout>
  );
};

const BadgeStyle = styled.button`
  background: ${({ status }) =>
    status === "active"
      ? "#34A853"
      : status === "retained"
      ? "#40c463"
      : status === "residence"
      ? "#6cd287"
      : status === "completed"
      ? "#34A853"
      : status === "internship"
      ? "#8adba0"
      : status === "not_assigned"
      ? "#DB6969"
      : status === "internship"
      ? "#48C76A"
      : status?.toLowerCase() === "terminated"
      ? "#D34444"
      : status === "in progress"
      ? "#FF8E00"
      : "#D34444"};
  color: white;
  border: none;
  margin: 0 auto;
  min-width: 120px;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 600;
  padding: 10px;
`;

export default AssignmentListing2;
