import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import FormInputComponent from "./enterpriseFormInputs/FormInputComponent";
import FormSelectComponent from "./enterpriseFormInputs/FormSelectComponent";
import FormTitleSection from "./FormTitleSection";
import { options } from "./formSelectOptions";
import { apiPost, apiGet, apiPut } from "../../../utils/apiHelpers";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

const AddUserForm = ({
  onClick,
  fetchAdminSingleManager,
  pageType,
  fetchClientSingleManager,
  isEditing,
  setIsEditing,
  updateId,
  organizationId,
  refetch
}) => {
  const [initialValues,] = useState({
    email: "",
    firstName: "",
    lastName: "",
    roles: [],
    phone: "",
    status: "",
  });
  if (pageType === "client" || pageType === "admin_client") {
    initialValues.organisationRole = "";
  }

  const [selectedRole, setSelectedRole] = useState([]);

  const fetchAdminUser = async () => {
    try {
      let response = await apiGet(`/admin/users/${updateId}`);
      let res = response.data.data.user;
      const fields = [
        "email",
        "firstName",
        "lastName",
        "roles",
        "organisationRole",
        "phone",
        "status",
      ];
      fields.forEach((field) => formik.setFieldValue(field, res[field], false));
      setSelectedRole(res.roles);
    } catch (err) {
      toast.error(err);
    }
  };

  const fetchClientUser = async () => {
    try {
      let response = await apiGet(`/private/users/${updateId}`);
      let res = response.data.data.user;
      const fields = [
        "email",
        "firstName",
        "lastName",
        "roles",
        "organisationRole",
        "phone",
        "status",
      ];
      fields.forEach((field) => formik.setFieldValue(field, res[field], false));
      setSelectedRole(res.roles);
    } catch (err) {
      toast.error(err);
    }
  };

  const fetchAdminClientUser = async () => {
    try {
      let response = await apiGet(`/admin/organisations/${organizationId}/users/${updateId}`);
      let res = response.data.data.user;
      const fields = [
        "email",
        "firstName",
        "lastName",
        "roles",
        "organisationRole",
        "phone",
        "status",
      ];
      fields.forEach((field) => formik.setFieldValue(field, res[field], false));
      setSelectedRole(res.roles);
    } catch (err) {
      toast.error(err);
    }
  };

  useEffect(() => {
    if (isEditing) {
      pageType === "admin" ? fetchAdminUser() : pageType === "client" ? fetchClientUser() : fetchAdminClientUser();
    }
    // eslint-disable-next-line
  }, []);

  const phoneRegExp = /^\d*(\+\d+)?$/;

  const validationSchema = Yup.object({
    email: Yup.string().email("*Invalid email format").required("*Required"),
    firstName: Yup.string()
      .max(17, "*Maximum string exceeded. 17max")
      .required("*Required"),
    lastName: Yup.string()
      .max(17, "*Maximum string exceeded. 17max")
      .required("*Required"),
    roles: Yup.array().required("*Required"),
    phone: Yup.string()
      .min(9, "*Phone number is not valid")
      .matches(phoneRegExp, "*Phone number is not valid")
      .required("Required"),
    status: Yup.string().required("*Required"),
    organisationRole: pageType === "client" ? Yup.string()
      .max(17, "*Maximum string exceeded. 17max")
      .required("*Required") : ""
  });
  let roleType = "admin"
  if (pageType === "client" || pageType === "admin_client") {
    roleType = "client"
  }
  
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientRoles, setClientRoles] = useState([]);

  async function fetchRoles() {
    try {
      const res = await apiGet(`/admin/roles/list?type=${roleType}`);
      setClientRoles(res.data.data);
    } catch (err) {
      toast.error(err);
    }
  }
  useEffect(() => {
    fetchRoles();
    // eslint-disable-next-line
  }, []);

  const new_token = localStorage.getItem("token");

  let multipartConfig = {
    headers: {
      Authorization: `Bearer ${new_token}`,
      "Content-Type": "application/json; charset=UTF-8",
    },
  };

  //create users
  const createClient = async (formData) => {
    //make call to the api
    setLoading(true)
    try {
      if (!isEditing) {
        if (pageType === "admin") {
          await apiPost(`/admin/users`, formData, multipartConfig);
          setSuccess(true);
          setLoading(loading)
          fetchAdminSingleManager();
        }
        if (pageType === "client") {
           await apiPost(
            `/private/users`,
            formData,
            multipartConfig
          );
          setSuccess(true);
          setLoading(loading)
          fetchClientSingleManager()
        }
        if (pageType === "admin_client") {
          await apiPost(
           `/admin/organisations/${organizationId}/users`,
           formData,
           multipartConfig
         );
         setSuccess(true);
         setLoading(loading)
         refetch()
        }
      }
      if (isEditing) {
        if (pageType === "admin") {
          await apiPut(`/admin/users/${updateId}`, formData, multipartConfig);
          setSuccess(true);
          setLoading(loading)
          fetchAdminSingleManager();
        }

        if (pageType === "client") {
          await apiPut(
            `/private/users/${updateId}`,
            formData,
            multipartConfig
          );
          setSuccess(true)
          setLoading(false)
          fetchClientSingleManager()
        }

        if (pageType === "admin_client") {
          await apiPut(
            `/admin/organisations/${organizationId}/users/${updateId}`,
            formData,
            multipartConfig
          );
          setSuccess(true)
          setLoading(false)
          refetch()
        }
      }
    } catch (err) {
      setLoading(false)
    }
  };

  const onSubmit = (values) => {
    createClient(values);
    setLoading(!loading);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  const closeForm = () => {
    formik.resetForm();
    onClick();
    setSuccess(false);
    setIsEditing(false);
  };
  const handleCheck = (e) => {
    const { checked, name } = e.target;

    if (
      checked &&
      formik.values.roles.findIndex((item) => item === name) === -1
    ) {
      formik.setFieldValue("roles", [...formik.values.roles, parseInt(name)]);
      setSelectedRole([...selectedRole, parseInt(name)]);
    } else {
      let newSelect = selectedRole.filter((v) => v !== parseInt(name));
      setSelectedRole(newSelect);
      formik.setFieldValue(
        "roles",
        formik.values.roles.filter((v) => v !== parseInt(name))
      );
    }
  };

  return (
    <StyledAddUserForm success={success}>
      <FormTitleSection title={isEditing ? "Edit User" : "Add New User"} onClick={closeForm} />
      <div className="form-section">
        <form onSubmit={formik.handleSubmit} className="client-form">
          <p className="form-font form-title">User Details</p>
          <FormInputComponent
            label="Email address"
            type="email"
            name="email"
            placeholder="Enter email address"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : null
            }
          />
          <FormInputComponent
            label="First Name"
            type="text"
            name="firstName"
            placeholder="Enter first name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            error={
              formik.touched.firstName && formik.errors.firstName
                ? formik.errors.firstName
                : null
            }
          />
          <FormInputComponent
            label="Last Name"
            type="text"
            name="lastName"
            placeholder="Enter last name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            error={
              formik.touched.lastName && formik.errors.lastName
                ? formik.errors.lastName
                : null
            }
          />

          <FormInputComponent
            label="Phone number"
            type="tel"
            name="phone"
            placeholder="Enter phone number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            error={
              formik.touched.phone && formik.errors.phone
                ? formik.errors.phone
                : null
            }
          />

          {pageType === "client" || pageType === "admin_client" ? (
            <FormInputComponent
              label="Organization Role"
              type="text"
              name="organisationRole"
              placeholder="Enter Organzation Role"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.organisationRole}
              error={
                formik.touched.organisationRole &&
                  formik.errors.organisationRole
                  ? formik.errors.organisationRole
                  : null
              }
            />
          ) : null}

          <div className="mt-5 mb-5">
            <p className="perm-head">Select Roles</p>
            <div className="grid-container mt-4">
              {clientRoles !== undefined && clientRoles.length !== 0 ?
                (
                  clientRoles.map((item, index) => (
                    <label
                      htmlFor={"chk" + index}
                      key={index}
                      className="check-label"
                    >
                      <input
                        className="checkbox-area"
                        type="checkbox"
                        id={"chk" + index}
                        onChange={handleCheck}
                        name={item.id}
                        value={parseInt(item.id)}
                        checked={selectedRole.find((x) => x === item.id)}
                      />
                      <span className="checkmark"></span>
                      <span className="item-span"> {item.name}</span>
                    </label>
                  ))) : null}
            </div>
          </div>

          <FormSelectComponent
            label="Status"
            name="status"
            options={options}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.status}
            error={
              formik.touched.status && formik.errors.status
                ? formik.errors.status
                : null
            }
          />
          <MyButton
            className="form-btn add-client-btn"
            loading={false}
            type="submit"
            full
          >
            {loading ? (
              <ClipLoader color={"#fff"} size={"30px"} />
            ) : (
            isEditing ? "Save User" : " Add New User")
             }
          </MyButton>
        </form>

        <div className="form-success card">
          <img
            src="/images/sucess-icon.svg"
            alt="green-tick"
            className="sucess-icon"
          />
          <h2 className="form-font green-text">Success</h2>
          <p className="form-font success-msg">
            You have successfully added a User.
          </p>
          <MyButton className="form-btn success-btn" onClick={closeForm}>
            View List of Users
          </MyButton>
        </div>
      </div>
    </StyledAddUserForm>
  );
};

const StyledAddUserForm = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);
  .underline {
    display: block;
  }
  .form-section {
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3.2rem;
  }
  .add-client-btn {
    margin-bottom: 23.8rem;
    margin-top: 5rem;
  }
  .client-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .stack-section {
    display: flex;
    flex-wrap: wrap;
    width: 32rem;
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    margin-top: 8rem;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2.rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 32rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
  .select-field {
    max-width: 35rem;
  }
  .perm-label {
    color: #21334f;
    font-size: 1.6rem;
    font-weight: 600;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    grid-column-gap: 1rem;
  }
  .checkbox-area {
    -webkit-appearance: button;
    margin-right: 1.5rem;
    display: none;
  }

  .check-label {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }
  .checkmark {
    width: 25px;
    height: 25px;
    border: 2px solid #34a853;
    display: inline-block;
    border-radius: 3px;
    margin-right: 1rem;
    background: #34a853
      url("https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/White_check.svg/1200px-White_check.svg.png")
      center/1250% no-repeat;
    transition: background-size 0.2s cubic-bezier(0.7, 0, 0.18, 1.24);
  }
  .check-label input:checked + .checkmark {
    background-size: 60%;
    transition: background-size 0.25s cubic-bezier(0.7, 0, 0.18, 1.24);
  }
  .item-span {
    font-size: 1.3rem;
    font-weight: 600;
  }
  .perm-head {
    font-size: 1.6rem;
    font-weight: 600;
  }
`;

export default AddUserForm;
