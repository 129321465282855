import nProgress from "nprogress";
import { apiGet, apiPost, apiPut } from "../../utils/apiHelpers";
import fileDownload from "../../utils/file-download";

export const listDecadevLoan = async (params) => {
  nProgress.start();
  const [filterOptions] = params.queryKey;
  const filterToString = new URLSearchParams(filterOptions).toString();
  try {
    const res = await apiGet(
      `/admin/loan-details?size=20&${filterToString}`,
      "admin"
    );
    nProgress.done();
    return res.data;
  } catch (err) {
    console.log(err);
  }
  nProgress.done();
};

export const decadevLoan = async (params) => {
  const [loanId] = params.queryKey;
  try {
    const res = await apiGet(
      `/admin/loan-details/details/${loanId}`,
      "admin"
    );

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const listLoanDefaulters = async (params) => {
  nProgress.start();
  const [filterOptions] = params.queryKey;
  const filterToString = new URLSearchParams(filterOptions).toString();
  try {
    const res = await apiGet(
      `/admin/loan-default?size=20&${filterToString}`,
      "admin"
    );
    nProgress.done();
    return res.data;
  } catch (err) {
    console.log(err);
  }
  nProgress.done();
};

export const getLoanDashboardCards = async (params) => {
  nProgress.start();
  const [filterOptions] = params.queryKey;
  const filterToString = new URLSearchParams(filterOptions).toString();
  try {
    const res = await apiGet(
      `/admin/loan-details/dashboard-card?size=20&${filterToString}`,
      "admin"
    );
    nProgress.done();
    return res.data;
  } catch (err) {
    console.log(err);
  }
  nProgress.done();
};

export const searchAllEngineersByName = async (params) => {
  nProgress.start();
  const [search] = params.queryKey;
  try {
    const res = await apiGet(
      `/admin/loan-details/engineers?search=${search}`,
      "admin"
    );
    nProgress.done();
    return res.data;
  } catch (err) {
    console.log(err);
  }
  nProgress.done();
};

export const createLoanDetail = async (formData, config) => {
  try {
    let response = await apiPost(`/admin/loan-details`, formData, config);
    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
  } catch (err) {
    throw err;
  }
};

export const updateLoanDetail = async (formData, config) => {
  const { loanId, ...rest } = formData;
  try {
    let response = await apiPut(`/admin/loan-details/${loanId}`, rest, config);
    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
  } catch (err) {
    throw err;
  }
};

export const completedLoan = async (params) => {
  nProgress.start();
  const [filterOptions] = params.queryKey;
  const filterToString = new URLSearchParams(filterOptions).toString();
  try {
    const res = await apiGet(
      `/admin/loan-details/completed?size=20&${filterToString}`,
      "admin"
    );
    nProgress.done();
    return res.data;
  } catch (err) {
    console.log(err);
  }
  nProgress.done();
};

export const moratoriumLoans = async (params) => {
  nProgress.start();
  const [filterOptions] = params.queryKey;
  const filterToString = new URLSearchParams(filterOptions).toString();
  try {
    const res = await apiGet(
      `/admin/loan-details/moratorium?size=20&${filterToString}`,
      "admin"
    );
    nProgress.done();
    return res.data;
  } catch (err) {
    console.log(err);
  }
  nProgress.done();
};

export const activeLoans = async (params) => {
  nProgress.start();
  const [filterOptions] = params.queryKey;
  const filterToString = new URLSearchParams(filterOptions).toString();
  try {
    const res = await apiGet(
      `/admin/loan-details/active-loan?size=20&${filterToString}`,
      "admin"
    );
    nProgress.done();
    return res.data;
  } catch (err) {
    console.log(err);
  }
  nProgress.done();
};

export const servicedByDecagon = async (params) => {
  nProgress.start();
  const [filterOptions] = params.queryKey;
  const filterToString = new URLSearchParams(filterOptions).toString();
  try {
    const res = await apiGet(
      `/admin/loan-details/loan-serviced?size=20&${filterToString}`,
      "admin"
    );
    nProgress.done();
    return res.data;
  } catch (err) {
    console.log(err);
  }
  nProgress.done();
};

export const defaultingLoans = async (params) => {
  nProgress.start();
  const [filterOptions] = params.queryKey;
  const filterToString = new URLSearchParams(filterOptions).toString();
  try {
    const res = await apiGet(
      `/admin/loan-details/defaulters?size=20&${filterToString}`,
      "admin"
    );
    nProgress.done();
    return res.data;
  } catch (err) {
    console.log(err);
  }
  nProgress.done();
};

export const nonDefaultingLoans = async (params) => {
  nProgress.start();
  const [filterOptions] = params.queryKey;
  const filterToString = new URLSearchParams(filterOptions).toString();
  try {
    const res = await apiGet(
      `/admin/loan-details/loan-performing?size=20&${filterToString}`,
      "admin"
    );
    nProgress.done();
    return res.data;
  } catch (err) {
    console.log(err);
  }
  nProgress.done();
};

export const exportDecadevLoan = async (id, name) => {
  nProgress.start();
  try {
    const data = await apiGet(`/admin/loan-default/${id}/download`, {
      responseType: "blob",
      headers: {
        "Content-type": "application/pdf",
      },
    });

    fileDownload(data.data, `${name}_loan_default.pdf`);
  } catch (err) {
    console.log(err);
  }
  nProgress.done();
};

export const listLoanActivityTracking = async (params) => {
  nProgress.start();
  const [filterOptions] = params.queryKey;
  const filterToString = new URLSearchParams(filterOptions).toString();
  try {
    const res = await apiGet(
      `/admin/loan-details/activity/tracking?size=20&${filterToString}`,
      "admin"
    );
    nProgress.done();
    return res.data;
  } catch (err) {
    console.log(err);
  }
  nProgress.done();
};
