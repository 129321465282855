import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import { apiPost, apiGet } from "../../../utils/apiHelpers";
import { toast } from "react-toastify";

const AddSubRequest = ({ show, setShow, handleShow, fetchSubRequest }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [teirOneId, setTeirOneId] = useState(0);
  const [teirTwoId, setTeirTwoId] = useState(0);

  // Fetch Package options
  const fetchPackageInfo = async () => {
    try {
      const res = await apiGet(`/reference/packages`);
      let result = res.data.data;
      setTeirOneId(result[0].id);
      setTeirTwoId(result[1].id);
    } catch (err) {
      toast.error(err);
    }
  };

  useEffect(() => {
    fetchPackageInfo();
  }, []);

  useEffect(() => {
    fetchSubRequest();
    // eslint-disable-next-line
  }, [success]);

  const new_token = localStorage.getItem("token");
  let config = {
    headers: {
      Authorization: `Bearer ${new_token}`,
      "Content-type": "application/json; charset=UTF-8",
    },
  };
  const reqSub = async (pckId) => {
    setLoading(true);
    try {
      await apiPost(`/private/subscriptions/requests`, { packageId: pckId }, config);
      setSuccess(true);
    } catch (err) {
      
    }
    setLoading(false);
  };

  return (
    <StyledDiv>
      <div className={showHideClassName}>
        <div className="form-layout card">
          <div>
            <div className="close" onClick={handleShow}>
              x
            </div>
          </div>

          {!success && (
            <div className="card-section">
              <div className="click-text">
                Click on your preferred Teir Plan to make a request
              </div>

              <div className="cards">
                <div className="card-sec" onClick={() => reqSub(teirOneId)}>
                  <p className="plan bold-font">Tier 1 Plan</p>
                  <p className="amount green-text bold-font">₦1,000,000</p>
                  <div className="details-section">
                    <div className="card-details">
                      <div className="details">
                        <p className="amount bold-font dot">º</p>
                        <p className="detail">
                          Ideal for small and medium teams.
                        </p>
                      </div>
                    </div>
                    <div className="card-details dashed">
                      <div className="details">
                        <p className="amount bold-font dot">º</p>
                        <p className="detail">
                          Hire up to 4 developers within a year.
                        </p>
                      </div>
                    </div>
                    <div className="card-details dashed">
                      <div className="details">
                        <p className="amount bold-font dot">º</p>
                        <p className="detail">
                          Enjoy dedicated performance management and evaluation
                          support.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-sec" onClick={() => reqSub(teirTwoId)}>
                  <p className="plan bold-font">Tier 2 Plan</p>
                  <p className="amount green-text bold-font">₦2,000,000</p>
                  <div className="details-section">
                    <div className="card-details">
                      <div className="details">
                        <p className="amount bold-font dot">º</p>
                        <p className="detail">
                          Ideal for large teams and enterprise-level
                          organizations.
                        </p>
                      </div>
                    </div>
                    <div className="card-details dashed">
                      <div className="details">
                        <p className="amount bold-font dot">º</p>
                        <p className="detail">
                          Hire up to 10 developers within a year.
                        </p>
                      </div>
                    </div>
                    <div className="card-details dashed">
                      <div className="details">
                        <p className="amount bold-font dot">º</p>
                        <p className="detail">
                          Enjoy dedicated performance management and evaluation
                          support.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {success && (
            <div className="form-success card">
              <img
                src="/images/sucess-icon.svg"
                alt="green-tick"
                className="sucess-icon"
              />
              <h2 className="form-font green-text">Success</h2>
              <p className="form-font success-msg">
                You have successfully created a new request.
              </p>
              <MyButton
                className="form-btn success-btn"
                loading={loading}
                onClick={handleShow}
              >
                View List of Subscription Request
              </MyButton>
            </div>
          )}
        </div>
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  .header-title {
    color: #045860;
    font-weight: 700;
  }
  .form-layout {
    padding: 3rem;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    margin-top: 5rem;
    width: 70%;
    margin: auto;
    position: relative;
    top: 15%;
  }
  .card-section {
    width: 100%;
    margin-bottom: 4rem;
  }
  .form-layout .cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 35rem));
    grid-gap: 1rem;
    justify-content: space-evenly;
  }
  .cards > div {
    border-radius: 10px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    cursor: pointer;
    transition: all 0.3s;
    border: 1px solid green;
  }

  .cards > div:hover {
    transform: scale(1.1);
  }
  .card-sec {
    margin-top: 2rem;
    background: #ffffff;
    box-shadow: 0px 14px 24px -12px rgba(45, 47, 72, 0.1);
    border-radius: 4px;
    text-align: center;
    padding: 2.4rem;
  }
  .bold-font {
    font-weight: 800;
    line-height: 3.2rem;
  }
  .plan {
    font-size: 1.6rem;
  }
  .amount {
    font-size: 2.4rem;
    margin-top: 1.6rem;
  }
 
  .details {
    display: flex;
  }
  .dot {
    align-items: flex-start;
    margin-right: 1rem;
  }
  .detail {
    text-align: left;
    margin-top: 1.6rem;
  }
  .card-details {
    font-size: 1.6rem;
    line-height: 2.7rem;
  }
  .dashed {
    border-top: 1px dashed rgba(33, 51, 79, 0.15);
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background: rgba(0, 0, 0, 0.6);
  }
  .modal-main {
    border-radius: 10px;
    padding: 3rem;
    position: fixed;
    background: white;
    width: 50%;
    margin: 0 auto;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .display-block {
    display: block;
  }
  .display-none {
    display: none;
  }
  .close {
    float: right;
    font-size: 3.5rem;
    color: #e31a1c;
    cursor: pointer;
  }
  .click-text {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  .form-success {
    width: 35%;
    text-align: center;
    margin: 0 auto;
  }
  .sucess-icon {
    width: 4.2rem;
    margin: 1.7rem auto;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2rem;
  }
  .success-msg {
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 32rem;
    margin: 0 auto;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
    margin-bottom: 1.7rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
  @media only screen and (min-width: 280px) and (max-width: 767px) {
    .form-layout {
      padding: 1rem;
      border: 1px solid #f0f0f0;
      border-radius: 10px;
      margin-top: 5rem;
      width: 100%;
      margin: auto;
      position: relative;
      top: 40%;
    }
    .header-title {
      color: #045860;
      font-weight: 700;
      font-size: 1rem;
      margin-top: 0%.8rem;
    }
  
    form {
      padding: 1rem 0.4rem;
      border: 1px solid #f0f0f0;
      border-radius: 10px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1365px) {
    form {
      padding: 2rem;
      border: 1px solid #f0f0f0;
      border-radius: 10px;
    }
    .form-layout {
      padding: 3rem;
      border: 1px solid #f0f0f0;
      border-radius: 10px;
      margin-top: 5rem;
      width: 70%;
      margin: auto auto;
      position: relative;
      top: 40%;
    }
  }
`;

export default AddSubRequest;
