import React from "react";
import Styled from "styled-components";
import { useTotalRegistered } from "../../../../../services/activity/query";


const TotalRegistered = ({ orgId, startDate, endDate }) => {

  const { data } = useTotalRegistered(startDate, endDate)
  
  return (
    <StyledGraph>
      <div className="header">Total number of registered users</div>
      <div className="number">{data?.data?.count}</div>
    </StyledGraph>
  );
};

const StyledGraph = Styled.div`
  overflow: auto;
  width: 100%;
  background: #fff;
  position: relative;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
  padding: 10px;

  .header {
    font-size: 1.8rem;
    font-weight: 600;
  }

  .number {
    font-size: 100px;
    font-weight: bolder;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
`;
export default TotalRegistered;
