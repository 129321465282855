import { useQuery } from "react-query";
import { fetchSubscriptionList, fetchSubscriptionRequestList } from "./api";

export const useSubscriptionList = (filterOptions, reset) => {
  const query = useQuery([filterOptions, "subscription-list"], fetchSubscriptionList, {
    keepPreviousData: true,
    onSuccess: () => reset()
  });

  return query;
};

export const useSubscriptionRequestList = (filterOptions) => {
  const query = useQuery([filterOptions, "subscription-request-list"], fetchSubscriptionRequestList, {
    keepPreviousData: true,
  });

  return query;
};
