import { useMutation, useQuery } from "react-query";
import { activateClient, activateUser, deactivateClient, deactivateUser, fetchOrganisationDocuments, fetchOrganisationInvoices, fetchOrganisations, fetchOrganisationSubscriptions, listOrgansationUsers } from "./api";

export const useFetchedOrganisations = (filterOptions, reset) => {
    const query = useQuery([filterOptions, "organisations"], fetchOrganisations,
    {
        onSuccess: () => reset && reset(),
        keepPreviousData: true,
    });
  
    return query;
};

export const useOrganisationUsers = (id, page) => {
  const query = useQuery([`organisation-users${id}`], () => listOrgansationUsers({page, id}));

  return query;
};

export const useOrganisationDocuments = (orgId) => {
  const query = useQuery([`organisation-doc${orgId}`], () => fetchOrganisationDocuments(orgId));

  return query;
};

export const useOrganisationSubscriptions = (orgId, filterOptions) => {
  const params = {orgId, filterOptions}
  const query = useQuery([params, `organisation-sub${orgId}`], fetchOrganisationSubscriptions);

  return query;
};

export const useOrganisationInvoices = (orgId) => {
  const query = useQuery([`organisation-invoice${orgId}`], () => fetchOrganisationInvoices(orgId));

  return query;
};

export const useActivateClient = (onActivateSuccess) => {
    const mutation = useMutation(
      (payload) => activateClient(payload),
      {
        onSuccess: () => onActivateSuccess(),
      }
    );
  
    return mutation;
};

export const useDeactivateClient = (onDeactivateSuccess) => {
    const mutation = useMutation(
      (payload) => deactivateClient(payload),
      {
        onSuccess: () => onDeactivateSuccess(),
      }
    );
  
    return mutation;
};

export const useDeactivateUser = (onDeactivateSuccess, id) => {
  const mutation = useMutation(
    (payload) => deactivateUser(payload, id),
    {
      onSuccess: () => onDeactivateSuccess(),
    }
  );

  return mutation;
}

export const useActivateUser = (onDeactivateSuccess, id) => {
  const mutation = useMutation(
    (payload) => activateUser(payload, id),
    {
      onSuccess: () => onDeactivateSuccess(),
    }
  );

  return mutation;
}