import React, { useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from "styled-components";
import { GrammarlyEditorPlugin } from '@grammarly/editor-sdk-react'

function FormWysiwygComponent({
    label,
    name,
    value,
    onChange,
    error,
    maxLength,
  }) {
  const inputRef = useRef(null);
    
  return (
    <StyledFormInputComponent>
      {label && (
        <label htmlFor={name} className="form-font form-label">
          {label} <span style={{fontSize: "10px"}}>{maxLength ? `${value?.length || 0}/${maxLength}` : ""}</span>
        </label>
      )}
        <GrammarlyEditorPlugin 
            clientId="client_1HVSLd98euSdd7jChbQmW8" 
            config={{
            activation: "immediate",
            // autocomplete: "on",
            underlines: "on",
            // toneDetector: "on"
        }}>
            <ReactQuill 
                ref={inputRef} 
                theme="snow" 
                value={value} 
                onChange={(e)=> {
                    onChange(e)
                }} 
            />
        </GrammarlyEditorPlugin>
        {error && <div className="form-error">{error}</div>}
    </StyledFormInputComponent>
)}

const StyledFormInputComponent = styled.div`
  margin-bottom: 2.4rem;
  .ql-container {
    height: 15rem
  }
`;

export default FormWysiwygComponent