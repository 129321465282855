import React, { useState, useEffect } from "react";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import FormTitleSection from "./FormTitleSection";
import FormUploadComponent from "./enterpriseFormInputs/FormUploadComponent";
import { apiPost } from "../../../utils/apiHelpers";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

const UploadAssessmentForm = ({ onClick, reload, refetch }) => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [bulkDocError, setBulkDocError] = useState("");
  const [bulkDoc, setBulkDoc] = useState({});

  const docType = ["xlsx", "xls"];

  useEffect(() => {
    setBulkDocError("");
  }, [bulkDoc]);

  const new_token = localStorage.getItem("token");

  let multipartConfig = {
    headers: {
      Authorization: `Bearer ${new_token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  //create Engineer
  const createAssessment = async () => {
    if (!bulkDoc.name) {
      setBulkDocError("*Required");
      return;
    }

    let formData = new FormData();
    formData.append("file", bulkDoc);
    setLoading(true);

    //make call to the api
    try {
      let response = await apiPost(
        `/admin/training-data/upload`,
        formData,
        multipartConfig
      );
      if (response.error) {
        throw response.data.response.status === 500
          ? new Error("")
          : response.data;
      }
      reload && reload();
      setSuccess(true);
      setLoading(false);
      onClick();
      toast.success("Assessment uploaded successfully");
    } catch (error) {
      setLoading(false);
    }
  };

  const closeForm = () => {
    // formik.resetForm();
    onClick();
    setSuccess(false);
  };
  // onSubmit={onSubmit}
  return (
    <StyledAddEngForm success={success}>
      <FormTitleSection title="Add Assessment" onClick={closeForm} />
      <div className="form-section">
        <form className="client-form">
          <FormUploadComponent
            docType={docType}
            label="Upload Assessment"
            error={bulkDocError}
            selectedFile={bulkDoc}
            setSelectedFile={setBulkDoc}
            acceptTypes={`xlsx`}
          />

          <MyButton
            className="form-btn add-client-btn"
            onClick={async () => {
              await createAssessment();
              refetch();
            }}
            full
          >
            {" "}
            {loading ? (
              <ClipLoader color={"#fff"} size={"30px"} />
            ) : (
              "Add Assessment"
            )}
          </MyButton>
        </form>
        {success && (
          <div className="form-success">
            <img
              src="/images/sucess-icon.svg"
              alt="green-tick"
              className="sucess-icon"
            />
            <h2 className="form-font green-text">Success</h2>
            <p className="form-font success-msg">
              You successfully added Assessment.
            </p>
            <MyButton className="form-btn success-btn" onClick={closeForm}>
              Okay, Go to Assessment Page
            </MyButton>
          </div>
        )}
      </div>
    </StyledAddEngForm>
  );
};

const StyledAddEngForm = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);
  .underline {
    display: block;
  }
  .form-section {
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3.2rem;
  }
  .add-client-btn {
    margin-bottom: 23.8rem;
  }
  .stack-section {
    display: flex;
    flex-wrap: wrap;
    width: 32rem;
  }
  .client-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 32rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
`;

export default UploadAssessmentForm;
