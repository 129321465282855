import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import FormInputComponent from "./enterpriseFormInputs/FormInputComponent";
import FormTitleSection from "./FormTitleSection";
import { useAdmin } from "../../../context/AdminState";
// import FormSelectComponent from "./enterpriseFormInputs/FormSelectComponent";
import {
  useCreateTrainingData,
  useEditTrainingData,
  useSingleTrainingData,
} from "../../../services/trainingData/query";
import { toast } from "react-toastify";
const EditAsessmentForm = ({
  onClick,
  isEditing = false,
  updateId,
  reload,
}) => {
  const [initialValues] = useState({
    firstName: "",
    lastName: "",
    algorithm: "",
    weeklyTask: "",
    assessmentTask: "",
    agileTest: "",
    cummulative: "",
  });

  const { fetchSquadsModal } = useAdmin();

  const { data, refetch } = useSingleTrainingData(updateId);

  useEffect(() => {
    if (isEditing) {
      if (data?.data) {
        const fields = [
          "title",
          "code",
          "minScore",
          "maxScore",
          "trainingDataPointCategory",
        ];
        fields.forEach((field) =>
          formik.setFieldValue(field, data?.data[field], false)
        );
      }
    }
    // eslint-disable-next-line
  }, [isEditing, data]);

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    code: Yup.string("Required").max(3, "Must not be more than 3 characters"),
    minScore: Yup.string()
      .required("Required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(1, "Required"),
    maxScore: Yup.number().required("Required"),
    trainingDataPointCategory: Yup.string().required("Required"),
  });

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const new_token = localStorage.getItem("token");

  let multipartConfig = {
    headers: {
      Authorization: `Bearer ${new_token}`,
      "Content-Type": "application/json; charset=UTF-8",
    },
  };

  const { mutate: postTrainingData } = useCreateTrainingData(multipartConfig);

  const { mutate } = useEditTrainingData(multipartConfig);
  //create users
  const createSquad = async (formData) => {
    setLoading(true);
    //make call to the api
    try {
      if (!isEditing) {
        postTrainingData(formData, {
          onSuccess: () => {
            reload();
            setSuccess(true);
            setLoading(false);
          },
          onError: (error) => {
            setSuccess(false);
            setLoading(false);
          },
        });
      }
      if (isEditing) {
        mutate(
          { updateId, formData },
          {
            onSuccess: () => {
              toast.success("Edited Successfully");
              refetch();
              reload();
              setSuccess(true);
              setLoading(false);
            },

            onError: (error) => {
              console.log(error, "error");
              setSuccess(false);
              setLoading(false);
            },
          }
        );
      }
    } catch (err) {
      console.log({ err });
      return err;
    }
  };

  const onSubmit = (values) => {
    createSquad(values);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    fetchSquadsModal();
    // eslint-disable-next-line
  }, [success]);
  const closeForm = () => {
    formik.resetForm();
    onClick();
    setSuccess(false);
  };

  return (
    <StyledAddUserForm success={success}>
      <FormTitleSection
        title={'Edit Assessment'}
        onClick={closeForm}
      />
      <div className="form-section">
        <form onSubmit={formik.handleSubmit} className="client-form">
          <FormInputComponent
            label="First Name"
            type="text"
            name="firstName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            error={
              formik.touched.firstName && formik.errors.firstName
                ? formik.errors.firstName
                : null
            }
            required={true}
          />
          <FormInputComponent
            label="Last Name"
            type="text"
            name="lastName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            error={
              formik.touched.lastName && formik.errors.lastName
                ? formik.errors.lastName
                : null
            }
            required={true}
            disabled={isEditing}
          />
          <FormInputComponent
            label="Algorithm"
            type="text"
            name="algorithm"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.algorithm}
            error={
              formik.touched.algorithm && formik.errors.algorithm
                ? formik.errors.algorithm
                : null
            }
            required={true}
          />
          <FormInputComponent
            label="Weekly Task "
            type="number"
            name="weeklyTask"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.weeklyTask}
            error={
              formik.touched.weeklyTask && formik.errors.weeklyTask
                ? formik.errors.weeklyTask
                : null
            }
            required={true}
          />

          <FormInputComponent
            label="Assessment Task "
            type="number"
            name="assessmentTask"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.assessmentTask}
            error={
              formik.touched.assessmentTask && formik.errors.assessmentTask
                ? formik.errors.assessmentTask
                : null
            }
            required={true}
          />

          <FormInputComponent
            label="Agile Task "
            type="number"
            name="agileTask"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.agileTask}
            error={
              formik.touched.agileTask && formik.errors.agileTask
                ? formik.errors.agileTask
                : null
            }
            required={true}
          />

          <FormInputComponent
            label="Cummulative "
            type="number"
            name="cummulative"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.cummulative}
            error={
              formik.touched.cummulative && formik.errors.cummulative
                ? formik.errors.cummulative
                : null
            }
            required={true}
          />

          {/* <FormSelectComponent
            label="Category"
            name="trainingDataPointCategory"
            options={[
              { label: "Select Category", value: "" },
              { label: "Technical", value: "TECHNICAL" },
              { label: "Agile", value: "AGILE" },
            ]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.trainingDataPointCategory}
            error={
              formik.touched.trainingDataPointCategory &&
              formik.errors.trainingDataPointCategory
                ? formik.errors.trainingDataPointCategory
                : null
            }
            required={true}
          /> */}

          <MyButton
            className="form-btn add-client-btn"
            loading={loading}
            type="submit"
            full
          >
            {isEditing ? "Update Data Point" : " Add New Data Point"}
          </MyButton>
        </form>
        <div className="form-success card">
          <img
            src="/images/sucess-icon.svg"
            alt="green-tick"
            className="sucess-icon"
          />
          <h2 className="form-font green-text">Success</h2>
          <p className="form-font success-msg">
            You successfully added a Training Data.
          </p>
          <MyButton className="form-btn success-btn" onClick={closeForm}>
            View List of Training Data
          </MyButton>
        </div>
      </div>
    </StyledAddUserForm>
  );
};

const StyledAddUserForm = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);
  .underline {
    display: block;
  }
  input .assig-date {
    width: 100% !important;
  }
  .form-section {
    width: 500px;
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3.2rem;
  }
  .add-client-btn {
    margin-bottom: 23.8rem;
    margin-top: 5rem;
  }
  .client-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .stack-section {
    display: flex;
    flex-wrap: wrap;
    width: 32rem;
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    margin-top: 8rem;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2.rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 32rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
  .select-field {
    // max-width: 35rem;
  }
  .perm-label {
    color: #21334f;
    font-size: 1.6rem;
    font-weight: 600;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    grid-column-gap: 1rem;
  }
  .checkbox-area {
    -webkit-appearance: button;
    margin-right: 1.5rem;
    display: none;
  }
  .check-label {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }
  .checkmark {
    width: 25px;
    height: 25px;
    border: 2px solid #34a853;
    display: inline-block;
    border-radius: 3px;
    margin-right: 1rem;
    background: #34a853
      url("https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/White_check.svg/1200px-White_check.svg.png")
      center/1250% no-repeat;
    transition: background-size 0.2s cubic-bezier(0.7, 0, 0.18, 1.24);
  }
  .check-label input:checked + .checkmark {
    background-size: 60%;
    transition: background-size 0.25s cubic-bezier(0.7, 0, 0.18, 1.24);
  }
  .item-span {
    font-size: 1.3rem;
    font-weight: 600;
  }
  .perm-head {
    font-size: 1.6rem;
    font-weight: 600;
  }
`;

export default EditAsessmentForm;
