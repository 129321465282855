import { apiGet } from "../../utils/apiHelpers";

export const fetchTechstacks = async () => {
  try {
    const res = await apiGet(`/reference/techstacks`);

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchFrameworks = async () => {
  try {
    let response = await apiGet(`/reference/frameworks`, {});

    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
    return response.data.data;
  } catch (err) {
    throw err
  }
};

export const fetchSquads = async () => {
  try {
    const res = await apiGet(`/reference/squads`);

    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const fetchWeeks = async (params) => {
  const [squadId] = params.queryKey;

  try {
    const res = await apiGet(`/admin/weeks/${squadId}`);

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchLocations = async (params) => {
  const [locType] = params.queryKey;
  try {
    const res = await apiGet(`/reference/locations?locType=${locType}`);

    return res.data;
  } catch (err) {
    console.log(err);
  }
};
