import {
    fetchPackages,
    fetchSinglePackage,
    createPackage,
    editPackage,
  } from "./api";
  import { useQuery, useMutation } from "react-query";
  
  export const useFetchedPackageList = () => {
    const query = useQuery(["package-list"], () => fetchPackages());
  
    return query;
  };
  
  export const useFetchedSinglePackage = (id) => {
    const query = useQuery([id, "single-package"], fetchSinglePackage, {
      enabled: id ? true : false,
      cacheTime: 0
    });
  
    return query;
  };
  
  export const useCreatePackage = () => {
    const mutation = useMutation((params) => createPackage(params));
  
    return mutation;
  };
  
  export const useEditPackage = () => {
    const mutation = useMutation((params) => editPackage(params, {}));
  
    return mutation;
  };
  