import React, { useState } from "react";
import styled from "styled-components";
import Layout from "../common/Layout";
import DynamicTitle from "../common/DynamicTitle";
import ClientActivityTracker from "../admin/ActivityTrackerView/ClientActivityTracker";
import AdminActivityTracker from "../admin/ActivityTrackerView/AdminActivityTracker";
import DevprofileTracker from "../admin/ActivityTrackerView/DevprofileTracker";

const AdminActivityTracking = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeModal] = useState(false);

  return (
    <Layout navType="admin" hasBackground>
      <DynamicTitle title="Activity | Fellowship Product" />
      <StyledActivityPage activeModal={activeModal}>
        <div className="btn-wrapper">
          <button
            onClick={() => setActiveTab(0)}
            className={activeTab === 0 ? "active" : "inactive"}>
            Client Activities Tracking
          </button>
          <button
            onClick={() => setActiveTab(1)}
            className={activeTab === 1 ? "active" : "inactive"}>
            Admin Activities Tracking
          </button>
          <button
            onClick={() => setActiveTab(2)}
            className={activeTab === 2 ? "active" : "inactive"}>
            Devprofile Activities Tracking
          </button>
        </div>
        <div className={`${activeTab === 0 ? "d-block" : "d-none"}`}>
          <ClientActivityTracker pageType="client-activity" />
        </div>
        <div className={`${activeTab === 1 ? "d-block" : "d-none"}`}>
          <AdminActivityTracker pageType="admin-activity" />
        </div>
        <div className={`${activeTab === 2 ? "d-block" : "d-none"}`}>
          <DevprofileTracker pageType="devprofile-activity" />
        </div>
      </StyledActivityPage>
    </Layout>
  );
};

const StyledActivityPage = styled.div`
  overflow-x: hidden;
  .btn-wrapper {
    margin-bottom: 4.2rem;
  }

  button {
    border: none;
    font-size: 2rem;
  }

  button:first-of-type {
    margin-right: 24px;
  }

  button:last-of-type {
    margin-left: 24px;
  }

  .active {
    color: #34a853;
    background: #fff;
    padding: 16px;
    border: 1px solid rgba(52, 168, 83, 0.26);
  }

  .inactive {
    color: #21334f;
    background: transparent;
  }

  table {
    border-collapse: separate;
    margin-bottom: 10px;
    white-space: nowrap;
    flex: 1 1 100%;
    width: 0;
  }

  tbody {
    overflow-x: scroll;
  }

  td {
    border: none;
    font-size: 16px;
    border-bottom: 1px solid black;
    padding: 15px 10px;
  }

  @media screen and (max-width: 768px) {
    button:first-of-type {
      margin-right: 8px;
    }

    button {
      border: none;
      font-size: 1.6rem;
    }
  }
`;

export default AdminActivityTracking;
