import { apiGet, apiPost, apiPut, apiDelete } from "../../utils/apiHelpers";

export const fetchBillingRates = async () => {
  try {
    let response = await apiGet(`/admin/billings`, {});

    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
    return response.data.data;
  } catch (err) {
    throw err;
  }
};

export const fetchSingleBillingRate = async (params) => {
  const [id] = params.queryKey;
  try {
    let response = await apiGet(`/admin/billings/${id}`, {});

    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const createBillingRate = async (formData) => {
  try {
    let response = await apiPost(`/admin/billings`, formData, {});

    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
  } catch (err) {
    console.log({ err });
    throw err;
  }
};

export const editBillingRate = async (data) => {
  try {
    let response = null;
    const { billingRateId, payload } = data;
    if (data) {
      response = await apiPut(`/admin/billings/${billingRateId}`, payload);
    }
    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
  } catch (error) {
    console.log({ error });
    throw error;
  }
};

export const deleteBillingRate = async (id) => {
  try {
    let response = await apiDelete(`/admin/billings/${id}`);
    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
  } catch (err) {
    console.log({ err });
    throw err;
  }
};
