import {
  fetchTechstacks,
  fetchSingleTechstack,
  createTechstack,
  editTechstack,
} from "./api";
import { useQuery, useMutation } from "react-query";

export const useFetchedTechstackList = () => {
  const query = useQuery(["techstack-list"], () => fetchTechstacks());

  return query;
};

export const useFetchedSingleTechstack = (id) => {
  const query = useQuery([id, "single-techstack"], fetchSingleTechstack, {
    enabled: id ? true : false,
  });

  return query;
};

export const useCreateTechstack = () => {
  const mutation = useMutation((params) => createTechstack(params));

  return mutation;
};

export const useEditTechstack = () => {
  const mutation = useMutation((params) => editTechstack(params, {}));

  return mutation;
};
