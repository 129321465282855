import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import FormInputComponent from "./enterpriseFormInputs/FormInputComponent";
import FormTitleSection from "./FormTitleSection";
import { ClipLoader } from "react-spinners";
import { useCreatePackage } from "../../../services/package/query";

const initialValues = {
  name: "",
  amount: "",
  duration: "",
  numOfEngineers: "",
  description: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required("*Required"),
  amount: Yup.string().required("*Required"),
  duration: Yup.number().required("*Required"),
  numOfEngineers: Yup.number().required("*Required"),
  description: Yup.string().required("*Required"),
});

const CreatePackageForm = ({ handleModal, refetch }) => {
  const [success, setSuccess] = useState(false);

  const { mutate: createPackage, isLoading } = useCreatePackage();

  const onSubmit = (values) => {
    let newNum = values.amount.split("");
    let amt = newNum.filter((a) => a !== "₦" && a !== "," && a).join("");
    values.amount = parseInt(amt);
    createPackage(values, {
      onSuccess: () => {
        refetch();
        setSuccess(true);
      },
      onError: (error) => {
        setSuccess(false);
      },
    });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const closeForm = () => {
    formik.resetForm();
    handleModal();
    setSuccess(false);
  };

  return (
    <StyledCreatePackageForm success={success}>
      <FormTitleSection title="Create Package" onClick={closeForm} />
      <div className="form-section">
        <form onSubmit={formik.handleSubmit} className="client-form">
          <p className="form-font form-title">Package Details</p>

          <FormInputComponent
            label="Amount Paid"
            name="amount"
            placeholder="Enter amount paid"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            prefix="₦"
            allowDecimals={false}
            value={formik.values.amount}
            error={
              formik.touched.amount && formik.errors.amount
                ? formik.errors.amount
                : null
            }
          />

          <FormInputComponent
            label="Package Name"
            type="text"
            name="name"
            placeholder="Enter package name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : null
            }
          />
          <FormInputComponent
            label="Duration (value should be in years)"
            type="number"
            name="duration"
            placeholder="Enter duration"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.duration}
            error={
              formik.touched.duration && formik.errors.duration
                ? formik.errors.duration
                : null
            }
          />
          <FormInputComponent
            label="Number Of Engineers"
            type="number"
            name="numOfEngineers"
            placeholder="Enter number of Engineers"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.numOfEngineers}
            error={
              formik.touched.numOfEngineers && formik.errors.numOfEngineers
                ? formik.errors.numOfEngineers
                : null
            }
          />
          <FormInputComponent
            label="Description"
            type="text"
            name="description"
            placeholder="Enter description"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
            error={
              formik.touched.description && formik.errors.description
                ? formik.errors.description
                : null
            }
          />
          <MyButton className="form-btn add-client-btn" type={"submit"} full>
            {isLoading ? (
              <ClipLoader color={"#fff"} size={"30px"} />
            ) : (
              "Add New Package"
            )}
          </MyButton>
        </form>
        <div className="form-success card py-2">
          <img
            src="/images/sucess-icon.svg"
            alt="green-tick"
            className="sucess-icon"
          />
          <h2 className="form-font green-text">Success</h2>
          <p className="form-font success-msg">
            You have successfully created a package.
          </p>
          <MyButton className="form-btn success-btn" onClick={closeForm}>
            Okay, View list of packages
          </MyButton>
        </div>
      </div>
    </StyledCreatePackageForm>
  );
};

const StyledCreatePackageForm = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);
  .form-section {
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3.2rem;
  }
  .add-client-btn {
    margin-bottom: 23.8rem;
  }
  .client-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2.rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 100% !important;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }

  .pck-input {
    display: inline-block;
    font-size: 1.6rem;
    background: transparent;
    -webkit-appearance: none;
    min-width: 32rem;
    border: none;
    height: 4.8rem;
    padding-bottom: 0rem;
    padding-left: 1rem;
    border: 1px solid rgba(33, 51, 79, 0.15) !important;
  }
  @media only screen and (max-width: 405px) {
    .pck-input {
      min-width: 100%;
    }
  }
`;

export default CreatePackageForm;
