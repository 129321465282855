import {
  LOADING,
  FETCH_CLIENT_SUCCESS,
  FETCH_NEW_CLIENT_SUCCESS,
  FETCH_ENGINEER_SUCCESS,
  FETCH_TOP_ENGINEER_SUCCESS,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_CLIENT_SUB_SUCCESS,
  FETCH_ASSIGNMENT_INFO,
  FETCH_STACK_SUCCESS,
  FETCH_CLIENT_ENG_SUCCESS,
  FETCH_USER_SUCCESS,
  ORG_SUB_SUCCESS,
  FETCH_PACKAGE_SUCCESS,
  FETCH_INDUSTRY_SUCCESS,
  FETCH_SQUAD_SUCCESS,
  ASSIGNMENT_LISTING,
  FETCH_TECH_STACK_SUCCESS,
  FETCH_UNASSIGNED,
  FETCH_SUB_REQ_SUCCESS,
  FETCH_ENG_REQ_SUCCESS,
  FETCH_ADMIN_ENG_SUCCESS,
  FETCH_ADMIN_SUB_SUCCESS,
  FETCH_DOCUMENT_SUCCESS,
  FETCH_BILLING_SUCCESS,
  INCREMENT_PAGE,
  DECREMENT_PAGE,
  SET_PAGE,
  SET_KEYWORD,
  SET_STATUS,
  SET_ENG_STATUS,
  SET_LANGUAGE,
  SET_SQUAD,
  SET_ENGINEER_STATUS,
  SET_CLIENT_TYPE,
  SET_CUSTOMER_TYPE,
  SET_ENGINEER_TYPE,
  OFF_SEARCH_LOADER,
  ON_SEARCH_LOADER,
  ON_FILTER_LOADER,
  OFF_FILTER_LOADER,
  SET_LABELS,
  SET_SQUAD_DETAILS,
  SET_SQUAD_ID,
  FETCH_PLACEMENT_HISTORY,
  SET_OPEN_RATING_MODAL,
  SET_CLOSE_RATING_MODAL,
  FETCH_ENGINEER_DETAILS,
  FETCH_INVOICE_SUCCESS,
  FETECH_LOCATION_SUCCESS,
  FETECH_TRAINING_LOCATION_SUCCESS,
  SET_CURRENT_LOCATION,
  SET_MIN_AGE,
SET_MAX_AGE,
  SET_TRAINING_LOCATION,
  SET_ENG_GENDER,
  SET_ENG_AGE,
} from "./types";

export default function AdminReducer(state, action) {
  switch (action.type) {
    case INCREMENT_PAGE:
      return {
        ...state,
        page: state.page + 1,
      };
    case DECREMENT_PAGE:
      return {
        ...state,
        page: state.page - 1,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case SET_KEYWORD:
      return {
        ...state,
        keyword: action.payload,
      };
    case SET_STATUS:
      return {
        ...state,
        status: action.payload,
      };
      case SET_ENG_STATUS:
      return {
        ...state,
        engStatus: action.payload,
      };
      case SET_CLIENT_TYPE:
      return {
        ...state,
        clientType: action.payload,
      };
      case SET_CUSTOMER_TYPE:
      return {
        ...state,
        customerType: action.payload,
      };
      case SET_ENGINEER_TYPE:
      return {
        ...state,
        engineerType: action.payload,
      };
      case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
      case SET_SQUAD:
      return {
        ...state,
        squad: action.payload,
      };
      case SET_SQUAD_ID:
      return {
        ...state,
        squadId: action.payload,
      };
      case SET_CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: action.payload,
      };
      case SET_MIN_AGE:
      return {
        ...state,
        minAge: action.payload,
      };
      case SET_MAX_AGE:
      return {
        ...state,
        maxAge: action.payload,
      };

      case SET_TRAINING_LOCATION:
      return {
        ...state,
        trainingLocation: action.payload,
      };
      case SET_ENG_GENDER:
      return {
        ...state,
        engineerGender: action.payload,
      };
      case SET_ENG_AGE:
      return {
        ...state,
        engineerAge: action.payload,
      };
      case SET_ENGINEER_STATUS:
      return {
        ...state,
        engineerStatus: action.payload,
      };
    case FETCH_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: {
          clients: action.payload,
          totalPages: action.meta.totalPages,
          first: action.meta.first,
          last: action.meta.last,
          totalElements: action.totalClients
        },
      };
    case FETCH_NEW_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: { ...state.dashboardData, overview: action.payload },
      };
    case FETCH_ADMIN_ENG_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: {
          adminEngReq: action.payload,
          totalPages: action.meta.totalPages,
          first: action.meta.first,
          last: action.meta.last,
        },
      };
    case FETCH_ADMIN_SUB_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: {
          adminSubReq: action.payload,
          totalPages: action.meta.totalPages,
          first: action.meta.first,
          last: action.meta.last,
        },
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: { ...state.dashboardData, users: action.payload },
      };
      case FETCH_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: { ...state.dashboardData, document: action.payload },
      };
      case FETCH_INVOICE_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: { ...state.dashboardData, invoice: action.payload },
      };

      case FETECH_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        locations: { ...state.locations, locations: action.payload },
      };

      case FETECH_TRAINING_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        trainingLocations: { ...state.trainingLocations, trainingLocations: action.payload },
      };
      case FETCH_BILLING_SUCCESS:
        return {
          ...state,
          loading: false,
          dashboardData: { ...state.dashboardData, billing: action.payload},
          levelList: [...action.payload]
        };
    case ORG_SUB_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: { ...state.dashboardData, sub: action.payload },
      };
    case FETCH_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: { package: action.payload },
      };
    case FETCH_INDUSTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: { ...state.dashboardData, industry: action.payload },
      };
    case ASSIGNMENT_LISTING:
      return {
        ...state,
        loading: false,
        dashboardData: { ...state.dashboardData, assignments: action.payload },
      };
    case FETCH_SQUAD_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: {
          squad: action.payload,
          totalPages: action.meta.totalPages,
          first: action.meta.first,
          last: action.meta.last,
        },
      };
    case FETCH_TECH_STACK_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: { ...state.dashboardData, techstack: action.payload },
      };

    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case FETCH_ENGINEER_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: {
          engineers: action.payload,
          totalPages: action.meta.totalPages,
          first: action.meta.first,
          last: action.meta.last,
          totalElements: action.totalEngineers
        },
      };
    case FETCH_TOP_ENGINEER_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: {
          ...state.dashboardData,
          engineers_overview: action.payload,
        },
      };
    case FETCH_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: {
          subscriptions: action.payload,
          totalPages: action.meta.totalPages,
          first: action.meta.first,
          last: action.meta.last,
        },
      };
    case FETCH_CLIENT_SUB_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: {
          client_subscriptions: action.payload,
          totalPages: action.meta.totalPages,
          first: action.meta.first,
          last: action.meta.last,
        },
      };
    case FETCH_CLIENT_ENG_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: {
          client_engineers: action.payload,
          totalPages: action.meta.totalPages,
          first: action.meta.first,
          last: action.meta.last,
        },
      };
    case FETCH_STACK_SUCCESS:
      return {
        ...state,
        loading: false,
        stackList: action.payload,
      };
    case FETCH_ASSIGNMENT_INFO:
      return {
        ...state,
        loading: false,
        assignmentStatusList: action.payload.assignmentStatusList,
        organisationsList: action.payload.organisationsList,
      };
    case FETCH_UNASSIGNED:
      return {
        ...state,
        loading: false,
        engineersList: action.payload,
      };
    case FETCH_SUB_REQ_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: {
          clientSubReq: action.payload,
          totalPages: action.meta.totalPages,
          first: action.meta.first,
          last: action.meta.last,
        },
      };
    case FETCH_ENG_REQ_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardData: {
          clientEngineersReq: action.payload,
          totalPages: action.meta.totalPages,
          first: action.meta.first,
          last: action.meta.last,
        },
      };
    case FETCH_PLACEMENT_HISTORY: 
      return {
        ...state,
        loading: false,
        dashboardData: {
          "placement-history": action.payload,
          totalPages: action.meta.totalPages,
          first: action.meta.first,
          last: action.meta.last,
          totalElements: action.totalEngineers
        },
      };
      
    case FETCH_ENGINEER_DETAILS: 
      return {
        ...state,
        loading: false,
        engDetails: action.payload,
    };
    case OFF_SEARCH_LOADER:
      return {
        ...state,
        searchLoader: false,
      };
    case ON_SEARCH_LOADER:
      return {
        ...state,
        searchLoader: true,
      };
    case ON_FILTER_LOADER:
      return {
        ...state,
        filterLoader: true,
      };
    case OFF_FILTER_LOADER:
      return {
        ...state,
        filterLoader: false,
      }
    case SET_LABELS:
      return {
        ...state,
        labels: {
          languagesLabel: { ...action.payload.languagesLabel },
          statusesLabel: { ...action.payload.statusesLabel },
          companyTypeLabel: { ...action.payload.companyTypeLabel },
          decaDevsLabel: { ...action.payload.decaDevsLabel },
          isLoading: false,
        },
      };
    case SET_SQUAD_DETAILS:
      return {
        ...state,
        squadDetails: {
          currentState: action.payload.currentState,
          squads: action.payload.squads,
          squadsObj: { ...action.payload.squadsObj},
          currentSquad: action.payload.currentSquad
        }
      };
      case SET_OPEN_RATING_MODAL:
      return {
        ...state,
        openRatingModal: {
          open: true,
          assignmentId: action.payload.assignmentId
        }
      };
      case SET_CLOSE_RATING_MODAL:
      return {
        ...state,
        openRatingModal: {
          open: false,
          assignmentId: ''
        }
      };
    default:
      return state;
  }
}
