function formatDate(str) {
  const moonLanding = new Date(str);
  // const getYear = (str|| "").split("-");
  // const year = Number(getYear[0]);
  let year;

  if (str) {
    year = str.slice(-4);
  }
  // console.log(getYear);

  let mon = moonLanding.getMonth();
  if (mon === 0) {
    return `January, ${year}`;
  } else if (mon === 1) {
    return `Feburary, ${year}`;
  } else if (mon === 2) {
    return `March, ${year}`;
  } else if (mon === 3) {
    return `April, ${year}`;
  } else if (mon === 4) {
    return `May, ${year}`;
  } else if (mon === 5) {
    return `June, ${year}`;
  } else if (mon === 6) {
    return `July, ${year}`;
  } else if (mon === 7) {
    return `August, ${year}`;
  } else if (mon === 8) {
    return `September, ${year}`;
  } else if (mon === 9) {
    return `October, ${year}`;
  } else if (mon === 10) {
    return `November, ${year}`;
  } else if (mon === 11) {
    return `December, ${year}`;
  }
}

function TableDateFormat(str) {
  let date = str;
  let dateSplit = date.split("-");
  let newArr = [];
  newArr[0] = dateSplit[1];
  newArr[1] = dateSplit[2];
  newArr[2] = dateSplit[0];
  let formatted = newArr.join("-").slice(0, 10).replace(/-/g, "/");
  return formatted;
}
let exported = {
  formatDate,
  TableDateFormat,
};

export const formatEngineerRatingDate = (date) => {
  if (!date) return "";
  const newDate = new Date(date);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  return newDate.toLocaleString("en-GB", options);
};
export default exported;
