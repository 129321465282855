import {
  fetchFrameworks,
  fetchSingleFramework,
  createFramework,
  editFramework,
} from "./api";
import { useQuery, useMutation } from "react-query";

export const useFetchedFrameworkList = () => {
  const query = useQuery(["framework-list"], () => fetchFrameworks());

  return query;
};

export const useFetchedSingleFramework = (id) => {
  const query = useQuery([id, "single-framework"], fetchSingleFramework, {
    enabled: id ? true : false,
  });

  return query;
};

export const useCreateFramework = () => {
  const mutation = useMutation((params) => createFramework(params));

  return mutation;
};

export const useEditFramework = () => {
  const mutation = useMutation((params) => editFramework(params, {}));
  return mutation;
};
