import React, { useState } from "react";
import styled from "styled-components";
import { Bar } from "react-chartjs-2";
import ActivityDropdown from "../../atoms/ActivityDropdown";

const ActivityTrackerBar = ({ language, count, header, setPeriod, numberOfEngineers }) => {
  const [currentState, setCurrentState] = useState("All");

  const data = {
    labels: language,
    datasets: [
      {
        label: "Engineer",
        backgroundColor: "#34A853",
        borderColor: "rgb(255, 99, 132)",
        data: numberOfEngineers,
        tension: 0.6,
        fill: true,
        pointRadius: 4,
        pointBorderColor: "#34A853",
        pointBackgroundColor: "yellow",
        pointStyle: "circle",
      },
    ],
  };

  
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      title: {
        display: false,
        text: "Request Engineer",
        font: {
          size: 20,
        },
      },
      legend: {
        display: false,
        labels: {
          boxHeight: 0,
          boxWidth: 15,
          usePointStyle: true,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          display: false,
        },
        grid: {
          borderDash: [4, 2],
          color: "#D7DBDE",
        },
      },
    },
  };

  return (
    <StyledTracker language={language}>
      <div className="card">
        <div className="header">
          <ActivityDropdown
            header={header}
            setPeriod={setPeriod}
            currentState={currentState}
            setCurrentState={setCurrentState}
          />
        </div>
        <div className="content">
          <div className="bar">
            <div className="p-4">
              <Bar options={options} data={data} />
            </div>
          </div>
        </div>
      </div>
    </StyledTracker>
  );
};

const StyledTracker = styled.div`
  .card {
    width: 100%;
    height: 360px;
    background: #fff;
    position: relative;
    .header {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }
    select {
      padding: 5px 20px 5px 2px;
      border: 1px solid #f1f1f1;
    }
    .bar {
      margin-top: 4rem;
      height: 100%;
      overflow-x: scroll;
    }
  }
  .label-container {
    /* width: 100%; */
    display: flex;
    /* grid-template-columns: repeat(${({ language }) =>
      language.length}, 1fr);  */
    padding: 0rem;
    margin-top: -10rem;
    gap: 1rem;
    align-items: center;
    div {
      font-weight: 600;
      font-size: 1.5rem;
      color: #000;
      transform: rotate(-90deg);
      display: flex;
      align-items: center;
      width: 100%;
      height: 3rem;
      justify-self: left;
    }
  }
`;

export default ActivityTrackerBar;
