import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
// import * as Yup from "yup";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import Select from "react-select";
import FormTitleSection from "./FormTitleSection";
import FormSelectComponent from "./enterpriseFormInputs/FormSelectComponent";
import { useAdmin } from "../../../context/AdminState";
import FormInputComponent from "./enterpriseFormInputs/FormInputComponent";
import { apiPut, apiGet } from "../../../utils/apiHelpers";
import { toast } from "react-toastify";
import FormUploadComponent from "./enterpriseFormInputs/FormUploadComponent";
import { formatErrors } from "../../../utils/helperFunctions";
import { ClipLoader } from "react-spinners";
import FormTextAreaComponent from "./enterpriseFormInputs/FormTextAreaComponent";
import { locationOption, placement, placementSource, placementStatus } from "../../../data";

const EditAssignmentForm = ({
  handleEditAssignment,
  assignmentId,
  engId,
  companyLocation,
  refetch
}) => {
  const formatDate = (date) => {
    return date.split("-").reverse().join("/");
  };

  const formatToIsoDate = (date) => {
    if(!date){
      return ""
    }
    return new Date(date).toISOString().slice(0, 10);
  };

  const docType = ["pdf", "docx", "doc"];

  const [placementValues, setPlacementValues] = useState({
    engineerId: engId || "",
    organisationId: "",
    placementType: "",
    placementStatus: "",
    assignmentDate: "",
    expectedEndDate: "",
    resumptionDate: "",
    workLocation: "",
    comment: "",
  });

  if (companyLocation === "International") {
    placementValues.billingRate = "";
  }

  const {
    fetchAssignmentInfo,
    fetchUnassigned,
    fetchBillings,
    organisationsList,
    levelList,
  } = useAdmin();

  useEffect(() => {
    fetchAsignmentDetails();
    fetchAssignmentInfo();
    fetchBillings();
    fetchUnassigned();
    // eslint-disable-next-line
  }, []);

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assignmentDetails, setAssignmentDetails] = useState({});
  const [contractDoc, setContractDoc] = useState({});

  const [contractDocError, setContractDocError] = useState("");

  useEffect(() => {
    setContractDocError("");
  }, [contractDoc]);

  const levelOptions = [];
  let newList = levelList?.map((lev) => ({
    value: lev.id,
    label: lev.engineerLevel,
  }));

  levelOptions.push({ label: "Select Engineer Level", value: "" }, ...newList);

  const organizationOptions = organisationsList.map((eng) => ({
    value: eng.id,
    label: eng.name,
  }));

  // const assignmentOptions = assignmentStatusList.map((eng) => ({ value: eng.id, label: eng.name }));

  const onSubmit = (allValues, { setFieldError }) => {
    const payload = {
      assignmentDate: formatDate(allValues.assignmentDate),
      expectedEndDate: formatDate(allValues.endDate),
      resumptionDate: formatDate(allValues.resumptionDate),
      organisationId: allValues.vendorId,
      placementStatus: allValues.status.toUpperCase(),
      placementType: allValues.placementType.toUpperCase(),
      placementSource: allValues.placementSource.toUpperCase(),
      billingRate: allValues.billingRate,
      engineerId: allValues.engineerId,
      workLocation: allValues.workLocation,
      comment: allValues.placementAdminComment,
      devComment: allValues.devComment,
      clientComment: allValues.clientComment,
    }

    let formData = new FormData();
    formData.append("contract_upload", contractDoc);

    formData.append(
      "assignment_dto",
      new Blob([JSON.stringify(payload)], {
        type: "application/json",
      })
    );

    editAssignment(formData, setFieldError);
  };

  // Fetch assignment Details
  const fetchAsignmentDetails = async () => {
    try {
      const res = await apiGet(`/admin/assignments/${assignmentId}`);
      let result = res.data.data;
      console.log(result)
      let newDetails = {
        ...result,
        organisationId: result.vendorId,
        organisation: result.vendor,
        placementAdminComment: result.placementAdminComment,
        placementType: result.placementType.replace(/ /g, '_'),
        status: result.status.replace(/ /g, '_'),
        placementSource: result.placementSource.replace(/ /g, '_'),
      };

      newDetails.resumptionDate =
        newDetails.resumptionDate !== ""
          ? formatToIsoDate(result.resumptionDate)
          : "";
      newDetails.assignmentDate = formatToIsoDate(result.assignmentDate);
      newDetails.endDate = formatToIsoDate(result.endDate);

      setAssignmentDetails(newDetails);
      setPlacementValues(newDetails);
      setContractDoc(newDetails.contract)
    } catch (err) {
      toast.error(err);
    }
  };

  //create organization
  const editAssignment = async (formData, setFieldError) => {
    const errorFunctions = {
      others: setFieldError,
    };

    setLoading(true);
    //make call to the api
    try {
      let response = await apiPut(
        `/admin/assignments/${assignmentId}`,
        formData,
        {}
      );
      if (response.error) {
        throw response.data.response.status === 500
          ? new Error("")
          : response.data;
      }
      setSuccess(true);
      setLoading(false);
      refetch && refetch()
    } catch (err) {
      console.log({ err });
      setLoading(false);

      if (
        err.response.data.subErrors &&
        err.response.data.subErrors.length !== 0
      ) {
        formatErrors(err.response.data.subErrors, errorFunctions);
      }

      toast.error(
        err?.response?.data?.message ||
          "Not able to update placement at this time"
      );
    }
  };

  const formik = useFormik({
    initialValues: placementValues,
    onSubmit,
    // validationSchema
  });

  useEffect(() => {
    formik.setValues(assignmentDetails);
    // eslint-disable-next-line
  }, [assignmentDetails]);

  return (
    <StyledCreatePackageForm success={success}>
      <FormTitleSection title="Edit Placement" onClick={handleEditAssignment} />
      <div className="form-section">
        <form onSubmit={formik.handleSubmit} className="client-form">
          <FormInputComponent
            label="Engineer"
            type="text"
            name="engineerId"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={
              Object.keys(assignmentDetails).length
                ? `${assignmentDetails?.name}`
                : ""
            }
            error={
              formik.touched.engineerId && formik.errors.engineerId
                ? formik.errors.engineerId
                : null
            }
            disabled={true}
          />

          <FormSelectComponent
            label="Assign to"
            name="organisationId"
            options={organizationOptions}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.organisationId}
            error={
              formik.touched.organisationId && formik.errors.organisationId
                ? formik.errors.organisationId
                : null
            }
          />

            <FormSelectComponent
              label="Placement Type"
              name="placementType"
              options={placement}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.placementType}
              error={
                formik.touched.placementType && formik.errors.placementType
                  ? formik.errors.placementType
                  : null
              }
              required
            />

            <FormSelectComponent
              label="Placement Status"
              name="status"
              options={placementStatus}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.status}
              error={
                formik.touched.status && formik.errors.status
                  ? formik.errors.status
                  : null
              }
              required
            />

            <FormSelectComponent
              label="Placement Source"
              name="placementSource"
              options={placementSource}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values?.placementSource?.toUpperCase()}
              error={
                formik.touched.placementSource && formik.errors.placementSource
                  ? formik.errors.placementSource
                  : null
              }
              required
            />

          <FormInputComponent
            label="Placement Date"
            type="date"
            name="assignmentDate"
            placeholder="Enter placement date"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.assignmentDate}
            error={
              formik.touched.assignmentDate && formik.errors.assignmentDate
                ? formik.errors.assignmentDate
                : null
            }
            className="assig-date"
          />

          <FormInputComponent
            label="Expected End Date"
            type="date"
            name="endDate"
            placeholder="Enter end date"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.endDate}
            error={
              formik.touched.endDate && formik.errors.endDate
                ? formik.errors.endDate
                : null
            }
            className="assig-date"
          />

          <div>
            <FormInputComponent
              label="Resumption Date"
              type="date"
              name="resumptionDate"
              placeholder="Enter resumption date"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.resumptionDate}
              error={
                formik.touched.resumptionDate && formik.errors.resumptionDate
                  ? formik.errors.resumptionDate
                  : null
              }
              className="assig-date"
            />
            <label className="assig-label" htmlFor="work-loacation">
              Work Location
            </label>
            <Select
              className="assig-select"
              options={locationOption}
              placeholder="Select Location"
              name="workLocation"
              onChange={(selectedOption) => {
                let d = selectedOption;
                let even = {
                  target: { name: "workLocation", value: d.value },
                };
                formik.handleChange(even);
              }}
              onBlur={formik.handleBlur}
              type="select"
              value={{
                label: formik.values.workLocation,
                value: formik.values.workLocation,
              }}
              error={
                formik.touched.workLocation && formik.errors.workLocation
                  ? formik.errors.workLocation
                  : null
              }
            />

            {companyLocation === "International" && (
              <FormSelectComponent
                label="Engineer Level"
                name="billingRate"
                options={levelOptions}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.billingRate}
                error={
                  formik.touched.billingRate && formik.errors.billingRate
                    ? formik.errors.billingRate
                    : null
                }
              />
            )}
             <div>
              <a href={contractDoc} target="_blank" rel="noreferrer">Contract link</a>
            </div>
            <FormUploadComponent
              label="Upload Contract"
              error={contractDocError}
              selectedFile={contractDoc}
              setSelectedFile={setContractDoc}
              acceptTypes={`pdf, docx, doc. Size Limit 5mb`}
              pdf={true}
              docType={docType}
            />
          </div>

          <FormTextAreaComponent
            label="Placement Admin Comment"
            type="text"
            name="placementAdminComment"
            placeholder="Enter comment"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.placementAdminComment}
            error={
              formik.touched.placementAdminComment && formik.errors.placementAdminComment
                ? formik.errors.placementAdminComment
                : null
            }
            className="w-100"
          />

          <FormTextAreaComponent
            label="Client Comment"
            type="text"
            name="clientComment"
            placeholder="Enter client comment"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.clientComment}
            error={
              formik.touched.clientComment && formik.errors.clientComment
                ? formik.errors.clientComment
                : null
            }
            className="w-100"
          />
          <FormTextAreaComponent
            label="Dev Comment"
            type="text"
            name="devComment"
            placeholder="Enter dev comment"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.devComment}
            error={
              formik.touched.devComment && formik.errors.devComment
                ? formik.errors.devComment
                : null
            }
            className="w-100"
          />

          <MyButton className="form-btn" type={"submit"} full>
            {" "}
            {loading ? (
              <ClipLoader color={"#fff"} size={"30px"} />
            ) : (
              "Edit Placement"
            )}
          </MyButton>
        </form>
        <div className="form-success">
          <img
            src="/images/sucess-icon.svg"
            alt="green-tick"
            className="sucess-icon"
          />
          <h2 className="form-font green-text">Success</h2>
          <p className="form-font success-msg">
            You successfully Edited an engineer placement.
          </p>
          <MyButton
            className="form-btn success-btn"
            onClick={handleEditAssignment}
          >
            Okay, Go back
          </MyButton>
        </div>
      </div>
    </StyledCreatePackageForm>
  );
};

const StyledCreatePackageForm = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);
  .form-btn {
    width: 100%;
    margin-top: 3.2rem;
  }
  .form-section {
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3.2rem;
  }
  input .assig-date {
    width: 100% !important;
  }
  .css-yk16xz-control {
    border-radius: 0;
    border: 1px solid rgba(33, 51, 79, 0.15) !important;
    margin: 0.5rem 0 2.4rem;
    height: 48px;
    font-size: 16px;
    color: green;
  }
  .css-1pahdxg-control {
    border: 1px solid rgba(33, 51, 79, 0.15) !important;
    box-shadow: none;
    height: 48px;
    margin: 0.5rem 0 2.4rem;
  }
  .css-1pahdxg-control:hover {
    border: 1px solid rgba(33, 51, 79, 0.15) !important;
    border-radius: 0;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-tlfecz-indicatorContainer {
    padding-right: 16px;
  }
  .css-tj5bde-Svg {
    color: #21334f;
    width: 15px;
  }
  .css-1wa3eu0-placeholder {
    color: rgba(33, 51, 79, 0.8);
    font-size: 16px;
  }
  .css-g1d714-ValueContainer {
    font-size: 16px;
  }

  .assig-label {
    color: black;
    font-weight: 600;
    font-size: 16px;
  }
  .add-client-btn {
    margin-bottom: 23.8rem;
  }
  .client-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2.rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 100% !important;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
`;

export default EditAssignmentForm;
