import React, { useState } from "react";
import styled from "styled-components";
import TableContainer from "../../common/TableContainer";
import { useFetchedPlacementRequests } from "../../../../services/engineerReq/query";
import ViewEngineerReq from "../../form/ViewEngineerReq";
import Pagination from "../../atoms/Pagination";

const EngBasedRequest = ({ pageType }) => {
  const [reqId, setReqId] = useState(null);
  const [viewEngReq, setViewEngReq] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    page: 1,
    size: 12,
  });

  const { data: requestList } = useFetchedPlacementRequests(filterOptions);

  const handleViewEngReqModal = (item) => {
    setReqId(item);
    setViewEngReq(!viewEngReq);
  };

  const increment = () => {
    setFilterOptions({ ...filterOptions, page: filterOptions.page + 1 });
  };

  const decrement = () => {
    setFilterOptions({ ...filterOptions, page: filterOptions.page - 1 });
  };

  const newPage = (n) => {
    setFilterOptions({ ...filterOptions, page: n });
  };

  return (
    <>
      <TableContainer>
        <table style={{ width: "100%" }}>
          <thead style={{ width: "100%" }}>
            <tr>
              <th>Contact Person</th>
              <th>Organisation Name</th>
              <th>Organisation Email</th>
              <th>Organisation Phone</th>
              <th>Request Date</th>
              <th>Status</th>
              {/* <th>Action</th> */}
            </tr>
          </thead>
          <tbody style={{ width: "100%" }}>
            {requestList?.data?.content.map((item) => (
              <tr className="" key={item.placementRequestId}>
                <td>{item.contactPerson}</td>
                <td>{item.organizationName}</td>
                <td>{item.organizationEmail}</td>
                <td>{item.organizationTelNo}</td>
                <td>
                  {item.dateCreated}
                  <span
                    onClick={() =>
                      handleViewEngReqModal(item.placementRequestId)
                    }
                    className="viewRequest">
                    View Request
                  </span>
                </td>
                <td>
                  {item.requestStatus ? (
                    <StatusBtn
                      className="status-bg"
                      status={item.requestStatus?.toLowerCase()}>
                      {item.requestStatus}
                    </StatusBtn>
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
      {requestList && (
        <div className="page-parent">
          <Pagination
            currentPage={requestList?.data.number + 1}
            totalClients={requestList?.data.numberOfElements}
            metaData={requestList?.data}
            page={requestList?.data.number + 1}
            newPage={newPage}
            increment={increment}
            decrement={decrement}
            className="pagination"
          />
        </div>
      )}
      {viewEngReq && (
        <ViewEngineerReq
          handleViewEngReqModal={handleViewEngReqModal}
          reqId={reqId}
        />
      )}
    </>
  );
};

const StatusBtn = styled.button`
  background: ${({ status }) =>
    status === "active"
      ? "#34A853"
      : status === "retained"
      ? "#40c463"
      : status === "residence"
      ? "#6cd287"
      : status === "completed"
      ? "#34A853"
      : status === "internship"
      ? "#8adba0"
      : status === "not_assigned"
      ? "#DB6969"
      : status === "internship"
      ? "#48C76A"
      : status?.toLowerCase() === "terminated"
      ? "#D34444"
      : status === "in progress"
      ? "#FF8E00"
      : "#D34444"};
  color: white;
  border: none;
  margin: 0 auto;
  max-width: 120px;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 600;
  padding: 10px;
`;

export default EngBasedRequest;
