import React from "react";
import styled from "styled-components";
import Modal from "../form/FormModal";

const ViewEngReq = ({engReqDetails, handleViewEngReqModal}) => {

  return (
    <ModalWrapper className="form-modal">
        <Modal centered>
        <div className="modalContent">
            <button onClick={handleViewEngReqModal} className="exit-btn">
            <img src="/images/times.svg" alt="x" className="times" />
            </button>
            <h3>Client’s Request Details</h3>
            <hr />
            <div className="modalTable">
            <div>
                <div className="header">
                <div className="header-col">Tech Stack</div>
                <div className="header-col">Number of Engineers</div>
                <div className="header-col">Experience</div>
                <div className="header-col">Budget</div>
                </div>
                <div className="body">
                    {
                        engReqDetails?.techStackRequest.map((data)=> (
                        <div key={data.id} className="body-content">
                            <div className="body-col">{data.techStack}</div>
                            <div className="body-col">{data.numberOfEngineers}</div>
                            <div className="body-col">{data.yearsOfExperience ? `${data.yearsOfExperience}years` :  "-"}</div>
                            <div className="body-col">{data.budget ? `N${data.budget}` : "-"}</div>
                        </div>
                        ))
                    }
                </div>
                <div className="info">
                <h4>Additional Information</h4>
                <div className="info-content">
                {engReqDetails && engReqDetails.comment}
                </div>
                </div>
            </div>
            </div>
        </div>
        </Modal>
    </ModalWrapper>  
  );
};

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  font-size: 1.6rem;
  
  .modalContent{
    background: #fff;
    width: 686px;
    position: relative;
    top: 40%;
    transform: translateY(-50%);
    padding: 32px 0;
  }

  .modalTable{
    padding: 10px 45px;
  }

  .header {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    text-align: center;
    font-weight: bold;
  }

  .body{
    background: #F7F8F8;
    margin-top: 10px;
  }

  .body-content {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    text-align: center;
    padding: 10px 0px;
  }

  h4 {
    color: #273b4a;
    font-size: 16px;
    font-weight: 700;
  }

  .info{
    margin-top: 24px;
  }

  .info-content {
    border: 1px solid #E6E6E6;
    padding: 10px;
  }

  .times {
    width: 30px;
  }
  
  .exit-btn {
    cursor: pointer;
    border: none;
    background-color: transparent;
    position: absolute;
    right: 20px;
  }
  h3 {
    text-align: center;
    color: #273b4a;
    font-size: 18px;
    font-weight: 700;
  }
  hr {
    margin-top: 20px;
    width: 100%;
  }
`;

export default ViewEngReq;
