import { apiGet } from "../../utils/apiHelpers";

//Fetch placement History
export const fetchPlacementHistory = async (params) => {
    const [filterOptions] = params.queryKey;

    try {
      const res = await apiGet(
        `/admin/assignments?page=${filterOptions.page}&size=12&search=${filterOptions.search}&assignTyp=${filterOptions.assignTyp}`,
        "admin"
      );
      
      return res.data
    } catch (err) {
      console.log({ err })
    }
};

//Fetch assignment listing per engineer
export const fetchEngAssignmentListing = async (id) => {
  try {
    const res = await apiGet(`/admin/engineers/${id}/assignments`);
    
    return res.data 
  } catch (err) {
    console.log({ err })
  }
};
  