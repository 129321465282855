import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
// import FormInputComponent from "./enterpriseFormInputs/FormInputComponent";
import FormTitleSection from "./FormTitleSection";
import { useAdmin } from "../../../context/AdminState";
import DatePickerDialog from "../atoms/DatePicker";
import { useCreateWeek, useEditWeek } from "../../../services/weeks/query";
import { toast } from "react-toastify";
import { format, getUnixTime, parseISO } from "date-fns";
import { useParams } from "react-router-dom";
import { mappedWeeks } from "../../../data/week";
import FormSelectComponent from "./enterpriseFormInputs/FormSelectComponent";

const AddWeeks = ({
  onClick,
  isEditing = false,
  updateId,
  reload,
  editingValues,
}) => {
  const [initialValues] = useState({
    name: "",
  });
  const [endDate, setEndDate] = useState("");
  const [startDate, setStartDate] = useState("");

  const { fetchSquadsModal } = useAdmin();

  useEffect(() => {
    if (isEditing) {
      if (editingValues) {
        const fields = ["name"];
        fields.forEach((field) =>
          formik.setFieldValue(field, editingValues[field], false)
        );
        setStartDate(editingValues["startDate"]);
        setEndDate(editingValues["endDate"]);
      }
    }
    // eslint-disable-next-line
  }, [isEditing]);

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
  });

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const weekParams = useParams();
  const { id: weekId } = weekParams;
  const new_token = localStorage.getItem("token");

  let multipartConfig = {
    headers: {
      Authorization: `Bearer ${new_token}`,
      "Content-Type": "application/json; charset=UTF-8",
    },
  };

  const { mutate: postWeeks } = useCreateWeek(multipartConfig);

  const { mutate: editWeek } = useEditWeek(multipartConfig);

  //create users
  const createWeek = async (formData) => {
    const formatFormData = { ...formData, weekNo: formData.name }
    setLoading(true);
    //make call to the api
    try {
      if (!isEditing) {
        postWeeks(formatFormData, {
          onSuccess: () => {
            reload();
            setSuccess(true);
            setLoading(false);
          },
          onError: (error) => {
            setSuccess(false);
            setLoading(false);
          },
        });
      }
      if (isEditing) {
        editWeek(
          { updateId, formatFormData },
          {
            onSuccess: () => {
              toast.success("Edited Successfully");
              reload();
              setSuccess(true);
              setLoading(false);
            },

            onError: (error) => {
              console.log(error, "error");
              setSuccess(false);
              setLoading(false);
            },
          }
        );
      }
    } catch (err) {
      console.log({ err });
      return err;
    }
  };

  useEffect(() => {
    // eslint-disable-next-line
  }, [success]);

  const onSubmit = (values) => {
    if (
      (startDate || endDate) &&
      getUnixTime(new Date(endDate)) < getUnixTime(new Date(startDate))
    ) {
      toast.error("End date cannot be before start date");
      return;
    }
    values.startDate =
      startDate && format(parseISO(startDate), "dd/MM/y")
        ? format(parseISO(startDate), "dd/MM/y")
        : "";
    values.endDate =
      endDate && format(parseISO(endDate), "dd/MM/y")
        ? format(parseISO(endDate), "dd/MM/y")
        : "";

    if (!isEditing) {
      values.squad = Number(weekId);
    }

    createWeek(values);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  useEffect(() => {
    fetchSquadsModal();
    // eslint-disable-next-line
  }, [success]);

  const closeForm = () => {
    formik.resetForm();
    onClick();
    setSuccess(false);
  };

  // console.log({ formik });

  return (
    <StyledAddUserForm success={success}>
      <FormTitleSection
        title={isEditing ? "Update Week" : "Add Week"}
        onClick={closeForm}
      />
      <div className="form-section">
        <form onSubmit={formik.handleSubmit} className="client-form">
          <FormSelectComponent
            label="Weeks"
            name="name"
            options={[{label: "Select Week", value: ""}, ...mappedWeeks]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : null
            }
          />

          <DatePickerDialog
            label="Start Date"
            name="startDate"
            setDate={setStartDate}
            date={startDate}
            required={true}
          />

          <DatePickerDialog
            label="End Date"
            name="endDate"
            setDate={setEndDate}
            date={endDate}
          />

          <MyButton
            className="form-btn add-client-btn"
            loading={loading}
            type="submit"
            full
          >
            {isEditing ? "Update Week" : " Add New Week"}
          </MyButton>
        </form>
        <div className="form-success card">
          <img
            src="/images/sucess-icon.svg"
            alt="green-tick"
            className="sucess-icon"
          />
          <h2 className="form-font green-text">Success</h2>
          <p className="form-font success-msg">
            You successfully added a Week.
          </p>
          <MyButton className="form-btn success-btn" onClick={closeForm}>
            View List of Weeks
          </MyButton>
        </div>
      </div>
    </StyledAddUserForm>
  );
};

const StyledAddUserForm = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);
  .underline {
    display: block;
  }
  input .assig-date {
    width: 100% !important;
  }
  .form-section {
    width: 500px;
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3.2rem;
  }
  .add-client-btn {
    margin-bottom: 23.8rem;
    margin-top: 5rem;
  }
  .client-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .stack-section {
    display: flex;
    flex-wrap: wrap;
    width: 32rem;
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    margin-top: 8rem;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2.rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 32rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
  .select-field {
    // max-width: 35rem;
  }
  .perm-label {
    color: #21334f;
    font-size: 1.6rem;
    font-weight: 600;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
    grid-column-gap: 1rem;
  }
  .checkbox-area {
    -webkit-appearance: button;
    margin-right: 1.5rem;
    display: none;
  }
  .check-label {
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }
  .checkmark {
    width: 25px;
    height: 25px;
    border: 2px solid #34a853;
    display: inline-block;
    border-radius: 3px;
    margin-right: 1rem;
    background: #34a853
      url("https://upload.wikimedia.org/wikipedia/commons/thumb/2/27/White_check.svg/1200px-White_check.svg.png")
      center/1250% no-repeat;
    transition: background-size 0.2s cubic-bezier(0.7, 0, 0.18, 1.24);
  }
  .check-label input:checked + .checkmark {
    background-size: 60%;
    transition: background-size 0.25s cubic-bezier(0.7, 0, 0.18, 1.24);
  }
  .item-span {
    font-size: 1.3rem;
    font-weight: 600;
  }
  .perm-head {
    font-size: 1.6rem;
    font-weight: 600;
  }
`;

export default AddWeeks;
