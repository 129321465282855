import { apiGet, apiPost, apiPut, apiDelete } from "../../utils/apiHelpers";

export const fetchIndustries = async () => {
  try {
    let response = await apiGet(`/admin/industries`, {});

    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
    return response.data.data;
  } catch (err) {
    throw err;
  }
};

export const fetchSingleIndustry = async (params) => {
  const [id] = params.queryKey;
  try {
    let response = await apiGet(`/admin/industries/${id}`, {});

    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const createIndustry = async (formData) => {
  try {
    let response = await apiPost(`/admin/industries`, formData, {});

    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
  } catch (err) {
    console.log({ err });
    throw err;
  }
};

export const editIndustry = async (data) => {
  try {
    let response = null;
    const { industryId, payload } = data;
    if (data) {
      response = await apiPut(`/admin/industries/${industryId}`, payload);
    }
    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
  } catch (error) {
    console.log({ error });
    throw error;
  }
};

export const deleteIndustry = async (id) => {
  try {
    let response = await apiDelete(`/admin/industries/${id}`);
    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
  } catch (err) {
    console.log({ err });
    throw err;
  }
};
