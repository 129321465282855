export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOADING = "LOADING";
export const VERIFIED = "VERIFIED";
export const SET_DASHBOARD = "SET_DASHBOARD";
export const FETCH_CLIENT_SUCCESS = "FETCH_CLIENT_SUCCESS";
export const FETCH_NEW_CLIENT_SUCCESS = "FETCH_NEW_CLIENT_SUCCESS";
export const FETCH_ENGINEER_SUCCESS = "FETCH_ENGINEER_SUCCESS";
export const FETCH_TOP_ENGINEER_SUCCESS = "FETCH_TOP_ENGINEER_SUCCESS";
export const FETCH_SUBSCRIPTION_SUCCESS = "FETCH_SUBSCRIPTION_SUCCESS";
export const FETCH_CLIENT_SUB_SUCCESS = "FETCH_CLIENT_SUB_SUCCESS";
export const FETCH_ASSIGNMENT_INFO = "FETCH_ASSIGNMENT_INFO";
export const FETCH_CLIENT_ENG_SUCCESS = "FETCH_CLIENT_ENG_SUCCESS";
export const FETCH_STACK_SUCCESS = "FETCH_STACK_SUCCESS";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const ORG_SUB_SUCCESS = "ORG_SUB_SUCCESS";
export const FETCH_PACKAGE_SUCCESS = "FETCH_PACKAGE_SUCCESS";
export const FETCH_INDUSTRY_SUCCESS = "FETCH_INDUSTRY_SUCCESS";
export const FETCH_SQUAD_SUCCESS = "FETCH_SQUAD_SUCCESS";
export const ASSIGNMENT_LISTING = "ASSIGNMENT_LISTING";
export const FETCH_TECH_STACK_SUCCESS = "FETCH_TECH_STACK_SUCCESS";
export const FETCH_UNASSIGNED = "FETCH_UNASSIGNED";
export const FETCH_ENG_REQ_SUCCESS = "FETCH_ENG_REQ_SUCCESS";
export const FETCH_SUB_REQ_SUCCESS = "FETCH_SUB_REQ_SUCCESS";
export const FETCH_ADMIN_SUB_SUCCESS = "FETCH_ADMIN_SUB_SUCCESS";
export const FETCH_ADMIN_ENG_SUCCESS = "FETCH_ADMIN_ENG_SUCCESS";
export const FETCH_DOCUMENT_SUCCESS = "FETCH_DOCUMENT_SUCCESS";
export const FETCH_BILLING_SUCCESS = "FETCH_BILLING_SUCCESS";
export const FETCH_PLACEMENT_HISTORY = "FETCH_PLACEMENT_HISTORY";
export const INCREMENT_PAGE = "INCREMENT_PAGE";
export const DECREMENT_PAGE = "DECREMENT_PAGE";
export const SET_PAGE = "SET_PAGE";
export const SET_KEYWORD = "SET_KEYWORD";
export const SET_STATUS = "SET_STATUS";
export const SET_CLIENT_TYPE = "SET_CLIENT_TYPE";
export const SET_CUSTOMER_TYPE = "SET_CUSTOMER_TYPE";
export const ON_SEARCH_LOADER = "ON_SEARCH_LOADER"
export const OFF_SEARCH_LOADER = "OFF_SEARCH_LOADER"
export const ON_FILTER_LOADER = "ON_FILTER_LOADER"
export const OFF_FILTER_LOADER = "OFF_FILTER_LOADER"
export const SET_LABELS = "SET_LABELS";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_ENGINEER_TYPE = "SET_ENGINEER_TYPE";
export const SET_SQUAD = "SET_SQUAD";
export const SET_ENG_STATUS = "SET_ENG_STATUS";
export const SET_ENGINEER_STATUS = "SET_ENGINEER_STATUS";
export const SET_SQUAD_DETAILS = "SET_SQUAD_DETAILS";
export const SET_OPEN_RATING_MODAL = "SET_OPEN_RATING_MODAL";
export const SET_CLOSE_RATING_MODAL = "SET_CLOSE_RATING_MODAL";
export const FETCH_ENGINEER_DETAILS = "FETCH_ENGINEER_DETAILS";
export const FETCH_INVOICE_SUCCESS = "FETCH_INVOICE_SUCCESS";
export const SET_ENG_AGE = "SET_ENG_AGE"
export const SET_TRAINING_LOCATION = "SET_TRAINING_LOCATION"
export const SET_ENG_GENDER = "SET_ENG_GENDER"
export const SET_CURRENT_LOCATION = "SET_CURRENT_LOCATION"
export const FETECH_LOCATION_SUCCESS = "FETECH_LOCATION_SUCCESS";
export const FETECH_TRAINING_LOCATION_SUCCESS = "FETECH_TRAINING_LOCATION_SUCCESS";
export const SET_SQUAD_ID = "SET_SQUAD_ID"
export const SET_MIN_AGE = "SET_MIN_AGE"
export const SET_MAX_AGE = "SET_MAX_AGE"
