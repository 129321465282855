import React, { useState } from "react";
import Layout from "../common/Layout";
import DynamicTitle from "../common/DynamicTitle";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import TableContainer from "../common/TableContainer";
import ActionDropdown from "../common/ActionDropdown";
import AddTrainingData from "../form/AddTrainingData";
import FormModal from "../form/FormModal";
import {
  // useDeleteTrainingData,
  useTrainingData,
} from "../../../services/trainingData/query";
import useDialog from "../../../utils/useDialog";
import { deleteDataPoint } from "../../../services/trainingData/api";
import ProtectedComponents from "../local-routes/ProtectedComponents";

const TrainingData = ({ pageType }) => {
  const [showModal, setShowModal] = useState(false);
  const [singleTrainingDataId, setSingleTrainingDataId] = useState("");
  const [edit, setEdit] = useState(false);
  const { deleteItemDialog } = useDialog();

  const { data: trainingData, refetch, isLoading } = useTrainingData();

  //const { mutate: deleteTrainingData } = useDeleteTrainingData();

  // const handleDelete = (id) => {
  //   deleteTrainingData(id, {
  //     onSuccess: () => {
  //       toast.success("Deleted Successfully");
  //       refetch();
  //     },

  //     onError: (error) => {
  //       toast.success("Error");
  //       console.log(error);
  //     },
  //   });
  //};
  const handleDelete = (id, name) => {
    deleteItemDialog(deleteDataPoint, id, name, refetch);
  };

  return (
    <Layout pageLoading={isLoading} navType="admin">
      <DynamicTitle title="Training Data | Fellowship Product" />

      <TrainingStyle>
        <div className="client-header">
          <h1>Data Points</h1>
          <ProtectedComponents permission={["manageTrainingDataPointSetUp"]}>
            <MyButton
              onClick={() => {
                setShowModal(!showModal);
                setEdit(false);
              }}
              className="btn-doc"
            >
              Add Data Point
            </MyButton>
          </ProtectedComponents>
        </div>

        <TableContainer>
          <table style={{ width: "100%" }}>
            <thead style={{ width: "100%" }}>
              <tr>
                <th>Title</th>
                <th>Code</th>
                <th>Minimum score</th>
                <th>Maximum score</th>
                <th>Category </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody style={{ width: "100%" }}>
              {trainingData?.data?.map(
                ({
                  id,
                  title,
                  code,
                  minScore,
                  maxScore,
                  trainingDataPointCategory,
                }) => (
                  <tr className="" key={id}>
                    <td>{title}</td>
                    <td>{code}</td>
                    <td>{minScore}</td>
                    <td>{maxScore}</td>
                    <td>{trainingDataPointCategory}</td>
                    <td>
                      <ActionDropdown>
                        <p
                          onClick={() => {
                            setSingleTrainingDataId(id);
                            setEdit(true);
                            setShowModal(!showModal);
                          }}
                        >
                          Edit Data Point
                        </p>

                        <p
                          onClick={() => {
                            handleDelete(id, title);
                          }}
                        >
                          Delete Data Point
                        </p>
                      </ActionDropdown>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </TableContainer>
      </TrainingStyle>

      {showModal && (
        <div className="form-modal">
          <FormModal className="form" handleModal={() => setShowModal(false)}>
            <AddTrainingData
              onClick={() => setShowModal(!showModal)}
              updateId={singleTrainingDataId}
              isEditing={edit}
              reload={refetch}
            />
          </FormModal>
        </div>
      )}
    </Layout>
  );
};

const TrainingStyle = styled.div`
  .client-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 4.8rem;
    margin-bottom: 1.1rem;
  }
  .client-header h1 {
    font-weight: 700;
    font-size: 2.4rem;
  }
`;

export default TrainingData;
