import React, { useState } from "react";
import styled from "styled-components";
import Pagination from "../../atoms/Pagination";
import TableContainer from "../../common/TableContainer";
// import { useFetchedEngineerRequests } from "../../../../services/engineerReq/query";
import ViewEngReq from "../../form/ViewEngReq";
import ActionDropdown from "../../common/ActionDropdown";
import { useDeleteTechStackEngineerRequests } from "../../../../services/client/query";
import { ClipLoader } from "react-spinners";

const TechstackReq = ({
  pageType,
  requestList,
  setFilterOptions,
  filterOptions,
}) => {
  const [engReqDetails, setEngReqDetails] = useState(null);
  const [viewEngReq, setViewEngReq] = useState(false);
  const [reqId, setReqId] = useState("");
  const handleViewEngReqModal = (item) => {
    setEngReqDetails(item);
    setViewEngReq(!viewEngReq);
  };

  const increment = () => {
    setFilterOptions({ ...filterOptions, page: filterOptions.page + 1 });
  };

  const decrement = () => {
    setFilterOptions({ ...filterOptions, page: filterOptions.page - 1 });
  };

  const newPage = (n) => {
    setFilterOptions({ ...filterOptions, page: n });
  };

  const { mutate, isLoading: isDeleteLoading } =
    useDeleteTechStackEngineerRequests(filterOptions);
  const handleDeleteRequest = (requestId) => {
    setReqId(requestId);
    mutate({ requestId });
  };

  return (
    <>
      <TableContainer>
        <table style={{ width: "100%" }}>
          <thead style={{ width: "100%" }}>
            <tr>
              <th>Organisation</th>
              <th>Request Date</th>
              <th>Numbers of Engineers</th>
              <th>Tech Stack</th>
              <th>Status</th>
              {pageType === "client" ? <th>Action</th> : null}
            </tr>
          </thead>
          <tbody style={{ width: "100%" }}>
            {requestList?.data?.content.map((item, index) => (
              <tr className="" key={item.id}>
                <td>{item.organisation}</td>
                <td>{item.requestDate}</td>
                <td>{item.numberOfEngineers}</td>
                <td>
                  {item.techStackRequest
                    .map((data) => data["techStack"])
                    .slice(0, 2)
                    .join(", ")}
                  ...{" "}
                  <span
                    onClick={() => handleViewEngReqModal(item)}
                    className="viewRequest">
                    View Request
                  </span>
                </td>
                <td>
                  {item.status ? (
                    <StatusBtn
                      className="status-bg"
                      status={item.status?.toLowerCase()}>
                      {item.status}
                    </StatusBtn>
                  ) : (
                    "-"
                  )}
                </td>
                {pageType === "client" ? (
                  <td>
                    <ActionDropdown>
                      <p onClick={() => handleDeleteRequest(item.id)}>
                        <span
                          style={{
                            verticalAlign: "middle",
                            display: "inline-block",
                            marginRight: "7px",
                          }}>
                          Cancel Request
                        </span>
                        <span
                          style={{
                            verticalAlign: "middle",
                            display: "inline-block",
                          }}>
                          {isDeleteLoading && reqId === item.id ? (
                            <ClipLoader color={"#000"} size={"20px"} />
                          ) : null}
                        </span>
                        {/* )} */}
                      </p>
                    </ActionDropdown>
                  </td>
                ) : null}
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
      {requestList && (
        <div className="page-parent">
          <Pagination
            currentPage={requestList?.data.number + 1}
            totalClients={requestList?.data.numberOfElements}
            metaData={requestList?.data}
            page={requestList?.data.number + 1}
            newPage={newPage}
            increment={increment}
            decrement={decrement}
            className="pagination"
          />
        </div>
      )}
      {viewEngReq && (
        <ViewEngReq
          handleViewEngReqModal={handleViewEngReqModal}
          engReqDetails={engReqDetails}
        />
      )}
    </>
  );
};

const StatusBtn = styled.button`
  background: ${({ status }) =>
    status === "active"
      ? "#34A853"
      : status === "retained"
      ? "#40c463"
      : status === "residence"
      ? "#6cd287"
      : status === "completed"
      ? "#34A853"
      : status === "internship"
      ? "#8adba0"
      : status === "not_assigned"
      ? "#DB6969"
      : status === "internship"
      ? "#48C76A"
      : status?.toLowerCase() === "terminated"
      ? "#D34444"
      : status === "in progress"
      ? "#FF8E00"
      : "#D34444"};
  color: white;
  border: none;
  margin: 0 auto;
  max-width: 120px;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 600;
  padding: 10px;
`;

export default TechstackReq;
