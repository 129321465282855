import React,{useEffect, useState} from "react";
import Linegraph from "../../../common/activityTrackerCards/Linegraph";
import {apiGet} from "../../../../../utils/apiHelpers"


const RequestSub = ({orgId}) => {
  const [currentState, setCurrentState] = useState('All');
  const [month, setMonth]=useState([])
  const [count, setCount] = useState([])
  const [period, setPeriod] = useState('')

  useEffect(()=>{
    fetchSubTracking();
    // eslint-disable-next-line
  },[orgId, currentState])

  const fetchSubTracking= async()=>{
    try{
      const res= await apiGet(`/admin/subscriptions/requests/tracking?period=${period}&organisation=${orgId}`, 'admin')

      const data=res.data.data
      const month=[]
      const count=[]
      for(let i=0; i<data.length; i++){
        let item = data[i];
        month.push(item.month);
        count.push(item.count)
      }
      setMonth(month)
      setCount(count)
    }
    catch(err){
      console.log("error", err)
    }
  }
  
  

  const chartData = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(1, "rgba(184, 211, 68, 0.4)");

    return {
      labels: month,
      datasets: [
        {
          data: count,
          pointRadius: 2,
          backgroundColor: gradient,
          fill: true,
          borderColor: "#B8D344",
          tension: 0.6,
          borderWidth: 1.33,
        },
      ],
    };
  };
  const canvas = document.createElement("canvas");
  const theChart = chartData(canvas);


  return (
    <Linegraph theChart={theChart} title="Request Subscription" currentState={currentState} setCurrentState={setCurrentState} setPeriod={setPeriod} />
  );
};

export default RequestSub;
