// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import FormSelectComponent from "./enterpriseFormInputs/FormSelectComponent";
import FormTitleSection from "./FormTitleSection";
import { ClipLoader } from "react-spinners";
import { useFetchedTechstacks } from "../../../services/reference/query";
import { useFetchedSquadAdminUsersList, useCreateSquadAdmin, 
  useFetchedSingleSquadAdmin, useEditSquadAdmin } from "../../../services/squad-admin/query";
import { getToken } from "../../../utils/helperFunctions";

const validationSchema = Yup.object({
  adminUser: Yup.string().required("*Required"),
  role: Yup.string().required("*Required"),
  language: Yup.string("select an option").required("*Required"),
});

const AddNewSquadAdmin = ({ closeModal, reload, isEditing, updateId, squadAdminId }) => {

  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(false);

  const { data: languages } = useFetchedTechstacks();

  const {data: squadAdminUsersList} = useFetchedSquadAdminUsersList();

  const {data: squadAdmin, refetch} = useFetchedSingleSquadAdmin(squadAdminId);

  let config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      "Content-Type": "application/json; charset=UTF-8",
    },
  };

  const { mutate: createSquadAdmin } = useCreateSquadAdmin(config);
  const {mutate: updateSquadAdmin} = useEditSquadAdmin(config);


  const initialValues = {
    adminUser: "",
    role: "",
    language: "",
  };

  let roleOptions = [
    {value: "STACK_LEAD", label:"Stack Lead"}, 
    {value: "STACK_ASSOCIATE", label: "Stack Associate"}, 
    {value: "PROGRAM_ASSOCIATE", label: "Program Associate"}
    ];


  let langaugeOptions = 
  languages?.data?.length > 0
      ? languages?.data?.map((language) => {
          return {
            value: language.id,
            label: language.name,
          };
        })
      : [];
    
    let squadUserList = 
    squadAdminUsersList?.data?.content.length > 0 
    ?  squadAdminUsersList?.data?.content?.map(squadAdmin => {
        return {
            value: squadAdmin.id,
            label: squadAdmin.name,
        }
    })
    : [];

    useEffect(() => {
      if (isEditing) {
        if (squadAdmin?.data) {
          const fields = [
            "adminUser",
            "language",
            "role",
          ];
          fields.forEach((field) =>
            formik.setFieldValue(field, squadAdmin?.data[field], false)
          );
        }
      }
      // eslint-disable-next-line
    }, [isEditing, squadAdmin]);
 
  const onSubmit = async (e) => {
      let payload = {
          adminUser: formik.values.adminUser,
          language: formik.values.language,
          role: formik.values.role,
          squad: updateId,
      }
    setLoading(true);
    e.preventDefault();
    try {
        if (!isEditing) {
            createSquadAdmin(payload, {
                onSuccess: () => {
                reload()
                setSuccess(true);
                setLoading(false);
                },
                onError: (error) => {
                setSuccess(false);
                setLoading(false);
                },
            });
        }
        if (isEditing) {
          updateSquadAdmin({squadAdminId, payload}, 
            {
              onSuccess: () => {
                reload()
                refetch()
                setSuccess(true);
                setLoading(false);
                },
                onError: (error) => {
                console.log('err', error.message);
                setSuccess(false);
                setLoading(false);
                },
            }
          )
        }
    } catch (err) {
        console.log({ err });
        return err;
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
  });

  return (
    <StyledAddEngForm success={success}>
      <FormTitleSection title={!isEditing ? "Add New Squad Admin" : "Update Squad Admin"} onClick={closeModal} />
      <div className="form-section">
        <form onSubmit={formik.handleSubmit} className="client-form">
          <FormSelectComponent
            label="Select a User"
            placeholder="Choose User"
            name="adminUser"
            options={[{label: "Select Squad Admin", value: ""}, ...squadUserList]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.adminUser}
            error={
              formik.touched.adminUser && formik.errors.adminUser
                ? formik.errors.adminUser
                : null
            }
            required={true}
          />

          <FormSelectComponent
            label="Select Role"
            name="role"
            options={[{label: "Select Role", value: ""}, ...roleOptions]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.role}
            error={
              formik.touched.role && formik.errors.role
                ? formik.errors.role
                : null
            }
            required={true}
          />

          <FormSelectComponent
            label="Select Language"
            name="language"
            options={[{label: "Select Language", value: ""}, ...langaugeOptions]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.language}
            error={
              formik.touched.language && formik.errors.language
                ? formik.errors.language
                : null
            }
            required={true}
          />

          <MyButton
            className="form-btn add-client-btn"
            type="submit"
            full
            onClick={onSubmit}
          >
            {loading ? (
              <ClipLoader color={"#fff"} size={"30px"} />
            ) : (
              !isEditing ? "Add Squad Admin" : "Update Squad Admin"
            )}
          </MyButton>
        </form>
        <div className="form-success">
          <img
            src="/images/sucess-icon.svg"
            alt="green-tick"
            className="sucess-icon"
          />
          <h2 className="form-font green-text">Success</h2>
          <p className="form-font success-msg">
            {
              (
                !isEditing ? "You successfully added an admin to the squad." : "You successfully Updated an admin to the squad."
              )
            }
          </p>
          <MyButton className="form-btn success-btn" onClick={closeModal}>
            Okay, go to Squad Admin List
          </MyButton>
        </div>
      </div>
    </StyledAddEngForm>
  );
};

const StyledAddEngForm = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);
  .underline {
    display: block;
  }
  .form-section {
    width: 500px;
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3.2rem;
  }
  .add-client-btn {
    margin-bottom: 3rem;
  }
  .stack-section {
    display: flex;
    flex-wrap: wrap;
    width: 32rem;
  }
  .client-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .downld-link {
    margin-bottom: 2rem;
    font-size: 16px;
    color: blue;
  }
  .downld-link:hover {
    margin-bottom: 2rem;
    font-size: 16px;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 32rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
`;

export default AddNewSquadAdmin;
