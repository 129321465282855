import React from 'react';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

const DateRange = ({ onChange, showOneCalendar, fromDate, toDate }) => {
  return (
    <>
      <DateRangePicker placeholder="Date" defaultValue={fromDate && toDate ? [fromDate, toDate] : null} onChange={onChange} showOneCalendar={showOneCalendar} preventOverflow />
    </>
  );
}

export default DateRange