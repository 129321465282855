import { toast } from "react-toastify";
import { apiDelete, apiGet, apiPost, apiPut } from "../../utils/apiHelpers";
import fileDownload from "../../utils/file-download";
import nprogress from "nprogress";

//Fetch engineers table data
export const fetchEngineers = async (params) => {
  const [filterOptions] = params.queryKey;
  try {
    const res = await apiGet(
      `/admin/engineers?page=${filterOptions.page}&size=${filterOptions.size}&search=${filterOptions.keyword}&stack=${filterOptions.language}&status=${filterOptions.engStatus}&engTyp=${filterOptions.engineerType}&assignTyp=${filterOptions.assignTyp}&squad=${filterOptions.squad}&currentLocation=${filterOptions.currentLocation}&trainingLocation=${filterOptions.trainingLocation}&min_age=${filterOptions.minAge}&max_age=${filterOptions.maxAge}&gender=${filterOptions.gender}&visibility=${filterOptions.visibility}&profile_video_cv=${filterOptions.videoCVAvailability}`,
      "admin"
    );

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchTopEngineers = async () => {
  try {
    const res = await apiGet(
      `/admin/dashboard/analytics/topEngineers?page=1&size=12`,
      "admin"
    );
    return res.data
  } catch (err) {
    console.log(err);
  }
};

export const createEngineer = async (formData, config) => {
  try {
    let response = await apiPost(`/admin/engineers`, formData, config);
    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
  } catch (err) {
    throw err;
  }
};

export const addUserToMailList = async (data, config) => {
  try {
    const res = await apiPost(
      `/admin/notification/mailing-lists`,
      data,
      config
    );
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const removeUserFromMailList = async (data, config, module) => {
  const newConfig = {
    ...config,
    data,
  };

  try {
    const res = await apiDelete(
      `/admin/notification/mailing-lists?module=${module}`,
      newConfig
    );
    toast.success("User successfully removed from mailing list");
    return res.data;
  } catch (err) {
    throw err;
  }
};

export const sendUserMail = async (data, config) => {
  const { modules, week, squad, mailingListIds } = data;
  const postData = {
    mailingListIds,
  };
  try {
    const res = await apiPost(
      `/admin/notification?module=${modules}&squadId=${squad}&weekNo=${week}`,
      postData,
      config
    );
    toast.success("Notification sent successfully");
    clearTimeout();
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const forgotPassword = async (data, config) => {
  const { userType, ...postData } = data;
  try {
    const res = await apiPost(`/forgotpassword/${userType}`, postData, config);
    toast.success("Reset Password Instructions Sent Successfully");
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const resetPassword = async (data, config) => {
  const { userType, ...postData } = data;
  try {
    const res = await apiPost(`/resetpassword/${userType}`, postData, config);
    toast.success("Reset Password Successfully Reset");
    setTimeout(() => {
      window.location.href = "/engineer/login";
    }, 2000);
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const downloadProfiles = async () => {
  try {
    const data = await apiGet(
      `/admin/engineers/downloadBulkEngineerProfile`,
      { responseType: "blob" },
      "admin"
    );
    fileDownload(data.data, "Engineer_Profile.xlsx");
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const sendLoginNotification = async (id) => {
  nprogress.start()
  try {
    await apiPost(`/admin/engineers/send-account-created-email/${id}`, {}, {});
    toast.success("Notification sent successfully");
  } catch (error) {
    console.log({ error });
  }
  nprogress.done()
};

export const unplaceEngineer = async (id) => {
  nprogress.start()
  try {
    await apiPost(`/admin/engineers/un_place/${id}`, {}, {});
    toast.success("Engineer unplaced successfully");
  } catch (error) {
    console.log({ error });
  }
  nprogress.done()
};

//SINGLE ENGINEER

export const fetchEngineerTrainingData = async (params) => {
  const [paramsOptions] = params.queryKey;
  try {
    const res = await apiGet(
      `/admin/engineers/${paramsOptions.id}/weeklyTrainingDataAggregate/?week=${paramsOptions.week}`
      // "admin"
    );

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

//Fetch engineers table data
export const fetchEngineerGraphTrainingData = async (paramsId) => {
  try {
    const res = await apiGet(
      `/admin/engineers/${paramsId}/weekOnWeekTrainingDataAggregate`,
      "admin"
    );

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const fetchAssessment = async (params) => {
  const [filterOptions] = params.queryKey;
  try {
    const res = await apiGet(
      `/admin/training-data?squad=${filterOptions.squad}&techStack=${filterOptions.language}&week=${filterOptions.week}`
    );

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const editAssessment = async (data) => {
  const { lang, week, squad, formValues: formData } = data;

  try {
    await apiPut(
      `/admin/training-data?lang=${lang}&squad=${squad}&week=${week}`,
      formData
    );
  } catch (err) {
    throw err;
  }
};

export const fetchMailingList = async (params) => {
  const [options] = params.queryKey;
  try {
    const res = await apiGet(
      `/admin/notification/mailing-lists?module=${options}`
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
export const fetchUsersList = async () => {
  try {
    const res = await apiGet(`/admin/users`);

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

//CLIENT SIDE

export const fetchPersonalEngineerTrainingData = async (params) => {
  const [paramsOptions] = params.queryKey;
  try {
    const res = await apiGet(
      `/dev/weeklyTrainingDataAggregate/?week=${paramsOptions.week}`
      // "admin"
    );

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

//Fetch engineers table data
export const fetchPersonalEngineerGraphTrainingData = async () => {
  try {
    const res = await apiGet(`/dev/weekOnWeekTrainingDataAggregate`, "admin");

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const viewIncidentsReport = async (params) => {
  const [engineerId] = params.queryKey;
  try {
    const res = await apiGet(`/admin/incident-reports/${engineerId}`);

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const createIncidentReport = async (formData, config) => {
  try {
    let response = await apiPost(`/admin/incident-reports`, formData, config);
    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
  } catch (err) {
    console.log({ err });
  }
};

export const toggleEngineersVisibility = async (engineersId) => {
  try {
    nprogress.start();

    await apiPost(
      `/admin/engineers/visibility-requests`,
      {
        engineersId,
      },
      {}
    );

    nprogress.done();
    toast.success("Resource updated successfully");
  } catch (error) {
    console.log({ error });
    nprogress.done();
  }
};

export const toggleAllEngineersVisibility = async (filterOptions) => {
  try {
    nprogress.start();

    await apiPost(
      `/admin/engineers/toggle-visibility?all=1&size=${filterOptions.size}&search=${filterOptions.keyword}&stack=${filterOptions.language}&status=${filterOptions.engStatus}&engTyp=${filterOptions.engineerType}&assignTyp=${filterOptions.assignTyp}&squad=${filterOptions.squad}&currentLocation=${filterOptions.currentLocation}&trainingLocation=${filterOptions.trainingLocation}&min_age=${filterOptions.minAge}&max_age=${filterOptions.maxAge}&gender=${filterOptions.gender}&visibility=${filterOptions.visibility}`,
      {},
      {}
    );

    nprogress.done();
    toast.success("Resource updated successfully");
  } catch (error) {
    console.log({ error });
    nprogress.done();
  }
};

//NOTIFICATIONS

export const fetchEngineerNotifications = async (params) => {
  try {
    const res = await apiGet(
      `/dev/notification`
      // "admin"
    );

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

//Read Notification
export const fetchSingleEngineerNotifications = async (notificationId) => {
  try {
    const res = await apiGet(`/dev/notification/${notificationId}`);

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteEngineerNotification = async (notificationId) => {
  try {
    const res = await apiDelete(`/dev/notification/${notificationId}`);
    toast.success("Notification successfully removed from List");
    return res.data;
  } catch (err) {
    throw err;
  }
};
