import React, { useState, useEffect } from "react";
import Linegraph from "../../../common/activityTrackerCards/Linegraph";
import { useGetSignUpData } from "../../../../../services/activity/query";

const SignUpActivity = ({ orgId, startDate, endDate }) => {
  const [month, setMonth] = useState([]);
  const [count, setCount] = useState([]);

  const { data: signUpData, isLoading } = useGetSignUpData(
    startDate,
    endDate
  );

  const getSignUpActivity = () => {
    try {
      const data = signUpData?.data;

      const month = [];
      const count = [];
      for (let i = 0; i < data?.length; i++) {
        let item = data?.[i];
        month.push(item?.period);
        count.push(item?.count);
      }
      setMonth(month);
      setCount(count);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getSignUpActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, startDate]);

  const chartData = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0.5, "rgba(52, 168, 83, .24)");
    gradient.addColorStop(0.7, "rgba(52, 168, 83, 0.24)");

    return {
      labels: month,
      datasets: [
        {
          data: count,
          pointRadius: 2,
          backgroundColor: gradient,
          fill: true,
          borderColor: "#B8D344",
          tension: 0.6,
          borderWidth: 1.33,
        },
      ],
    };
  };
  const canvas = document.createElement("canvas");
  const theChart = chartData(canvas);

  return (
    <Linegraph
      theChart={theChart}
      title="Sign Ups"
    />
  );
};

export default SignUpActivity;
