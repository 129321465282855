/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { useFormik } from "formik";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import FormInputComponent from "./enterpriseFormInputs/FormInputComponent";
import FormTitleSection from "./FormTitleSection";
import { setSelect } from "../../../utils/helperFunctions";
import { ClipLoader } from "react-spinners";
import DatePickerDialog from "../atoms/DatePicker";
import { useCreateEngineer } from "../../../services/engineer/query";
import { FormMultiSelectComponent } from "./enterpriseFormInputs/FormMultiSelectComponent";
import {
  useFetchedFrameworks,
  useFetchedTechstacks,
} from "../../../services/reference/query";
import FormWysiwygComponent from "./enterpriseFormInputs/FormWysiwygComponent";

const initialValues = {
  dateOfBirth: "",
  frameworks: [],
};

const AddJobRole = ({ onClick, reload }) => {
  const [success, setSuccess] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState("");

  const new_token = localStorage.getItem("token");

  let multipartConfig = {
    headers: {
      Authorization: `Bearer ${new_token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const onCreateSuccess = () => {
    reload();
    setSuccess(true);
  };

  const { data: frameworks } = useFetchedFrameworks();
  const { data: stacks } = useFetchedTechstacks();

  const { mutate, isLoading } = useCreateEngineer(
    multipartConfig,
    onCreateSuccess
  );

  const onSubmit = (values) => {
    mutate(values);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  const closeForm = () => {
    formik.resetForm();
    onClick();
    setSuccess(false);
  };

  return (
    <StyledAddJobRole success={success}>
      <FormTitleSection title="Create Job Role" onClick={closeForm} />
      <div className="form-section">
        <form className="client-form">
          <FormInputComponent
            label="Job Title"
            type="text"
            name="jobTitle"
            placeholder="Enter job title"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.jobTitle}
            error={
              formik.touched.jobTitle && formik.errors.jobTitle
                ? formik.errors.jobTitle
                : null
            }
            required
          />

          <FormMultiSelectComponent
            label="Stack"
            name="stack"
            options={setSelect(stacks?.data, "", "", "", true)}
            onChange={(selectedOption) => {
              formik.setFieldValue("stack", selectedOption);
            }}
            value={formik.values.stack}
            error={
              formik.touched.stack && formik.errors.stack
                ? formik.errors.stack
                : null
            }
            required
          />
          <FormMultiSelectComponent
            label="Framework"
            name="frameworks"
            options={setSelect(frameworks, "", "", "", true)}
            onChange={(selectedOption) => {
              formik.setFieldValue("frameworks", selectedOption);
            }}
            value={formik.values.frameworks}
            error={
              formik.touched.frameworks && formik.errors.frameworks
                ? formik.errors.frameworks
                : null
            }
            required
          />
          <FormInputComponent
            label="Position"
            type="number"
            name="position"
            placeholder="3"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.position}
            error={
              formik.touched.position && formik.errors.position
                ? formik.errors.position
                : null
            }
            required
          />
          <DatePickerDialog
            label="Deadline"
            name="deadline"
            setDate={setDateOfBirth}
            date={dateOfBirth}
            required
          />
          <FormWysiwygComponent
            label="Description"
            name="description"
            // value={proj.description}
            maxLength="999"
            onChange={(e) => {}}
            // error={
            //   proj.description.length > 999
            //     ? "Should not exceed 1000 characters"
            //     : null
            // }
            required
          />

          <MyButton className="form-btn add-client-btn" type="submit" full>
            {" "}
            {isLoading ? (
              <ClipLoader color={"#fff"} size={"30px"} />
            ) : (
              "Create Job Role"
            )}
          </MyButton>
        </form>
        <div className="form-success card p-3">
          <img
            src="/images/sucess-icon.svg"
            alt="green-tick"
            className="sucess-icon"
          />
          <h2 className="form-font green-text">Success</h2>
          <p className="form-font success-msg">
            You successfully created a Job role.
          </p>
          <MyButton className="form-btn success-btn" onClick={closeForm}>
            Go to job role list
          </MyButton>
        </div>
      </div>
    </StyledAddJobRole>
  );
};

const StyledAddJobRole = styled.div`
  width: 70rem;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);
  padding: 10px 20px;
  .underline {
    display: block;
  }
  .form-section {
    padding: 4rem 4rem 0 2rem;
    overflow-y: scroll;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  .two-column {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%;
  }
  .add-client-btn {
    margin-bottom: 3rem;
  }
  .stack-section {
    display: flex;
    flex-wrap: wrap;
    width: 32rem;
  }
  .certification-type {
    max-width: 100px;
    width: 100%;
  }
  .client-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 32rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }

  .edu-new {
    display: grid;
    grid-template-columns: 1fr;
    height: 100%;
    align-items: center;

    div {
      input {
        min-width: 100%;
      }
    }
    .span-btn {
      font-size: 2rem !important;
      color: red !important;
      cursor: pointer;
    }
  }

  .hq-toggle p {
    font-size: 1.6rem;
  }

  .workexp-new {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    height: 100%;
    align-items: center;

    div {
      input {
        min-width: 100%;
      }
    }
    .span-btn {
      font-size: 2rem !important;
      color: red !important;
      cursor: pointer;
    }
  }

  .project-new {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    height: 100%;
    align-items: center;

    div {
      input {
        min-width: 100%;
      }
    }
    .span-btn {
      font-size: 2rem !important;
      color: red !important;
      cursor: pointer;
    }
  }

  .add-more {
    text-align: center;
    font-size: 1.8rem;
    color: #21334f;
    width: 100%;
    padding: 0.7rem 1rem;
    background: #fff;
    border: 1px solid rgba(33, 51, 79, 0.15);
    margin-bottom: 2rem;

    span {
      font-weight: 800;
      font-size: 1.8rem;
      margin-right: 0.7rem;
    }
  }
  textarea {
    min-width: 100% !important;
  }

  fieldset {
    border: 0.1px gray solid;
    padding: 1rem 2rem;
    margin-top: 5rem;
  }

  legend {
    background: linear-gradient(96.67deg, #34a853 0%, #b8d344 100%);
    color: white;
    text-align: center;
    padding: 1rem;
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }
  .last-one {
    margin-bottom: 6rem;
  }

  @media only screen and (min-width: 280px) and (max-width: 767px) {
    width: 100%;
    .two-column {
      grid-template-columns: 1fr;
    }
    .cert-new {
      display: grid;
      grid-template-columns: 1fr;
    }
    .edu-new {
      display: grid;
      grid-template-columns: 1fr;
    }
    .project-new {
      display: grid;
      grid-template-columns: 1fr;
    }
    .workexp-new {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
`;

export default AddJobRole;
