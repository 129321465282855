import { Switch } from '@headlessui/react'
import styled from "styled-components";

export default function FormToggleComponent({onChange, value}) {
  return (
    <StyledFormToggleComponent>
      <Switch
        checked={value}
        onChange={onChange}
        className={`toggle-wrapper ${value ? 'switch-bg-active' : 'switch-bg-default'}`}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={`toggler ${value ? 'switch-pos-active' : 'switch-pos-default'}`}
        />
      </Switch>
    </StyledFormToggleComponent>
  )
}

const StyledFormToggleComponent = styled.div`
  .toggle-wrapper {
    position: relative;
    display: inline-flex;
    height: 32px;
    width: 74px;
    cursor: pointer;
    border-radius: 9999px;
    border-width: 2px;
    border-color: transparent;
  }

  .toggler {
    display: inline-block;
    background: #fff;
    height: 28px;
    width: 34px;
    border-radius: 9999px;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  }

  .switch-bg-active {
    background: #34a853;
  }

  .switch-bg-default {
    background: #ccc;
  }

  .switch-pos-active {
    transform: translateX(3.5rem);
  }

  .switch-pos-default {
    transform: translateX(0px);
  }
`;
