import {
  fetchEngineerRequests,
  fetchPlacementRequestDetail,
  fetchPlacementRequests,
} from "./api";
import { useQuery } from "react-query";

export const useFetchedEngineerRequests = (filterOptions) => {
  const query = useQuery(
    [filterOptions, "engineer-req"],
    fetchEngineerRequests
  );

  return query;
};

export const useFetchedPlacementRequests = (filterOptions) => {
  const query = useQuery(
    [filterOptions, "placement-req"],
    fetchPlacementRequests
  );

  return query;
};

export const useFetchedPlacementRequestDetail = (requestId) => {
  const query = useQuery(
    [requestId, "placement-req-detail"],
    fetchPlacementRequestDetail
  );

  return query;
};

