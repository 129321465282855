import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { adminNotificationActions } from "../common/listingData";
import Layout from "../common/Layout";
import ProtectedComponents from "../local-routes/ProtectedComponents";
import MyButton from "../../landingPage/MyButton";
import { MdMailOutline, MdAdd } from "react-icons/md";
import { RxTrash } from "react-icons/rx";
import TableContainer from "../common/TableContainer";
import NotificationsFilter from "../filterFields/NotificationsFilter";
import {
  useFetchedMailingList,
  useSendNotification,
  useRemoveUsers,
} from "../../../services/engineer/query";
import FormModal from "../form/FormModal";
import AddUserToMailList from "../form/AddUserToMailListForm";
import { mappedWeeks } from "../../../data/week";
import { useFetchedSquads } from "../../../services/reference/query";

const AdminNotificationPage = ({ pageType }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState("");
  const [postData, setPostData] = useState({
    mailingListIds: [],
  });
  const [filterOptions, setFilterOptions] = useState({
    modules: "WEEKLY_PERFORMANCE_NOTIFICATION",
    week: "",
    squad: "",
  });

  const { data: squads } = useFetchedSquads();

  useEffect(() => {
    setFilterOptions({
      ...filterOptions,
      squad: squads?.data[0].id,
      week: 1,
    });

    // eslint-disable-next-line
  }, [squads]);

  const { data: mailingList, refetch } = useFetchedMailingList(
    filterOptions.modules
  );

  const addUser = (id) => {
    setPostData((prevState) => {
      return {
        mailingListIds: postData.mailingListIds?.includes(id)
          ? prevState.mailingListIds.filter((mailId) => mailId !== id)
          : [...prevState.mailingListIds, id],
      };
    });
  };

  const addAllUsers = () => {
    setPostData((prev) => {
      return {
        ...prev,
        mailingListIds:
          prev.mailingListIds.length === mailingList.data.content.length
            ? []
            : mailingList.data.content.map((user) => user.mailingListId),
      };
    });
  };

  const handleModal = (id) => {
    setIsModalOpen(!isModalOpen);
  };

  const new_token = localStorage.getItem("token");

  let multipartConfig = {
    headers: {
      Authorization: `Bearer ${new_token}`,
      "Content-Type": "application/json",
    },
  };

  const reset = () => {
    setPostData({
      mailingListIds: [],
    });
  };

  const { mutate } = useSendNotification(multipartConfig, reset);
  const { mutate: removeUser } = useRemoveUsers(
    multipartConfig,
    reset,
    refetch,
    filterOptions.modules
  );

  const handleRemoveUsers = (e) => {
    e.preventDefault();
    removeUser(postData);
  };

  const handleFilterChange = (name, value) => {
    setFilterOptions({ ...filterOptions, [name]: value });
  };

  return (
    <Layout navType="admin">
      <StyledDashboardPage isModalOpen={isModalOpen}>
        <div className="page-header">
          <h4>Notifications</h4>
          <ProtectedComponents permission={["manageMailingList"]}>
            <div className="notifications-div">
              <MyButton
                transparent
                onClick={() => {
                  mutate({ ...postData, ...filterOptions });
                }}
                className="btn-comp"
              >
                <div className="btn-items">
                  {adminNotificationActions.btn1Info}
                  <MdMailOutline />
                </div>
              </MyButton>
              <ProtectedComponents permission={["manageAdminUser", "viewAdminUser", "manageMailingList"]}>
                <MyButton
                  onClick={() => {
                    handleModal();
                    setActiveModal("add-user");
                  }}
                  className="btn-comp"
                >
                  <div className="btn-items">
                    {adminNotificationActions.btn2Info}
                    <MdAdd />
                  </div>
                </MyButton>
              </ProtectedComponents>
              <div className="btn-items red">
                <button onClick={handleRemoveUsers}>
                  {adminNotificationActions.btn3Info}
                </button>
                <div className="remove-icon">
                  <RxTrash size={"1.6rem"} />
                </div>
              </div>
            </div>
          </ProtectedComponents>
        </div>
        <NotificationsFilter
          weeks={mappedWeeks}
          squads={squads}
          handleFilterChange={handleFilterChange}
        />
        <TableContainer>
          <table style={{ width: "100%" }}>
            <thead style={{ width: "100%" }}>
              <tr>
                <th className="select-all" onClick={addAllUsers}>
                  Select all
                </th>
                <th>Names</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {mailingList?.data?.content?.map(
                ({ name, email, userId, mailingListId }) => {
                  return (
                    <tr key={userId}>
                      <td className="check">
                        <input
                          type="checkbox"
                          className="select"
                          onClick={() => addUser(mailingListId)}
                          checked={postData.mailingListIds?.includes(
                            mailingListId
                          )}
                          readOnly
                        />
                      </td>
                      <td>{name}</td>
                      <td>{email}</td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </TableContainer>
      </StyledDashboardPage>
      {isModalOpen && (
        <div className="form-modal">
          <FormModal handleModal={handleModal}>
            {activeModal === "add-user" && (
              <AddUserToMailList
                refetch={refetch}
                onClick={handleModal}
                mailList={mailingList?.data?.content}
              />
            )}
          </FormModal>
        </div>
      )}
    </Layout>
  );
};

const StyledDashboardPage = styled.div`
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 2.8rem;
    margin-bottom: 1.1rem;
  }
  .page-header h4 {
    font-weight: 700;
    font-size: 2.4rem;
  }
  .page-header h3 {
    margin-bottom: -10rem;
    font-weight: 700;
    font-size: 2.4rem;
  }

  .notifications-div {
    display: flex;
    gap: 1rem;
  }

  .btn-items {
    display: flex;
    align-items: center;
    gap: 10.5px;
  }
  .red {
    color: #f04438;
    user-select: none;
  }
  .red:hover {
    opacity: 0.8;
  }
  .red:active {
    opacity: 0.6;
  }

  .btn-items button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    font-size: 16px;
  }
  .select-all {
    text-align: center;
    cursor: pointer;
    user-select: none;
  }
  .check {
    text-align: center;
  }
  .select {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.3rem;
    border: 1px solid #101828;
    cursor: pointer;
  }
  /* style a selected input checkbox */

  .select:checked {
    background: #34a853;
  }
  td .check {
    text-align: center;
  }
  @media screen and (max-width: 1170px) {
    width: 100%;
    .page-header {
      margin-top: 3.4rem;
      margin-bottom: 1.1rem;
    }
  }
  @media screen and (max-width: 800px) {
  }
  @media screen and (max-width: 770px) {
    .page-header h4 {
      font-size: 2rem;
    }
    .notifications-div {
      width: 100%;
      overflow: scroll;
      flex-direction: row;
    }
  }

  @media screen and (max-width: 600px) {
    .btn-items button {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 500px) {
    .page-header {
      margin-top: 1rem;
    }

    .page-header h4 {
      margin-bottom: 16px;
    }
  }
`;

export default AdminNotificationPage;
