import nProgress from "nprogress";
import { toast } from "react-toastify";
import { apiDelete, apiGet, apiPatch, apiPut } from "../../utils/apiHelpers";

export const fetchOrganisations = async (params) => {
    const [filterOptions] = params.queryKey;
    try {
      const res = await apiGet(
        `/admin/organisations?page=${filterOptions.page}&size=${filterOptions.size}&search=${filterOptions.keyword || ""}&status=${filterOptions.status || ""}&client_type=${filterOptions.client_type || ""}`,
        "admin"
      );
  
      return res.data;
    } catch (err) {
      console.log(err);
    }
};

//Fetch user listing per organization
export const listOrgansationUsers = async (params) => {
  try {
    const res = await apiGet(`/admin/organisations/${params.id}/users?page=${params.page}`);
    
    return res.data
  } catch (err) {
    console.log(err)
  }
};

export const fetchOrganisationDocuments = async (orgId) => {
  try {
    const res = await apiGet(`/admin/organisations/${orgId}/documents`);

    return res.data
  } catch (err) {
    console.log(err);
  }
};

export const fetchOrganisationSubscriptions = async (params) => {
  const [{ orgId, filterOptions }] = params.queryKey;
  const page = filterOptions?.page || 1
  try {
    const res = await apiGet(`/admin/organisations/${orgId}/subscriptions?page=${page}`);

    return res.data
  } catch (err) {
    console.log(err);
  }
};

export const fetchOrganisationInvoices = async (orgId) => {
  try {
    const res = await apiGet(`/admin/organisations/${orgId}/invoices`);

    return res.data
  } catch (err) {
    console.log(err);
  }
};

export const activateClient = async (item) => {
  nProgress.start()

  const [firstName, lastName] = item.contactPerson.split(" ");
  const userPayload = {
    email: item.email.toLowerCase(),
    firstName: firstName,
    lastName: lastName,
    organisationRole: "user",
    phone: item.telephoneNumber,
    roles: [0],
    status: "ACTIVE",
  };

  try {
    await apiPut(`/admin/organisations/${item.id}/activate`, userPayload, {});
  } catch (error) {
    throw error
  }
  nProgress.done()
};

export const deactivateClient = async (item) => {
  nProgress.start()

  const [firstName, lastName] = item.contactPerson.split(" ");
  const userPayload = {
    email: item.email.toLowerCase(),
    firstName: firstName,
    lastName: lastName,
    organisationRole: "user",
    phone: item.telephoneNumber,
    roles: [0],
    status: "INACTIVE",
  };

  try {
    await apiPut(`/admin/organisations/${item.id}/deactivate`, userPayload, {});
  } catch (error) {
    throw error
  }
  nProgress.done()
};

export const sendReviewNotification = async (id) => {
  try {
    nProgress.start()

    await apiGet(`/admin/organisations/${id}/review_notification`);
    nProgress.done()
    toast.success("Notification sent successfully");
  } catch (error) {
    console.log({ error });
    nProgress.done()
  }
};

//deactivate users
export const deactivateUser = async (item, id) => {
  nProgress.start()
  item.status = "INACTIVE";
  const userPayload = {
    ...item,
  };

  try {
    await apiPut(
      `/admin/organisations/${id}/users/${item.id}/deactivate`,
      userPayload,
      {}
    );
  } catch (err) {
    console.log({ err });
    throw err
  }
  nProgress.done()
};

//delete users
export const deleteUsers = async (params) => {
  nProgress.start()
  try {
    await apiDelete(`/admin/organisations/${params.orgId}/users/${params.userId}`, {});
    
  } catch (err) {
    throw err
  }
  nProgress.done()
};

//activate users
export const activateUser = async (item, id) => {
  nProgress.start()
  item.status = "ACTIVE";
  const userPayload = {
    ...item,
  };

  try {
    await apiPut(
      `/admin/organisations/${id}/users/${item.id}/activate`,
      userPayload,
      {}
    );
  } catch (err) {
    throw err
  }
  nProgress.done()
};

export const handleGenerateLink = async (id) => {
  try {
    nProgress.start()

      await apiPatch(
        `/admin/organisation/users/${id}/resendPasswordLink`
      );
    nProgress.done()
    toast.success("Notification sent successfully");
  } catch (error) {
    console.log({ error });
    nProgress.done()
  }
};
