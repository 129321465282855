import { useQuery } from "react-query";
import { fetchEngAssignmentListing, fetchPlacementHistory } from "./api";

export const usePlacementHistory = (filterOptions, reset) => {
  const query = useQuery([filterOptions, "placement-history"], fetchPlacementHistory, {
    keepPreviousData: true,
    onSuccess: () => reset()
  });

  return query;
};

export const useEngAssignmentListing = (id) => {
  const query = useQuery([`eng-assignment-history${id}`], () => fetchEngAssignmentListing(id));

  return query;
};