import { useState } from "react";
import styled from "styled-components";
import LoginActivity from "./devprofileActivity/LoginActivity";
import CompanyDropdown from "../../atoms/CompanyDropdown";
import DateRangePicker from "../../atoms/DateRangePicker";
import { format } from "date-fns";
import TotalRegistered from "./devprofileActivity/TotalRegistered";
import SignUpActivity from "./devprofileActivity/SignUpTracker";
import ProfileViewActivity from "./devprofileActivity/ProfileViewsTracker";
import ChooseForMeActivity from "./devprofileActivity/ChooseForMeTracker";
import SelectedEngineersTracker from "./devprofileActivity/SelectedEngineersTracking";
import ChooseEngineerForMeAndSelectEngineerMultiLines from "./devprofileActivity/ChooseEngineerForMeAndSelectEngineerMultiLines";
import { Link } from "react-router-dom";

function DevprofileTracker({ pageType }) {
  const [orgName, setOrgName] = useState("Activity Overview");
  const [organisationDetails, setOrganisationDetails] = useState(null)
  const [orgId, setOrgId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleClick = (id, name) => {
    setOrgId(id);
    setOrgName(name);
  };

  const handleDateRangeChange = (value) => {
    if (value) {
      const formatStartDate = format(value[0], "dd/MM/y");
      const formatEndDate = format(value[1], "dd/MM/y");
      setStartDate(formatStartDate);
      setEndDate(formatEndDate);
    } else {
      setStartDate("");
      setEndDate("");
    }
  };

  return (
    <StyledActivity>
      <div className="header">
        <div className="company-header">
          <h2>You’re currently viewing:</h2>
          <CompanyDropdown
            handleClick={handleClick}
            orgName={orgName}
            orgId={orgId}
            setOrgName={setOrgName}
            setOrgId={setOrgId}
            setOrganisationDetails={setOrganisationDetails}
          />
        </div>
      </div>
      {organisationDetails && <table>
        <tbody>
          <tr>
            <td>
              <Link to={`/admin/clients/users/${organisationDetails?.value}`}>{organisationDetails?.name}</Link>
            </td>
            <td>{organisationDetails?.contactPerson}</td>
            <td>{organisationDetails?.email}</td>
            <td>{organisationDetails?.telephoneNumber}</td>
            <td>{organisationDetails?.role}</td>
          </tr>
        </tbody>
      </table>}
      <div className="date-range">
        <div className="mr-2">Choose Date Range:</div>
        <DateRangePicker onChange={handleDateRangeChange} />
      </div>
      <div className="graph-cards">
        <SignUpActivity orgId={orgId} startDate={startDate} endDate={endDate} />
        <LoginActivity orgId={orgId} startDate={startDate} endDate={endDate} />
        <TotalRegistered
          orgId={orgId}
          startDate={startDate}
          endDate={endDate}
        />
        <ChooseEngineerForMeAndSelectEngineerMultiLines
          orgId={orgId}
          startDate={startDate}
          endDate={endDate}
        />
        <ChooseForMeActivity
          orgId={orgId}
          startDate={startDate}
          endDate={endDate}
        />

        <SelectedEngineersTracker
          orgId={orgId}
          startDate={startDate}
          endDate={endDate}
        />
        <ProfileViewActivity
          orgId={orgId}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
    </StyledActivity>
  );
}

const StyledActivity = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)),
    #fafffb;

  .company-header {
    display: flex;
    gap: 20px;
    align-items: center;
    flex-direction: row;
  }

  .company-header > h2 {
    font-weight: 700;
    font-size: 21px;
    line-height: 30px;
    color: #21334f;
    cursor: pointer;
    padding: 0;
    margin: 0;
    white-space: nowrap;
  }

  .date-range {
    display: flex;
    margin-top: 16px;
    align-items: center;
  }

  .graph-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    // grid-auto-rows: 300px;
    gap: 20px;
    margin-top: 20px;
  }

  .chevron {
    width: 14px;
    height: 8px;
    margin-left: 25px;
  }

  @media only screen and (max-width: 767px) {
    .graph-cards {
      display: grid;
      grid-template-columns: 1fr;
    }

    .company-header {
      flex-direction: column;
      justify-content: flex-start;
    }

    .company-header > h2 {
      font-size: 20px;
    }
  }
`;

export default DevprofileTracker;
