/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import FormInputComponent from "./enterpriseFormInputs/FormInputComponent";
import FormSelectComponent from "./enterpriseFormInputs/FormSelectComponent";
import FormTitleSection from "./FormTitleSection";
import FormUploadComponent from "./enterpriseFormInputs/FormUploadComponent";
import FormImgComponent from "./enterpriseFormInputs/FormImgComponent";
import SelectedStack from "./SelectedStack";
import { apiGet } from "../../../utils/apiHelpers";
import { setSelect } from "../../../utils/helperFunctions";
import { toast } from "react-toastify";
import { useAdmin } from "../../../context/AdminState";
import { ClipLoader } from "react-spinners";
import { BsTrash } from "react-icons/bs";
// import FormTextAreaComponent from "./enterpriseFormInputs/FormTextAreaComponent";
import DatePickerDialog from "../atoms/DatePicker";
import { useCreateEngineer } from "../../../services/engineer/query";
import { FormMultiSelectComponent } from "./enterpriseFormInputs/FormMultiSelectComponent";
import { useFetchedFrameworks } from "../../../services/reference/query";
import FormWysiwygComponent from "./enterpriseFormInputs/FormWysiwygComponent";
import FormTextAreaComponent from "./enterpriseFormInputs/FormTextAreaComponent";
import FormToggleComponent from "./enterpriseFormInputs/FormToggleComponent";
import { eduQualification } from "../../../data";
// import FormToggleComponent from "./enterpriseFormInputs/FormToggleComponent";

const initialValues = {
  email: "",
  firstName: "",
  lastName: "",
  telephoneNo: "",
  technicalSkills: "",
  majorTechStacks: "",
  squad: "",
  status: "ACTIVE",
  videoResumeLink: "",
  gender: "",
  engineerType: "",
  engineerBatch: "",

  // new fields
  currentLocation: "",
  decadevEmailAddress: "",
  dateOfBirth: "",
  gitHubProfileLink: "",
  linkedInProfileLink: "",
  frameworks: [],
  about: "",
  // visibleInFrontEnd: false
};
// week, batch,
const phoneRegExp = /^\d*(\+\d+)?$/;
// match if decagon email address
// const emailRegExp = /^(\.?[a-z\d·]){4,}@decagonhq(\.com)?$/;
// const linkRegExp =
//   /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email format").required("*Required"),
  firstName: Yup.string().required("*Required"),
  lastName: Yup.string().required("*Required"),
  telephoneNo: Yup.string()
    .min(9, "*Phone number is not valid")
    .matches(phoneRegExp, "Phone number is not valid"),
  technicalSkills: Yup.string(),
  gender: Yup.string(),
  engineerType: Yup.string(),
  majorTechStacks: Yup.number("select an option"),
  squad: Yup.string("select an option"),
  about: Yup.string(""),
  videoResumeLink: Yup.string().matches(
    /^(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:(?:watch)?\?(?:.*&)?v(?:i)?=|embed\/|v\/|shorts\/))([\w-]{11})(?:\S+)?$/,
    "Must be a Youtube Link"
  ),
  decadevEmailAddress: Yup.string(),
  // status: Yup.string("select an option"),
  // gitHubProfileLink: Yup.string().matches(linkRegExp, "Link is not valid"),
  // linkedInProfileLink: Yup.string().matches(linkRegExp, "Link is not valid"),
});

const AddEngForm = ({ onClick, reload }) => {
  const { stackList, fetchStackOptions, locations, fetchLocation } = useAdmin();

  const [success, setSuccess] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [stack, setStack] = useState("");
  const [resumeDoc, setResumeDoc] = useState({});
  const [profileImg, setProfileImg] = useState({});
  const [stackResult, setStackResult] = useState([]);
  const [stacks, setStacks] = useState({});
  const [squadOptions, setSquadOptions] = useState([]);
  const [dateOfBirth, setDateOfBirth] = useState("");

  const [resumeDocError, setResumeDocError] = useState("");
  const [profileImgError, setProfileImgError] = useState("");
  const [selectedOptionsError, setSelectedOptionsError] = useState("");
  const [educations, setEducations] = useState([
    {
      course: "",
      grade: "",
      graduationDate: "",
      institution: "",
      qualification: "",
      mainQualification: false,
    },
  ]);

  const [projects, setProjects] = useState([
    {
      description: "",
      mainProject: false,
      name: "",
      technologyUsed: "",
      projectLink: "",
    },
  ]);
  const [workExperiences, setWorkExperiences] = useState([
    {
      companyName: "",
      description: "",
      role: "",
      startDate: "",
      endDate: "",
    },
  ]);

  const [certifications, setCertifications] = useState([
    {
      dateIssued: "",
      expiryDate: "",
      issuingOrganisation: "",
      name: "",
    },
  ]);
  const [addMoreEdu, setAddMoreEdu] = useState(0);
  const [addMoreProjects, setAddMoreProjects] = useState(0);
  const [addMoreWorkExp, setAddMoreWorkExp] = useState(0);
  const [addMoreCert, setAddMoreCert] = useState(0);

  const new_token = localStorage.getItem("token");

  let multipartConfig = {
    headers: {
      Authorization: `Bearer ${new_token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const onCreateSuccess = () => {
    reload();
    setSuccess(true);
  };

  const { data: frameworks } = useFetchedFrameworks();

  const { mutate, isLoading } = useCreateEngineer(
    multipartConfig,
    onCreateSuccess
  );

  // handle changes
  const handleChanges = (e, index, type) => {
    const value = e.target.value;
    const name = e.target.name;
    if (type === "education") {
      if (name === "mainQualification") {
        const changeMainQualification = educations.map((item, i) => {
          if (index !== i) {
            item.mainQualification = false;
          } else item.mainQualification = value;

          return item;
        });

        setEducations(changeMainQualification);
      }
      const newEdu = [...educations];
      newEdu[index][name] = value;
      newEdu[index].indexing = index;
      setEducations(newEdu);
    }
    if (type === "projects") {
      const newProject = [...projects];
      if (name === "mainProject" && value === "true") {
        newProject[index][name] = true;
      } else if (name === "mainProject" && value === "false") {
        newProject[index][name] = false;
      } else {
        newProject[index][name] = value;
        newProject[index].indexing = index;
      }
      setProjects(newProject);
    }
    if (type === "workExperiences") {
      const newExp = [...workExperiences];
      newExp[index][name] = value;
      newExp[index].indexing = index;
      setWorkExperiences(newExp);
    }

    if (type === "certifications") {
      const newCert = [...certifications];
      newCert[index][name] = value;
      newCert[index].indexing = index;
      setCertifications(newCert);
    }
  };

  let locationOptions =
    locations?.locations?.length > 0
      ? locations?.locations?.map((location) => {
          return {
            value: location.id,
            label: location.name,
          };
        })
      : [];
  // delete item
  const deleteItems = (index, type) => {
    if (type === "educations") {
      const newEdu = [...educations];
      const newFilteredEdu = newEdu.filter((item, i) => item.indexing !== index);
      setEducations(newFilteredEdu);
    }
    if (type === "projects") {
      const newProject = [...projects];
      const newFilteredProj = newProject.filter((item, i) => item.indexing !== index);
      setProjects(newFilteredProj);
    }
    if (type === "workExperiences") {
      const newExp = [...workExperiences];
      const newFilteredExp = newExp.filter((item, i) => item.indexing !== index);
      setWorkExperiences(newFilteredExp);
    }

    if (type === "certifications") {
      const newCert = [...certifications];
      const newFilteredCert = newCert.filter((item, i) => item.indexing !== index);
      setCertifications(newFilteredCert);
    }
  };

  // add more button
  const addMore = (type) => {
    if (type === "educations") {
      let count = addMoreEdu;
      let newCount = count + 1;
      setAddMoreEdu(newCount);
      setEducations([
        ...educations,
        {
          course: "",
          grade: "",
          graduationDate: "",
          institution: "",
          qualification: "",
          mainQualification: false,
        },
      ]);
    }
    if (type === "projects") {
      let count = addMoreProjects;
      let newCount = count + 1;
      setAddMoreProjects(newCount);
      setProjects([
        ...projects,
        {
          description: "",
          mainProject: false,
          name: "",
          technologyUsed: "",
          projectLink: "",
        },
      ]);
    }
    if (type === "workExperiences") {
      let count = addMoreWorkExp;
      let newCount = count + 1;
      setAddMoreWorkExp(newCount);
      setWorkExperiences([
        ...workExperiences,
        {
          companyName: "",
          description: "",
          endDate: "",
          startDate: "",
          role: "",
        },
      ]);
    }
    if (type === "certifications") {
      let count = addMoreCert;
      let newCount = count + 1;
      setAddMoreCert(newCount);
      setCertifications([
        ...certifications,
        {
          dateIssued: "",
          expiryDate: "",
          issuingOrganisation: "",
          name: "",
        },
      ]);
    }
  };

  const docType = ["pdf", "docx", "doc"];

  const pictureType = ["jpg", "jpeg", "gif", "png"];

  const engineersStatus = [
    {
      value: "ACTIVE",
      label: "ACTIVE",
    },
    {
      value: "DISENGAGED",
      label: "INACTIVE",
    },
  ];

  useEffect(() => {
    setResumeDocError("");
  }, [resumeDoc]);

  useEffect(() => {
    setProfileImgError("");
  }, [profileImg]);

  useEffect(() => {
    setSelectedOptionsError("");
  }, [selectedOptions]);

  useEffect(() => {
    setStack(selectedOptions[selectedOptions.length - 1]);
  }, [selectedOptions]);

  useEffect(() => {
    let stckOpts = setSelect(stackList, "Stack");
    setStackResult(stckOpts);
  }, [stackList]);

  useEffect(() => {
    let result = getStacksFromstackResult(stackResult);
    setStacks(result);
  }, [stackResult]);

  useEffect(() => {
    fetchSquadOptions();
    fetchStackOptions();
    fetchLocation();
    // eslint-disable-next-line
  }, []);

  function getStacksFromstackResult(stackResult) {
    let obj = {};

    for (let i = 0; i < stackResult.length; i++) {
      let current = stackResult[i];

      obj[current.value] = current.label;
    }
    return obj;
  }

  const handleStack = (event, majorTechStacks) => {
    if (
      stack !== "" &&
      majorTechStacks !== event.target.value &&
      !selectedOptions.includes(event.target.value)
    ) {
      setSelectedOptions([...selectedOptions, event.target.value]);
    }
  };

  const deleteStack = (index) => {
    if (index > -1) {
      setSelectedOptions(selectedOptions.filter((value, i) => i !== index));
    }
  };

  const fetchSquadOptions = async () => {
    try {
      const res = await apiGet(`/admin/squads`);
      let result = res.data.data;
      let options = result.map((item) => {
        return { value: item.id, label: "SQ" + item.name };
      });
      options.unshift({ value: "", label: "Select Squad" });
      setSquadOptions(options);
    } catch (err) {
      toast.error(err);
    }
  };

  const toNumbers = (arr) => arr.map(Number);
  const formatDate = (date) => {
    return date.split("-").reverse().join("/");
  };

  const onSubmit = (values) => {
    values.otherTechStacks = selectedOptions;
    values.dateOfBirth = formatDate(dateOfBirth);
    let newArr = [];
    newArr.push(values.majorTechStacks);
    values.majorTechStacks = newArr;
    values.otherTechStacks = toNumbers(values.otherTechStacks);
    values.majorTechStacks = toNumbers(values.majorTechStacks);
    values.currentLocation = Number(values.currentLocation);
    values.frameworks = values.frameworks.map((item) => item.value);
    if (!resumeDoc.name || !selectedOptions[0]) {
      if (!resumeDoc.name) {
        // setResumeDocError("*Required");
      }
      if (!selectedOptions[0]) {
        // setSelectedOptionsError("*Required");
      }
      // return;
    }
    const payload = {
      educations: educations,
      projects: projects,
      workExperiences: workExperiences,
      certifications: certifications,
    };
    payload.educations.map((item) => {
      item.graduationDate = formatDate(item.graduationDate);
      return item;
    });
    payload.workExperiences.map((item) => {
      item.endDate = formatDate(item.endDate);
      item.startDate = formatDate(item.startDate);
      return item;
    });
    payload.certifications.map((item) => {
      item.dateIssued = formatDate(item.dateIssued);
      item.expiryDate = formatDate(item.expiryDate);
      return item;
    });
    values.squad = parseInt(values.squad);
    values = { ...values, ...payload };

    let formData = new FormData();
    formData.append("resume_upload", resumeDoc);
    if (profileImg) {
      formData.append("profile_upload", profileImg);
    }
    formData.append(
      "engineer_dto",
      new Blob([JSON.stringify(values)], {
        type: "application/json",
      })
    );
    mutate(formData);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const closeForm = () => {
    formik.resetForm();
    onClick();
    setResumeDoc(() => ({}));
    setSuccess(false);
  };

  const engType = [
    { label: "Engineer Type", value: "" },
    { label: "Decadevs", value: "DECADEV" },
    { label: "Non-Decadevs", value: "NON_DECADEV" },
  ];

  const genderOptions = [
    { value: "", label: "Select Gender" },
    { value: "MALE", label: "MALE" },
    { value: "FEMALE", label: "FEMALE" },
  ];

  const projectsOptions = [
    { value: "", label: "Choose" },
    {
      value: "true",
      label: "True",
    },
    {
      value: "false",
      label: "False",
    },
  ];

  const gradeOptions = [
    { value: "", label: "Choose" },
    { value: "PASS", label: "Pass" },
    { value: "FIRST_CLASS", label: "First Class" },
    { value: "SECOND_CLASS_UPPER", label: "Second Class Upper" },
    { value: "SECOND_CLASS_LOWER", label: "Second Class Lower" },
    { value: "THIRD_CLASS", label: "Third Class" },
  ];

  return (
    <StyledAddEngForm success={success}>
      <FormTitleSection title="Add New Engineer" onClick={closeForm} />
      <div className="form-section">
        <form onSubmit={formik.handleSubmit} className="client-form">
          <div className="d-flex justify-content-between">
            <FormImgComponent
              pictureType={pictureType}
              label="Upload Image"
              type="file"
              error={profileImgError}
              selectedFile={profileImg}
              setSelectedFile={setProfileImg}
              acceptTypes={`jpg, jpeg, gif, png. Size Limit 5mb`}
            />
            {/* <div>
              <label className="form-label form-font">Engineer visibility</label>
              <FormToggleComponent 
                onChange={(x) => {
                  formik.setFieldValue("visibleInFrontEnd", x)
                }}
                value={formik.values.visibleInFrontEnd}
              />
            </div> */}
          </div>
          <div className="two-column">
            <FormSelectComponent
              label="Engineer Type"
              name="engineerType"
              options={engType}
              onChange={formik.handleChange}
              value={formik.values.engineerType}
              error={selectedOptionsError}
            />
            <FormSelectComponent
              label="Squad"
              name="squad"
              options={squadOptions}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.squad}
              error={
                formik.touched.squad && formik.errors.squad
                  ? formik.errors.squad
                  : null
              }
            />
          </div>
          <div className="two-column">
            <FormInputComponent
              label="Email address"
              type="email"
              name="email"
              placeholder="Enter email address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null
              }
              required={true}
            />
            <FormInputComponent
              label="Decadev Email Address"
              name="decadevEmailAddress"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.decadevEmailAddress}
              error={
                formik.touched.decadevEmailAddress &&
                formik.errors.decadevEmailAddress
                  ? formik.errors.decadevEmailAddress
                  : null
              }
            />
          </div>
          <div className="two-column">
            <FormInputComponent
              label="First Name"
              type="text"
              name="firstName"
              placeholder="Enter first name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              error={
                formik.touched.firstName && formik.errors.firstName
                  ? formik.errors.firstName
                  : null
              }
              required
            />
            <FormInputComponent
              label="Last Name"
              type="text"
              name="lastName"
              placeholder="Enter last name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              error={
                formik.touched.lastName && formik.errors.lastName
                  ? formik.errors.lastName
                  : null
              }
              required
            />
          </div>
          <div className="two-column">
            <FormInputComponent
              label="Phone number"
              type="tel"
              name="telephoneNo"
              placeholder="Enter phone number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.telephoneNo}
              error={
                formik.touched.telephoneNo && formik.errors.telephoneNo
                  ? formik.errors.telephoneNo
                  : null
              }
            />
          </div>
          <div className="two-column">
            <FormSelectComponent
              label="Current Location"
              name="currentLocation"
              options={[
                { label: "Current Location", value: "" },
                ...locationOptions,
              ]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.currentLocation}
              error={
                formik.touched.currentLocation && formik.errors.currentLocation
                  ? formik.errors.currentLocation
                  : null
              }
            />

            <DatePickerDialog
              label="Date of Birth"
              name="dateOfBirth"
              setDate={setDateOfBirth}
              date={dateOfBirth}
            />
          </div>
          <div className="two-column">
            <FormSelectComponent
              label="Gender"
              name="gender"
              options={genderOptions}
              onChange={formik.handleChange}
              value={formik.values.gender}
              onBlur={formik.handleBlur}
              error={
                formik.touched.gender && formik.errors.gender
                  ? formik.errors.gender
                  : null
              }
            />
            <FormSelectComponent
              label="Status"
              name="status"
              options={engineersStatus}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.status}
              error={
                formik.touched.status && formik.errors.status
                  ? formik.errors.status
                  : null
              }
            />
          </div>

          <div className="two-column">
            <FormInputComponent
              label="Technical Skills"
              type="text"
              name="technicalSkills"
              placeholder="GitHub, Jira"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.technicalSkills}
              error={
                formik.touched.technicalSkills && formik.errors.technicalSkills
                  ? formik.errors.technicalSkills
                  : null
              }
            />

            <FormSelectComponent
              label="Major Stack"
              name="majorTechStacks"
              options={stackResult}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.majorTechStacks}
              error={
                formik.touched.majorTechStacks && formik.errors.majorTechStacks
                  ? formik.errors.majorTechStacks
                  : null
              }
            />
          </div>

          <FormTextAreaComponent
            label="About"
            name="about"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.about}
            error={
              formik.touched.about && formik.errors.about
                ? formik.errors.about
                : null
            }
            maxLength="999"
            pageFormType="engReg"
          />

          <FormMultiSelectComponent
            label="Framework"
            name="frameworks"
            options={setSelect(frameworks, "", "", "", true)}
            onChange={(selectedOption) => {
              formik.setFieldValue("frameworks", selectedOption);
            }}
            value={formik.values.frameworks}
            error={
              formik.touched.frameworks && formik.errors.frameworks
                ? formik.errors.frameworks
                : null
            }
          />

          <FormSelectComponent
            label="Other stack (select technology)"
            name="stack"
            options={stackResult}
            onChange={(e) => handleStack(e, formik.values.majorTechStacks)}
            value={stack}
            error={selectedOptionsError}
          />

          <div className="stack-section">
            {selectedOptions &&
              selectedOptions.map((option, index) => {
                return (
                  <SelectedStack
                    key={index}
                    name={stacks[option]}
                    onClick={(e) => {
                      e.preventDefault();
                      deleteStack(index);
                    }}
                  />
                );
              })}
          </div>

          <FormInputComponent
            label="Github Profile Link"
            name="gitHubProfileLink"
            placeholder="https://github.com/username"
            value={formik.values.gitHubProfileLink}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.gitHubProfileLink &&
              formik.errors.gitHubProfileLink
                ? formik.errors.gitHubProfileLink
                : null
            }
          />

          <FormInputComponent
            label="LinkedIn Profile Link"
            name="linkedInProfileLink"
            placeholder="https://www.linkedin.com/in/username"
            value={formik.values.linkedInProfileLink}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.linkedInProfileLink &&
              formik.errors.linkedInProfileLink
                ? formik.errors.linkedInProfileLink
                : null
            }
          />

          <fieldset>
            <legend className="heading-text">Educations</legend>

            {educations.map((edu, i) => (
              <div className="edu-new" key={i}>
                <FormInputComponent
                  label="Institution"
                  name="institution"
                  value={edu.institution}
                  onChange={(e) => {
                    handleChanges(e, i, "education");
                  }}
                />
                <div className="two-column">
                  <FormInputComponent
                    label="Course"
                    name="course"
                    value={edu.course}
                    onChange={(e) => {
                      handleChanges(e, i, "education");
                    }}
                  />
                  <FormSelectComponent
                    label="Grade"
                    name="grade"
                    formName="grade"
                    options={gradeOptions}
                    value={edu.grade}
                    onChange={(e) => {
                      handleChanges(e, i, "education");
                    }}
                  />
                </div>
                <div className="two-column">
                  <FormSelectComponent
                    label="Qualification"
                    name="qualification"
                    formName="qualification"
                    options={eduQualification}
                    value={edu.qualification}
                    onChange={(e) => {
                      handleChanges(e, i, "education");
                    }}
                  />
                  <FormInputComponent
                    label="Graduation Date"
                    name="graduationDate"
                    type="date"
                    value={edu.graduationDate}
                    onChange={(e) => {
                      handleChanges(e, i, "education");
                    }}
                  />
                </div>
                <div className="hq-toggle mb-3">
                  <p>Is this your Highest Qualification?</p>
                  <FormToggleComponent
                    name="mainQualification"
                    onChange={(x) => {
                      handleChanges(
                        { target: { name: "mainQualification", value: x } },
                        i,
                        "education"
                      );
                    }}
                    value={edu?.mainQualification}
                  />
                </div>
                {i > 0 && (
                  <div
                    className="span-btn"
                    onClick={() => {
                      deleteItems(edu.indexing, "educations");
                    }}
                  >
                    <BsTrash className="delete-icon" />
                  </div>
                )}
              </div>
            ))}

            <button
              className="add-more"
              type="button"
              onClick={() => {
                addMore("educations");
              }}
            >
              {" "}
              <span>+</span> Add More Educational Background
            </button>
          </fieldset>

          <fieldset>
            <legend className="heading-text">Projects</legend>

            {projects.map((proj, i) => (
              <div className="project-new" key={i}>
                <div className="two-column">
                  <FormInputComponent
                    label="Name"
                    name="name"
                    value={proj.name}
                    onChange={(e) => {
                      handleChanges(e, i, "projects");
                    }}
                  />

                  <FormSelectComponent
                    label="Show to recruiters"
                    options={projectsOptions}
                    name="mainProject"
                    formName="mainProject"
                    onChange={(e) => {
                      handleChanges(e, i, "projects");
                    }}
                    value={proj.mainProject}
                  />
                </div>
                <FormInputComponent
                  label="Techonology used"
                  name="technologyUsed"
                  value={proj.technologyUsed}
                  onChange={(e) => {
                    handleChanges(e, i, "projects");
                  }}
                />

                <FormInputComponent
                  label="Project Link"
                  name="projectLink"
                  value={proj.projectLink}
                  onChange={(e) => {
                    handleChanges(e, i, "projects");
                  }}
                />

                <FormWysiwygComponent
                  label="Description"
                  name="description"
                  value={proj.description}
                  maxLength="999"
                  onChange={(e) => {
                    handleChanges(
                      { target: { name: "description", value: e } },
                      i,
                      "projects"
                    );
                  }}
                  error={
                    proj.description.length > 999
                      ? "Should not exceed 1000 characters"
                      : null
                  }
                />

                {i > 0 && (
                  <div
                    className="span-btn"
                    onClick={() => {
                      deleteItems(proj.indexing, "projects");
                    }}
                  >
                    <BsTrash className="delete-icon" />
                  </div>
                )}
              </div>
            ))}

            <button
              className="add-more"
              type="button"
              onClick={() => {
                addMore("projects");
              }}
            >
              {" "}
              <span>+</span> Add More Projects
            </button>
          </fieldset>

          <fieldset>
            <legend className="heading-text">Work Experiences</legend>

            {workExperiences.map((proj, i) => (
              <div className="workexp-new" key={i}>
                <FormInputComponent
                  label="Company Name"
                  name="companyName"
                  value={proj.companyName}
                  onChange={(e) => {
                    handleChanges(e, i, "workExperiences");
                  }}
                />
                <FormInputComponent
                  label="Role"
                  name="role"
                  value={proj.role}
                  onChange={(e) => {
                    handleChanges(e, i, "workExperiences");
                  }}
                />

                <FormWysiwygComponent
                  label="Description"
                  name="description"
                  value={proj.description}
                  maxLength="999"
                  onChange={(e) => {
                    handleChanges(
                      { target: { name: "description", value: e } },
                      i,
                      "workExperiences"
                    );
                  }}
                  error={
                    proj.description.length > 999
                      ? "Should not exceed 1000 characters"
                      : null
                  }
                />

                <div className="two-column">
                  <FormInputComponent
                    label="Start Date"
                    name="startDate"
                    type="date"
                    value={proj.startDate}
                    onChange={(e) => {
                      handleChanges(e, i, "workExperiences");
                    }}
                  />
                  <FormInputComponent
                    label="End Date"
                    name="endDate"
                    type="date"
                    value={proj.endDate}
                    onChange={(e) => {
                      handleChanges(e, i, "workExperiences");
                    }}
                  />
                </div>

                {i > 0 && (
                  <div
                    className="span-btn"
                    onClick={() => {
                      deleteItems(proj.indexing, "workExperiences");
                    }}
                  >
                    <BsTrash className="delete-icon" />
                  </div>
                )}
              </div>
            ))}

            <button
              className="add-more"
              type="button"
              onClick={() => {
                addMore("workExperiences");
              }}
            >
              {" "}
              <span>+</span> Add More Work Experience
            </button>
          </fieldset>

          <fieldset className="last-one">
            <legend className="heading-text">Certifications</legend>

            {certifications.map((proj, i) => (
              <div className="workexp-new" key={i}>
                <FormInputComponent
                  label="Name"
                  name="name"
                  value={proj.name}
                  onChange={(e) => {
                    handleChanges(e, i, "certifications");
                  }}
                />
                <FormInputComponent
                  label="Issuing Organisation"
                  name="issuingOrganisation"
                  value={proj.issuingOrganisation}
                  onChange={(e) => {
                    handleChanges(e, i, "certifications");
                  }}
                />

                <div className="two-column">
                  <FormInputComponent
                    label="Date Issued"
                    name="dateIssued"
                    type="date"
                    value={proj.dateIssued}
                    onChange={(e) => {
                      handleChanges(e, i, "certifications");
                    }}
                  />
                  <FormInputComponent
                    label="Expiry Date"
                    name="expiryDate"
                    type="date"
                    value={proj.expiryDate}
                    onChange={(e) => {
                      handleChanges(e, i, "certifications");
                    }}
                  />
                </div>

                {i > 0 && (
                  <div
                    className="span-btn"
                    onClick={() => {
                      deleteItems(proj.indexing, "certifications");
                    }}
                  >
                    <BsTrash className="delete-icon" />
                  </div>
                )}
              </div>
            ))}

            <button
              className="add-more"
              type="button"
              onClick={() => {
                addMore("certifications");
              }}
            >
              {" "}
              <span>+</span> Add More Certification
            </button>
          </fieldset>

          <FormInputComponent
            label="Video CV"
            type="text"
            name="videoResumeLink"
            placeholder="https://www.youtube.com/embed/*"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.videoResumeLink}
            error={
              formik.touched.videoResumeLink && formik.errors.videoResumeLink
                ? formik.errors.videoResumeLink
                : null
            }
          />

          <FormUploadComponent
            label="Upload Resume"
            error={resumeDocError}
            selectedFile={resumeDoc}
            setSelectedFile={setResumeDoc}
            docType={docType}
            acceptTypes={`pdf, docx, doc. Size Limit 5mb`}
          />

          <MyButton className="form-btn add-client-btn" type="submit" full>
            {" "}
            {isLoading ? (
              <ClipLoader color={"#fff"} size={"30px"} />
            ) : (
              "Add New Engineer"
            )}
          </MyButton>
        </form>
        <div className="form-success card p-3">
          <img
            src="/images/sucess-icon.svg"
            alt="green-tick"
            className="sucess-icon"
          />
          <h2 className="form-font green-text">Success</h2>
          <p className="form-font success-msg">
            You successfully added an Engineer.
          </p>
          <MyButton className="form-btn success-btn" onClick={closeForm}>
            Okay, Go to view Engineer's list
          </MyButton>
        </div>
      </div>
    </StyledAddEngForm>
  );
};

const StyledAddEngForm = styled.div`
  width: 70rem;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);
  padding: 10px 20px;
  .underline {
    display: block;
  }
  .form-section {
    padding: 4rem 4rem 0 2rem;
    overflow-y: scroll;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  .two-column {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    width: 100%;
  }
  .add-client-btn {
    margin-bottom: 3rem;
  }
  .stack-section {
    display: flex;
    flex-wrap: wrap;
    width: 32rem;
  }
  .certification-type {
    max-width: 100px;
    width: 100%;
  }
  .client-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 32rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }

  .edu-new {
    display: grid;
    grid-template-columns: 1fr;
    height: 100%;
    align-items: center;

    div {
      input {
        min-width: 100%;
      }
    }
    .span-btn {
      font-size: 2rem !important;
      color: red !important;
      cursor: pointer;
    }
  }

  .hq-toggle p {
    font-size: 1.6rem;
  }

  .workexp-new {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    height: 100%;
    align-items: center;

    div {
      input {
        min-width: 100%;
      }
    }
    .span-btn {
      font-size: 2rem !important;
      color: red !important;
      cursor: pointer;
    }
  }

  .project-new {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    height: 100%;
    align-items: center;

    div {
      input {
        min-width: 100%;
      }
    }
    .span-btn {
      font-size: 2rem !important;
      color: red !important;
      cursor: pointer;
    }
  }

  .add-more {
    text-align: center;
    font-size: 1.8rem;
    color: #21334f;
    width: 100%;
    padding: 0.7rem 1rem;
    background: #fff;
    border: 1px solid rgba(33, 51, 79, 0.15);
    margin-bottom: 2rem;

    span {
      font-weight: 800;
      font-size: 1.8rem;
      margin-right: 0.7rem;
    }
  }
  textarea {
    min-width: 100% !important;
  }

  fieldset {
    border: 0.1px gray solid;
    padding: 1rem 2rem;
    margin-top: 5rem;
  }

  legend {
    background: linear-gradient(96.67deg, #34a853 0%, #b8d344 100%);
    color: white;
    text-align: center;
    padding: 1rem;
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }
  .last-one {
    margin-bottom: 6rem;
  }

  @media only screen and (min-width: 280px) and (max-width: 767px) {
    width: 100%;
    .two-column {
      grid-template-columns: 1fr;
    }
    .cert-new {
      display: grid;
      grid-template-columns: 1fr;
    }
    .edu-new {
      display: grid;
      grid-template-columns: 1fr;
    }
    .project-new {
      display: grid;
      grid-template-columns: 1fr;
    }
    .workexp-new {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
`;

export default AddEngForm;
