import React from "react";
import OrganizationSubscription from '../admin/OrganizationView/OrganizationSubscription';
import Layout from "../common/Layout";
import styled from "styled-components";
import DynamicTitle from "../common/DynamicTitle";


const SingleClientSubscription = ({isAuthenticated}) => {

  return (
    <Layout navType="client">
      <DynamicTitle title="Client Subscription | Fellowship Product" />
      <StyledDashboardPage >
      <OrganizationSubscription isAuthenticated={isAuthenticated}/>
       
      </StyledDashboardPage>
    </Layout>
  );
};

const StyledDashboardPage = styled.div`

`;

export default SingleClientSubscription;
