import styled from "styled-components";

export const StatusBtn = styled.button`
  background: ${({ status }) =>
    status === "active"
      ? "#34A853"
      : status === "retained"
      ? "#40c463"
      : status === "residence"
      ? "#6cd287"
      : status === "completed"
      ? "#34A853"
      : status === "internship"
      ? "#8adba0"
      : status === "not_assigned"
      ? "#DB6969"
      : status === "internship"
      ? "#48C76A"
      : status?.toLowerCase() === "terminated"
      ? "#D34444"
      : status === "in progress"
      ? "#FF8E00"
      : "#D34444"};
  color: white;
  border: none;
  margin: 0 auto;
  min-width: 120px;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 600;
  padding: 10px;
`;
