import React, { useState, useEffect } from "react";
import { apiGet } from "../../../../../utils/apiHelpers";
import MultiBars from "../../../common/activityTrackerCards/MultiBars";

const AssignEngineer = () => {
  const [currentState, setCurrentState] = useState("All");
  const [month, setMonth] = useState([]);
  const [interview, setInterview] = useState([]);
  const [internship, setInternship] = useState([]);
  const [residence, setResidence] = useState([]);
  const [period, setPeriod] = useState("");

  const findIndexArr = (arr, str) =>
    arr.findIndex((x) => x.placementType === str);

  const getAssignEng = async () => {
    try {
      const res = await apiGet(
        `/admin/assignment/tracking?period=${period}`,
        {}
      );

      const data = res.data.data;

      const month = [];
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        month.push(item.period);
      }

      const interview = [];
      const internship = [];
      const residence = [];

      data.forEach(function (item, i) {
        const findResidence = findIndexArr(item.placementCounts, "RESIDENCE");
        const findInterview = findIndexArr(item.placementCounts, "INTERVIEW");
        const findInternship = findIndexArr(item.placementCounts, "INTERNSHIP");

        residence.push(
          findResidence >= 0 ? item.placementCounts[findResidence].count : 0
        );
        interview.push(
          findInterview >= 0 ? item.placementCounts[findInterview].count : 0
        );
        internship.push(
          findInternship >= 0 ? item.placementCounts[findInternship].count : 0
        );
      });

      setResidence(residence);
      setInterview(interview);
      setInternship(internship);
      setMonth(month);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getAssignEng();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentState]);

  const data = {
    labels: month,
    datasets: [
      {
        label: "Interview",
        data: interview,
        backgroundColor: "#D34444",
      },
      {
        label: "Internship",
        data: internship,
        backgroundColor: "#E69F00",
      },
      {
        label: "Residence",
        data: residence,
        backgroundColor: "#34A853",
      },
    ],
  };

  return (
    <MultiBars
      data={data}
      title="Assign Engineer"
      currentState={currentState}
      setCurrentState={setCurrentState}
      setPeriod={setPeriod}
    />
  );
};

export default AssignEngineer;
