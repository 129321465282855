import React, { useState } from "react";
import Layout from "../common/Layout";
import DynamicTitle from "../common/DynamicTitle";
import TableContainer from "../common/TableContainer";
import { useLocation } from "react-router-dom";
import Pagination from "../atoms/Pagination";
import { useHistory } from "react-router-dom";
import { removeEmptyObj } from "../../../utils/helperFunctions";
import { StyledDashboardPage } from "../styles/styleDashboard";
import { useSubscriptionRequestList } from "../../../services/subscription/query";
import {StatusBtn} from "../styles/statusBtn"

const AdminSubscriptionRequestPage = ({ pageType }) => {
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(window.location.search);

  const initialValues = {
    page: Number(queryParams.get("page")) || 1
  };
  const [filterOptions, setFilterOptions] = useState(initialValues);
  const [paramsValues, setParamsValues] = useState(
    removeEmptyObj(initialValues, "size") || {}
  );

  const {
    data: subscriptionRequestList,
    isLoading,
  } = useSubscriptionRequestList(filterOptions);

  const increment = () => {
    setParamsValues({ ...paramsValues, page: paramsValues.page + 1 });
    setFilterOptions({ ...filterOptions, page: filterOptions.page + 1 });

    const params = new URLSearchParams({
      ...paramsValues,
      page: paramsValues.page + 1,
    });
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const decrement = () => {
    setParamsValues({ ...paramsValues, page: paramsValues.page - 1 });
    setFilterOptions({ ...filterOptions, page: filterOptions.page - 1 });

    const params = new URLSearchParams({
      ...paramsValues,
      page: paramsValues.page - 1,
    });
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const newPage = (n) => {
    setParamsValues({ ...paramsValues, page: n });
    setFilterOptions({ ...filterOptions, page: n });

    const params = new URLSearchParams({ ...paramsValues, page: n });
    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  return (
    <Layout navType="admin" pageLoading={isLoading}>
      <DynamicTitle title="Subscription Request | Fellowship Product" />
      <StyledDashboardPage>
        <div className="page-header">
          <h4>Subscription Request</h4>
        </div>
        <TableContainer>
          <table style={{ width: "100%" }}>
            <thead style={{ width: "100%" }}>
              <tr>
                <th>Organisation</th>
                <th>Package Name</th>
                <th>Request Date</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody style={{ width: "100%" }}>
              {subscriptionRequestList?.data?.content.map((item, index) => (
                <tr className="" key={index}>
                  <td>{item.organisation}</td>
                  <td>{item.packageName}</td>
                  <td>{item.requestDate}</td>
                  <td>
                    {item.status ? (
                      <StatusBtn
                        className="status-bg"
                        status={item.status?.toLowerCase()}
                      >
                        {item.status}
                      </StatusBtn>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
        {subscriptionRequestList && (
          <div className="page-parent">
            <Pagination
              currentPage={subscriptionRequestList?.data.number + 1}
              totalClients={subscriptionRequestList?.data.numberOfElements}
              metaData={subscriptionRequestList?.data}
              page={subscriptionRequestList?.data.number + 1}
              newPage={newPage}
              increment={increment}
              decrement={decrement}
              className="pagination"
            />
          </div>
        )}
      </StyledDashboardPage>
    </Layout>
  );
};

export default AdminSubscriptionRequestPage;
