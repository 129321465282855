import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import FormInputComponent from "./enterpriseFormInputs/FormInputComponent";
import FormSelectComponent from "./enterpriseFormInputs/FormSelectComponent";
import FormTitleSection from "./FormTitleSection";
import FormUploadComponent from "./enterpriseFormInputs/FormUploadComponent";
import { apiPost, apiGet } from "../../../utils/apiHelpers";
import { toast } from "react-toastify";
import {
  getCountryList,
  convertCurrencyToInt,
} from "../../../utils/helperFunctions";
import { ClipLoader } from "react-spinners";
import { employeeNumber, developerNumber } from "../common/listingData";

const socialOptions = [
  { value: "", label: "Where Did You Hear About Us" },
  { value: "Social Media", label: "Social Media" },
  { value: "Linkedin", label: "Linkedin" },
  { value: "Word of mouth", label: "Word of mouth" },
];

const locationOptions = [
  { value: "", label: "Choose your Option" },
  { value: "LOCAL", label: "Local" },
  { value: "INTERNATIONAL", label: "International" },
];

const engineerOptions = [
  { value: "", label: "Select option" },
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const phoneRegExp = /^\d*(\+\d+)?$/;

const new_token = localStorage.getItem("token");

let multipartConfig = {
  headers: {
    Authorization: `Bearer ${new_token}`,
    "Content-Type": "multipart/form-data",
  },
};

const initialValues = {
  name: "",
  email: "",
  telephoneNumber: "",
  lastName: "",
  firstName: "",
  address: "",
  countryId: "",
  amountPaid: "",
  datePaid: "",
  industryId: "",
  packageId: "",
  engineeringLead: "",
  numberOfEmployees: "",
  numberOfDevelopers: "",
  customerStatus: "",
  startDate: "",
  subscriptionStatus: "",
  hearAboutUs: "",
  companyLocation: "",
  companyType: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required("*Required"),
  firstName: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string().email("Invalid email format"),
  telephoneNumber: Yup.string()
    .min(9, "*Phone number is not valid")
    .matches(phoneRegExp, "Phone number is not valid"),
  address: Yup.string(),
  countryId: Yup.number(),
  amountPaid: Yup.string(),
  datePaid: Yup.date(),
  startDate: Yup.date().when(
    "datePaid",
    (datePaid, yup) =>
      datePaid && yup.min(datePaid, "Start date cannot be before date paid")
  ),
  industryId: Yup.number(),
  packageId: Yup.number(),
  engineeringLead: Yup.bool(),
  numberOfEmployees: Yup.string(),
  numberOfDevelopers: Yup.string(),
  status: Yup.string(),
  subscriptionStatus: Yup.string(),
  hearAboutUs: Yup.string(),
  companyLocation: Yup.string(),
  companyType: Yup.string(),
});

const AddClientForm = ({ onClick, reload }) => {
  const formatDate = (date) => {
    return date.split("-").reverse().join("/");
  };

  const [success, setSuccess] = useState(false);
  const [contractDoc, setContractDoc] = useState({});
  const [receiptDoc, setReceiptDoc] = useState({});
  const [logoDoc, setLogoDoc] = useState({});
  const [logoDocError, setLogoDocError] = useState("");
  const [loading, setLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [industries, setIndustries] = useState([]);

  const pictureType = ["jpg", "jpeg", "gif", "png"];
  // const docType = ["pdf", "docx", "doc"];
  // const receiptType = ["png", "jpg", "jpeg", "gif", "pdf"];

  useEffect(() => {
    setLogoDocError("");
  }, [logoDoc]);

  useEffect(() => {
    fetchIndustries();
    getCountryList().then((res) => setCountryList(res));
    // eslint-disable-next-line
  }, []);

  // Fetch industries Details
  const fetchIndustries = async () => {
    try {
      const res = await apiGet(`/admin/industries`);
      let result = res.data.data;
      for (let i = 0; i < result.length; i++) {
        let item = result[i];
        Object.assign(item, { label: item.name, value: item.id });
      }
      result.unshift({ label: "Select Industry", value: "" });

      setIndustries(result);
    } catch (err) {
      toast.error(err);
    }
  };

  const onSubmit = (values, { setFieldError }) => {
    let contactPerson = `${values.firstName} ${values.lastName}`;

    values.startDate = formatDate(values.startDate);
    values.datePaid = formatDate(values.datePaid);
    values.contactPerson = contactPerson;
    values.amountPaid = convertCurrencyToInt(values.amountPaid);

    let formData = new FormData();
    formData.append("contract_upload", contractDoc);
    formData.append("receipt_upload", receiptDoc);
    formData.append("logo_upload", logoDoc);
    formData.append(
      "organisation_dto",
      new Blob([JSON.stringify(values)], {
        type: "application/json",
      })
    );
    createClient(formData, setFieldError);
  };

  //create organization
  const createClient = async (formData, setFieldError) => {
    setLoading(true);
    //make call to the api
    try {
      let response = await apiPost(
        `/admin/organisations`,
        formData,
        multipartConfig
      );

      if (response.error) {
        throw response.data.response.status === 500
          ? new Error("")
          : response.data;
      }

      setSuccess(true);
      reload && reload();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const closeForm = () => {
    formik.resetForm();
    onClick();
    setContractDoc(() => ({}));
    setReceiptDoc(() => ({}));
    setLogoDoc(() => ({}));
    setSuccess(false);
  };

  return (
    <StyledAddClientForm success={success}>
      <FormTitleSection title="Add a New Customer" onClick={closeForm} />
      <div className="form-section">
        <form onSubmit={formik.handleSubmit} className="client-form">
          <p className="form-font form-title">Client Details</p>
          <fieldset>
            <legend>Company Details</legend>

            <FormInputComponent
              label="Company Name"
              type="text"
              name="name"
              placeholder="Enter company name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              error={
                formik.touched.name && formik.errors.name
                  ? formik.errors.name
                  : null
              }
            />

            <FormInputComponent
              label="Contact Person’s First name"
              type="text"
              name="firstName"
              placeholder="Enter company person’s first name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
              error={
                formik.touched.firstName && formik.errors.firstName
                  ? formik.errors.firstName
                  : null
              }
            />

            <FormInputComponent
              label="Contact Person’s Last Name"
              type="text"
              name="lastName"
              placeholder="Enter company person’s last name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
              error={
                formik.touched.lastName && formik.errors.lastName
                  ? formik.errors.lastName
                  : null
              }
            />
            <FormInputComponent
              label="Email address"
              type="email"
              name="email"
              placeholder="Enter email address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null
              }
            />
            <FormInputComponent
              label="Phone number"
              type="tel"
              name="telephoneNumber"
              placeholder="Enter phone number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.telephoneNumber}
              error={
                formik.touched.telephoneNumber && formik.errors.telephoneNumber
                  ? formik.errors.telephoneNumber
                  : null
              }
            />
            <FormInputComponent
              label="Address"
              type="text"
              name="address"
              placeholder="Enter address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
              error={
                formik.touched.address && formik.errors.address
                  ? formik.errors.address
                  : null
              }
            />
            <FormSelectComponent
              label="Country Name"
              name="countryId"
              options={countryList}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.countryId}
              error={
                formik.touched.countryId && formik.errors.countryId
                  ? formik.errors.countryId
                  : null
              }
            />

            <FormSelectComponent
              label="Industry Type"
              name="industryId"
              options={industries}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.industryId}
              error={
                formik.touched.industryId && formik.errors.industryId
                  ? formik.errors.industryId
                  : null
              }
            />
            <FormSelectComponent
              label="Engineering Lead"
              name="engineeringLead"
              options={engineerOptions}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.engineeringLead}
              error={
                formik.touched.engineeringLead && formik.errors.engineeringLead
                  ? formik.errors.engineeringLead
                  : null
              }
            />
            <FormSelectComponent
              label="Number Of Employees"
              name="numberOfEmployees"
              options={employeeNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.employeeNumber}
              error={
                formik.touched.numberOfEmployees &&
                formik.errors.numberOfEmployees
                  ? formik.errors.numberOfEmployees
                  : null
              }
            />

            <FormSelectComponent
              label="Number Of Developers"
              name="numberOfDevelopers"
              options={developerNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.developerNumber}
              error={
                formik.touched.numberOfDevelopers &&
                formik.errors.numberOfDevelopers
                  ? formik.errors.numberOfDevelopers
                  : null
              }
            />
          </fieldset>
          <fieldset>
            <legend>Company Location</legend>

            <FormSelectComponent
              label="Company Location"
              name="companyLocation"
              options={locationOptions}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.companyLocation}
              error={
                formik.touched.companyLocation && formik.errors.companyLocation
                  ? formik.errors.companyLocation
                  : null
              }
            />

            {/* <FormSelectComponent
              label="Company Type"
              type="text"
              name="companyType"
              options={typeOptions}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.companyType}
              error={
                formik.touched.companyType && formik.errors.companyType
                  ? formik.errors.companyType
                  : null
              }
            /> */}
          </fieldset>

          <fieldset>
            <legend>Others</legend>

            {/* <FormSelectComponent
              label="Organisation Status"
              name="customerStatus"
              options={options}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.customerStatus}
              error={
                formik.touched.customerStatus && formik.errors.customerStatus
                  ? formik.errors.customerStatus
                  : null
              }
            /> */}

            <FormSelectComponent
              label="Where Did You Hear About Us"
              type="text"
              name="hearAboutUs"
              options={socialOptions}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.hearAboutUs}
              error={
                formik.touched.hearAboutUs && formik.errors.hearAboutUs
                  ? formik.errors.hearAboutUs
                  : null
              }
            />
          </fieldset>

          <fieldset className="last-block">
            <legend>Upload Section</legend>
            <FormUploadComponent
              pictureType={pictureType}
              label="Upload Logo"
              error={logoDocError}
              selectedFile={logoDoc}
              setSelectedFile={setLogoDoc}
              acceptTypes={`jpg, jpeg, gif, png. Size Limit 5mb`}
            />
          </fieldset>

          <MyButton className="form-btn add-client-btn" type={"submit"} full>
            {" "}
            {loading ? (
              <ClipLoader color={"#fff"} size={"30px"} />
            ) : (
              "Add a New Customer"
            )}
          </MyButton>
        </form>
        <div className="form-success card">
          <img
            src="/images/sucess-icon.svg"
            alt="green-tick"
            className="sucess-icon"
          />
          <h2 className="form-font green-text">Success</h2>
          <p className="form-font success-msg">
            You successfully added a client. You can assign engineers to this
            client.
          </p>
          <MyButton className="form-btn success-btn" onClick={closeForm}>
            Okay, Go to Client Page
          </MyButton>
        </div>
      </div>
    </StyledAddClientForm>
  );
};

const StyledAddClientForm = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);

  fieldset {
    border: 0.1px gray solid;
    padding: 1rem 2rem;
    margin-top: 3rem;
  }
  .last-block {
    margin-bottom: 5rem;
  }

  legend {
    background: linear-gradient(96.67deg, #34a853 0%, #b8d344 100%);
    color: white;
    padding: 5px 10px;
  }
  .underline {
    display: block;
  }
  .form-section {
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3.2rem;
  }
  .add-client-btn {
    margin-bottom: 23.8rem;
  }
  .client-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-top: 8rem;
    padding: 1rem;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2.rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 32rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
`;

export default AddClientForm;
