// eslint-disable-next-line no-unused-vars
import { apiGet, apiPost, apiPut, apiDelete } from "../../utils/apiHelpers";

// fetch framework
export const fetchFrameworks = async () => {
  try {
    let response = await apiGet(`/admin/frameworks`, {});

    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
    return response.data.data;
  } catch (err) {
    throw err
  }
};

export const fetchSingleFramework = async (params) => {
  const [id] = params.queryKey;
  try {
    let response = await apiGet(`/admin/frameworks/${id}`, {});

    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
    return response.data;
  } catch (err) {
    throw err
  }
};


export const createFramework = async (formData) => {
  let config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json; charset=UTF-8",
    },
  };

  try {
    let response = await apiPost(`/admin/frameworks`, formData, config);
    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
  } catch (err) {
    console.log({ err });
    throw err;
  }
};

export const editFramework = async (data) => {
  try {
    let response = null;
    const { frameworkId, payload } = data;
    if (data) {
      response = await apiPut(
        `/admin/frameworks/${frameworkId}`,
        payload,
      );
    }
    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
  } catch (error) {
    console.log({ error });
  }
};

export const deleteFramework = async (id) => {
  try {
    let response = await apiDelete(`/admin/frameworks/${id}`);
    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
  } catch (err) {
    console.log({ err });
  }
};
