import React, { useState } from "react";
import styled from "styled-components";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ActivityDropdown from "../../atoms/ActivityDropdown";

const ActivityTrackerDoughnut = (props) => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const [currentState, setCurrentState] = useState("All");

  const data = {
    labels: [`${props.greenLabel}`, `${props.goldLabel}`],
    datasets: [
      {
        label: "My First Dataset",
        data: [`${props.greenData}`, `${props.goldData}`],
        backgroundColor: [
          `${props.backgroundColor1}`,
          `${props.backgroundColor2}`,
        ],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#f2f2f2",
        bodyColor: "black",
        padding: 10,
        caretSize: 10,
        yAlign: "bottom",
        displayColors: false,
        usePointStyle: true,
      },
    },
    maintainAspectRatio: true,
    responsive: true,
    cutout: 50,
  };

  const valueCheck = (green, gold) => {
    return (
      (green === null || green === 0 || green === "0") &&
      (gold === null || gold === 0 || gold === "0")
    );
  };

  return (
    <Tracker
      centerText={props.centerText}
      backgroundOne={props.backgroundColor1}
      backgroundTwo={props.backgroundColor2}
    >
      <div className="card">
        <div className="header">
          <ActivityDropdown
            header={props.header}
            currentState={currentState}
            setCurrentState={setCurrentState}
            setPeriod={props.setPeriod}
            isLoading={props.isLoading}
          />
        </div>
        <div className="content">
          <div className="doughnut">
            {!valueCheck(props.greenData, props.goldData) && (
              <div>
                <Doughnut data={data} options={options} />
                <div className="donut-inner">
                  <p className="number">{props.centerValue}</p>
                  <p className="text">{props.centerText}</p>
                </div>
              </div>
            )}

            {valueCheck(props.greenData, props.goldData) && (
              <div className="grey-circle">
                <div>
                  <p>{props.centerValue}</p>
                  <p>{props.centerText}</p>
                </div>
              </div>
            )}
          </div>

          <div className="small-cards">
            <div>
              <h3>{props.greenCardNumber}</h3>
              <p>{props.greenCardText}</p>
            </div>
            <div>
              <h3>{props.goldCardNumber}</h3>
              <p>{props.goldCardText}</p>
            </div>
          </div>
        </div>
      </div>
    </Tracker>
  );
};

const Tracker = styled.div`
  .card {
    width: 100%;
    height: 360px;
    background: #fff;
    position: relative;

    .header {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
    }

    .content {
      display: grid;
      box-sizing: border-box;
      padding: 2rem;
      row-gap: 1.5rem;
      place-items: center;
      height: 100%;
    }
    select {
      padding: 5px 20px 5px 2px;
      border: 1px solid #f1f1f1;
    }
    .doughnut {
      width: 120px;
      height: 120px;

      & .grey-circle {
        border-radius: 50%;
        box-shadow: 0px 0px 0px 10px #d3d3d3;
        width: 100px;
        height: 100px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0px;

        & p:first-of-type {
          font-weight: 700;
          font-size: 28px;
          color: #000;
          text-align: center;
          margin: 0;
          padding: 0;
        }
        & p:last-of-type {
          color: rgba(33, 51, 79, 0.65);
          font-weight: 600;
          font-size: 10px;
          text-align: center;
          margin: 0;
          padding: 0;
        }
      }
    }

    .donut-inner {
      text-align: center;
      margin-top: ${(props) => (props.centerText ? "-75%" : "-68%")};
      font-family: sans-serif;
    }
    .donut-inner .number {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      margin: 0;
      padding: 0;
    }

    .donut-inner .text {
      color: rgba(33, 51, 79, 0.65);
      font-weight: 600;
      font-size: 10px;
      line-height: 13px;
      margin: 0;
      padding: 0;
    }
    .small-cards {
      display: flex;
      justify-content: center;
      gap: 4rem;

      div:first-of-type {
        background-color: ${(props) => props.backgroundOne};
      }

      & div:last-of-type {
        background-color: ${(props) => props.backgroundTwo};
      }
    }
    .small-cards > div {
      width: 136px;
      height: 64px;
      background: #34a853;
      border-radius: 10px;
      text-align: center;
      display: grid;
      align-items: center;
      color: #fff;

      h3 {
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
      }
      p {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
`;

export default ActivityTrackerDoughnut;
