import { toast } from "react-toastify";
// eslint-disable-next-line no-unused-vars
import { apiGet, apiPost, apiPut, apiDelete } from "../../utils/apiHelpers";

// fetch squad
export const fetchSquadAdmin = async (squadId) => {
  try {
    let response = await apiGet(`/admin/squad-admins?squad=${squadId}`, {});

    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
    return response.data.data;
  } catch (err) {
    toast.error(err?.response?.data?.message || "error fetching squad Admins");
  }
};

export const fetchSingleSquadAdmin = async (params) => {
  const [id] = params.queryKey;
  try {
    let response = await apiGet(`/admin/squad-admins/${id}`, {});

    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
    return response.data;
  } catch (err) {
    toast.error(err?.response?.data?.message || "error fetching squad details");
  }
};

// fetch Admin Users
export const fetchSquadAdminUsers = async () => {
  try {
    let response = await apiGet(`/admin/users`, {});

    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
    return response.data;
  } catch (err) {
    toast.error(
      err?.response?.data?.message || "error fetching squad Admin Users"
    );
  }
};

export const createSquadAdminData = async (formData, config) => {
  try {
    let response = await apiPost(`/admin/squad-admins`, formData, config);
    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
  } catch (err) {
    console.log({ err });
    throw err;
  }
};

export const editSquadAdmin = async (data, config) => {
  try {
    let response = null;
    const { squadAdminId, payload } = data;
    if (data) {
      response = await apiPut(
        `/admin/squad-admins/${squadAdminId}`,
        payload,
        config
      );
    }
    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
  } catch (error) {
    console.log({ error });
  }
};

export const deleteSquadAdmin = async (id) => {
  try {
    let response = await apiDelete(`/admin/squad-admins/${id}`);
    if (response.error) {
      throw response.data.response.status === 500
        ? new Error("")
        : response.data;
    }
  } catch (err) {
    console.log({ err });
  }
};
