import React, { useState } from "react";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import FormTitleSection from "./FormTitleSection";
import { ClipLoader } from "react-spinners";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { useAddUsers, useFetchedUsers } from "../../../services/engineer/query";

const AddUserToMailList = ({ onClick, reload, mailList, refetch }) => {
  const [success, setSuccess] = useState(false);
  const [showDrop, setShowDrop] = useState(true);
  const [postData, setPostData] = useState({
    notificationModule: "WEEKLY_PERFORMANCE_NOTIFICATION",
    userIds: [],
  });

  const closeForm = () => {
    onClick();
    setSuccess(false);
  };

  const new_token = localStorage.getItem("token");

  let multipartConfig = {
    headers: {
      Authorization: `Bearer ${new_token}`,
      "Content-Type": "application/json",
    },
  };

  const onAddSuccess = () => {
    refetch();
    setSuccess(true);
  };

  const { data: userList } = useFetchedUsers();

  const { mutate, isLoading } = useAddUsers(multipartConfig, onAddSuccess);

  const addUserToList = (id) => {
    setPostData((prevState) => {
      return {
        ...prevState,
        userIds: postData.userIds?.includes(id)
          ? prevState.userIds.filter((userId) => userId !== id)
          : [...prevState.userIds, id],
      };
    });
  };
  const addAllUsers = () => {
    setPostData((prev) => {
      return {
        ...prev,
        userIds:
          prev.userIds.length === userList.data.content.length
            ? []
            : userList.data.content.map((user) => user.id),
      };
    });
  };

  return (
    <StyledAddEngForm success={success}>
      <FormTitleSection title="Add User" onClick={closeForm} />
      <div className="select-container">
        <div className="test">
          <div
            className="select-section"
            onClick={() => setShowDrop(!showDrop)}
          >
            <button className="select-drop-btn">Select</button>
            {showDrop ? (
              <RiArrowDropDownLine size={"1.6rem"} />
            ) : (
              <RiArrowDropUpLine size={"1.6rem"} />
            )}
          </div>

          {showDrop && (
            <div className="users-list">
              {userList?.data.content.length === mailList.length ? (
                <span>All Users have been added</span>
              ) : (
                <>
                  <div className="select-all single-user">
                    <input
                      type="checkbox"
                      className="select"
                      onClick={addAllUsers}
                      checked={
                        postData.userIds.length ===
                        userList?.data.content.length
                      }
                    />
                    <span>Select all</span>
                  </div>
                  <ul>
                    {userList &&
                      userList.data.content.map((user) => {
                        if (!mailList.find((mail) => mail.userId === user.id)) {
                          return (
                            <li className="single-user" key={user.id}>
                              <input
                                type="checkbox"
                                className="select"
                                onClick={() => addUserToList(user.id)}
                                checked={postData.userIds?.includes(user.id)}
                                readOnly
                              />
                              <span>{user.name}</span>
                            </li>
                          );
                        } else {
                          return null;
                        }
                      })}
                  </ul>
                  <button
                    className="add-btn"
                    onClick={() => {
                      mutate(postData);
                    }}
                    disabled={postData.userIds.length > 0 ? false : true}
                  >
                    {isLoading ? <ClipLoader color="white" /> : "Add"}
                  </button>
                </>
              )}
            </div>
          )}
        </div>

        {success && (
          <div className="form-success">
            <img
              src="/images/sucess-icon.svg"
              alt="green-tick"
              className="sucess-icon"
            />
            <h2 className="form-font green-text">Success</h2>
            <p className="form-font success-msg">
              You successfully added User(s).
            </p>
            <MyButton className="form-btn success-btn" onClick={closeForm}>
              Okay, Go to Notification Page
            </MyButton>
          </div>
        )}
      </div>
    </StyledAddEngForm>
  );
};

const StyledAddEngForm = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);

  /* .form-section {
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  } */

  .select-container {
    overflow-y: scroll;
    padding: 1.6rem;
    font-size: 1.4rem;
  }
  .select-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20.11rem;
    cursor: pointer;
    padding-bottom: 0.8rem;
    border-bottom: 1px solid #eaecf0;
    user-select: none;
  }

  .select-section button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .users-list {
    margin-top: 1.6rem;
    display: flex;
    flex-direction: column;
  }

  .select-all {
    margin-bottom: 2.4rem;
  }

  .select {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.3rem;
    border: 1px solid #101828;
    cursor: pointer;
  }
  .select:checked {
    background: #34a853;
  }
  .single-user {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .users-list ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  .add-btn {
    background: none;
    color: #ffffff;
    border: none;
    padding: 1.2rem;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin-top: 2.4rem;
    background-color: #34a853;
    border-radius: 6px;
  }
  .add-btn:hover {
    opacity: 0.8;
  }
  .add-btn:active {
    opacity: 0.6;
  }

  .add-btn:disabled {
    // greyer background for
    background-color: b3e6e9;
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .test {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 32rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
`;

export default AddUserToMailList;
