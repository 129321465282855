import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import FormInputComponent from "./enterpriseFormInputs/FormInputComponent";
import FormTitleSection from "./FormTitleSection";
import { useEditTechstack } from "../../../services/techstack/query";

const UpdateTechstackForm = ({
  techstackId,
  handleModal,
  techstackName,
  refetch,
}) => {
  const initialValues = {
    name: techstackName || "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("*Required"),
  });

  const [success, setSuccess] = useState(false);

  const { mutate: updateTechstack, isLoading } = useEditTechstack();
  
  const onSubmit = (values) => {
    updateTechstack(
      { techstackId, payload: values },
      {
        onSuccess: () => {
          refetch();
          setSuccess(true);
        },
        onError: (error) => {
          setSuccess(false);
        },
      }
    );
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <StyledCreatePackageForm success={success}>
      <FormTitleSection title="Edit TechStack" onClick={handleModal} />
      <div className="form-section">
        <form onSubmit={formik.handleSubmit} className="client-form">
          <p className="form-font form-title">TechStack Details</p>

          <FormInputComponent
            label="TechStack Name"
            type="text"
            name="name"
            placeholder="Enter techStack name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : null
            }
          />

          <MyButton
            className="form-btn add-client-btn"
            loading={isLoading}
            type="submit"
            full
          >
            Edit TechStack
          </MyButton>
        </form>
        <div className="form-success card p-4">
          <img
            src="/images/sucess-icon.svg"
            alt="green-tick"
            className="sucess-icon"
          />
          <h2 className="form-font green-text">Success</h2>
          <p className="form-font success-msg">
            You've successfully edited a TechStack.
          </p>
          <MyButton
            className="form-btn success-btn"
            onClick={handleModal}
          >
            Okay, Go back to TechStack Listing Page
          </MyButton>
        </div>
      </div>
    </StyledCreatePackageForm>
  );
};

const StyledCreatePackageForm = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);
  .form-section {
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3.2rem;
  }
  .add-client-btn {
    margin-bottom: 23.8rem;
  }
  .client-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2.rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 100% !important;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
`;

export default UpdateTechstackForm;
