import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
// import Select from "react-select";
import FormTitleSection from "./FormTitleSection";
import FormSelectComponent from "./enterpriseFormInputs/FormSelectComponent";
import { useAdmin } from "../../../context/AdminState";
import FormInputComponent from "./enterpriseFormInputs/FormInputComponent";
import { apiPost } from "../../../utils/apiHelpers";
// import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { setSelect } from "../../../utils/helperFunctions";
import { BsTrash } from "react-icons/bs";
import FormUploadComponent from "./enterpriseFormInputs/FormUploadComponent";
import FormTextAreaComponent from "./enterpriseFormInputs/FormTextAreaComponent";

const RequestEngForm = ({ onClick, OrgId, fetchEngReq }) => {
  const { stackList, fetchStackOptions } = useAdmin();

  const initialValues = {
    organisationId: OrgId ? `${OrgId}` : "",
    additionalComment: "",
    engineerRequestTechStackDtos: [],
  };

  const validationSchema = Yup.object({
    organisationId: Yup.string().required("*Required"),
    additionalComment: Yup.string(),
  });

  const new_token = localStorage.getItem("token");

  let multipartConfig = {
    headers: {
      Authorization: `Bearer ${new_token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  // const formatDate = (date) => {
  //   return date.split("-").reverse().join("/");
  // };

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stackResult, setStackResult] = useState([]);

  const [jobDescDoc, setJobDescDoc] = useState({});
  const [jobDescError] = useState("");

  const docType = ["pdf", "docx", "doc"];

  useEffect(() => {
    fetchStackOptions();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let stckOpts = setSelect(stackList, "", "", "Language");
    setStackResult(stckOpts);
  }, [stackList]);

  const [languages, setLanguages] = useState([
    {
      jobDescriptionFileName: "",
      id: 0,
      techStackId: 0,
      numberOfEngineers: "",
      yearsOfExperience: "",
      budget: "",
    },
  ]);

  const [addMoreLanguage, setAddMoreLanguage] = useState(0);

  // handles certication input values
  const handleChanges = (e, index, type) => {
    const value = e.target.value;
    const name = e.target.name;

    const newLanguage = [...languages];
    if (type === "select") {
      newLanguage[index]["techStackId"] = value;
    } else {
      newLanguage[index][name] = value;
      newLanguage[index].id = index;
    }
    setLanguages(newLanguage);
  };

  // delete item
  const deleteItems = (index, type) => {
    if (type === "languages") {
      const newLanguage = [...languages];
      const newFilteredLang = newLanguage.filter(
        (item, i) => item.id !== index
      );
      setLanguages(newFilteredLang);
    }
  };

  // add more certications button function
  const addMore = (type) => {
    if (type === "languages") {
      let count = addMoreLanguage;
      let newCount = count + 1;
      setAddMoreLanguage(newCount);
      setLanguages([
        ...languages,
        {
          jobDescriptionFileName: "",
          id: newCount,
          techStackId: 0,
          numberOfEngineers: "",
          yearsOfExperience: "",
          budget: "",
        },
      ]);
    }
  };

  const onSubmit = (values) => {
    const mapArr = languages.map((data) => {
      return {
        jobDescriptionFileName: data.jobDescriptionFileName.name,
        techStackId: data.techStackId,
        numberOfEngineers: data.numberOfEngineers,
        yearsOfExperience: data.yearsOfExperience,
        budget: data.budget,
      };
    });

    const arrOfImg = languages.map((data) => {
      return [data.jobDescriptionFileName];
    });

    values.organisationId = parseInt(values.organisationId);
    values.engineerRequestTechStackDtos = mapArr;

    let formData = new FormData();
    arrOfImg.forEach((fileData) =>
      formData.append("jobDescription_upload", fileData)
    );

    formData.append(
      "request_dto",
      new Blob([JSON.stringify(values)], {
        type: "application/json",
      })
    );

    requestEngineer(formData);
  };

  //create organization
  const requestEngineer = async (formData) => {
    setLoading(true);

    //make call to the api
    try {
      let response = await apiPost(
        `/private/assignments/requests`,
        formData,
        multipartConfig
      );
      if (response.error) {
        throw response.data.response.status === 500
          ? new Error("")
          : response.data;
      }
      setSuccess(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  useEffect(() => {
    fetchEngReq();
    // eslint-disable-next-line
  }, [success]);

  const closeForm = () => {
    formik.resetForm();
    onClick();
    setSuccess(false);
  };
  // const customStyles = {
  //   indicatorSeparator: (styles) => ({ display: "none" }),
  //   placeholder: (defaultStyles) => {
  //     return {
  //       ...defaultStyles,
  //       color: "rgba(33,51,79,0.8)",
  //       fontSize: "1rem",
  //       fontWeight: "700",
  //     };
  //   },

  //   option: (styles, { data, isDisabled, isFocused, isSelected }) => {
  //     return {
  //       ...styles,
  //       backgroundColor: isFocused ? "#4285F4" : "#fff",
  //       color: isFocused ? "#fff" : "#263238",
  //       fontSize: "1.3rem",
  //       cursor: isDisabled ? "not-allowed" : "default",
  //     };
  //   },
  // };

  return (
    <StyledCreatePackageForm success={success}>
      <FormTitleSection title="Request Engineers" onClick={() => closeForm()} />
      <div className="form-section">
        {!success && (
          <form onSubmit={formik.handleSubmit} className="client-form">
            <div>
              {languages.map((lang, i) => (
                <div className="project-new">
                  <FormSelectComponent
                    label="What Engineering language?"
                    options={stackResult}
                    name="techStackId"
                    onChange={(e) => {
                      handleChanges(e, i, "select");
                    }}
                    value={lang.techStackId}
                    error={lang.techStackId === 0 ? "Required" : null}
                  />
                  <FormInputComponent
                    label="How many Engineers do you need?"
                    placeholder="Numbers of Engineer"
                    name="numberOfEngineers"
                    value={lang.numberOfEngineers}
                    error={lang.numberOfEngineers === "" ? "Required" : null}
                    onChange={(e) => {
                      handleChanges(e, i, "input");
                    }}
                  />
                  <FormInputComponent
                    label="Budget"
                    placeholder="Enter a Budget"
                    name="budget"
                    value={lang.budget}
                    error={lang.budget === "" ? "Required" : null}
                    onChange={(e) => {
                      handleChanges(e, i, "input");
                    }}
                  />
                  <FormInputComponent
                    label="Years of Experience"
                    placeholder="Years of experience"
                    name="yearsOfExperience"
                    value={lang.yearsOfExperience}
                    error={lang.yearsOfExperience === "" ? "Required" : null}
                    onChange={(e) => {
                      handleChanges(e, i, "input");
                    }}
                  />
                  <FormUploadComponent
                    label="Upload Job Description"
                    name="jobDescriptionFileName"
                    error={jobDescError}
                    selectedFile={jobDescDoc}
                    setSelectedFile={setJobDescDoc}
                    acceptTypes={`pdf, docx, doc. Size Limit 5mb`}
                    pdf={true}
                    docType={docType}
                    initialArrayValue={languages}
                    setArrayValue={setLanguages}
                    currentIndex={i}
                    className="mb-0"
                  />
                  {i > 0 && (
                    <div
                      className="span-btn"
                      onClick={() => {
                        deleteItems(lang.id, "languages");
                      }}>
                      <BsTrash className="delete-icon" fontSize={16} />
                    </div>
                  )}
                </div>
              ))}

              <MyButton
                className="add-more mb-4"
                type="button"
                onClick={() => {
                  addMore("languages");
                }}
                full
                green>
                {" "}
                <span className="plus">+</span> Add Another Language
              </MyButton>
            </div>
            <FormTextAreaComponent
              label="Any Additional Information"
              type="text"
              name="additionalComment"
              placeholder="Type here..."
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.comment}
              error={
                formik.touched.comment && formik.errors.comment
                  ? formik.errors.comment
                  : null
              }
              className="w-100 pt-3"
            />

            <MyButton className="form-btn mb-5" type={"submit"} full>
              {" "}
              {loading ? (
                <ClipLoader color={"#fff"} size={"30px"} />
              ) : (
                "Request Engineers"
              )}
            </MyButton>
          </form>
        )}
        {success && (
          <div className="form-success card">
            <img
              src="/images/sucess-icon.svg"
              alt="green-tick"
              className="sucess-icon"
            />
            <h2 className="form-font green-text">Success</h2>
            <p className="form-font success-msg">
              You've successfully Requested for Engineers.
            </p>
            <MyButton className="form-btn success-btn" onClick={closeForm}>
              View Request List
            </MyButton>
          </div>
        )}
      </div>
    </StyledCreatePackageForm>
  );
};

const StyledCreatePackageForm = styled.div`
  width: 700px;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);
  .form-btn {
    width: 100%;
    margin-top: 3.2rem;
  }
  .form-section {
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3.2rem;
  }
  input .assig-date {
    width: 100% !important;
  }
  .css-yk16xz-control {
    border-radius: 0;
    border: 1px solid rgba(33, 51, 79, 0.15) !important;
    margin: 0.5rem 0 2.4rem;
    height: 48px;
    font-size: 16px;
    color: green;
  }
  .css-1pahdxg-control {
    border: 1px solid rgba(33, 51, 79, 0.15) !important;
    box-shadow: none;
    height: 48px;
    margin: 0.5rem 0 2.4rem;
  }
  .css-1pahdxg-control:hover {
    border: 1px solid rgba(33, 51, 79, 0.15);
    border-radius: 0;
    /* box-shadow: none; */
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-tlfecz-indicatorContainer {
    padding-right: 16px;
  }
  .css-tj5bde-Svg {
    color: #21334f;
    width: 15px;
  }
  .select-work {
    color: rgba(33, 51, 79, 0.8);
    font-size: 16px;
    font-weight: 400;
    /* line-height: 20px; */
  }
  .css-g1d714-ValueContainer {
    font-size: 16px;
  }
  .assig-label {
    color: black;
    font-weight: 600;
    font-size: 16px;
    /* line-height: 20px; */
  }
  .add-client-btn {
    margin-bottom: 23.8rem;
  }
  .client-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .add-more:hover {
    color: #fff;
    text-decoration: none;
  }

  .plus {
    font-size: 26px;
  }

  .span-btn {
    margin-top: 16px;
    margin-bottom: 16px;
    cursor: pointer;
  }

  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0rem 1rem 1.7rem 1rem;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-top: 1.7rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2.rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 100% !important;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
    margin: 0 auto;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
  .assig-select::placeholder {
    color: rgba(33, 51, 79, 0.8) !important;
  }
`;

export default RequestEngForm;
