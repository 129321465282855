import React, { useState } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import MyButton from '../../landingPage/MyButton';
import FormTextAreaComponent from "./enterpriseFormInputs/FormTextAreaComponent";
import FormTitleSection from './FormTitleSection';
// import { toast } from "react-toastify";
import { apiPost } from "../../../utils/apiHelpers";
import { ClipLoader } from 'react-spinners';


const initialValues = {
    comment: "",

};


const validationSchema = Yup.object({
    comment: Yup.string().required('*Required'),

})


const CreateComment = ({ onClick, handleOpenComment, assignmentId, reload, id }) => {

    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false)

    const onSubmit = (values) => {

        createComment(values);
    };

    //create comment
    const createComment = async (values) => {

        setLoading(true);
        //make call to the api
        try {
            let response = await apiPost(`/assignments/${assignmentId}/comments`, values, {}, true);
            if (response.error) {
                throw response.data.response.status === 500 ? new Error("") : response.data;
            }
            setSuccess(true);
            setLoading(false);
            reload();

        } catch (err) {
            console.log({ err })
            setLoading(false);
        }
    };

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    })

    const closeForm = () => {
        formik.resetForm()
        onClick();
        setSuccess(false);
    }

    return (
        <StyledCreatePackageForm success={success}>
            <FormTitleSection
                title="Create Comment"
                onClick={closeForm}
            />
            <div className="form-section">
                <form onSubmit={formik.handleSubmit} className="client-form">
                    <p className="form-font form-title">Comment Details</p>

                    <FormTextAreaComponent
                        label="Comment"
                        type="text"
                        name="comment"
                        placeholder="Enter comment"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.comment}
                        error={
                            formik.touched.comment && formik.errors.comment
                                ? formik.errors.comment
                                : null
                        }
                    />

                    <MyButton className="form-btn add-client-btn" type={"submit"} full>
                        {" "}
        {loading ? <ClipLoader color={"#fff"} size={"30px"}/> : "Add New Comment"}
            
        </MyButton>
        </form>
        <div className="form-success card p-3">
        <img
            src="/images/sucess-icon.svg"
            alt="green-tick"
            className="sucess-icon"
        />
        <h2 className="form-font green-text">Success</h2>
        <p className="form-font success-msg">
            You successfully created an comment.
        </p>
        <MyButton className="form-btn success-btn" onClick={closeForm}>
            Okay, Go back to engineer Page
        </MyButton>
        </div>
    </div>
    </StyledCreatePackageForm>
    )
}

const StyledCreatePackageForm = styled.div`
    width: 100%;
    height: 100vh;
    z-index: 10;
    overflow-y: scroll;
    background: white;
    border: 1px solid rgba(33, 51, 79, 0.15);
    .form-section{
        padding: 4rem 4rem 0 4rem;
        overflow-y: scroll;
    }
    .form-title{
        font-size: 2rem;
        line-height: 2.5rem;
        margin-bottom: 3.2rem;
    }
    .add-client-btn{
        margin-bottom: 23.8rem;
    }
    .client-form{
        display: ${({ success }) => (success ? "none" : "block")};
        
    }
    .form-success{
        display: ${({ success }) => (success ? "flex" : "none")};
        width: 100%;
        flex-direction: column;
        align-items: center;

    }
    .sucess-icon{
        width: 4.2rem;
        margin-bottom: 1.7rem;
    }
    .green-text{
        font-size: 2.4rem;
        margin-bottom: 2.2.rem;
    }
    .success-msg{
        width: 25.9rem;
        font-size: 1.6rem;
        line-height: 2.4rem;
        text-align: center;
        margin-bottom: 3.8rem;
    }
    .success-btn{
        width: 100% !important;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 3.2rem;
    }
    .success-btn:hover,
    .success-btn:focus{
        background: #34A853;
        color: #ffffff;
        text-decoration: none;
        cursor: pointer;
    }

`;

export default CreateComment
