// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import MyButton from "../../landingPage/MyButton";
import FormTitleSection from "./FormTitleSection";
import { ClipLoader } from "react-spinners";
import {
  useCreateFramework,
  useEditFramework,
} from "../../../services/framework/query";
import FormInputComponent from "./enterpriseFormInputs/FormInputComponent";

const validationSchema = Yup.object({
  name: Yup.string().required("*Required"),
});

const AddNewFramework = ({
  closeModal,
  reload,
  isEditing,
  frameworkId,
  frameworkName,
}) => {
  const [success, setSuccess] = useState(false);

  const { mutate: createFramework, isLoading: createLoading } =
    useCreateFramework();
    
  const { mutate: updateFramework, isLoading: editLoading } =
    useEditFramework();

  const initialValues = {
    name: "",
  };

  useEffect(() => {
    if (isEditing) {
      const fields = ["name"];
      fields.forEach((field) =>
        formik.setFieldValue(field, frameworkName, false)
      );
    }
    // eslint-disable-next-line
  }, [isEditing]);

  const onSubmit = async (e) => {
    let payload = {
      name: formik.values.name,
    };
    e.preventDefault();
    try {
      if (!isEditing) {
        createFramework(payload, {
          onSuccess: () => {
            reload();
            setSuccess(true);
          },
          onError: (error) => {
            setSuccess(false);
          },
        });
      }
      if (isEditing) {
        updateFramework(
          { frameworkId, payload },
          {
            onSuccess: () => {
              reload();
              setSuccess(true);
            },
            onError: (error) => {
              console.log("err", error.message);
              setSuccess(false);
            },
          }
        );
      }
    } catch (err) {
      console.log({ err });
      return err;
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
  });

  return (
    <StyledAddFramework success={success}>
      <FormTitleSection
        title={!isEditing ? "Add New Framework" : "Update Framework"}
        onClick={closeModal}
      />
      <div className="form-section">
        <form onSubmit={formik.handleSubmit} className="framework-form">
          <FormInputComponent
            label="Name"
            type="text"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={
              formik.touched.name && formik.errors.name
                ? formik.errors.name
                : null
            }
            required={true}
          />

          <MyButton
            className="form-btn add-client-btn"
            type="submit"
            full
            onClick={onSubmit}
          >
            {createLoading || editLoading ? (
              <ClipLoader color={"#fff"} size={"30px"} />
            ) : !isEditing ? (
              "Add Framework"
            ) : (
              "Update Framework"
            )}
          </MyButton>
        </form>
        <div className="form-success">
          <img
            src="/images/sucess-icon.svg"
            alt="green-tick"
            className="sucess-icon"
          />
          <h2 className="form-font green-text">Success</h2>
          <p className="form-font success-msg">
            {!isEditing
              ? "You successfully added a Framework."
              : "You successfully Updated a Framework."}
          </p>
          <MyButton className="form-btn success-btn" onClick={closeModal}>
            Okay, go to Framework List
          </MyButton>
        </div>
      </div>
    </StyledAddFramework>
  );
};

const StyledAddFramework = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);
  .underline {
    display: block;
  }
  .form-section {
    width: 500px;
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3.2rem;
  }
  .add-client-btn {
    margin-bottom: 3rem;
  }
  .stack-section {
    display: flex;
    flex-wrap: wrap;
    width: 32rem;
  }
  .framework-form {
    display: ${({ success }) => (success ? "none" : "block")};
  }
  .form-success {
    display: ${({ success }) => (success ? "flex" : "none")};
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .downld-link {
    margin-bottom: 2rem;
    font-size: 16px;
    color: blue;
  }
  .downld-link:hover {
    margin-bottom: 2rem;
    font-size: 16px;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 32rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
`;

export default AddNewFramework;
