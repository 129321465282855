import {
  createEngineer,
  downloadProfiles,
  fetchEngineers,
  fetchAssessment,
  fetchEngineerTrainingData,
  fetchEngineerGraphTrainingData,
  editAssessment,
  fetchMailingList,
  fetchUsersList,
  addUserToMailList,
  sendUserMail,
  fetchPersonalEngineerGraphTrainingData,
  fetchPersonalEngineerTrainingData,
  forgotPassword,
  resetPassword,
  removeUserFromMailList,
  viewIncidentsReport,
  createIncidentReport,
  toggleEngineersVisibility,
  toggleAllEngineersVisibility,
  fetchEngineerNotifications,
  fetchSingleEngineerNotifications,
  deleteEngineerNotification,
  fetchTopEngineers,
  unplaceEngineer,
} from "./api";
import { useQuery, useMutation, useQueryClient, useQueries } from "react-query";

export const useFetchedEngineers = (filterOptions, reset) => {
  const query = useQuery([filterOptions, "engineers"], fetchEngineers, {
    onSuccess: () => reset(),
    keepPreviousData: true,
  });

  return query;
};

export const useFetchedTopEngineers = () => {
  const query = useQuery(["top-engineers"], fetchTopEngineers);

  return query;
};

export const useCreateEngineer = (config, onCreateSuccess) => {
  const mutation = useMutation((params) => createEngineer(params, config), {
    onSuccess: () => onCreateSuccess(),
  });

  return mutation;
};

export const useAddUsers = (config, onAddSuccess) => {
  const queryClient = useQueryClient();
  const mutation = useMutation((params) => addUserToMailList(params, config), {
    onSuccess: () => {
      queryClient.invalidateQueries("mailingList");
      onAddSuccess();
    },
  });

  return mutation;
};

export const useRemoveUsers = (config, onRemoveSuccess, refetch, modules) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (params) => removeUserFromMailList(params, config, modules),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("mailingList");
        onRemoveSuccess();
        refetch();
      },
    }
  );

  return mutation;
};

export const useSendNotification = (config, reset) => {
  const mutation = useMutation((params) => sendUserMail(params, config), {
    onSuccess: () => {
      reset();
    },
  });

  return mutation;
};

export const useForgotPassword = (config) => {
  const mutation = useMutation((params) => forgotPassword(params, config), {
    onSuccess: () => {},
    onError: () => {},
  });
  return mutation;
};

export const useResetPassword = (config) => {
  const mutation = useMutation((params) => resetPassword(params, config), {
    onSuccess: () => {},
    onError: () => {},
  });
  return mutation;
};

export const useDownloadProfiles = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    "download-profiles",
    downloadProfiles
  );
  return { data, isLoading, refetch };
};

export const useFetchEngineerTrainingData = (paramsOptions, dependentId) => {
  const query = useQuery(
    [paramsOptions, "engineers"],
    fetchEngineerTrainingData,
    {
      enabled: !!dependentId,
    }
  );

  return query;
};

export const useFetchEngineerGraphTrainingData = (paramsId) => {
  const { data, isLoading, refetch } = useQuery(
    [paramsId, "engineers-graph-training-data"],
    () => fetchEngineerGraphTrainingData(paramsId)
  );

  return { data, isLoading, refetch };
};

export const useFetchedAssessment = (filterOptions, dependant) => {
  const query = useQuery([filterOptions, "assessments"], fetchAssessment, {
    enabled: !!dependant,
    cacheTime: 0,
  });

  return query;
};

export const useEditAssessment = () => {
  const mutation = useMutation(
    (params, formData) => editAssessment(params, formData),
    {
      onSuccess: () => {},
      onError: () => {},
    }
  );
  return mutation;
};

export const useFetchedMailingList = (params) => {
  const query = useQuery([params, "mailingList"], fetchMailingList);

  return query;
};

export const useFetchedUsers = () => {
  const query = useQuery("usersList", fetchUsersList);

  return query;
};

// CLIENT SIDE

export const useFetchPersonalEngineerTrainingData = (
  paramsOptions,
  dependentId
) => {
  const query = useQuery(
    [paramsOptions, "engineers"],
    fetchPersonalEngineerTrainingData,
    {
      enabled: !!dependentId,
    }
  );

  return query;
};

export const useFetchPersonalEngineerGraphTrainingData = () => {
  const { data, isLoading, refetch } = useQuery(
    "client-engineers-graph-training-data",
    fetchPersonalEngineerGraphTrainingData
  );

  return { data, isLoading, refetch };
};

export const useViewIncidentsReport = (params, dependant) => {
  const query = useQuery(
    [params, "view-incident-reports"],
    viewIncidentsReport,
    {
      enabled: dependant ? true : false,
    }
  );

  return query;
};

export const useCreateIncidentReport = (config, onCreateSuccess) => {
  const mutation = useMutation(
    (params) => createIncidentReport(params, config),
    {
      onSuccess: () => onCreateSuccess(),
    }
  );

  return mutation;
};

export const useToggleVisibility = (onCreateSuccess) => {
  const mutation = useMutation((params) => toggleEngineersVisibility(params), {
    onSuccess: () => onCreateSuccess(),
  });

  return mutation;
};

export const useToggleAllVisibility = (filterOptions, onCreateSuccess) => {
  const mutation = useMutation(
    () => toggleAllEngineersVisibility(filterOptions),
    {
      onSuccess: () => onCreateSuccess(),
    }
  );

  return mutation;
};

export const useUplaceEngineer = (onCreateSuccess) => {
  const mutation = useMutation((params) => unplaceEngineer(params), {
    onSuccess: () => onCreateSuccess(),
  });

  return mutation;
};

//NOTIFICATIONS

export const useEngineerNotifications = () => {
  const { data, isLoading, refetch } = useQuery(
    ["get-all-notifications"],
    fetchEngineerNotifications,
    {
      cacheTime: 0,
    }
  );

  return { data, isLoading, refetch };
};

export const useEngineerReadNotifications = (notificationIds, time) => {
  const queryClient = useQueryClient();

  const results = useQueries(
    notificationIds?.map((notificationId) => {
      return {
        queryKey: ["read-notification", notificationId],
        queryFn: () => fetchSingleEngineerNotifications(notificationId),
        onSettled: () => {
          queryClient.invalidateQueries({
            queryKey: ["get-all-notifications"],
          });
        },
        enabled: time,
      };
    }) ?? []
  );

  return results;
};

export const useDeleteSingleNotification = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation((params) => deleteEngineerNotification(params), {
    onSuccess: () => {
      queryClient.invalidateQueries("get-all-notifications");
    },
  });

  return mutation;
};
