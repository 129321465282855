import {
  fetchTrainingData,
  fetchSingleTrainingData,
  createTrainingData,
  editTrainingData,
  deleteTrainingData,
} from "./api";
import { useQuery, useMutation } from "react-query";

export const useTrainingData = () => {
  const { data, isLoading, refetch } = useQuery(
    "training-data",
    fetchTrainingData
  );

  return { data, isLoading, refetch };
};

export const useSingleTrainingData = (trainingId) => {
  const { data, isLoading, refetch } = useQuery(
    ["single-training-data", trainingId],
    () => fetchSingleTrainingData(trainingId)
  );

  return { data, isLoading, refetch };
};

export const useCreateTrainingData = (config) => {
  const mutation = useMutation((params) => createTrainingData(params, config), {
    // onSuccess: () => onCreateSuccess(),
  });

  return mutation;
};

export const useEditTrainingData = (config) => {
  const mutation = useMutation(
    (params, formData) => editTrainingData(params, formData, config),
    {
      // onSuccess: () => {
      //   console.log("blue");
      // },
      onError: () => {
        console.log("red");
      },
    }
  );
  return mutation;
};

export const useDeleteTrainingData = () => {
  const mutation = useMutation("delete-training-data", deleteTrainingData, {});

  return mutation;
};
