import { useEffect } from "react";
import styled from "styled-components";
// import { apiGet } from "../../../utils/apiHelpers";
// import { CircleLoader } from "react-spinners";
import { useAdmin } from "../../../context/AdminState";
import Select from "react-select";

const CompanyDropdown = ({
  handleClick,
  orgName,
  orgId,
  setOrgName,
  setOrgId,
  setOrganisationDetails
}) => {
  const { organisationsList, fetchAssignmentInfo } = useAdmin();

  useEffect(() => {
    fetchAssignmentInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = organisationsList.map(({ id, name, contactPerson, email, telephoneNumber, role }) => ({
    label: `${name || "No Name"} - ${contactPerson || "No Contact Person"}`,
    value: id,
    contactPerson,
    email,
    name,
    telephoneNumber,
    role
  }));
  options.unshift({ label: "Activity Overview", value: "" });

  const customStyles = {
    indicatorSeparator: (styles) => ({ display: "none" }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#21334f",
      "&:hover": {
        color: "#21334f",
      },
    }),
    placeholder: (defaultStyles, isFocused) => {
      return {
        ...defaultStyles,
        color: "rgba(33,51,79,0.8)",
        fontSize: "21px",
        fontWeight: "700",
      };
    },
    control: (base, state, isFocused) => ({
      ...base,
      border: state.isFocused ? "1px solid #34A853" : "1px solid transparent",
      boxShadow: "none",
      backgroundColor: "transparent",
      "&:hover": {
        border: "1px solid #34A853",
        cursor: "pointer",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? " #34A853" : "#fff",
        color: isFocused ? "#fff" : "#263238",
        fontSize: "1rem",
        cursor: isDisabled ? "not-allowed" : "pointer",
        padding: "5px",
      };
    },
  };

  const handleChange = (e) => {
    setOrganisationDetails(e)
    setOrgName(e.label);
    setOrgId(e.value);
  };

  return (
    <Wrapper>
      <Select
        className="my-select"
        options={options}
        styles={customStyles}
        placeholder={orgName}
        onChange={(e) => handleChange(e)}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  position: relative;
  box-sizing: border-box;

  & .my-select {
    font-size: 2rem;
    font-weight: 700;
    border: none;
    outline: none;
    color: #21334f;
    width: 250px;
  }

  & .opener {
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    & div {
      display: flex;
      gap: 0.8rem;
      align-items: center;
      cursor: pointer;
    }
  }

  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #21334f;
  }

  @media only screen and (max-width: 767px) {
    h3 {
      font-size: 20px;
    }
  }
`;

export default CompanyDropdown;
