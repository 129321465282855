import styled from "styled-components";

export const FilterFields = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) =>
  columns ? `repeat(${columns}, 1fr)`
    : "repeat(4, 1fr)" };
  align-items: center;
  gap: 10px;
  width: 100%;

  .search-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    background: #ffffff;
    position: relative;
    border: 1px #34a853 solid;
    border-radius: 0.5rem;
    padding-left: 8px;
  }

  .search-wrap input {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .input-search {
    padding: 0 0.25rem;
  }
  .select-wrap {
    margin-left: 0;
    position: relative;
  }
  .select-wrap-expansion {
    color: #34a853;
    cursor: pointer;
    font-size: 16px;
  }

  .clear-filters {
    margin-left: 1rem;
    font-size: 12px;
    cursor: pointer;
  }

  // .engineer-filters-wrapper > *{
  //   width: 100%;
  // }

  .engineer-filters > .select-wrap {
    display: inline-block;
  }

  .engineer-filters {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
  }

  .css-g1d714-ValueContainer {
    padding: 0;
    padding-left: 8px;
  }

  .css-1hwfws3 {
    padding: 0;
    padding-left: 8px;
  }

  .css-1g6gooi {
    margin: 0;
    padding: 0;
  }

  .css-b8ldur-Input {
    margin: 0;
    padding: 0;
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-yk16xz-control {
    border: none;
    height: 5rem;
  }
  .css-1pahdxg-control {
    border: none;
    height: 5rem;
    box-shadow: none;
  }
  .css-1pahdxg-control:hover {
    border: 1px #34a853 solid !important;
  }

  input {
    padding-left: 1.6rem;
  }
  input,
  .my-select {
    height: 5rem;
    font-size: 1.6rem;
    border: none;
    outline: none;
    color: #21334f;
    width: 100%;
  }

  .my-select {
    padding-bottom: 1rem;
  }

  .custom-select:focus {
    border: none;
  }
  input:focus {
    border: none;
  }

  .input-spinner-select {
    position: absolute;
    right: calc(0% + 15px);
  }
  .input-spinner-filter {
    position: absolute;
    right: calc(0% + 40px);
    top: 45%;
  }

  input::placeholder {
    font-size: 16px;
    line-height: 20px;
    color: rgba(33, 51, 79, 0.6);
  }

  .age {
    width: 100%;
    color: hsl(0, 0%, 50%);
    background-color: white;
    position: relative;
    padding: 0 8px;
  }
  .placeholder-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    height: 5rem;
  }
  .age-container {
    position: absolute;
    top: 50px;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    align-items: center;
  }
  .age-input {
    height: 2.5rem;
    width: 100%;
    border: 1px solid #ccc;
    outline: 1px solid #ccc;
    margin-top: 1rem;
  }
`;
