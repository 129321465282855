import { Combobox } from "@headlessui/react";
import styled from "styled-components";

export const ComboboxComponent = ({
  name,
  dynamicName,
  onChange,
  onSelect,
  inputValue,
  placeholder,
  options,
  required = false,
  error,
  inputRef,
  disabled,
}) => {
  return (
    <StyledCombobox>
      <Combobox onChange={onChange} name={dynamicName}>
        <div className="input-field">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M13.1629 13.1759L17.5 17.5M15 8.75C15 12.2018 12.2018 15 8.75 15C5.29822 15 2.5 12.2018 2.5 8.75C2.5 5.29822 5.29822 2.5 8.75 2.5C12.2018 2.5 15 5.29822 15 8.75Z"
              stroke="#5C6881"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <Combobox.Input
            //displayValue={(option) => option?.firstName}
            placeholder={placeholder || "John Doe"}
            onChange={onChange}
            ref={inputRef}
            name={name}
            autoComplete="false"
            required={required}
            readOnly={disabled}
          />
        </div>
        {error && <div className="text-red-800 text-xs">{error}</div>}
        {!disabled && (
          <Combobox.Options className="options-dropdown absolute mt-1 w-full overflow-auto rounded-md bg-white py-1 shadow-lg">
            {
              options?.map((option, i) => (
                <Combobox.Option
                  onClick={() => onSelect(option)}
                  key={i + option.firstName + option.lastName + option.squad}
                  className={({ active, selected }) =>
                    `relative cursor-pointer py-2 pl-4 pr-4 ${
                      active ? "option-active text-white" : "text-gray-900"
                    }`
                  }
                  value={option}
                >
                  {({ selected, active }) => (
                    <>
                      <span>
                        {option.firstName + " " + option.lastName} (Squad{" "}
                        {option.squad})
                      </span>
                    </>
                  )}
                </Combobox.Option>
              ))
              // )
            }
          </Combobox.Options>
        )}
      </Combobox>
    </StyledCombobox>
  );
};

const StyledCombobox = styled.div`
  position: relative;

  .options-dropdown {
    max-height: 25rem;
  }

  .option-active {
    background: #3cab52;
    cursor: pointer;
  }

  .input-field {
    border-radius: 4px;
    border: 1px solid #d0d5dd;
    background: #f9fafb;
    width: 100%;
    padding: 0 16px;
    color: #5c6881;
    display: flex;
    align-items: center;
  }

  .input-field input {
    background: transparent;
    padding: 12px 0;
    margin-left: 8px;
    width: 100%;
  }
`;
